import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useStyles } from "./processResults.styles";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import { register, updateDepression } from "src/redux/content/test/thunks";
import { questions } from "./dataDepression";
import { sumWeights } from "src/helpers/utils";
import Loading from "src/components/common/Loading";
import { Grid, Button } from "@material-ui/core";

const ProcessResult = () => {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const { contentTest, auth } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    if (contentTest.step === 3) {
      handlerSubmit();
    }
  }, [contentTest.step]);

  const handlerSubmit = async () => {
    try {
      const data = {
        user_id: auth.id,
        ct_type_test_id: contentTest.ct_type_test_id,
        answer_1: contentTest.answers[0],
        weight_1: questions[0].weights[contentTest.answers[0]],
        answer_2: contentTest.answers[1],
        weight_2: questions[1].weights[contentTest.answers[1]],
        answer_3: contentTest.answers[2],
        weight_3: questions[2].weights[contentTest.answers[2]],
        answer_4: contentTest.answers[3],
        weight_4: questions[3].weights[contentTest.answers[3]],
        answer_5: contentTest.answers[4],
        weight_5: questions[4].weights[contentTest.answers[4]],
        answer_6: contentTest.answers[5],
        weight_6: questions[5].weights[contentTest.answers[5]],
        answer_7: contentTest.answers[6],
        weight_7: questions[6].weights[contentTest.answers[6]],
        answer_8: contentTest.answers[7],
        weight_8: questions[7].weights[contentTest.answers[7]],
        answer_9: contentTest.answers[8],
        weight_9: questions[8].weights[contentTest.answers[8]],
      };

      const weights: Record<string, number> = {
        "1": questions[0].weights[contentTest.answers[0]],
        "2": questions[1].weights[contentTest.answers[1]],
        "3": questions[2].weights[contentTest.answers[2]],
        "4": questions[3].weights[contentTest.answers[3]],
        "5": questions[4].weights[contentTest.answers[4]],
        "6": questions[5].weights[contentTest.answers[5]],
        "7": questions[6].weights[contentTest.answers[6]],
        "8": questions[7].weights[contentTest.answers[7]],
        "9": questions[8].weights[contentTest.answers[8]],
      };

      const totalWeight = sumWeights(weights, 1, 10);
      let nivelDepression = 1; //No hay señales de depresión
      let nivelDepressionText = "No hay señales de depresión"; //No hay señales de depresión
      if (totalWeight >= 5 && totalWeight < 10) {
        nivelDepression = 2; //Leve
        nivelDepressionText = "Leve";
      }
      if (totalWeight >= 10 && totalWeight < 15) {
        nivelDepression = 3; //Moderada
        nivelDepressionText = "Moderada";
      }
      if (totalWeight >= 15 && totalWeight < 20) {
        nivelDepression = 4; //Moderada - severa
        nivelDepressionText = "Moderada - severa";
      }
      if (totalWeight >= 20) {
        nivelDepression = 5; //Severa
        nivelDepressionText = "Severa";
      }
      if ((weights[6] = 1)) {
        nivelDepression = nivelDepression + 5; //Severa
      }

      await dispatch(register({ ...data }))
        .unwrap()
        .then((res: any) => {
          const newData = {
            nivel_depression: nivelDepression,
            nivel_depression_text: nivelDepressionText,
          };
          dispatch(updateDepression({ ...newData }))
            .unwrap()
            .then(() => {
              dispatch(updateStep(4));
            });
        });
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          src="./images/OrentaAll.png"
          alt=""
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            "Estamos calculando tus <br /> resultados, ¡Un momento!"
          </p>
        </div>
        <div className={classes.content}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Loading />
          </Grid>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.information}>
          <p className={classes.information1}>"La salud mental es salud"</p>
        </div>
        <div className={classes.buttons}>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            //onClick={}
            disabled={true}
          >
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ProcessResult;
