import { createSlice } from '@reduxjs/toolkit';
import { getAllCompanies, getProblems,getCatalogRolsAreas,getCatalogCompanies,getCompanyByUserId, getCompany } from './thunks';

// Initial state for Redux store:
export const initialStateCompanies = {
  companies: {
    total: 0,
    data: [],
    paginate: {
      current_page: 1,
      items: 0,
      pages: 1,
    },
    success: false,
  },
  company:{},
  problems: [],
  loading: false,
  catalog_rols_areas:{
    rols:[],
    areas:[]
  }
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialStateCompanies, // Define initial state
  reducers: {
    setCompanies: (state: any, action: any) => {
      state.companies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.loading = false;
    });
    builder.addCase(getProblems.fulfilled, (state, action) => {
      state.problems = action.payload;
    });
    builder.addCase(getCatalogRolsAreas.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCatalogRolsAreas.fulfilled, (state, action) => {
      state.catalog_rols_areas = action.payload;
      state.loading = false;
    });
    builder.addCase(getCatalogRolsAreas.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCatalogCompanies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCatalogCompanies.fulfilled, (state, action) => {
      state.companies.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getCatalogCompanies.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCompanyByUserId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyByUserId.fulfilled, (state, action) => {
      state.company = action.payload;
      state.loading = false;
    });
    builder.addCase(getCompanyByUserId.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Export actions generated by "createSlice()":
export const { setCompanies } = companiesSlice.actions;

// Export reducer generated by "createSlice()":
export default companiesSlice.reducer;
