import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    margin: '20px 0 30px',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },
  select: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  field: {
    width: '300px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  button: {
    height: '40px',
  },
}));
