import { QuestionInterface } from "../modelo";

export const questions: QuestionInterface[] = [
  {
    id: 1,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Ha tenido poco interés o placer en hacer cosas",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 2,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Se ha sentido decaído(a), deprimidoa(a) o sin esperanzas",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 3,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Ha tenido dificultad para quedarse o permanecer dormido(a), o ha dormido demasiado",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 4,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Se ha sentido cansado(a) o con poca energía",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 5,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Ha tenido poco apetito o comido en exceso",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 6,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Se ha sentido mal con usted mismo(a) - o que es un fracaso o que ha quedado mal con usted mismo(a) o con su familia",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 7,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Ha tenido dificultad para concentrarse en ciertas actividades, tales como leer el periódico o ver la televisión",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 8,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "¿Se ha movido o hablado tan lento que otras personas podrían haberlo notado? o lo contrario – muy inquieto(a) o agitado(a) que ha estado moviéndose mucho más de lo normal",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  {
    id: 9,
    text1: "Durante las últimas 2 semanas, ¿qué tan seguido has tenido molestias debido a los siguientes problemas?",
    text2: "Ha tenido pensamientos de que estaría mejor muerto(a) o de lastimarse de alguna manera",
    options: ["Ningún día", "Varios días", "Más de la mitad de los días", "Casi todos lo días"],
    weights: [0,1,2,3]
  },
  
];