import React, { useState } from 'react';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { Category, ContentCategories,CategoryText } from './CategoriesResults.styles';
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';

interface Props {
  items: string[];
  clickedItems: boolean[] // Arreglo de textos para los elementos
  onItemClick: (index: number) => void; // Función de devolución de llamada para el clic en un elemento
}

const MyComponent: React.FC<Props> = ({ items, onItemClick,clickedItems }) => {
  //const classes = useStyles(); // Inicializa los estilos
  const { contentTest } = useAppSelector((state: RootState) => state);

  return (
    <ContentCategories>
      <GridContainer lg={12} p-lg={'0px'} p-sm={'0px'}>
            {items.map((text, index) => (
                <GridItem key={`grid_cat_${index}`} lg={6} md={4} sm={2} onClick={() => onItemClick(index)}>
                  <Category key={`cat_${index}`} className={clickedItems[index]? "seleccionado":"noSeleccionado"}>
                  </Category>
                  <CategoryText>{text}</CategoryText>
                </GridItem>
            ))}
          
      </GridContainer>
    </ContentCategories>
  );
};

export default MyComponent;
