import * as API from "./apis";
import Swal from "sweetalert2";
import { RootState } from "../../domain/models/RootState";
import { createAsyncThunkApp } from "../hooks";

export const getAllUsers = createAsyncThunkApp<any, any>(
  "Users/getAllUsers",
  async ({ filter, pagination }: any, thunkAPI) => {
    try {
      const { auth }: RootState = thunkAPI.getState();
      const res: any = await API.getAllUsers({ filter, pagination });
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hubo un error al cargar los datos",
        text: "intenta de nuevo",
      });
      throw error;
    }
  }
);
export const updateUsers = createAsyncThunkApp(
  "Users/updateUsers",
  async (values: any) => {
    try {
      console.log("thunks", values);
      const res = await API.updateUsers(values);
      Swal.fire({
        icon: "success",
        title: `Editado con exito`,
      });
      return res;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Hubo un error al editar los datos`,
        text: "intenta de nuevo",
      });
      throw error;
    }
  }
);

export const createUser = createAsyncThunkApp(
  "Users/createUser",
  async (user: any, thunkAPI) => {
    try {
      const { auth }: RootState = thunkAPI.getState();
      const res = await API.createUser(user);
      if (res.status === 200) {
        Swal.fire("Correcto", "El usuario se agregó correctamente", "success");
        return true;
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hubo un error al crear el usuario",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const getContentsUser = createAsyncThunkApp(
  "Users/getContentsUser",
  async (id: number) => {
    try {
      const res: any = await API.getContentsUser(id);
      return res;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hubo un error al cargar los datos",
        text: "intenta de nuevo",
        confirmButtonColor: "#57A450",
        cancelButtonColor: "#57A450",
      });
      throw error;
    }
  }
);
