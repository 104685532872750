import { createSlice } from '@reduxjs/toolkit';
import { getAllUsers, getContentsUser } from './thunks';

// Initial state for Redux store:
export const initialStateUsers = {
  users: {
    total: 0,
    data: [],
    paginate: {
      current_page: 1,
      items: 0,
      pages: 1,
    },
    success: false,
  },
  contents:[],
  loading: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialStateUsers, // Define initial state
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
    });
    builder.addCase(getContentsUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContentsUser.fulfilled, (state, action) => {
      state.contents = action.payload;
      state.loading = false;
    });
    builder.addCase(getContentsUser.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Export reducer generated by "createSlice()":
export default usersSlice.reducer;
