import React, { useState, useEffect } from "react";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import {
  Card,
  SectionInformation,
  Tab,
  TabsContainer,
  Title,
} from "./Information.styles";
import { useNavigate } from "react-router-dom";
import Button from "src/components/common/Button";
import { Carousel } from "react-responsive-carousel";

const Information = () => {
  const [activeTab, setActiveTab] = useState(1);
  const tabsTitles = [
    "Tu aliado en tu bienestar organizacional.",
    "Tu espacio de bienestar a tu manera.",
  ];
  const tabs = ["Empresas", "Colaboradores"];
  // const [cardsData,setCardsData]  = useState([
  //   [
  //     { image: "/images/home/employee/Information/DynamicCardN1.png", title: "La falta de sueño promueve el aumento de peso", text: "",active:true,otherCard:1 },
  //     { image: "", title: "Dormir menos de 6 ó 7 horas", text: "Aumenta la hormona que te hace sentir hambre y disminuye la que te hace sentir satisfecho.",active:false,otherCard:0 }
  //   ],
  //   [
  //     { image: "/images/home/employee/Information/DynamicCardN2.png", title: "Octubre 10", text: "Día Internacional de la Salud Mental.",active:true,otherCard:1 },
  //     { image: "", title: "Dormir menos de 6 ó 7 horas", text: "Aumenta la hormona que te hace sentir hambre y disminuye la que te hace sentir satisfecho.",active:false,otherCard:0}
  //   ],
  // ]);
  const [cardsData, setCardsData] = useState([
    [
      {
        image: "/images/Dashboard/Information/webinarJunio.png",
        title: "El nacimiento de una madre",
        text: "7 de Jun 10:00am CDMX - <a href='https://youtube.com/live/TaMz9IJ9Mys?feature=share'>LINK</a>",
        active: true,
        otherCard: 1,
      },
      { image: "", title: "", text: "", active: false, otherCard: 0 },
    ],
    [
      {
        image: "/images/home/employee/Information/DynamicCardN1.png",
        title: "La falta de sueño promueve el aumento de peso",
        text: "",
        active: true,
        otherCard: 1,
      },
      {
        image: "",
        title: "Dormir menos de 6 ó 7 horas",
        text: "Aumenta la hormona que te hace sentir hambre y disminuye la que te hace sentir satisfecho.",
        active: false,
        otherCard: 0,
      },
    ],
  ]);

  const customStyle = {
    boxShadow: "none",
  };

  const navigate = useNavigate();
  const goToRequestDemo = () => {
    navigate(`/request-demo`, { replace: true });
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveTab((prevTab) => (prevTab === 1 ? 2 : 1));
  //   }, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const activeOtherCard = (
    index: number,
    otherCard: number,
    activeTab: number
  ) => {
    // cardsData[activeTab - 1][index].active = false;
    // cardsData[activeTab - 1][cardsData[activeTab - 1][index].otherCard].active = true;

    // setCardsData(cardsData);
    window.open(`/home`, "_self");
  };

  return (
    <SectionInformation>
      <Carousel
        showThumbs={false} // Oculta los botones de navegación
        showStatus={false} // Oculta los indicadores de posición
        infiniteLoop={true} // Activa el bucle infinito
        centerMode={false} // Desactiva el modo centrado
        swipeable={true} // Permite el deslizamiento táctil
        emulateTouch={true} // Emula el toque en dispositivos no táctiles
        dynamicHeight={false} // Desactiva la altura dinámica
        showArrows={false} // Muestra las flechas de navegación
        showIndicators={false}
        interval={8000}
        autoPlay={true}
      >
        {cardsData.map((cards, index) => (
          <GridContainer p-lg={"0px"}>
            {cards.map((card, index) => (
              <>
                <GridItem
                  lg={4}
                  className={card.active ? "show" : "hidden"}
                >
                  <Card
                    key={`carrousel-${index}`}
                    onClick={() =>
                      activeOtherCard(index, card.otherCard, activeTab)
                    }
                    style={{
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                      marginTop: "0",
                      marginBottom: "5px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <>
                      {card.image != "" && (
                        <img
                          src={card.image}
                          alt={card.title}
                        />
                      )}
                      {card.title != "" && <h2>{card.title}</h2>}
                      {card.text != "" && (
                        <div dangerouslySetInnerHTML={{ __html: card.text }} />
                      )}
                    </>
                  </Card>
                </GridItem>
              </>
            ))}
          </GridContainer>
        ))}
      </Carousel>
    </SectionInformation>
  );
};

export default Information;
