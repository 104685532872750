import React, { useEffect } from "react";
import { useStyles } from "./index.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/domain/models/RootState";
import ElementCarousel from "../Carousel/elementCarousel";
import { useNavigate } from "react-router-dom";
import Button from "src/components/common/Button";
import Header from "../Header";
import { saveTracking } from "src/redux/content/thunks";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

function Index() {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const { content, auth } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const goToHome = () => {
    navigate(`/home?view=explora`, { replace: true });
  };

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
      // window.open(`/home`, "_self");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            {content.templates[content.currentOrchestratorIndex].title}
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.animationContainer}>
            <img src="/images/content/success.png" />
          </div>
          <div className={classes.information}>
            <p className={classes.information1}>Avanza al siguiente tema:</p>
          </div>
          <ElementCarousel />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={goToHome}>
            <span>Regresar a la biblioteca</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Index;
