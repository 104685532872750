import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Components
import Form from './Form';

// Actions de Redux
import { createUser } from './../../redux/users/thunks';

const NewUser = () => {
  const navigate = useNavigate();
  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch<any>();

  const initialValues = {
    country: process.env.REACT_APP_ENV_COUNTRY,
    email: '',
    name: '',
    ct_rols_id: null,
    status_users_id: 1,
    ct_users_status_id: 1,
    phone: null,
  };

  // cuando el usuario haga submit
  const submitNewUser = (values: any) => {
    // crear el nuevo housekey
    dispatch(createUser(values))
      .unwrap()
      .then((res: any) => {
        if (res) {
          navigate('/users', { replace: true });
        }
      });
  };

  return (
    <Form
      submitEdit={submitNewUser}
      initialValues={initialValues}
      actionText='GUARDAR'
    />
  );
};

export default NewUser;
