export  const isClientSide = ()=>typeof window !== 'undefined' && typeof document !== 'undefined';

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

export const sendDataLayer = (data: any): void => {
    if (isClientSide()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
}
