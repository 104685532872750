export const benefitsList = [
    {
        title: 'Asesoría legal, médica y nutricional',
        text: '',
        indice:1
    },
    {
        title: 'Reportes de bienestar',
        text: '',
        indice:2
    },
    {
        title: 'Sistema de supervisión clínica de calidad',
        text: '',
        indice:3
    },
    {
        title: 'Atención inmediata y 24/7 por psicólogos certificados',
        text: '',
        indice:4
    },
    {
        title: 'Terapias clínicas y seguimiento ilimitado',
        text: '',
        indice:5
    },
    {
        title: 'Herramientas de evidencia clínica para afrontar retos reales',
        text: '',
        indice:6
    },
    {
        title: 'Accesible y funcional para las necesidades individuales del  100% de tus colaboradores',
        text: '',
        indice:7
    },
];
