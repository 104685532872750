import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    background: '#F9FAFC',
    padding: '32px',
    height: '100%',
  },
  gridArea: {
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 48%)',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: ' repeat(1, 100%)',
    },
  },
}));
