import { DepressionResult } from "../modelo";

export const results: DepressionResult[] = [
  {
    id: 1,
    title: "No hay señales de depresión",
    text1: "No tienes síntomas importantes que podrían indicar una depresión.",
    text2: "En este momento, sólo te recomiendo que sigas trabajando en ti y tu crecimiento personal.",
    text3: "",
    tools1: [],
    text4: "",
    tools2: [],
  },
  {
    id: 2,
    title: "Leve",
    text1: "Tienes algunos síntomas leves que podrían indicar una depresión.",
    text2: "En este momento, es importante que le sigas la pista a la intensidad de estos síntomas.",
    text3: "Te recomiendo que explores estas herramientas digitales:",
    tools1: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"}],
    text4: "Si te sientes listo agenda una sesión con uno de nuestros psicólogos.",
    tools2: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
  },
  {
    id: 3,
    title: "Moderada",
    text1: "Tienes algunos síntomas moderados que podrían indicar una depresión.",
    text2: "En este momento, es importante que trabajes estos síntomas para evitar que se vuelvan más intensos.",
    text3: "Te propongo que consideres marcar a tu línea o agendar una sesión con uno de nuestros psicólogos.",
    tools1: [{"title":"En linea contigo","image":"/images/home/employee/Shortcuts/Enlineacontigo.png","text":"shortcut","link":"/linea-contigo"},{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Te recomiendo que explores estas herramientas digitales:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"}],
  },
  {
    id: 4,
    title: "Moderada - Severa",
    text1: "Tienes síntomas significativos que podrían indicar una depresión.",
    text2: "En este momento, es importante que trabajes estos síntomas con un profesional de la salud mental para evitar se vuelvan más intensos.",
    text3: "Te invito a que agendes una sesión con uno de nuestros psicólgos. ",
    tools1: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Herramientas digitales sugeridas:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"}],
  },
  {
    id: 5,
    title: "Severa",
    text1: "Tienes varios síntomas significativos que podrían indicar una depresión. ¡No los dejes pasar desapercibidos!",
    text2: "En este momento, es crucial que trabajes estos síntomas con un profesional de la salud mental para evitar que continúen o se vuelvan más intensos.",
    text3: "Te invito a que  agendes una sesión con uno de nuestros psicólogos. ",
    tools1: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Herramientas digitales sugeridas:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"}],
  },
  {
    id: 6,
    title: "No hay señales de depresión",
    text1: "No tienes síntomas importantes que podrían indicar una depresión.",
    text2: "En este momento, sólo te recomiendo que sigas trabajando en ti y tu crecimiento personal.",
    text3: "",
    tools1: [],
    text4: "",
    tools2: [],
  },
  {
    id: 7,
    title: "Leve",
    text1: "Tienes algunos síntomas leves que podrían indicar una depresión.",
    text2: "En este momento, es importante que le sigas la pista a la intensidad de estos síntomas.",
    text3: "Te recomiendo que explores estas herramientas digitales:",
    tools1: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"},{"title":"Fortalecer mi autoestima","text":"fortalecer mi autoestima","link":"/home?view=explora"}],
    text4: "Si estas listo agenda una cita con uno de nuestros psicólogos.",
    tools2: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
  },
  {
    id: 8,
    title: "Moderada",
    text1: "Tienes algunos síntomas moderados que podrían indicar una depresión.",
    text2: "En este momento, es importante que trabajes estos síntomas para evitar se vuelvan más intensos.",
    text3: "Te propongo que consideres marcar a tu línea o agendar una sesión con uno de nuestros psicólogos.",
    tools1: [{"title":"En linea contigo","image":"/images/home/employee/Shortcuts/Enlineacontigo.png","text":"shortcut","link":"/linea-contigo"},{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Te recomiendo que explores estas herramientas digitales:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"},{"title":"Fortalecer mi autoestima","text":"fortalecer mi autoestima","link":"/home?view=explora"}],
  },
  {
    id: 9,
    title: "Moderada - Severa",
    text1: "Tienes síntomas significativos que podrían indicar una depresión.",
    text2: "En este momento, es importante que trabajes estos síntomas con un profesional de la salud mental para evitar se vuelvan más intensos.",
    text3: "Te invito a que agendes una sesión con uno de nuestros psicólgos. ",
    tools1: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Herramientas digitales sugeridas:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"},{"title":"Fortalecer mi autoestima","text":"fortalecer mi autoestima","link":"/home?view=explora"}],
  },
  {
    id: 10,
    title: "Severa",
    text1: "Tienes varios síntomas significativos que podrían indicar una depresión. ¡No los dejes pasar desapercibidos!",
    text2: "En este momento, es crucial que trabajes estos síntomas con un profesional de la salud mental para evitar que continúen o se vuelvan más intensos.",
    text3: "Te invito a que  agendes una sesión con uno de nuestros psicólgos. ",
    tools1: [{title: "Agendar sesión","image":"/images/home/employee/Shortcuts/TuTerapia.png","text":"shortcut",'link':"/terapia-invidual"}],
    text4: "Herramientas digitales sugeridas:",
    tools2: [{"title":"Manejar la tristeza","text":"manejar la tristeza","link":"/home?view=explora"},{"title":"Fortalecer mi autoestima","text":"fortalecer mi autoestima","link":"/home?view=explora"}],
  },
  
];