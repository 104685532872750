import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStylesG } from "./index.styles";
import { login } from "../../../redux/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { updateStep } from "../../../redux/auth/slice";
import Button from "src/components/common/Button";

const Login = () => {
  const classes = useStylesG({ colorHeader: "#014951", heightHeader: "210px" });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { token } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   if (token) {
  //     window.location.href = "/home";
  //   }
  // }, [token]);

  useEffect(() => {
    dispatch(updateStep(1));
  }, []);

  const initialValues = {
    user: "",
    password: "",
  };

  const goToCreateAccount = () => {
    navigate(`/create-account`, { replace: true });
  };

  const validationSchema = yup.object({
    user: yup.string().required("Correo electronico obligatorio"),
    password: yup.string().required("Contraseña obligatoria"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => sendLogin({ values }),
  });

  const sendLogin = async ({
    values,
    isGoogleAuth,
    googleCredentialResponse,
  }: any) => {
    setLoading(true);
    try {
      if (isGoogleAuth) {
        await dispatch(
          login({ googleCredentialResponse, isGoogleAuth })
        ).unwrap();
        return;
      }

      const data = {
        email: values.user,
        password: values.password,
      };
      await dispatch(login({ data }))
        .unwrap()
        .then((res: any) => {
          console.log("hi");
          // navigate(`/home`, { replace: true });
        });
      navigate("/home");
    } finally {
      setLoading(false);
    }
  };

  const onAction = () => formik?.handleSubmit();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          className={classes.transparentImage}
          src="/images/Auth/Ingresar/HeroIMG.png"
          alt=""
        />
        <img
          className={classes.logo}
          src="/images/Auth/Ingresar/Logo.svg"
          alt=""
        />
        <div className={classes.titles}>
          <p className={classes.title}>¡Bienvenido a Orenta!</p>
          <p className={classes.subtitle}>
            Transforma tu mente y abraza tu potencial
          </p>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.instructions}>
          <p>Ingresa tus datos para continuar:</p>
        </div>
        <div className={classes.content}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              id="form-user"
              label="Correo Electrónico"
              variant="outlined"
              name="user"
              style={{ backgroundColor: "#ffffff", background: "#ffffff" }}
              onChange={formik.handleChange("user")}
              error={formik.touched.user && Boolean(formik.errors.user)}
              helperText={formik.errors.user as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <img
                        src={"/images/Auth/Ingresar/mail_outline.svg"}
                        alt="email"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="w-full">
              <TextField
                id="form-password"
                label="Contraseña"
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                className="w-full"
                onChange={formik.handleChange("password")}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.errors.password as any}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {/* Usa tus imágenes SVG aquí */}
                        {!showPassword ? (
                          <img
                            src={"/images/Auth/Ingresar/eye-line.svg"}
                            alt="eye-open"
                          />
                        ) : (
                          <img
                            src={"/images/Auth/Ingresar/eye-off-line.svg"}
                            alt="eye-closed"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <div className={"flex justify-end mt-1 " + classes.forgot}>
                <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.footer}>
        {!loading ? (
          <div className={classes.buttons}>
            <Button onClick={onAction}>Ingresar a mi cuenta</Button>
            <Button
              backgroundColor="transparent"
              borderColor="#EDEDED"
              onClick={goToCreateAccount}
            >
              Registrar en Orenta
            </Button>
          </div>
        ) : (
          <Loading height="150px" />
        )}
      </div>
    </div>
  );
};
export default Login;
