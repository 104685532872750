import React from 'react';
//import { getUtms } from 'helpers/utms.helpers';
import { benefitsList } from './Benefits.constants';
import { WrapperBenefits, BenefitsCard, BenefitsButton } from './Benefits.styles';
import useLocalStorage from 'src/hooks/useLocalStorage';
import Icon from 'src/components/common/Icon';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/common/Button';

const Benefits = () => {
    const [profile] = useLocalStorage('profile');
    const site = process.env.NEXT_PUBLIC_REACT_APP_PROPERTIES_SERVICES;

    const navigate = useNavigate();
    const goToRequestDemo = () => {
        navigate(`/request-demo`, { replace: true });
    }

    const handleClickPublicar = () => {
        let url: string;
        const utms = "";//getUtms(window.location + '');
        if (profile) {
            url = `${site}/publicar/abtesting${utms && '?' + utms}`;
        } else {
            url = `${site}/login?origin=/publicar&next=/publicar/abtesting${utms && '?' + utms}`;
        }
        window.location.href = url;
    };

    return (
            <WrapperBenefits>
                <BenefitsCard>
                    <GridContainer p-lg={'0px'}>
                        <GridItem lg={6} md={4} >
                            <h2 className="benefits-title">
                                ¿Por qué elegir Orenta?
                            </h2>
                        </GridItem>
                        <GridItem lg={6} md={4}>     
                            <p className="benefits-paragraph">Orenta es una solución proactiva e integral de bienestar emocional para todos tus colaboradores.</p>
                        </GridItem>
                    </GridContainer>
                    <div className="item-card">
                        <div className="orenta-icon-close item-title">
                            PAEs tradicionales
                        </div>
                        <div className="orenta-icon-circle item-title">   
                            Orenta
                        </div>
                        <div className="item-text item-title item-title-service">Servicios</div>
                    </div>
                    {benefitsList.map(({ title, text , indice}:any) => (
                        <div className="item-card" key={title}>
                            <div className='item-elements'><Icon className="orenta-icon-circle" icon="orenta-icon-circle-check-filled" size={24} /></div>
                            { indice <= 2 ?
                            <div className='item-elements'>
                                <Icon className="orenta-icon-circle" icon="orenta-icon-circle-check-filled" size={24} />
                            </div>
                                :
                            <div className='item-elements'>
                                <Icon className="orenta-icon-close" icon="orenta-icon-close" size={14} />
                            </div>
                            }
                            
                            <div className="item-text item-elements">
                                <p >{title}</p>
                                {/* <p className="item-paragraph">{text}</p> */}
                            </div>
                        </div>
                    ))}
                </BenefitsCard>
                <BenefitsButton>
                    <GridContainer p-lg={'16px'}>
                        <GridItem lg={8} md={4} className={"separator logo-lg"} >
                            <hr className="horizontal-line" />
                        </GridItem>
                        <GridItem lg={4} md={4} sd={4}>     
                            <Button onClick={goToRequestDemo}>
                                Solicita una demo
                            </Button>
                        </GridItem>
                    </GridContainer>
                </BenefitsButton>
            </WrapperBenefits>
    );
};

export default Benefits;
