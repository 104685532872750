import React, { useEffect } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import Introduction from './introduction';
import Question from '../question';
import LoadingExperience from './loadingExperience';
import Categories from './categories';
import UpdateCategories from './updateCategories';
import {questions} from './dataSelfKnowledge';
import { useDispatch } from 'react-redux';
import { setCtTypeTestId } from 'src/redux/content/test/slice';

const Index = () => {
  const dispatch = useDispatch<any>();
  const { contentTest } = useAppSelector((state: RootState) => state);
  
  useEffect(() => {
    dispatch(setCtTypeTestId(1));
  }, []);


  switch(contentTest.step){
    case 1: return (<Introduction />);
    case 2: return (<Question questions={questions}/>);
    case 3: return (<LoadingExperience />);
    case 4: return (<Categories />);
    case 5: return (<UpdateCategories />);
    
  }
  return (
    <Introduction />
  );
};
export default Index;
