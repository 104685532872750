import React from 'react';
import Orchestrator from './orchestrator';
import Template from 'src/components/common/Template';
import Layout from 'src/components/Layout';
import Information from './information';
import Beneficios from './Beneficios/Beneficios';

const Index = () => {

    return (
      <Layout>  
        <Template backgroundColor={'linear-gradient(180deg, #6375B6 0%, #4E9FC0 100%)'}>
          {{
            secondaryChild: <Orchestrator />,
            principalChild: <Information />,
          }}
        </Template>
        <Beneficios />
      </Layout>
    );
  
};
export default Index;
