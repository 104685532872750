import {
  RegisterProspectRequest,
  newRegisterProspectRequest,
} from "../auth/models/RegisterRequest";
import clientAxios from "./../../config/axios";
import clientStrapi from "./../../config/strapi";

export const getAllProspects = ({ filter, pagination }: any) => {
  const filterTmp = filter ?? "";
  const complement = pagination.page
    ? `page=${pagination.page}&items_per_page=${pagination.rowsPerPage}${filterTmp}`
    : `all=1${filterTmp}`;
  const url = `/prospects?country=${process.env.REACT_APP_ENV_COUNTRY}&${complement}`;
  return clientAxios.get(url);
};

export const getProspectByNid = ({ id }: { id: string }) => {
  const complement = `all=1&id=${id}`;
  const url = `/prospects?country=${process.env.REACT_APP_ENV_COUNTRY}&${complement}`;
  return clientAxios.get(url);
};

export const updateProspect = (prospect: RegisterProspectRequest) => {
  const url = `/prospects?country=${process.env.REACT_APP_ENV_COUNTRY}&id=${prospect.id}`;
  return clientAxios.put(url, prospect);
};

export const createProspect = async (data: RegisterProspectRequest) => {
  const url = `/prospects`;
  const result = await clientAxios.post(url, data);
  return result.data;
};

export const createProspectFull = async (data: RegisterProspectRequest) => {
  const url = `/prospects/post_request_demo`;
  const result = await clientAxios.post(url, data);
  return result.data;
};

export const newCreateProspectFull = async (
  data: newRegisterProspectRequest
) => {
  const url = `/prospects`;

  const result = await clientStrapi().post(url, { data });
  return result.data;
};
