// Libraries.
import styled from 'styled-components';


export const ContentSectionFaq = styled.section`
    display: flex;
    justify-content: center; 
`;

export const WrapperSection = styled.section`
    background: ${({ theme }) => theme.color.scarpaGray25};
    padding: 32px 16px;
    margin-bottom: 48px;
    border-radius: 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 64px 91px;
        max-width: 808px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 64px 40px;
        max-width: 808px;
        width: 100%;
        max-width: 808px;
    }
`;

export const Title = styled.h2`
    /* SubTitle */
    font-family: "Antonia H3";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
    color: ${({ theme }) => theme.color.titleSection};
    margin-bottom: 21px;
    background: ${({ theme }) => theme.color.white};
    text-align: center;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        font-size: 32px;
        line-height: 40px;
    }
`;
export const Description = styled.div`
    padding: 0 16px;
    margin-bottom: 48px;
    p {
        font-family: Antonia H3;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px; /* 130% */
        letter-spacing: -1.2px;
        text-align: center;
        color: ${({ theme }) => theme.color.titleSection};
        margin: 0;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 0;
    }
`;
