// Libraries.
import styled from 'styled-components';

interface IAccordion {
    active?: boolean;
}

interface ITitle {
    as?: string;
}

export const WrapperSection = styled.section`
    background: ${({ theme }) => theme.color.white};
    padding: 32px 16px;
    margin-bottom: 48px;
    border-radius: 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 64px 91px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 64px 106px;
    }
`;

export const Title = styled.h2`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.color.scarpaGray800};
    margin-bottom: 21px;
    text-align: center;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        font-size: 32px;
        line-height: 40px;
    }
`;
export const Description = styled.div`
    padding: 0 16px;
    margin-bottom: 48px;
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: ${({ theme }) => theme.color.scarpaGray500};
        margin: 0;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 0;
    }
`;

export const AccordionItem = styled.div<IAccordion>`
    border-bottom: 1px solid ${({ theme }) => theme.color.scarpaGray200};
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 16px;
    margin-bottom: 30px;
    width: 100%;
`;

export const TitleFaq = styled.div<ITitle>`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.scarpaGray800};
    margin: 0;
`;

export const AccordionItemTitle = styled.div<IAccordion>`
    display: grid;
    padding: ${({ active }) => (active ? '12px 16px 0' : '12px 16px')};
    justify-content: space-between;
    cursor: pointer;
    grid-template-columns: auto 24px;
    gap: 16px;
    svg {
        transition: all 0.2s;
        transform: ${({ active }) => (active ? 'rotate(180deg)' : 'none')};
        path {
            fill: ${({ theme }) => theme.color.questions};
            stroke-width: 25px;
           
        }
    }
`;

export const AccordionItemContent = styled.div<IAccordion>`
    display: ${({ active }) => (active ? 'block' : 'none')};
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.scarpaGray500};
    p {
        margin: 0 0 16px;
    }
    ul,
    ol {
        padding-inline-start: 16px;
        margin: 16px 0;
    }
    ul {
        list-style: inherit;
    }
    a {
        color: ${({ theme }) => theme.color.scarpaGray500};
        text-decoration: underline;
    }
`;
