import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';

export const CardTotal = ({
  list,
  title,
  color,
  progress,
  icon: Icon,
}: any) => {
  const classes = useStyles();
  const total = list[0][1];
  const active = list[1][1];
  const percentageCard = (Number(active) / Number(total)) * 100;

  return (
    <Card className={classes.container}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={9}>
            <Typography color='textSecondary' gutterBottom variant='overline'>
              {title}
            </Typography>
            <Typography color='textPrimary' variant='h4'>
              {total}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Avatar className={`${classes.avatar} ${color}`}>
              {Icon && <Icon />}
            </Avatar>
          </Grid>
        </Grid>
        {progress && (
          <Box sx={{ pt: 1 }}>
            <Typography color='textSecondary' gutterBottom variant='overline'>
              {percentageCard.toFixed()}% activos
            </Typography>
            <LinearProgress
              value={percentageCard}
              variant='determinate'
              color={color}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
