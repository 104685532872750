// Libraries.
import styled, { css } from 'styled-components';

export const MainCss = css`   
    width: 100%;
    margin: 0 auto;
    min-width: auto;
    max-width: 100%;
    background-color: ${({ theme }) => theme.color.white};
    @media ${(props) => props.theme.screenSizes.tablet} {
        max-width: calc(100% - 64px);
    }
    @media ${(props) => props.theme.screenSizes.laptop} {
        width: 100%;
        max-width: 1254px;
    }
    @media ${(props) => props.theme.screenSizes.laptopL} {
        max-width: 1328px;
    }
`;

export const StickyHeader = css`
    &.sticky-header {
        padding-top: 56px;
    }
    @media ${(props) => props.theme.screenSizes.tablet} {
        &.sticky-header {
            padding-top: 64px;
        }
    }
    @media ${(props) => props.theme.screenSizes.laptop} {
        &.sticky-header {
            padding-top: 80px;
        }
    }
`

export const FixedHeader = css`
    position: fixed;
    left: 0;
    right: 0;
    top: 56px;
    z-index: ${({theme}) => theme.zindex.header};
    @media ${({theme}) => theme.screenSizes.tablet} {
        top: 64px;
    }
    @media ${({theme}) => theme.screenSizes.laptop} {
        top: 80px;
    }
`

export const WrapperMain = styled.main`
    //padding-top: 50px;
    ${MainCss}
    ${StickyHeader}
    @media ${(props) => props.theme.screenSizes.tablet} {
       // padding-top: 74px;
    }
    @media ${(props) => props.theme.screenSizes.laptop} {
        //padding-top: ${(props) => props.theme.marginTopLG ? props.theme.marginTopLG : '80px'};
    }
`;

export const WrapperMainContainer = styled.div`
    ${MainCss}
`;
