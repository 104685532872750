import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import Basic from "./Basic";
import Informative from "./Informative";
import Fork from "./Fork";
import Success from "./Success";
import Video from "./VideoQuestion/video";
import Perception from "./Perception";
import Introduction from "./Introduction";
import Classify from "./Classify";
import QuizFeedback from "./QuizFeedback";
import SelectionMultiple from "./SelectionMultiple";
import Feedback from "./Feedback";
import { sendDataLayer } from "src/helpers/gtm/sendDataLayer";
import { Navigate, useParams } from "react-router-dom";
import { useEffect } from "react";
//import InformativeComplete from "./InformativeComplete";

const Orchestrator = ({ templates }: any) => {
  const { content } = useAppSelector((state: RootState) => state);
  const { id } = useParams();

  useEffect(() => {
    sendDataLayer({
      event: "content-view",
      step: (content.currentOrchestratorIndex + 1) / content.templates.length,
      content_id: id,
    });
  }, [content.templates[content.currentOrchestratorIndex]]);

  document.title = `Orenta - ${content.title}`;

  if (!content.templates?.[content.currentOrchestratorIndex]?.type_template_id)
    return <Navigate to="/home" />;

  switch (
    content.templates[content.currentOrchestratorIndex].type_template_id
  ) {
    case 1:
      return <Basic />; //Básico
    case 2:
      return <Informative />; //Informativo
    case 3:
      return <Fork />; //Bifurcación
    case 4:
      return <Success />; //Exitoso
    case 5:
      return <Perception />; //Percepción
    case 6:
      return <Classify />; //Clasificación
    case 7:
      return <Video />; //VideoQuestion
    case 8:
      return <QuizFeedback />; //QuizFeedback
    case 9:
      return <SelectionMultiple />; //SelectionMultiple
    case 10:
      return <Feedback />; //SelectionMultiple
    // case 10:
    // return <Question />;
    // case 10: return (<InformativeComplete />);//InformativeComplete
    default:
      return <Introduction />;
  }
};

export default Orchestrator;
