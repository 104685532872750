import React from 'react';
import styled from 'styled-components';

interface SecurityIndicatorProps {
  securityLevel: number;
}
const ContainerProgress = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
`;
const Emoticon = styled.div`
  font-size: 12px;
 
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ccc;
 
`;

const Progress = styled.div<{ width: string,color:string }>`
  height: 100%;
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
`;

const SecurityText = styled.div`
  color: #606060;
  /* H6 Bold */
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 175% */
  letter-spacing: -0.36px;
`;

const SecurityIndicator: React.FC<SecurityIndicatorProps> = ({ securityLevel }) => {

  const getEmoticon = () => {
    switch (securityLevel) {
      case 1:
        return '😐';
      case 2:
        return '😊';
      case 3:
        return '😀';
      case 4:
        return '😁';
      default:
        return '😐';
    }
  };

  const getProgressBarWidth = () => {
    return `${(securityLevel ) * 25}%`;
  };

  const getSecurityText = () => {
    switch (securityLevel) {
      case 1:
        return 'Nivel de seguridad: Bajo';
      case 2:
        return 'Nivel de seguridad: Medio';
      case 3:
        return 'Nivel de seguridad: Alto';
      case 4:
        return 'Nivel de seguridad: Muy Alto';
      default:
        return 'Nivel de seguridad: Bajo';
    }
  };

  const getProgressBarColor = () => {
    switch (securityLevel) {
      case 1:
        return '#014951';
      case 2:
        return '#FCE497';
      case 3:
        return '#6674B2';
      case 4:
        return '#014951';
      default:
        return '#014951';
    }
  };

  return (
    <div>
      <ContainerProgress>
        <Emoticon>{getEmoticon()}</Emoticon>
        <ProgressBar>
          <Progress width={getProgressBarWidth()} color={getProgressBarColor()}/>
        </ProgressBar>
      </ContainerProgress>
      
      <SecurityText>{getSecurityText()}</SecurityText>
    </div>
  );
};

export default SecurityIndicator;
