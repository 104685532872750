import React, { useEffect, useState } from 'react';
import { FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSyles } from './RemplacePassword.styles';
import { login, updatePassword } from 'src/redux/auth/thunks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { RegisterRequest } from 'src/redux/auth/models/RegisterRequest';
import Button from 'src/components/common/Button';
import { useStylesG } from 'src/components/Auth/Login/index.styles';
import Header from 'src/components/Auth/header';
import SecurityIndicator from 'src/components/Auth/SecurityIndicator';
import Loading from 'src/components/common/Loading';


const Step8 = () => {
  const classes = useSyles({ colorHeader: '#003C3C' });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRev, setShowPasswordRev] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [securityLevel,setSecurityLevel] = useState(0);
  const initialValues = {
    user_id: auth.register.user_id,
    password: "",
    verify_password: "",
    currentPassword: "",
  };
  const handlerSubmit = (values: RegisterRequest,email:string) => {
    dispatch(
      updatePassword({ ...values})
    )
      .unwrap()
      .then(() => {
        navigate(0);
      })
      .catch((e: Error) => {});
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Debe contener al menos 8 caracteres")
      .matches(/[a-zA-Z]/, "Debe contener al menos una letra")
      .matches(/[0-9]/, "Debe contener al menos un número")
      .matches(/[^a-zA-Z0-9]/, "Debe contener al menos un carácter especial"),
    verify_password: yup
      .string()
      .oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
    currentPassword: yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values,auth.register.email);
    },
  });

  useEffect(() => {
      let securityLevelTmp = 0;
      if(formik.values.password != "" && formik.values.password.length >= 8){
        securityLevelTmp++;
      }
      if(formik.values.password.match(/[a-zA-Z]/) !== null){
        securityLevelTmp++;
      }
      if(formik.values.password.match(/[0-9]/) !== null){
        securityLevelTmp++;
      }
      if(formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null){
        securityLevelTmp++;
      }
      setSecurityLevel(securityLevelTmp);
  }, [formik.values.password]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibilityRev = () => {
    setShowPasswordRev(!showPasswordRev);
  };

  return (
    <div className={classesG.container}>
      {/* <Header title={"Cambia tu contraseña"} 
              subtitle={""}
              colorHeader={"#014951"}
              heightHeader={'220px'}
      /> */}
      
      <div className={classesG.body}>
        <div className={classes.titles}>
        <p className={classes.title}>Cambia tu contraseña</p>
          <div className={classes.containerList}>
          <p className={classes.subtitle}>Utiliza los siguientes criterios:</p>
            <div className={classes.item}>
              <FormControlLabel
                control={<Checkbox checked={formik.values.password != "" && formik.values.password.length >= 8} className={classes.checkbox}/>}
                label={
                  <span className={classes.label}>
                    Al menos 8 caracteres: cuantos más caracteres, mejor.
                  </span>
                }
                
              />
            </div>
            <div className={classes.item}>
                <FormControlLabel
                  control={<Checkbox className={classes.checkbox} checked={formik.values.password.match(/[a-zA-Z]/) !== null} />}
                  label={
                    <span className={classes.label}>
                      Una mezcla de letras mayúsculas y minúsculas
                    </span>
                  }
                />
            </div>
            <div className={classes.item}>
                <FormControlLabel
                  control={<Checkbox className={classes.checkbox} checked={formik.values.password.match(/[0-9]/) !== null} />}
                  label={
                    <span className={classes.label}>
                      Una mezcla de letras y números.
                    </span>
                  }
                />
            </div>
            <div className={classes.item}>
                <FormControlLabel
                  control={<Checkbox className={classes.checkbox} checked={formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null} />}
                  label={
                    <span className={classes.label}>¡Inclusión de al menos uno de estos caracteres especiales! @ # ?</span>
                  }
                />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              id="currentPassword"
              label="Contraseña Vieja"
              variant="outlined"
              name="currentPassword"
              type={showCurrentPassword ? "text" : "password"}
              //  className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.currentPassword &&
                Boolean(formik.errors.currentPassword)
              }
              helperText={formik.errors.currentPassword as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      edge="end"
                    >
                      {!showCurrentPassword ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="password"
              label="Contraseña"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              //  className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.errors.password as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPassword ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {securityLevel > 0 && (
              <SecurityIndicator securityLevel={securityLevel} />
            )}

            <TextField
              id="verify_password"
              label="Confirmar Contraseña"
              variant="outlined"
              name="verify_password"
              type={showPasswordRev ? "text" : "password"}
              //className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.verify_password &&
                Boolean(formik.errors.verify_password)
              }
              helperText={formik.errors.verify_password as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibilityRev}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPasswordRev ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <div className="w-full">
            {true ? (
              <div className={`${classes.buttons} w-full`}>
                <Button
                  onClick={onAction}
                  disabled={
                    !formik.isValid ||
                    formik.values.password !== formik.values.verify_password
                  }
                >
                  <span>Modificar contraseña</span>
                </Button>
              </div>
            ) : (
              <Loading height="150px" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step8;
