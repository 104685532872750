import clientAxios from "./../../config/axios";
import clientStrapi from "./../../config/strapi";
import { RegisterRequest } from "./models/RegisterRequest";
import { ILogin } from "./state";

export const login = async (data: any) => {
  const intent = await clientStrapi().post("/auth/local", {
    identifier: data.email,
    password: data.password,
  });
  localStorage.setItem("jwt", intent.data.jwt);
  return intent;
};

export const googleAuth = (data: ILogin["googleCredentialResponse"]) => {
  const url = `auth/google-login`;
  return clientAxios.post(url, {
    data,
    country: process.env.REACT_APP_ENV_COUNTRY as "MX" | "CO",
  });
};

export const forgotPasswordService = async (data: RegisterRequest) => {
  const intent = await clientStrapi().post("/auth/forgot-password", {
    email: data.email,
  });
  return intent;
};

export const resetPassword = async ({
  code,
  password,
  passwordConfirmation,
}: {
  code?: string;
  password: string;
  passwordConfirmation: string;
}) => {
  const intent = await clientStrapi().post("/auth/reset-password", {
    code,
    password,
    passwordConfirmation,
  });
  localStorage.setItem("jwt", intent.data.jwt);
  return intent;
};

export const firstLogin = async (data: { email: string }) => {
  const login = await clientStrapi().post("/auth/local", {
    identifier: data.email,
    password: "password", //default password
  });
  localStorage.setItem("jwt", login.data.jwt);
};

export const register = async (data: RegisterRequest) => {
  // update user with data
  const result = await clientStrapi().put(`/users/${data.user_id}`, data);
  return result.data;
};

export const registerEmail = async (data: { email: string }) => {
  const user = (
    await clientStrapi().get(`/users?filters[email][$eq]=${data.email}`)
  ).data[0];
  if (!user) throw new Error("ERR_BAD_REQUEST");
  if (user.confirmed) throw new Error("User already registered");
  return user;
};

export const sendCode = async (data: RegisterRequest) => {
  const result = await clientStrapi().post("/verificationCode", data);
  return result.data;
};

export const updatePassword = async (data: RegisterRequest) => {
  let login = await clientStrapi().post("/auth/change-password", {
    currentPassword: data.currentPassword ?? "password", //default password
    password: data.password,
    passwordConfirmation: data.verify_password,
  });
  localStorage.setItem("jwt", login.data.jwt);
  return login;
};

export const postValidateUserCode = async (data: any) => {
  const result = await clientStrapi().post("/verificationCode/verify", data);
  return result;
};

export const setTestResults = async (data: any) => {
  await clientStrapi().put(`/users/${data.user_id}`, { test_results: data });
  return clientStrapi().get("/users/me?populate=*");
};

export const me = async () => {
  const { data } = await clientStrapi().get("/users/me?populate=*");
  return data;
};

export const updateUser = async (id: number, data: any) => {
  const res = await clientStrapi().put(`/users/${id}`, data);
  return res;
};

export const exists = async (email?: string) => {
  if (!email) return false;
  const res = await clientStrapi().get(
    `/users?limit=2&filters[email][$eq]=${email}`
  );
  return res.data.length > 0;
};
