import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles((theme) => ({
  layout: {
    background: '#fff',
    width: '100%',
    height: '100vh',
    paddingTop: '64px',
    display: 'flex',
  },
  main: {
    flexGrow: '1',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
    },
  },
}));
