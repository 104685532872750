import React, { useEffect, useState } from "react";
import { useActionData, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../common/Loading";
import { getContent } from "src/redux/content/thunks";
import { RootState } from "src/domain/models/RootState";
import Orchestrator from "./orchestrator";
import Introduction from "./Introduction";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

const Index = () => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const { content, auth } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!content.content.length) {
      dispatch(getContent({ id: id })).then((res: any) => {
        if (
          (performance.getEntriesByType("navigation")[0] as any).type ===
          "back_forward"
        )
          dispatch(
            updateCurrentOrchestratorIndex(res.payload.content.length - 1)
          );
      });
    }
  }, [id]);
  if (content.loading) {
    return (
      <div className="flex items-center justify-center flex-col">
        <Introduction />
      </div>
    );
  } else {
    return <Orchestrator templates={content.templates} />;
  }
};

export default Index;
