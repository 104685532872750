import React from 'react'
import { OrentaHamburger } from './Hamburger.styles'


const Hamburger = ({ open, onClick }) => {
  return (
    <OrentaHamburger data-gtm="hamburger-menu" className={open && 'open '} onClick={onClick} >
        <span className='OrentaLineHamburger'></span>
        <span className='OrentaLineHamburger'></span>
        <span className='OrentaLineHamburger'></span>
    </OrentaHamburger>
  )
}

export default Hamburger
