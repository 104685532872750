import { useStyles } from './review.styles'
import Button from 'src/components/common/Button';
import Header from '../Header';
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';
import { useDispatch } from 'react-redux';
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";


function Template({handleContinue}:any) {

  const classes = useStyles({ 
    backgroundColor: "#6674B2",
    titleColor: '#FFFFFF',
    titleFontSize: '24px',
    titleWeight: 700,
    titleTextAlign: 'center',
    subtitleColor: '#FFFFFF',
    subtitleFontSize: '16px',
    subtitleWeight: 500,
    subtitleTextAlign: 'center',
  });
  const { content } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if(content.currentOrchestratorIndex - 1 >= 0){
      dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1));
    }else{
      window.open(`/home`,'_self');
    }
  };
    return (
      <div className={classes.root} style={{    backgroundSize: "contain",backgroundRepeat: 'no-repeat'}}>
        {/* <div className={classes.root} style={{    backgroundSize: "contain",backgroundRepeat: 'no-repeat'}} onTouchStart={(e)=>onTouchStart(e)} onTouchMove={(e)=>onTouchMove(e)} onTouchEnd={(e)=>onTouchEnd(e)}> */}
        <div className={classes.overlay} />
        <div className={classes.text}>
          <div className={classes.container}>
            <div className={classes.header}>
              <Header fnBack={handleBack}/>
            </div>
            <div className={classes.body}>
              <div className={classes.content}>
                
                <div className={classes.titles}>
                  <p className={classes.title}>Revisa tu lista de debilidades</p>
                  <p className={classes.subtitle}>Modifícalas de acuerdo a las 4 reglas de oro</p>
                </div> 
                
              </div>
            </div>
            <div className={classes.footer}>
              <div className={classes.buttons}>
                <Button onClick={() => handleContinue()} >
                  <span >Siguiente</span>
                </Button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  
}

export default Template;