import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./questionOpinion.styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { saveAnswer } from "src/redux/content/thunks";
import Button from "src/components/common/Button";
import Header from "../Header";
import Loading from "src/components/common/Loading";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

const Retroalimentation = ({
  question,
  opinions,
  setOpinions,
  questionIndex,
  onContinue,
}: any) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const { content, auth } = useAppSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      window.open(`/home`, "_self");
    }
  };

  const handleContinue = async () => {
    setLoading(true);
    try {
      const data = {
        user: auth.id,
        template_id: question.id,
        template_response: null,
        template_response_id: null,
        type_template_id: question.type_template_id,
        response: {
          complementary: opinions[questionIndex]["complementary"],
          positive: opinions[questionIndex]["positive"],
          negative: opinions[questionIndex]["negative"],
        },
      };

      await dispatch(saveAnswer({ ...data }))
        .unwrap()
        .then((res: any) => {
          setLoading(false);
          onContinue();
        });
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  const handleFinish = () => {
    // Guarda las opiniones en localStorage
    localStorage.setItem("opinions", JSON.stringify(opinions));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>{question.question}</p>
          {opinions[questionIndex].negative.map((text: any, index: any) => (
            <div className={classes.titles}>
              <p className={classes.subtitle}>{text}</p>
              <TextField
                fullWidth
                placeholder={`Versión revisada-${index + 1}`}
                variant="outlined"
                value={opinions[questionIndex].complementary[index]}
                onChange={(e) => {
                  const updatedOpinions = [...opinions];
                  updatedOpinions[questionIndex].complementary[index] =
                    e.target.value;
                  setOpinions(updatedOpinions);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          {loading ? (
            <Loading />
          ) : (
            <Button onClick={() => handleContinue()}>
              <span>Siguiente</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Retroalimentation;
