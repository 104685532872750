import React from 'react';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { ContentPortada, WrapperText, WrapperPortada, WrapperImg, Header2, Description, Operator,WrapperPreloader,FondoWhite } from './Portada.styles';
import Icon from 'src/components/common/Icon';
import { ContentB, DescriptionB, Header2B, WrapperB, WrapperCategory, WrapperTextB } from './BrindamosApoyo/brindamosApoyo.styles';
import Preloader from './preloader';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/common/Button';
const Portada = () => {
    const TITLE_MAIN_IMG = 'Orenta es la solución de bienestar emocional';
    const STATICS = "/images"
    const navigate = useNavigate();
    const goToRequestDemo = () => {
        navigate(`/request-demo`, { replace: true });
    }

    return (
        <WrapperPortada  >
            <ContentPortada >
                <GridContainer p-lg={'0'} p-sm={'0'} ggap-lg={0} className={"contenedorCat"}>
                    <GridItem lg={1} md={0} sm={0}>
                        <></>
                    </GridItem>
                    <GridItem lg={10} md={8}>
                        <WrapperB  >
                            <ContentB >
                                <GridContainer>
                                    <GridItem lg={12} md={12} sm={6}>
                                        <GridContainer p-lg={'0'}>
                                            <GridItem lg={5} md={5}>
                                                <WrapperCategory>
                                                    <img
                                                        src={`${STATICS}/home/rodrigues.png`}
                                                        alt={""}
                                                        title={""}
                                                        />
                                                        <p>Rodrigo Busca <br />Enfrentar la tristeza</p>
                                                </WrapperCategory>
                                                <Preloader
                                                    progress={75}
                                                    barColor="#00E486"
                                                    heightBar="20px"
                                                    rainbowBar={true}
                                                    animation={false}
                                                    limitInferior={100}
                                                    limitInter={100}
                                                    limitSup={100}
                                                    leftText='Bajo'
                                                    centerText='Medio'
                                                    rightText='Alto'
                                                    title='Nivel de bienestar'
                                                    titleAlignment='center'
                                                />
                                            </GridItem>
                                            <GridItem lg={7} md={7} >
                                                <WrapperPreloader >
                                                <GridContainer p-lg={'0'} p-sm={0} p-md={0}>
                                                     <GridItem lg={6} >
                                                        
                                                        <Preloader
                                                            progress={100}
                                                            barColor="#00E486"
                                                            heightBar="25px"
                                                            rainbowBar={false}
                                                            animation={true}
                                                            limitInferior={30}
                                                            limitInter={40}
                                                            limitSup={70}
                                                            leftText=''
                                                            centerText=''
                                                            rightText=''
                                                            title='Herramientas Digitales'
                                                            titleAlignment='left'
                                                        />
                                                    </GridItem>
                                                    <GridItem lg={6}>
                                                        
                                                        <Preloader
                                                            progress={50}
                                                            barColor="#00E486"
                                                            heightBar="25px"
                                                            rainbowBar={false}
                                                            animation={true}
                                                            limitInferior={20}
                                                            limitInter={30}
                                                            limitSup={50}
                                                            leftText=''
                                                            centerText=''
                                                            rightText=''
                                                            title='Terapia'
                                                            titleAlignment='left'
                                                        />
                                                    </GridItem>
                                                    <GridItem lg={6} >
                                                        
                                                        <Preloader
                                                            progress={50}
                                                            barColor="#00E486"
                                                            heightBar="25px"
                                                            rainbowBar={false}
                                                            animation={true}
                                                            limitInferior={20}
                                                            limitInter={10}
                                                            limitSup={100}
                                                            leftText=''
                                                            centerText=''
                                                            rightText=''
                                                            title='Línea de ayuda'
                                                            titleAlignment='left'
                                                        />
                                                    </GridItem>
                                                    <GridItem lg={6} >
                                                        
                                                        <Preloader
                                                            progress={50}
                                                            barColor="#00E486"
                                                            heightBar="25px"
                                                            rainbowBar={false}
                                                            animation={true}
                                                            limitInferior={40}
                                                            limitInter={70}
                                                            limitSup={100}
                                                            leftText=''
                                                            centerText=''
                                                            rightText=''
                                                            title='Talleres'
                                                            titleAlignment='left'
                                                        />
                                                    </GridItem>
                                                    </GridContainer>
                                                </WrapperPreloader>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                <WrapperTextB >
                                    <Header2B><div>Brindamos a tus equipos el apoyo que necesitan cuando lo necesitan.</div></Header2B>
                                    <DescriptionB>Para cualquier reto y en cualquier momento ofrecemos acceso inmediato a herramientas y acompañamiento de profesionales 24/7 para que nada los detenga.</DescriptionB>
                                </WrapperTextB>
                            </ContentB>
                        </WrapperB>
                    </GridItem>
                    <GridItem lg={1} md={0} sm={0} >
                        <></>
                    </GridItem>
                </GridContainer>
                <GridContainer p-lg={'0px'}>
                    <GridItem lg={1} md={4}>
                        <></>
                    </GridItem>
                    <GridItem lg={5} md={4}>
                        <WrapperText >
                            <Header2><div>Un equipo saludable <br /> es un equipo productivo.</div></Header2>
                            <Description>Orenta es la solución de bienestar emocional que ayuda
                                a tus equipos a transformar sus mentes y abrazar su potencial a
                                través de herramienta digitales, atención psicológica 24/7, 
                                terapias individuales y talleres.</Description>
                            <GridContainer p-lg={'0px'}>
                                <GridItem lg={8} md={4}>
                                    <Button onClick={goToRequestDemo} >
                                        Solicita una demo
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <Operator>Operado por <img src={`${STATICS}/home/operator.png`}
                                        alt={TITLE_MAIN_IMG}
                                        title={TITLE_MAIN_IMG} /> Fundación Origen
                            </Operator>
                        </WrapperText>
                    </GridItem>
                    <GridItem lg={6} md={4} customOrder={{ order: 1,  mobileOrder: -1 }}>
                        <WrapperImg >
                            <div className="row">
                                <div className="laptop">
                                    <img
                                        src={`${STATICS}/home/IMG.png`}
                                        alt={TITLE_MAIN_IMG}
                                        title={TITLE_MAIN_IMG}
                                    />
                                </div>
                            </div>
                        </WrapperImg>
                    </GridItem>
                </GridContainer>
            </ContentPortada>
            <FondoWhite></FondoWhite>
        </WrapperPortada>
    );
};

export default Portada;
