// Libraries.
import styled from 'styled-components';

export const WrapperTemplate = styled.div<{ backgroundColor: string }>`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    isolation: isolate;
    position: relative;
    z-index: 1;
    background:  ${props => props.backgroundColor || props.theme.color.green3};
    @media ${({ theme }) => theme.screenSizes.tablet} {
        justify-content: flex-end;
        isolation: isolate;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        position: relative;
        /*background-color: ${({ theme }) => theme.color.green3};*/

        //padding: 320px 56px 0px;
    }
`;

export const WrapperB = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-bottom: 50px;
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0px 20px 66px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    z-index: 3;
    border-radius: 24px;
     position: relative;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        justify-content: flex-end;
        isolation: isolate;
        position: relative;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        position: relative;
        background-color: ${({ theme }) => theme.color.white};
        //padding: 320px 56px 0px;
    }
`;

export const ContentB = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    //padding: 60px 16px 0px 16px;
    //gap: 72px;
    //background: rgba(250, 250, 250, 0.75);
    width: 100%;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        //gap: 56px;
        border-radius: 24px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        //gap: 56px;
        border-radius: 24px;
    }
    
`;

export const FondoWhite = styled.div`
    height: 25%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        background-color: white;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        height: 25%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        background-color: white;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        height: 25%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        background-color: white;
    }
    
`;
export const WrapperPreloader = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 50px;
width: 100%;
isolation: isolate;
background-color: ${({ theme }) => theme.color.white};
@media ${({ theme }) => theme.screenSizes.tablet} {
    justify-content: flex-end;
    isolation: isolate;
}
@media ${({ theme }) => theme.screenSizes.laptop} {
    display: flex;
    position: relative;
    background-color: ${({ theme }) => theme.color.white};

    //padding: 320px 56px 0px;
}
`;

export const ContentTemplate = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 70px 0px 0px 0px;
    //gap: 72px;
    //background: rgba(250, 250, 250, 0.75);
    width: 100%;
    //position: relative;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding-top: 100px;
        //gap: 56px;
        border-radius: 24px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding-top: 100px;
        //gap: 56px;
        border-radius: 24px;
        //padding-bottom: 240px;
        .contenedorCat{
            //position: absolute;
            //bottom: -710px;
            //left: 10%;
        }
    }
    
`;

export const WrapperText = styled.div`
    color:${({ theme }) => theme.color.white};
    button {
        border-radius: 30px;
        background: ${({ theme }) => theme.color.greenActions};
        color:${({ theme }) => theme.color.dark};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        height: 52px;
        width: 100%;
    }
`;

export const Header2 = styled.h2`
    font-family: 'Antonia H3';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 118.519% */
    letter-spacing: -1.62px;

    display: flex;
    align-items: center;
    padding-bottom: 19px;
    color: ${({ theme }) => theme.color.white};;
    span{
        color: ${({ theme }) => theme.color.white};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
    
`;

export const Description = styled.p`
    font-family: "Work Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 163.636% */
    letter-spacing: -0.66px;

    display: flex;
    align-items: center;
    text-align: center;
    padding-bottom: 32px;
    color: ${({ theme }) => theme.color.white};;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: center;
        text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`

export const Operator = styled.p`
    font-feature-settings: 'liga' off;
    /* H2 Bold */
    font-family: "Work Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.6px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${({ theme }) => theme.color.white};
    padding-top: 20px;
    img {
        padding: 10px;
        width: 50px;
        height: auto;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: center;
        text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`

export const WrapperImg = styled.div`
    color:${({ theme }) => theme.color.white};
    
    //position: relative;
    //width: 100%; /* Asegura que el contenedor tenga un ancho definido */
    //height: 0; /* Inicialmente establece la altura en 0 para mantener la relación de aspecto */
    //padding-bottom: 100%; /* Establece la altura en función del ancho para mantener la relación de aspecto */
    //overflow: hidden; /* Recorta cualquier contenido que se desborde del contenedor */
    //img{
      //      position: absolute;
       //     width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
       //     height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
        //    object-fit: fill; 
        //}
    @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
        //img{
         //   position: absolute;
          //  width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
           // height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
           // object-fit: fill; 
           // bottom: 60px;
        //}
    }
    @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
        //img{
        //    position: absolute;
         //   width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
         //   height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
         //   object-fit: fill;
         //   bottom: 60px;
        //}
    }
    
    
`;
