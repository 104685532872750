// Libraries.
import styled from 'styled-components';

export const TitlePreloader = styled.p`
        color: "#014951";
        /* H5 Bold */
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        padding: 20px 0 20px 0;
        &.left{
            text-align: left;
        }
        &.center{
            text-align: center;
        }
        &.right {
            text-align: right;
        }

`;

export const SubTitlePreloader = styled.p`
        color: "#014951";
        /* H6 Bold */
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 175% */
        letter-spacing: -0.36px;
        padding: 10px 0 10px 0;
        &.left{
            text-align: left;
        }
        &.center{
            text-align: center;
        }
        &.right {
            text-align: right;
        }

`;

