import React from 'react';
import { footerContent } from './footerContent';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { ContentFooterOrenta, WrapperApps, WrapperCopyright, WrapperFooterOrenta, WrapperLinks } from './FooterOrenta.styles';
import Icon from 'src/components/common/Icon';
const FooterOrenta = () => {
    return (
        <WrapperFooterOrenta data-gtm="container-footer" >
            <ContentFooterOrenta >
                <GridContainer p-lg={'0px'}>
                    <GridItem lg={3}>
                        <WrapperApps >
                            <div>
                                <div className="logo">
                                    <img src="/images/Logo.svg"
                                        width={"160px"} height={"20px"}
                                        alt="orenta.com" title="orenta.com" />
                                </div>
                                <p className="about">{footerContent.orenta.text}</p>
                            </div>
                        </WrapperApps>
                    </GridItem>
                    <GridItem lg={9}>
                        <WrapperLinks >
                            <div>
                                <p className="subtitle">Compañia</p>
                                <ul>
                                    <li>
                                        <a data-gtm={footerContent.acercaDe.gtm} data-testid="acerca-de" href={footerContent.acercaDe.link} title={footerContent.acercaDe.text}>
                                            {footerContent.acercaDe.text}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className="subtitle">Contacto</p>
                                <ul>
                                    <li>
                                        <a data-gtm={footerContent.preguntas.gtm} data-testid="preguntas" href={footerContent.preguntas.link} title={footerContent.preguntas.text}>
                                            {footerContent.preguntas.text}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className="subtitle">Legal</p>
                                <ul>
                                    <li>
                                        <a data-gtm={footerContent.privacidad.gtm} data-testid="privacidad" href={footerContent.privacidad.link} title={footerContent.privacidad.text}>
                                            {footerContent.privacidad.text}
                                        </a>
                                    </li>
                                    <li>
                                        <a data-gtm={footerContent.terminos.gtm} data-testid="terminos-y-condiciones" href={footerContent.terminos.link} title={footerContent.terminos.text}>
                                            {footerContent.terminos.text}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                {/* <p className="subtitle">
                                    <div className="social-networks">
                                    <a
                                            data-gtm={footerContent.instagram.gtm}
                                            data-testid="instagram"
                                            // onClick={() => sendSocialTracking('instagram')}
                                            href={footerContent.instagram.link}
                                            target="_blank"
                                            title="orenta.com instagram"
                                        >
                                            <Icon icon="orenta-icon-instagram" />
                                        </a>
                                    
                                        <a
                                            data-gtm={footerContent.twitter.gtm}
                                            data-testid="twitter"
                                            // onClick={() => sendSocialTracking('twitter')}
                                            href={footerContent.twitter.link}
                                            target="_blank"
                                            title="orenta.com twitters"
                                        >
                                            <Icon icon="orenta-icon-twitter" />
                                        </a>
                                        <a
                                            data-gtm={footerContent.facebook.gtm}
                                            data-testid="facebook"
                                            // onClick={() => sendSocialTracking('facebook')}
                                            href={footerContent.facebook.link}
                                            title="orenta.com facebook"
                                            target="_blank"
                                            >
                                            <Icon icon="orenta-icon-facebook" />
                                        </a>
                                        <a
                                            data-gtm={footerContent.linkedin.gtm}
                                            data-testid="linkedin"
                                            // onClick={() => sendSocialTracking('linkedin')}
                                            href={footerContent.linkedin.link}
                                            title="orenta.com linkedin"
                                            target="_blank"
                                        >
                                            <Icon icon="orenta-icon-linkedin" />
                                        </a>
                                    </div>
                                </p> */}
                                <ul>
                                    <li>
                                        <p className="schedule">
                                            Si necesitas ayuda
                                        </p>
                                        <p className="schedule">
                                            inmediata, llama al
                                        </p>
                                    </li>
                                    <li>
                                        <a
                                            data-gtm={footerContent.contactoTelefono.gtm}
                                            data-testid="contacto-telefono"
                                            // onClick={() => sendSocialTracking('phone')}
                                            href={footerContent.contactoTelefono.link}
                                            className="phone"
                                            title="contactar a orenta.com por teléfono"
                                        >
                                            {footerContent.contactoTelefono.text}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </WrapperLinks>
                    </GridItem>
                    <GridItem >
                        <WrapperCopyright >
                            <GridContainer p-lg={'0px'} p-sm={'0px'} ggap-lg={'0px'}>
                                <GridItem lg={12}>
                                    <p className="copyright">{footerContent.copyright.text}</p>
                                </GridItem>
                                <GridItem lg={2}>
                                    
                                </GridItem>
                            </GridContainer>
                        </WrapperCopyright>
                    </GridItem>
                </GridContainer>
            </ContentFooterOrenta>
        </WrapperFooterOrenta>
    );
};

export default FooterOrenta;
