import { RolesIds } from './Roles';
import { SystemActions } from './SystemActions';

const GuestPermissions: SystemActions[] = [
  // SystemActions.read_keys,
  // SystemActions.read_loans_p2p,
  // SystemActions.create_loans_p2p,
  // SystemActions.edit_loans_p2p,
  // SystemActions.read_loans_by_session,
];
const AreaManagerPermissions: SystemActions[] = [
  ...GuestPermissions,

  SystemActions.edit_users,
  SystemActions.read_users,
];
const StorerPermissions: SystemActions[] = [
  ...GuestPermissions,

  SystemActions.read_companies,
  SystemActions.edit_companies,
  SystemActions.create_companies,
];

const AdministratorPermissions: SystemActions[] = [
  ...StorerPermissions,
  SystemActions.create_users,
  SystemActions.edit_users,
  SystemActions.read_users,
];
export const RolePermissions = {
  [RolesIds.Administrator]: AdministratorPermissions,
  [RolesIds.AreaManager]: AreaManagerPermissions,
  [RolesIds.Storer]: StorerPermissions,
  [RolesIds.Guest]: GuestPermissions,
  allowed: (roleid: RolesIds, action: SystemActions) =>
    RolePermissions[roleid].includes(action),
};
