import styled from 'styled-components';

export const ProfileAvatarWrapper = styled.div`
    height: auto;
    float: left;
    width: 100%;
    font-size: 32px;
    background-color: ${({ theme }) => theme.color.white};
    margin-right: 8px;
    overflow: hidden;
    color: ${({ theme }) => theme.color.white};
    .rounded-avatar {
        display: inherit;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        border-radius: 25px;
        width: 100%;
        object-fit: cover;
        padding: 0px;
        border: 0px;
        margin-bottom: 0px;
    }
    &.nologged .rounded-avatar {
        display: inherit;
        background-color: ${({ theme }) => theme.color.nologgedBG};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        width: auto;
        margin-right: 24px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        margin-right: 8px;
        font-size: 16px;
        width: 32px;
        height: 32px;
        display: inline-block;
        vertical-align: top;
        .rounded-avatar {
            display: inherit;
            width: 32px;
            height: 32px;
        }
        .user-letters {
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
        }
    }
`;
