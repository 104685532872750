import { css } from 'styled-components';

export const SchrollCSS = css`
    &::-webkit-scrollbar {
        background: ${({ theme }) => theme.color.white};
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border: 1px solid ${({ theme }) => theme.color.white};
        background: ${({ theme }) => theme.color.scarpaGray200};
        border-radius: 4px;
    }
`;
