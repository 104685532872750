import React from 'react';
import { Outlet, Navigate } from 'react-router';

const Protected = ({ isAuth, children, redirecTo = '/' }) => {
  if (!isAuth) {
    return <Navigate to={redirecTo} />;
  }
  return children ? children : <Outlet />;
};

export default Protected;
