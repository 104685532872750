import React, { useState, useEffect } from 'react';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import styled, { keyframes } from 'styled-components';

const images = [
  'cliente1.png',
  'cliente2.png',
  'cliente3.png',
  'cliente4.png',
]; // Lista de imágenes que deseas mostrar

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const moveRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
  -100% {
    transform: translateX(0%);
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  animation: ${moveRight} 40s linear infinite;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
  margin-right: 200px;
`;

const Clientes: React.FC = () => {
  return (
    <GridContainer p-lg={'0px'}>
      <GridItem lg={12} >
        <CarouselContainer>
          <ImageWrapper>
            {images.map((image, index) => (
              <Image
                key={index}
                src={`/images/home/${image}`}
                alt={`Imagen ${index + 1}`}
              />
            ))}
           {images.map((image, index) => (
              <Image
                key={index}
                src={`/images/home/${image}`}
                alt={`Imagen ${index + 1}`}
              />
            ))}
            {images.map((image, index) => (
              <Image
                key={index}
                src={`/images/home/${image}`}
                alt={`Imagen ${index + 1}`}
              />
            ))}
            {images.map((image, index) => (
              <Image
                key={index}
                src={`/images/home/${image}`}
                alt={`Imagen ${index + 1}`}
              />
            ))}
          </ImageWrapper>
        </CarouselContainer>
    </GridItem>
    </GridContainer>
  );
};

export default Clientes;
