
import React from "react";

import { useStyles } from './menu.styles'
import { useNavigate } from 'react-router-dom';
import Button from "src/components/common/Button";
import Header from "../Header";
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';
import { useDispatch } from 'react-redux';
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

const Menu = ({questions,handleOptionClick}:any) => {
  const navigate = useNavigate();
  const classes = useStyles({colorHeader: '#FFFFF'});
  const { content } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if(content.currentOrchestratorIndex - 1 >= 0){
      dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1));
    }else{
      window.open(`/home`,'_self');
    }
  };

  const goToHome = () => {
    //navigate(`/home`, { replace: true });
    dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + questions.length));
  };
  
  const renderOptions = () => {
    return questions.map((option:string, index:number) => (
      <div className={classes.row}>
        <Button 
        backgroundColor='transparent'
        borderColor='#EDEDED'
        onClick={() => handleOptionClick(index)} >
          {questions[index].question}
        </Button>
      
        {/* <Button
          key={index}
          variant={"outlined"}
          color="primary"
          onClick={() => handleOptionClick(index)}
          style={{ margin: "5px",width:'100%',height:'48px' }}
        >
          {questions[index].question}
        </Button> */}
      </div>
    ));
  };

  return (
    <div className={classes.container}>
    <div className={classes.header}>
      <Header fnBack={handleBack}/>
    </div>
    <div className={classes.body}>
      <div className={classes.titles}>
        <p className={classes.title}>Descríbete</p>
        <p className={classes.subtitle}>En cada una de estas áreas</p>
      </div>
      <div className={classes.content}>
        <div className={classes.form}>
              {renderOptions()}
        </div>
      </div>
    </div>
    <div className={classes.footer}>
          <div className={classes.buttons}>
          <Button onClick={goToHome} >
            <span >¡Listo!</span>
          </Button>
            {/* <Button
            size='large'
            color='secondary'
            variant='contained'
            //onClick={handleContinue}
            //disabled={disabledOption}
            >
            <span >¡Listo!</span>
            </Button>     */}
        </div>
      </div>
    </div>
  );

}

export default Menu;
