import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
//Material
import {
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
//Components
import { useSyles } from './styles';
import Layout from '../common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getProblems } from '../../redux/companies/thunks';
import { Box } from '@material-ui/core';

type PropsForm = {
  submitCompany: (_: any) => void;
  initialValues: any;
  actionText?: any;
  type: string;
};
const Form = ({
  submitCompany,
  initialValues,
  actionText,
  type,
}: PropsForm) => {
  const dispatch = useDispatch<any>();
  const problems = useSelector((state: any) => state.properties.problems);
  const [problemList, setProblemList] = useState([]);
  const listProblems: any = Object.entries(problemList)
    .filter((key) => key[1])
    .map((key) => Number(key[0]));
  const navigate = useNavigate();
  const classes = useSyles();

  const isEdit = type === 'edicion';

  // función que redirige de forma programada
  const cancelAdd = () => {
    navigate(`/properties`, { replace: true });
  };

  useEffect(() => {
    dispatch(getProblems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    nid: yup.string().required('Escribe el NID de la propiedad'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const _values = { ...values, problems: listProblems };
      submitCompany(_values);
    },
  });

  const handleChangeProblem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProblemList({
      ...problemList,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Layout>
      <div className={classes.container}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            id='form-nid'
            name='nid'
            label='NID'
            variant='outlined'
            required
            fullWidth
            disabled={isEdit}
            value={formik.values.nid}
            onChange={formik.handleChange}
            error={formik.touched.nid && Boolean(formik.errors.nid)}
            helperText={
              formik.touched.nid && formik.errors.nid
                ? 'Escribe el NID de la propiedad'
                : false
            }
            data-testid='form-nid'
          />
          <TextField
            id='form-url'
            name='url'
            label='URL'
            variant='outlined'
            required
            fullWidth
            value={formik.values.url}
            onChange={formik.handleChange}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={
              formik.touched.url && formik.errors.url
                ? 'Escribe un nombre'
                : false
            }
            data-testid='form-url'
          />
          <TextField
            id='form-address'
            name='address'
            label='Dirección'
            variant='outlined'
            fullWidth
            value={formik.values.address}
            onChange={formik.handleChange}
            data-testid='form-address'
          />
          {type === 'agregar' && (
            <FormGroup data-testid='observation-checkbox'>
              <Box sx={{ pt: 3, pb: 2, color: '#320066' }}>
                <b>Observaciones</b>
              </Box>
              {problems &&
                problems.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={problemList[item.id]}
                        onChange={handleChangeProblem}
                        name={item.id}
                        color='primary'
                      />
                    }
                    label={item.description}
                    data-testid={`observation-${item.id}-checkbox`}
                  />
                ))}
            </FormGroup>
          )}

          {(problemList[7] || isEdit) && (
            <TextField
              id='form-observation'
              name='observations'
              label='Observación'
              value={formik.values.observations}
              variant='outlined'
              fullWidth
              onChange={formik.handleChange}
              data-testid='form-observation'
            />
          )}

          <div className={classes.flex}>
            <Button onClick={cancelAdd} data-testid='cancel-button'>
              Cancelar
            </Button>
            <Button
              className={classes.button}
              size='medium'
              color='secondary'
              variant='contained'
              onClick={onAction}
              data-testid='action-button'
            >
              {actionText}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Form;
