import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { random } from '../../../../helpers/random';
//import { getUtms } from '../../../../helpers/utms.helpers';
import { useAppSelector } from 'src/redux/hooks';
import { OrentaMenuItemList } from './OrentaMenuItem.styles';

const OrentaMenuItem = ({ option, subMenu, handleMenuRight, openLogin }) => {
    // Evitar que se agregue un atributo rel vacio.
    const optionRel =  (option.rel ? {rel:option.rel} : {});
    const { auth } = useAppSelector((state) => state);
    const site = ""//process.env.NEXT_PUBLIC_REACT_APP_PROPERTIES_SERVICES;

    const [elementMenu, setElementMenu] = useState({
        clasesItem: '',
        open: false,
    });
    //const [urlStringParams, setUrlStringParams] = useState("");

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const actualUrlString = new URL(window.location.href);
        let urlStringParamsLocal = new URLSearchParams(actualUrlString.search.slice(1));
        //setUrlStringParams(urlStringParamsLocal.toString);
    }, []);

    const handleDropdownToggle = (optionLocal) => {
        if(["Ingresar", "Crear cuenta"].includes(optionLocal.title)){
             openLogin(optionLocal.title);
             return;
        }
        if (!optionLocal) {
            return;
        }
        if (optionLocal?.options.length > 0) {
            if (!elementMenu.open) {
                setElementMenu({
                    clasesItem: 'orenta-submenu-item-open',
                    open: true,
                });
            } else {
                setElementMenu({
                    clasesItem: '',
                    open: false,
                });
            }
        }

        handleMenuRight(!elementMenu.open, optionLocal?.id);

        let url = optionLocal?.link;

        if (url === '/logout' ) {
            //cerrar sesión
            window.location.reload();
        }
        if (url !== '#') {
            //MANTENER PARAMETROS (UTM'S)
            const utms = "";//getUtms(window.location + '');
            if (utms !== '') {
                if (url.indexOf('?') > -1) {
                    url = `${url}&${utms}`;
                } else {
                    url = `${url}?${utms}`;
                }
            }

            if (auth == null && optionLocal?.login_required) {
                 openLogin('Ingresar');
                 return;
            }
            window.location.href = site + url;
        }
    };

    let clases = 'orenta-menu-item ' + elementMenu.clasesItem;
    if (option?.options?.length > 0) {
        clases = 'orenta-menu-item orenta-submenu-item ' + elementMenu.clasesItem;
    }

    const getElementMenuItem = () => {
        return (
            <>
                {option?.classIcon?.trim() !== '' ? <i className={'orenta-menu-item-icon ' + option.classIcon}></i> : null}
                <span data-testid="itemTitle" className={`orenta-menu-item-title ${option.classTitle} `}>{option.title}</span>
                {option.labelName ? <span className={'orenta-menu-item-class-label ' + option.classLabel}> {option.labelName}</span> : null}
                {option.options && option.options.length > 0 ? (
                    <i className="orenta-menu-item-icon-arrow">
                        <span className="right"></span>
                    </i>
                ) : null}
            </>
        );
    };

    return (
        <>
            {option.title === 'profile' && (
                <>
                    {subMenu}
                </>
            )}
            {!['profile', 'login'].includes(option.title) && (
                <OrentaMenuItemList data-testid="submenu" key={option.id + random(0, 16)} className={clases} >
                    <a
                        data-gtm={option?.gtm && `menu-${option.gtm}`}
                        data-testid="clickMenuItem"
                        //href={`${site}${option.link}${urlStringParams}`}
                        href={`${site}${option.link}`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDropdownToggle(option);
                        }}
                        title={option.title}
                        {...optionRel}
                    >
                        {getElementMenuItem()}
                    </a>
                    {subMenu}
                </OrentaMenuItemList>
            )}
        </>
    );
};

export default OrentaMenuItem;
