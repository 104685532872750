import React from 'react';
import { useStyles } from './informative.styles'
import Button from 'src/components/common/Button';
import Header from '../Header';
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';
import { useDispatch } from 'react-redux';
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

function Index({handleContinue}:any) {
  const classes = useStyles({ 
    backgroundColor: "#ffffff",
    titleHeaderColor: '#ffffff',
    titleHeaderBackgroundColor: '#104B71',
    titleHeaderFontSize: '24px',
    titleHeaderWeight: 700,
    titleHeaderTextAlign: 'center',
    subtitleHeaderColor: '#104B71',
    subtitleHeaderFontSize: '16px',
    subtitleHeaderWeight: 500,
    subtitleHeaderTextAlign: 'center',

    titleBodyColor: '#104B71',
    titleBodyBackgroundColor: '#104B71',
    titleBodyFontSize: '24px',
    titleBodyWeight: 700,
    titleBodyTextAlign: 'center',
    subtitleBodyColor: '#104B71',
    subtitleBodyFontSize: '16px',
    subtitleBodyWeight: 500,
    subtitleBodyTextAlign: 'center',

  });
  const { content } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if(content.currentOrchestratorIndex - 1 >= 0){
      dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1));
    }else{
      window.open(`/home`,'_self');
    }
  };
    return (
      <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack}/>
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.titleHeader}>4 reglas de oro</p>
        </div>
        <div className={classes.content}>
          <div className={classes.titles}>
            <p className={classes.subtitleBody}><img src="/images/content/perception/check_box_24px.svg" /><img src="/images/content/perception/lenguajedespectivo.png" /></p>
            <p className={classes.subtitleBody}>Evita el lenguaje despectivo</p>
          </div>
          <div className={classes.titles}>
            <p className={classes.subtitleBody}><img src="/images/content/perception/check_box_24px.svg" /><img src="/images/content/perception/hechos.png" /></p>
            <p className={classes.subtitleBody}>Apégate a los hechos</p>
          </div>
          <div className={classes.titles}>
            <p className={classes.subtitleBody}><img src="/images/content/perception/check_box_24px.svg" /><img src="/images/content/perception/seespecifico.png" /></p>
            <p className={classes.subtitleBody}>Sé específico</p>
          </div>
          <div className={classes.titles}>
            <p className={classes.subtitleBody}><img src="/images/content/perception/check_box_24px.svg" /><img src="/images/content/perception/encuentra.png" /></p>
            <p className={classes.subtitleBody}>Encuentra excepciones y fortalezas</p>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
          
            <div className={classes.buttons}>
              
              <Button onClick={handleContinue} >
                  <span >Siguiente</span>
                </Button>    
          </div>
        </div>
      </div>
    );
 
}

export default Index;