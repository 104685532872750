import React, { useEffect } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useStyles } from "./index.styles";
import { useDispatch } from "react-redux";

import Loading from "src/components/common/Loading";
import { Grid } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa los estilos del carrusel
import Header from "../Header";
import { saveTracking } from "src/redux/content/thunks";

const ProcessResult = () => {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const { content, auth } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            Hola {auth.name}, estamos cargando tu contenido{" "}
          </p>
          <p className={classes.subtitle}></p>
        </div>
        <div className={classes.content}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Loading />
          </Grid>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.information}>
          <Carousel
            showThumbs={false} // Oculta los botones de navegación
            showStatus={false} // Oculta los indicadores de posición
            infiniteLoop={true} // Activa el bucle infinito
            centerMode={false} // Desactiva el modo centrado
            swipeable={true} // Permite el deslizamiento táctil
            emulateTouch={true} // Emula el toque en dispositivos no táctiles
            dynamicHeight={false} // Desactiva la altura dinámica
            showArrows={false} // Muestra las flechas de navegación
            showIndicators={false}
            interval={1500}
            autoPlay={true}
          >
            <p className={classes.information1}>"La salud mental es salud"</p>

            <p className={classes.information1}>
              "Hacer tus necesidades una prioridad, <br />
              no es egoísmo, <br />
              es autocuidado."
            </p>

            <p className={classes.information1}>
              "Tu bienestar es un proceso, <br />
              no un destino"
            </p>

            <p className={classes.information1}>
              "Tomando un respiro para <br />
              nutrir tu bienestar"
            </p>
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default ProcessResult;
