export const getHeaderAlign = (cellData: {
  numeric?: boolean;
  center?: boolean;
}) => {
  if (cellData.numeric) {
    return "right";
  }
  if (cellData.center) {
    return "center";
  }
  return "left";
};

export const ocultarCorreo = (email: string): string => {
  const partes = email.split("@");

  if (partes.length !== 2) {
    throw new Error("Dirección de correo inválida");
  }

  const [nombreUsuario, dominio] = partes;
  const nombreUsuarioVisible = nombreUsuario
    .slice(0, 2)
    .padEnd(dominio.length, "*");
  const correoOculto = `${nombreUsuarioVisible}@${dominio}`;

  return correoOculto;
};

export const obtenerTokenDesdeURL = (): string | null => {
  const urlObj = new URL(window.location.href);
  const searchParams = new URLSearchParams(urlObj.search);
  const token = searchParams.get("code");
  return token;
};

export const getParameterUrl = (parameter: string): string | null => {
  const urlObj = new URL(window.location.href);
  const searchParams = new URLSearchParams(urlObj.search);
  const token = searchParams.get(parameter);
  return token;
};

export const agregarNumeroSiNoExiste = (
  numero: number,
  arreglo: number[]
): number[] => {
  if (!arreglo.includes(numero)) {
    arreglo.push(numero);
  }
  return arreglo;
};

type WeightsCount = {
  weight0: number;
  weight1: number;
  weight4: number;
  weight5: number;
  hedonic45: number;
  hedonic01: number;
};

export const countWeights = (weights: Record<string, number>): WeightsCount => {
  const counts: WeightsCount = {
    weight0: 0,
    weight1: 0,
    weight4: 0,
    weight5: 0,
    hedonic45: 0,
    hedonic01: 0,
  };

  for (const key in weights) {
    const weight = weights[key];

    switch (weight) {
      case 0:
        counts.weight0++;
        if (Number(key) < 3) {
          counts.hedonic01++;
        }
        break;
      case 1:
        counts.weight1++;
        if (Number(key) < 3) {
          counts.hedonic01++;
        }
        break;
      case 4:
        counts.weight4++;
        if (Number(key) < 3) {
          counts.hedonic45++;
        }
        break;
      case 5:
        counts.weight5++;
        if (Number(key) < 3) {
          counts.hedonic45++;
        }
        break;
      default:
        // Handle other weights if needed
        break;
    }
  }

  return counts;
};

export const sumWeights = (
  weights: Record<string, number>,
  start: number,
  end: number
): number => {
  let result = 0;

  for (const key in weights) {
    const weight = weights[key];
    if (Number(key) + 1 >= start && Number(key) + 1 <= end) {
      result += weight;
    }
  }
  return result;
};

export const mapper = (categories: Record<number, number>): any => {
  //["Fortalecer mi autoestima","Manejar preocupaciones o ansiedad","Conocer mis emociones","Enfrentar la tristeza","Regular el estrés", "Reforzar mi relación de pareja"]
  const categoriesMapper = [4, 2, 11, 3, 1, 5];
  const categoriesResult = [];
  for (const key in categories) {
    const cat = categoriesMapper[categories[key]];
    categoriesResult.push(cat);
  }
  return categoriesResult;
};
