import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  logout,
  register,
  registerEmail,
  sendCode,
  postValidateUserCode,
  updatePassword,
  forgotPasswordService,
  firstLogin,
  resetPassword,
  setTestResults,
} from "./thunks";
import { AuthState } from "./state";

// Initial state for Redux store:
export const initialStateAuth: AuthState = {
  user: "",
  register: {
    email: "",
    step: 1,
    name: "",
    user_id: 0,
  },
  token: "",
  type: 3,
  name: "",
  role_name: "",
  id: "",
  loading: false,
  letters: "",
  color: undefined,
  image: undefined,
  email: "",
  self_knowledge_complete: false,
  self_knowledge: true,
  full_name: "",
  area: "",
  rol: "",
  related_contents: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuth, // Define initial state
  reducers: {
    getUser: (state: any, action: any) => {
      state.loans = action.payload;
    },
    confirmRegister: (state: any, action: any) => {
      state.register.email = action.payload;
    },
    updateStep: (state, action) => {
      state.register.step = action.payload;
      state.loading = false;
    },
    setSelfKnowledge: (state, action) => {
      state.self_knowledge = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.jwt;
      const user = action.payload.user;
      state.type = 3; // user.ct_rols_id;
      state.role_name = "Colaborador";
      // RolesOptions.find((r) => Number(r.id) === Number(user.ct_rols_id))
      //   ?.name || "Desconocido";
      state.name = user.name || "";
      state.self_knowledge_complete = user.test_results != null;
      state.self_knowledge = true;
      state.full_name = `${user.name ?? ""} ${user.lastname ?? ""} ${
        user.second_lastname ?? ""
      }`;
      state.area = user.area;
      state.rol = user.rol;
      state.email = user.email;
      state.user = action.payload.email || user?.email || "";
      state.id = user.id || "";
      state.department = user?.department;
      state.job = user?.rol;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      state.register.email = action.payload.email;
      state.register.name = action.payload.name;
      state.register.user_id = action.payload.id;
      state.register.step = 7;
    });
    builder.addCase(register.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(registerEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerEmail.fulfilled, (state, action) => {
      state.loading = false;
      //state.register.email = action.payload.email;
      state.register.email = action.meta.arg.email;
      state.register.user_id = action.payload.id;
      state.register.step = 2;
    });
    builder.addCase(registerEmail.rejected, (state, action) => {
      console.log();
      state.loading = false;
      if (action.error.message != "User already registered")
        state.register.step = 9;
    });

    builder.addCase(sendCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendCode.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendCode.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(forgotPasswordService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPasswordService.fulfilled, (state, action) => {
      state.loading = false;
      state.register.email = action.payload.email;
      state.register.step = 2;
    });
    builder.addCase(forgotPasswordService.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postValidateUserCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postValidateUserCode.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postValidateUserCode.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(firstLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(firstLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.register.step = 8;
    });
    builder.addCase(firstLogin.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.jwt;
      const user = action.payload.user;
      state.type = 3; // user.ct_rols_id;
      state.role_name = "Colaborador";
      // RolesOptions.find((r) => Number(r.id) === Number(user.ct_rols_id))
      //   ?.name || "Desconocido";
      state.name = user.name || "";
      state.self_knowledge_complete = user.test_results != null;
      state.self_knowledge = true;
      state.full_name = `${user.username ?? ""} ${user.lastname ?? ""} ${
        user.second_lastname ?? ""
      }`;
      state.area = user.area;
      state.rol = user.rol;
      state.email = user.email;
      state.user = action.payload.email || user?.email || "";
      state.id = user.id || "";
    });
    builder.addCase(updatePassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logout.pending, (state, action) => {
      localStorage.removeItem("jwt");
      state.token = "";
      state.user = "";
      state.loading = false;
      state.type = 3;
      state.register.step = 1;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.token = action.payload.jwt;
      state.loading = false;
      state.type = 3;
      state.register.step = 1;
      const user = action.payload.user;
      state.role_name = "Colaborador";
      state.name = user.name || "";
      state.self_knowledge_complete = user.test_results != null;
      state.self_knowledge = true;
      state.full_name = `${user.username ?? ""} ${user.lastname ?? ""} ${
        user.second_lastname ?? ""
      }`;
      state.area = user.area;
      state.rol = user.rol;
      state.email = user.email;
      state.user = action.payload.email || user?.email || "";
      state.id = user.id || "";
    });

    builder.addCase(setTestResults.fulfilled, (state, action) => {
      state.related_contents = action.payload.related_contents;
      state.self_knowledge_complete = action.payload.test_results != null;
    });
  },
});

// Export actions generated by "createSlice()":
export const { getUser, updateStep, setSelfKnowledge } = authSlice.actions;

// Export reducer generated by "createSlice()":
export default authSlice.reducer;
