import { useEffect } from "react";

import { useStyles } from "./index.styles";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useDispatch } from "react-redux";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import Button from "src/components/common/Button";
import Header from "../Header";
import { saveTracking } from "src/redux/content/thunks";
import { useNavigate } from "react-router-dom";

const SelectionMultiple = () => {
  const { content, auth } = useAppSelector((state: RootState) => state);
  const estilos = JSON.parse(
    content.templates[content.currentOrchestratorIndex].style
  );
  const navigate = useNavigate();

  const classes = useStyles({
    backgroundColor: estilos?.background?.color
      ? estilos.background.color
      : "red",
    titleColor: estilos?.title?.color ? estilos.title.color : "#104B71",
    titleFontSize: estilos?.title?.fontSize ? estilos.title.fontSize : "24px",
    titleWeight: estilos?.title?.fontWeight ? estilos.title.fontWeight : 700,
    titleTextAlign: estilos?.title?.textAlign
      ? estilos.title.textAlign
      : "center",
    subtitleColor: estilos?.subtitle?.color
      ? estilos.subtitle.color
      : "#104B71",
    subtitleFontSize: estilos?.subtitle?.fontSize
      ? estilos.subtitle.fontSize
      : "16px",
    subtitleWeight: estilos?.subtitle?.fontWeight
      ? estilos.subtitle.fontWeight
      : 500,
    subtitleTextAlign: estilos?.subtitle?.textAlign
      ? estilos.subtitle.textAlign
      : "center",
  });
  const dispatch = useDispatch<any>();

  const handleContinue = () => {
    handleUpdateCurrentOrchestratorIndex();
  };
  const renderOptions = () => {
    try {
      return content.templates[content.currentOrchestratorIndex].options.map(
        (option: any, index: number) => {
          let imgUrl =
            content.templates[content.currentOrchestratorIndex].images[index];
          try {
            new URL(imgUrl);
          } catch (e) {
            imgUrl = process.env.REACT_APP_STRAPI_BASE_URL + imgUrl;
          }

          return (
            <div key={`list-item-${index}`}>
              <p
                className={classes.subtitle}
                key={`list-img-${index}`}
                style={{ marginBottom: "20px" }}
              >
                <img
                  src={imgUrl}
                  style={{ maxWidth: "30px" }}
                />
              </p>
              <p
                className={classes.subtitle}
                key={`list-entry-${index}`}
              >
                {option}
              </p>
            </div>
          );
        }
      );
    } catch (e) {
      return <></>;
    }
  };

  const handleUpdateCurrentOrchestratorIndex = async () => {
    dispatch(
      updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
    );
  };

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            {content.templates[content.currentOrchestratorIndex].item}
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.titles}>{renderOptions()}</div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={handleContinue}>
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectionMultiple;
