import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles((theme) => ({
  row: {
    background: '#F1F8F5',
  },
  cell: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}));
