import React, { useState, useEffect } from "react";
//import { data } from "./assets"
import { useDragAndDrop } from "./hooks/useDragAndDrop";
import { Data } from "./interfaces";
import { ContainerCards } from "./ContainerCards";
import "./index.css";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import Loading from "src/components/common/Loading";
import { useStyles } from "./classify.styles";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { saveAnswer, saveTracking } from "src/redux/content/thunks";
import Header from "../Header";

interface Item {
  id: number;
  content: string;
  status: string;
}

const classifyItems = (
  typesClassify: [string, string],
  items: Item[]
): { section_1: string[]; section_2: string[]; neutral: string[] } => {
  const section_1: string[] = [];
  const section_2: string[] = [];
  const neutral: string[] = [];

  const [section1Key, section2Key] = typesClassify;

  items.forEach((item) => {
    if (item.status === section1Key) {
      section_1.push(item.content);
    } else if (item.status === section2Key) {
      section_2.push(item.content);
    } else {
      neutral.push(item.content);
    }
  });

  return { section_1, section_2, neutral };
};

const Index = () => {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const [typesClassify, setTypesClassify] = useState<string[]>([]);
  const { content, auth } = useAppSelector((state: RootState) => state);
  useEffect(() => {
    setTypesClassify([
      content.templates[content.currentOrchestratorIndex].section_1,
      content.templates[content.currentOrchestratorIndex].section_2,
    ]);
  }, [content.templates.length]);

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleUpdateCurrentOrchestratorIndex = async () => {
    const { section_1, section_2, neutral } = classifyItems(
      [typesClassify[0], typesClassify[1]],
      listItems
    );
    try {
      const data = {
        user: auth.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        template_response: null,
        template_response_id: null,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        response: {
          section_1: section_1,
          section_2: section_2,
          neutral: neutral,
        },
      };

      // await dispatch(saveAnswer({ ...data }))
      //   .unwrap()
      //   .then((res: any) => {
      //     dispatch(
      //       updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
      //     );
      //   });
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      window.open(`/home`, "_self");
    }
  };

  const data: Data[] = content.templates[
    content.currentOrchestratorIndex
  ].options.map((option: string, index: number) => ({
    id: index + 1,
    content: option,
    status: "neutral",
  }));
  const { isDragging, listItems, handleDragging, handleUpdateList } =
    useDragAndDrop(data);
  if (content.templates.length) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header fnBack={handleBack} />
        </div>
        <div className={classes.body}>
          <div className={classes.titles}>
            <p className={classes.title}>
              Separa {typesClassify[0]} y {typesClassify[1]}
            </p>
          </div>
          <div className={classes.content}>
            <div className="grid">
              {typesClassify.map((container) => (
                <ContainerCards
                  items={listItems}
                  status={container}
                  key={container}
                  isDragging={isDragging}
                  handleDragging={handleDragging}
                  handleUpdateList={handleUpdateList}
                />
              ))}
            </div>
            <div className="grid2">
              <ContainerCards
                items={listItems}
                status={"neutral"}
                key={"neutral"}
                isDragging={isDragging}
                handleDragging={handleDragging}
                handleUpdateList={handleUpdateList}
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleUpdateCurrentOrchestratorIndex}
            >
              <span>¡Listo!</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Index;
