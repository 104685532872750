import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProfileAvatarWrapper } from './styles';
//import useIsLogged from 'hooks/useIsLogged';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';

export const ProfileAvatar = () => {
    const { auth} = useAppSelector((state: RootState) => state);
    //const { isLogged } = useIsLogged();
    const  isLogged  = false;
    const [styles, setStyles] = useState({});
    const [image, setImage] = useState({ avatar: false, letter: false, noUser: true });

    useEffect(() => {
        if (auth?.id && !auth?.image) {
            setStyles({ backgroundColor: auth.color });
            setImage({ avatar: false, letter: true, noUser: false });
        }
        if (auth?.image) {
            setImage({ avatar: true, letter: false, noUser: false });
        }
    }, [auth]);

    return (
        <ProfileAvatarWrapper data-testid="avatar" className={`orenta-profile-avatar ${!isLogged && 'nologged'}`} >
            <div data-testid="rounded" className="rounded-avatar" style={styles}>
                {image.avatar && (
                    <img
                        data-testid="user-avatar"
                        width={64}
                        height={64}
                        src={auth?.image}
                        alt="fóto de perfil"
                        title="fóto de perfil"
                    />
                )}
                {image.letter && (
                    <span data-testid="user-letters" className="user-letters">
                        {auth.letters}
                    </span>
                )}
                {image.noUser && (
                    <img
                        data-testid="no-user"
                        width={64}
                        height={64}
                        src="/static/img/Avatar.svg"
                        alt="usuario no logeado"
                        title="usuario no logeado"
                    />
                )}
            </div>
        </ProfileAvatarWrapper>
    );
};
