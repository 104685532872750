import React from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { useSyles } from './styles';
import { getHeaderAlign } from 'src/helpers/utils';

export type PropsCell = {
  id: string | number;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  center?: boolean;
};
type PropsCellsList = {
  cellsList: PropsCell[];
};

const TableHeader = ({ cellsList }: PropsCellsList) => {
  const classes = useSyles();
  return (
    <>
      {cellsList.length > 0 && (
        <TableHead>
          <TableRow className={classes.row}>
            {cellsList.map((cell: PropsCell) => (
              <TableCell
                key={`prop-cell-${cell.id}`}
                align={getHeaderAlign(cell)}
                padding={cell.disablePadding ? 'none' : 'normal'}
                className={classes.cell}
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
    </>
  );
};

export default TableHeader;
