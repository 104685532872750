import { useStyles } from './index.styles'
import { useDispatch } from 'react-redux';
import Button from 'src/components/common/Button';
import Header from 'src/components/Contents/Header';
import { updateStep } from '../../../../../redux/content/test/slice';

function Template() {
  const dispatch = useDispatch<any>();
  const estilos = null
  const onNext = () => {
    dispatch(updateStep(2));
  };
  const handleBack = () => {
    dispatch(updateStep(0));
  };
  const classes = useStyles({ 
    backgroundColor: "white",
    titleColor: '#149D90',
    titleFontSize: '24px',
    titleWeight: 700,
    titleTextAlign: 'center',
    subtitleColor: '#104B71',
    subtitleFontSize: '16px',
    subtitleWeight: 500,
    subtitleTextAlign: 'center',
  });
    return (
      <div className={classes.root} style={{    backgroundSize: "contain",backgroundRepeat: 'no-repeat'}}>
        {/* <div className={classes.root} style={{    backgroundSize: "contain",backgroundRepeat: 'no-repeat'}} onTouchStart={(e)=>onTouchStart(e)} onTouchMove={(e)=>onTouchMove(e)} onTouchEnd={(e)=>onTouchEnd(e)}> */}
        <div className={classes.overlay} />
        <div className={classes.text}>
          <div className={classes.container}>
            <div className={classes.header}>
              <Header fnBack={handleBack}/>
            </div>
            <div className={classes.body}>
              <div className={classes.content}>
                
                <div className={classes.titles}>
                  <p className={classes.title}>Durante las últimas 2 semanas</p>
                  <p className={classes.subtitle}>¿Qué tan seguido has tenido molestias debido a los siguientes problemas?</p>
                </div> 
                
              </div>
            </div>
            <div className={classes.footer}>
              <div className={classes.buttons}>
                <Button onClick={onNext} >
                  <span >Siguiente</span>
                </Button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  
}

export default Template;