import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useSyles = makeStyles({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },
 
  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '104px',
    margin:'10px',
    borderRadius: '8px',
    background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
      title:{
          color:'#2E95BB',
          /* Heading/Heading 03 */
          fontFamily: 'Playfair Display',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',  
      },
      subtitle:{
          color: '#2E95BB',
          fontFamily: 'Work Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '130%',
          letterSpacing: '-0.42px',
      },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingTop: '10px',
    paddingLeft: '30px',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    alignItems: 'baseline',
    justifyContent: 'left',
    position:"relative",
    '& > img ': {
      width: '100vW',
      bottom: '10px',
      height: 'auto',
      position: 'absolute',
      left: '0px',
      objectFit: 'cover',
    },
  },
  

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
      height:'48px'
    },
  }

});
