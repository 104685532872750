import React from 'react';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { ContentBienestarEmocional, WrapperText, WrapperBienestarEmocional, WrapperImg, Header2, Description, Operator } from './BienestarEmocional.styles';
import Button from 'src/components/common/Button';
import { useNavigate } from 'react-router-dom';

const BienestarEmocional = () => {
    const TITLE_MAIN_IMG = 'Orenta es la solución de bienestar emocional';
    const STATICS = "/images"
    const navigate = useNavigate();
    const goToRequestDemo = () => {
        navigate(`/request-demo`, { replace: true });
    }
    return (
        <WrapperBienestarEmocional  >
            <ContentBienestarEmocional >
                <GridContainer p-lg={'0px'}>
                    <GridItem lg={1} md={4}>
                        <></>
                    </GridItem>
                    
                    <GridItem lg={6} md={4}>
                        <WrapperImg >
                            <div className="row">
                                <div className="logo-lg">
                                    <img
                                    src={`${STATICS}/home/impacto_social.png`}
                                    alt={TITLE_MAIN_IMG}
                                    title={TITLE_MAIN_IMG}
                                    />
                                </div>
                                <div className="logo-sm">
                                    <img
                                    src={`${STATICS}/home/impacto_social_mobile.png`}
                                    alt={TITLE_MAIN_IMG}
                                    title={TITLE_MAIN_IMG}
                                    />
                                </div>
                            </div>
                        </WrapperImg>
                    </GridItem>
                    <GridItem lg={5} md={4}  customOrder={{ order: -1,  mobileOrder: 1 }}>
                        <WrapperText >
                            <Header2><div>Bienestar <span>Emocional</span> <br /> con Impacto Social.</div></Header2>
                            <Description>Cuando cuidas la salud mental de tu colaboradores con Orenta, estás cuidando la de miles de personas menos afortunadas sin acceso apoyo psicológico. Una parte de tus ingresos contribuye a mantener la línea de ayuda gratuita de Fundación Origen.</Description>
                            <GridContainer p-lg={'0px'}>
                                <GridItem lg={8} md={4}>
                                    <Button onClick={goToRequestDemo}>
                                        Solicita una demo
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <Operator>Operado por <img src={`${STATICS}/home/operator2.png`}
                                        alt={""}
                                        title={""} /> Fundación Origen
                            </Operator>
                        </WrapperText>
                    </GridItem>
                </GridContainer>

            </ContentBienestarEmocional>
        </WrapperBienestarEmocional>
    );
};

export default BienestarEmocional;
