// Libraries.
import styled from 'styled-components';



export const WrapperText = styled.div<{ sepTop: string}>`
    color:${({ theme }) => theme.color.white};
    padding-top: '0px';
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding-top: ${props => props.sepTop || '57px'};
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding-top: ${props => props.sepTop || '57px'};
    }
`;

export const ContentRequest = styled.div`
    padding: 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 60px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 60px;
    }
`;

export const ContentImgHappy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: auto;
        height: 234px;
    }  
`;

export const Information = styled.div`
    display: flex;
    padding: 57px 10px 20px 10px;
    flex-direction: column;
    align-items: center;
    gap:10px;
    align-self: stretch;
    border-radius: 8px;
    margin-bottom:0px;
    .information1{
        color: #5396BE;
        text-align: center;
        /* H6 Bold */
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 175% */
        letter-spacing: -0.36px;
    }
`;

export const ContentSuccessText = styled.div`
    align-self: stretch;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; 
    .title{
        color: var(--colors-navy, #104B71);
        /* Title Mobile */
        font-family: Antonia H3;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 100% */
        letter-spacing: -0.9px;
        text-align: center;
        
        @media ${({ theme }) => theme.screenSizes.tablet} {
            text-align: left;
        }
        @media ${({ theme }) => theme.screenSizes.laptop} {
            text-align: left;
        }
    }
    .subtitle{
        color: var(--colors-navy, #104B71);
        /* H6 Bold */
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 175% */
        letter-spacing: -0.36px;
        text-align: center;
        
        @media ${({ theme }) => theme.screenSizes.tablet} {
            text-align: left;
        }
        @media ${({ theme }) => theme.screenSizes.laptop} {
            text-align: left;
        }
    }
`;

export const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 37px 0px 37px 0px;
    button {
      width: 100%;
      margin-top:10px;
      text-transform:initial;
      height:48px;
      border-radius: 30px;
      background-color: ${({ theme }) => theme.color.greenActions};
        @media ${({ theme }) => theme.screenSizes.tablet} {
            
            width: 90%;
        }
        @media ${({ theme }) => theme.screenSizes.laptop} {
            width: 90%;
            
        }
    }
`;

interface H2Props {
    align: string;
    color: string;
  }

export const Header2 = styled.h2<H2Props>`
    font-family: 'Antonia H3';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 100% */
    letter-spacing: -0.9px;
    text-align: ${props => props.align || "left"};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px 19px 0px;
    /* padding-top: 60px;
    padding-bottom: 19px; */
    color: ${props => props.color || 'white'};
    
    span{
        color: ${({ theme }) => theme.color.white};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        text-align: center;
        font-size: 50px;
        line-height: 64px; /* 118.519% */
        letter-spacing: -1.62px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        text-align: center;
        font-size: 50px;
        line-height: 64px; /* 118.519% */
        letter-spacing: -1.62px;
    }
    
`;

export const Description = styled.p`
    font-family: "Work Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 163.636% */
    letter-spacing: -0.66px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 16px;
    color: ${({ theme }) => theme.color.white};

    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 20px 150px 74px 150px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 20px 150px 74px 150px;
    }
`

