import React, { useEffect } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useSyles } from "./introduction.styles";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import { register, updateSelfKnowledge } from "src/redux/content/test/thunks";
import { questions } from "./dataSelfKnowledge";
import { agregarNumeroSiNoExiste, mapper } from "src/helpers/utils";
import { Grid, Button } from "@material-ui/core";
import Loading from "src/components/common/Loading";
import { useNavigate } from "react-router-dom";
import { setTestResults } from "src/redux/auth/thunks";
import { setSelfKnowledge } from '../../../../redux/auth/slice';

const LoadingExperience = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const { contentTest, auth } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (contentTest.step === 3) {
      handlerSubmit();
    }
    // dispatch(updateStep(4));
    // navigate("/home");
  }, [contentTest.step]);

  const handlerSubmit = async () => {
    try {
      const data = {
        user_id: auth.id,
        ct_type_test_id: contentTest.ct_type_test_id,
        answer_1: contentTest.answers[0],
        weight_1: questions[0].weights[contentTest.answers[0]],
        answer_2: contentTest.answers[1],
        weight_2: questions[1].weights[contentTest.answers[1]],
        answer_3: contentTest.answers[2],
        weight_3: questions[2].weights[contentTest.answers[2]],
        answer_4: contentTest.answers[3],
        weight_4: questions[3].weights[contentTest.answers[3]],
        answer_5: contentTest.answers[4],
        weight_5: questions[4].weights[contentTest.answers[4]],
        answer_6: contentTest.answers[5],
        weight_6: questions[5].weights[contentTest.answers[5]],
        answer_7: contentTest.answers[6],
        weight_7: questions[6].weights[contentTest.answers[6]],
        answer_8: contentTest.answers[7],
        weight_8: questions[7].weights[contentTest.answers[7]],
        answer_9: contentTest.answers[8],
        weight_9: questions[8].weights[contentTest.answers[8]],
        answer_10: contentTest.answers[9],
        weight_10: 0,
      };

      let categoriesTmp: number[] = [];

      const weights: Record<string, number> = {
        "4": questions[3].weights[contentTest.answers[3]],
        "5": questions[4].weights[contentTest.answers[4]],
        "6": questions[5].weights[contentTest.answers[5]],
        "7": questions[6].weights[contentTest.answers[6]],
        "8": questions[7].weights[contentTest.answers[7]],
        "9": questions[8].weights[contentTest.answers[8]],
      };

      // Convertir el objeto en un array de pares clave-valor y ordenarlo por valor
      const weightsOrdenado: Array<[string, number]> = Object.entries(
        weights
      ).sort((a, b) => b[1] - a[1]);

      // Obtener el primer índice del arreglo ordenado por valor
      const primerIndiceOrdenado: string | undefined = weightsOrdenado[0]?.[0];
      const segundoIndiceOrdenado: string | undefined = weightsOrdenado[1]?.[0];
      const terceroIndiceOrdenado: string | undefined = weightsOrdenado[2]?.[0];

      if (data.answer_10 !== -1) {
        categoriesTmp.push(data.answer_10);
      }
      if (weightsOrdenado[0]?.[1] > 0) {
        categoriesTmp = agregarNumeroSiNoExiste(
          Number(weightsOrdenado[0]?.[0]) - 4,
          categoriesTmp
        );
      }

      if (weightsOrdenado[1]?.[1] > 0) {
        categoriesTmp = agregarNumeroSiNoExiste(
          Number(weightsOrdenado[1]?.[0]) - 4,
          categoriesTmp
        );
      }

      if (weightsOrdenado[2]?.[1] > 0 && categoriesTmp.length < 3) {
        categoriesTmp = agregarNumeroSiNoExiste(
          Number(weightsOrdenado[2]?.[0]) - 4,
          categoriesTmp
        );
      }

      if (categoriesTmp.length < 3) {
        categoriesTmp = agregarNumeroSiNoExiste(2, categoriesTmp);
      }

      if (categoriesTmp.length < 3 && contentTest.answers[0] !== 0) {
        switch (contentTest.answers[0]) {
          case 1:
            categoriesTmp = agregarNumeroSiNoExiste(3, categoriesTmp);
            break;
          case 2:
            categoriesTmp = agregarNumeroSiNoExiste(4, categoriesTmp);
            break;
          case 3:
            categoriesTmp = agregarNumeroSiNoExiste(1, categoriesTmp);
            break;
          case 4:
            categoriesTmp = agregarNumeroSiNoExiste(2, categoriesTmp);
            break;
        }
      }

      const categoriesMap = mapper(categoriesTmp);

      await dispatch(setTestResults(data)).unwrap();
      // .then((res: any) => {
      // const newData = {
      //   categories: categoriesMap,
      //   categoriesOrig: categoriesTmp,
      //   categories_complement: [],
      // };
      // dispatch(updateSelfKnowledge({ ...newData }))
      //   .unwrap()
      //   .then(() => {
      //     dispatch(updateStep(4));
      //   });
      // });
      dispatch(updateStep(4));
      // navigate("/home");
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleUpdateStep = (newStep: number) => {};

  const goToHome = () => {
    dispatch(setSelfKnowledge(false));
    navigate(`/home`, { replace: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          src="./images/OrentaAll.png"
          alt=""
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>"Diseñando tu experiencia"</p>
        </div>
        <div className={classes.content}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Loading />
          </Grid>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.information}>
          <p className={classes.information1}>"La salud mental es salud"</p>
        </div>
      </div>
    </div>
  );
};
export default LoadingExperience;
