import { useEffect } from "react";
import TagManager from 'react-gtm-module';
import { sendDataLayer } from "src/helpers/gtm/sendDataLayer";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM!
}

const Gtm = () => {
  useEffect(() => {
    if(typeof window !== 'undefined'){
        TagManager.initialize(tagManagerArgs);
        const dataLayer = {
          event: 'pageview'
      };
      sendDataLayer(dataLayer);
    }

  }, []);
  return (<></>
  );
};

export default Gtm;