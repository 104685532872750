// Libraries.
import styled from 'styled-components';



export const WrapperP = styled.div`
    background: var(--gradients-g-1, linear-gradient(180deg, #6375B6 0%, #4E9FC0 100%));
    padding: 100px 50px 0px 50px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 100px 100px 0px 100px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 100px 100px 0px 100px;
    }
`;


export const ContentImgCel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
        position: relative;
        height: 100%;
        img {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }  
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {

        position: relative;
        height: 100%;
        img {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }  
    }
`;

export const ContentImgCodBi = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 30px;
    background: #FFF;
    width: fit-content;
    margin-bottom:100px;
    img {
        width: 100%;
        height: auto;
    }
`;


export const Header1 = styled.h1`
    font-family: 'Antonia H3';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 118.519% */
    letter-spacing: -1.62px;
    text-align: left;
    padding: 0px 0px 0px 0px;
    color: white;
    
    @media ${({ theme }) => theme.screenSizes.tablet} {
        font-size: 54px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        font-size: 54px;
    }
    
`;

export const DescriptionCBI = styled.p`
    font-family: "Work Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 163.636% */
    letter-spacing: -0.66px;
    display: flex;
    align-items: left;
    text-align: left;
    padding: 40px 0px 70px 0px;
    color: ${({ theme }) => theme.color.white};

    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: left;
        text-align: left;
        padding: 40px 0px 70px 0px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 40px 0px 70px 0px;
    }
`

