// Libraries.
import styled from 'styled-components';

export const OrentaMenuItemList = styled.li`
    &.orenta-menu-item,
    &.orenta-submenu-item {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        float: none;
        padding: 0;
        a {
            padding: 16px 16px 16px 16px;
            cursor: pointer;
            display: flex;
            flex-grow: 1;
            align-items: stretch;
            margin: 0;
            text-decoration: none;
            position: relative;
            outline: none;
            font: normal;
            line-height: inherit;
            color: ${({ theme }) => theme.color.dark};
            .orenta-submenu-container {
                display: flex;
                align-items: center;
                flex-grow: 0;
                padding: 0;
            }
            .orenta-menu-item-title {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                align-items: center;
            }
            .orenta-menu-item-icon-arrow {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                line-height: 0;
                color: ${({ theme }) => theme.color.greenPrimary};
                &:before {
                    transition: all 0.3s ease;
                    transform: rotateZ(0deg);
                }
                .right {
                    transition: all 0.3s ease;
                    transform: rotate(90deg);
                    &:after {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-top: 1px solid ${({ theme }) => theme.color.dark};
                        border-right: 1px solid ${({ theme }) => theme.color.dark};
                        transform: rotate(45deg);
                    }
                }
            }
            &:hover {
                background-color: ${({ theme }) => theme.color.greenLight};
            }
        }
        .orenta-submenu-container {
            max-height: 0;
            transition: max-height 0.3s ease-out;
            overflow: hidden;
            .orenta-menu-nav {
                width: 100%;
                padding: 0%;
            }
            a {
                padding: 16px 32px;
                .orenta-menu-item-title {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    &.orenta-submenu-item-open {
        .orenta-menu-item-icon-arrow {
            transform: rotate(180deg);
            .right {
                &:after {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-top: 1px solid ${({ theme }) => theme.color.greenPrimary};
                    border-right: 1px solid ${({ theme }) => theme.color.greenPrimary};
                    transform: rotate(45deg);
                }
            }
        }
        .orenta-submenu-container.open {
            max-height: 500px;
            transition: max-height 0.3s ease-in;
            .orenta-menu-item {
                border: none;
            }
        }
    }
    &.orenta-submenu-item{
        border-bottom: 1px solid ${({ theme }) => theme.color.lightGray2};
        border-top: 1px solid ${({ theme }) => theme.color.lightGray2};
    }
    &:nth-child(2){
        border-top: none;
    }

    @media ${({ theme }) => theme.screenSizes.tablet} {
        &.orenta-menu-item,
        &.orenta-submenu-item {
            a {
                padding: 16px 24px 16px 24px;
                margin: 0 32px;
            }
            .orenta-submenu-container {
                a {
                    padding: 16px 40px;
                    margin: 0 32px;
                }
            }
        }
    }

    @media ${({ theme }) => theme.screenSizes.laptop} {
        &.orenta-menu-item
        &.orenta-submenu-item {
            &:last-child {
                border: none;
            }
            &:first-child {
                border: none;
            }
            a {
                span {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                }
                &:hover {
                    background: ${({ theme }) => theme.color.greenLight};
                }
            }
            .orenta-submenu-container .orenta-menu-nav {
                .orenta-menu-item a {
                    span {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        &.orenta-menu-item,
        &.orenta-submenu-item {
            a {
                padding: 16px 24px 16px 24px;
                margin: 0 0;
            }
            .orenta-submenu-container {
                a {
                    padding: 16px 40px;
                    margin: 0 0;
                }
            }
        }
    }
`;
