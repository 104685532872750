import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import {  Buttons, ContentFormulario, ContentImgCompany, Footer, Header, Job, Name,Email } from './information.styles';
import Loading from 'src/components/common/Loading';
import Button from 'src/components/common/Button';
import JobDescription from 'src/components/Users/JobDescription';


const Information = ({handleSectionClick}:any) => {
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  

  return (
    <ContentFormulario>
      
      <Header>
        <GridContainer p-lg={'0px'} p-sm={'0px'}>
          <GridItem lg={8} sm={6} sx={3}>
                <Name>{`${auth.full_name}`}</Name>
                <JobDescription job={auth.job} department={auth.department}/>
                <Email style={{fontSize:'12px'}}>{`${auth?.email}`}</Email>
          </GridItem>
          <GridItem lg={8} sm={4} sx={1}>
                <ContentImgCompany><img src="" alt="" /></ContentImgCompany>
          </GridItem>
        </GridContainer>
      </Header>
      <Footer>
        <GridContainer p-lg={'0px'} p-sm={'0px'}>
          <GridItem lg={12}>
            {!loading ? (
              <Buttons>
                <Button
                  onClick={() => handleSectionClick(2)}>
                  Editar mi perfil
                </Button>
                
              </Buttons>
            ) : (
              <Loading height='150px' />
            )}
          </GridItem>
            
        </GridContainer>
      </Footer>
    </ContentFormulario>
  );

};
export default Information;
