import { makeStyles } from "@material-ui/core";

interface StylesProps {
  colorHeader: string;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    paddingBottom: "0px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    background: "#FFF",
  },

  header: (props: StylesProps) => ({
    width: "calc(100vw - 20px)",
    height: "74px",
    margin: "10px",
    borderRadius: "8px",
    background: props.colorHeader,
    display: "flex",
    flexShrink: 0,
    justifyContent: "left" /* Centrado horizontal */,
    alignItems: "center",
    "& > img ": {
      width: "30%",
      height: "auto",
    },
  }),
  body: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
  },
  titles: {
    alignSelf: "stretch",
    display: "flex",
    width: "100%",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  title: {
    color: "#625B71)",
    /* M3/title/medium */
    fontFamily: "Antonia H3",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.15px",
    textAlign: "center",
  },
  subtitle: {
    color: "#625B71",
    /* M3/label/medium - prominent */
    fontFamily: "Work Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px" /* 133.333% */,
    letterSpacing: "0.5px",
  },

  content: {
    flex: 2,
    display: "flex",
    width: "100%",
    paddingBottom: "0px",
    flexDirection: "column",
    // justifyContent: 'center',
    backgroundColor: "#FFF",
    //alignItems: 'center',
  },
  animationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "auto",
    animation: "$fadeIn ease-in-out 2s",
  },
  circle: {
    width: 200,
    height: 200,
    borderRadius: "50%",
    background: "#D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  checkIcon: {
    position: "absolute",
    fontSize: 100,
    color: "white",
    opacity: 0,
    animation: "$checkSlide ease-in-out 2s forwards",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  "@keyframes checkSlide": {
    "0%": { opacity: 0, transform: "translateY(-20px)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
  },

  footer: {
    backgroundColor: "#FFF",
    color: "white",
    textAlign: "center",
    width: "100%",
    height: "auto",
  },

  buttons: {
    width: "100%",
    display: "grid",
    padding: "16px",
    "& > button ": {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      marginTop: "10px",
      textTransform: "initial",
      height: "48px",
    },
  },
  information: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    padding: "16px",
    maxHeight: "100px",
    "& > img ": {
      width: "20%",
      height: "auto",
    },
  },
  information1: {
    alignSelf: "stretch",
    color: "#000000",
    textAlign: "left",
    /* Heading/Heading 06/Bold */
    fontFamily: "Work Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px" /* 130% */,
  },
  information2: {
    alignSelf: "stretch",
    color: "#003C3C",
    textAlign: "center",
    /* Heading/Heading 06/Medium */
    fontFamily: "Work Sans",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%" /* 14px */,
    letterSpacing: "-0.3px",
    "& > a ": {
      color: "#2E95BB",
      /* Heading/Heading 06/Bold */
      fontFamily: "Work Sans",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "13px",
    },
  },
}));
