import { useState } from 'react';
import parse from 'html-react-parser';
import { AccordionItem, AccordionItemTitle, AccordionItemContent, TitleFaq ,ImageIcon,HrDivisor} from './styles';

type QuestionProps = {
    name: string;
    acceptedAnswer: {
        text: string;
    };
    icon: string;
};

type FacilAccordionProps = {
    schema: {
        mainEntity: QuestionProps[];
    };
    titleAs?: 'h3' | 'h2' | 'h4' | 'h5';
};

const FacilAccordion = ({ schema, titleAs }: FacilAccordionProps) => {
    const [activeState, setActiveState] = useState([true]);
    const toggleAccordion = (index: number) => {
        let newActiveState = activeState.slice();
        if (activeState[index] === true) {
            newActiveState[index] = !newActiveState[index];
        } else {
            newActiveState = activeState.map(() => false);
            newActiveState[index] = true;
        }
        setActiveState(newActiveState);
    };

    return (
        <>
            {schema?.mainEntity.map((question, index) => (
                <AccordionItem
                    key={index}
                    active={activeState[index]}
                    className="accordion-item"
                    data-testid={`container-q${index + 1}`}
                    data-gtm={`container-q${index + 1}`}
                >
                    <AccordionItemTitle className="accordion-item-title" onClick={() => toggleAccordion(index)} active={activeState[index]}>
                        <ImageIcon src={question.icon} /><TitleFaq as={titleAs}>{question.name}</TitleFaq>
                    </AccordionItemTitle>
                    <HrDivisor active={activeState[index]} />
                    <AccordionItemContent className="accordion-item-content" active={activeState[index]}>
                        {parse(question.acceptedAnswer.text)}
                    </AccordionItemContent>
                </AccordionItem>
            ))}
        </>
    );
};

export default FacilAccordion;
