import styled from 'styled-components';



export const SectionServices = styled.section`
   background-color: transparent;
   
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 65px 92px 32px 92px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 95px 132px 32px 132px;
    }
    
`;


// Estilos para una tarjeta individual
export const Card = styled.div`
  background: transparent;
  height: 170px;
  margin-bottom: 48px;
  img {
    width: -webkit-fill-available;
    height: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  h2{
    padding: 14px;
    /* SubTitle */
    color: #104B71;
    text-align: center;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 140% */
    letter-spacing: -0.42px;

  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  
`;