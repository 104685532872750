const { default: styled } = require('styled-components');

export const WrapperProfileMenu = styled.div`
    padding-left: 16px;
    .orenta-profile-menu {
        display: flex;
        margin-top: 24px;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    .orenta-profile-avatar-info {
        display: grid;
        width: 100%;
        margin-top: 24px;
        .orenta-menu-item-icon-arrow {
            transform: rotate(90deg);
        }
        .orenta-menu-item-name {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: ${({ theme }) => theme.color.dark};
        }
        .orenta-menu-item-email {
            font-family: 'Work Sans';
            margin: 8px 0 32px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: ${({ theme }) => theme.color.grayMedium};
            display: block;
        }
    }

    &.nologged {
        .login-signin {
            margin-top: 24px;
            width: 100%;
            a {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-decoration-line: underline;
                color: ${({ theme }) => theme.color.greenPrimary};
                &:nth-child(1) {
                    margin-right: 24px;
                }
                &:nth-child(2) {
                    margin-left: 24px;
                }
            }
        }
        .orenta-wrapper-user-menu {
            height: 168px;
            display: flex;
            align-items: center;
        }
        .orenta-profile-avatar-info {
            display: none;
        }
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding-left: 32px;
        &.nologged {
            .orenta-wrapper-user-menu {
                height: 120px;
                .login-signin {
                    margin: 0px;
                    width: auto;
                }
                a:nth-child(1) {
                    margin-right: 32px;
                }
                a:nth-child(2) {
                    margin-left: 32px;
                }
            }
            .orenta-profile-menu {
                display: flex;
                align-items: center;
                flex-direction: row;
            }
        }
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        height: 80px;
        &.nologged {
            .orenta-wrapper-user-menu {
                height: 80px;
            }
            .login-signin {
                display: none;
            }
        }
        .orenta-wrapper-user-menu {
            display: flex;
            display: flex;
            align-items: center;
            height: 82px;
        }
        .orenta-profile-menu {
            margin-top: 0px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .orenta-profile-avatar-info {
                display: inline-block;
                vertical-align: top;
                margin-top: 0px;
                width: auto;
                .orenta-menu-item-name {
                    font-size: 16px;
                    line-height: 24px;
                }
                .orenta-menu-item-email {
                    display: none;
                }
            }
        }
        &.orenta-user-menu {
            padding: 0;
            .orenta-wrapper-user-menu {
                .orenta-menu-item-name {
                    max-width: auto;
                    display: block;
                    /* margin-top:4px; */
                    .textName {
                        max-width: 134px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        vertical-align: top;
                    }
                    .orenta-menu-item-icon-arrow {
                        display: inline-block;
                        margin: 0 6px;
                    }
                }
            }
        }
    }
`;

export const ArrowButton = styled.i`
    display: none;
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: inline-block;
        margin-right: 4px;
        margin-left: 10px;
        margin-top: 0px;
        &:before {
            transition: all 0.3s ease;
            transform: rotateZ(0deg);
        }
        .right {
            transition: all 0.3s ease;
            transform: rotate(0deg);
            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 1px solid ${({ theme }) => theme.color.dark};
                border-right: 1px solid ${({ theme }) => theme.color.dark};
                transform: rotate(45deg);
            }
        }
    }
`;
