import React, {  useState } from 'react';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSyles } from './index.styles';
import { useDispatch } from 'react-redux';
import Loading from '../../common/Loading';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { RegisterProspectRequest } from 'src/redux/auth/models/RegisterRequest';
import { createProspect } from 'src/redux/prospects/thunks';
import Header from '../header';
import { useStylesG } from '../Login/index.styles';
import Button from 'src/components/common/Button';

const EmailNotFound = () => {
  const classes = useSyles({ colorHeader: '#FFFFF' });
  const classesG = useStylesG({});
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = false;//useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  const initialValues = {
    name_company: '',
    name: '',
    lastname: '',
    human_resources_manager: '',
    email_manager: '',
  };

  const handlerSubmit = (values: RegisterProspectRequest) => {
    dispatch(
      createProspect({ ...values})
    )
      .unwrap()
      .then(() => {
        setSuccess(true);
      });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    name_company: yup.string().required('El nombre de la empresa es requerido'),
    name: yup.string().required('El nombre es requerido'),
    lastname: yup.string().required('El apellido es requerido'),
    human_resources_manager: yup.string().required('El nombre del encargado de RH es requerido'),
    email_manager: yup.string().required('El email del encargado de RH es requerido'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });


  const goToLogin = () => {
    navigate(`/login`, { replace: true });
  };

  return (
    <div className={classesG.container}>
      <Header title={"Hola, parece que tu empresa aún no forma parte de Orenta 😔"} 
              subtitle={"Si quieres llevar Orenta a tu empresa, ayúdanos a conectar con la persona encargada en tu equipo."}
              colorHeader={"#014951"}
              heightHeader={'310px'}
      />
      <div className={classesG.body}>
       
        <div className={classes.content}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={classes.row}>
        <TextField 
          id='form-name_company'
          name='name_company'
          label="Nombre de tu empresa"
          variant='outlined'
          fullWidth
          value={formik.values.name_company}
          disabled={loading}
          onChange={formik.handleChange}
          error={formik.touched.name_company && Boolean(formik.errors.name_company)}
          helperText={
            formik.touched.name_company &&
            Boolean(formik.errors.name_company) &&
            formik.errors.name_company
          } className={classes.inputR} />
      </div>
        <div className={classes.row}>
        <TextField 
          id='form-name'
          name='name'
          label="Tu nombre"
          variant='outlined'
          fullWidth
          value={formik.values.name}
          disabled={loading}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={
            formik.touched.name &&
            Boolean(formik.errors.name) &&
            formik.errors.name
          }
          className={classes.inputL} />
        <TextField
        id='form-lastname'
        name='lastname'
        label="Tu apellido" 
        variant='outlined'
        fullWidth
        value={formik.values.lastname}
        disabled={loading}
        onChange={formik.handleChange}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={
          formik.touched.lastname &&
          Boolean(formik.errors.lastname) &&
          formik.errors.lastname
        }
        className={classes.inputR} />
      </div>
      <div className={classes.row}>
        <TextField 
        id='form-human_resources_manager'
        name='human_resources_manager'
        label="Nombre del encargado" 
        variant='outlined'
        fullWidth
        value={formik.values.human_resources_manager}
        disabled={loading}
        onChange={formik.handleChange}
        error={formik.touched.human_resources_manager && Boolean(formik.errors.human_resources_manager)}
        helperText={
          formik.touched.human_resources_manager &&
          Boolean(formik.errors.human_resources_manager) &&
          formik.errors.human_resources_manager
        }
        className={classes.inputR} />
      </div>
      <div className={classes.row}>
        <TextField 
        id='form-name'
          name='email_manager'
          label="Correo de la persona encargada"
          variant='outlined'
          fullWidth
          value={formik.values.email_manager}
          disabled={loading}
          onChange={formik.handleChange}
          error={formik.touched.email_manager && Boolean(formik.errors.email_manager)}
          helperText={
            formik.touched.email_manager &&
            Boolean(formik.errors.email_manager) &&
            formik.errors.email_manager
          }
            className={classes.inputR} />
      </div>
    
       </form>
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
             <div className={classes.information}>
              <p className={classes.information1}>Si tienes algún problema para acceder a Orenta, no dudes en escribirnos a soporte.orenta@forigen.org  ¡Estamos aquí para ayudarte en todo lo que necesites!</p>
             </div>
             <Button onClick={onAction} >
                <span >Enviar solicitud</span>
              </Button>
              <Button
                  backgroundColor='transparent'
                  borderColor='#EDEDED'
                  onClick={goToLogin}>
                  Volver
                </Button>
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default EmailNotFound;
