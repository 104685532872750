import React, { useState } from 'react';
import Icon from 'src/components/common/Icon';
import styled from 'styled-components';

const NavigationBar = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f2f2f2;
  z-index: 1000;
`;

const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
  
const NavSectionActive = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#014951' : 'transparent')};
  display: flex;
  width: 64px;
  height: 32px;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color:${(props) => (props.active ? '#FFFFFF' : '#000000')};
  svg {
    width: 24px;
    height: 24px;
    path{
      fill:${(props) => (props.active ? '#FFFFFF' : '#000000')};
    }
  }
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
`;

const Text = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
`;

const Navigation = ({activeSection,handleSectionClick}:any) => {
  

  return (
    <NavigationBar>
      <NavSection  onClick={() => handleSectionClick(1)}>
        <NavSectionActive active={activeSection === 1}>
          <Icon icon="orenta-icon-house" />
        </NavSectionActive>
        <Text>Inicio</Text>
      </NavSection>
      <NavSection  onClick={() => handleSectionClick(2)}>
        <NavSectionActive active={activeSection === 2}>
          <Icon icon="orenta-icon-search" />
        </NavSectionActive>
        <Text>Biblioteca</Text>
      </NavSection>
      <NavSection  onClick={() => handleSectionClick(3)}>
        <NavSectionActive active={activeSection === 3}>
          <Icon icon="orenta-icon-user-circle" />
        </NavSectionActive>
        <Text>Perfil</Text>
      </NavSection>
    </NavigationBar>
  );
};


export default Navigation;
