import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
//Material
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
//Components
import { useSyles } from './Register.styles';
// Redux
import { useDispatch } from 'react-redux';
import { RegisterRequest } from '../../../redux/auth/models/RegisterRequest';
import { useAppSelector } from '../../../redux/hooks';
import { register } from '../../../redux/auth/thunks';
import { AreaOptions } from '../../../domain/constants/Areas';

export function Register(_props: any) {
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const classes = useSyles();
  const [success, setSuccess] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    id_area: '',
  };

  const handlerSubmit = (values: RegisterRequest) => {
    dispatch(
      // register({ ...values, id_area: parseInt(values.id_area.toString()) })
    )
      .unwrap()
      .then(() => {
        setSuccess(true);
      });
  };

  // función que redirige de forma programada
  const backToLogin = () => {
    navigate(`/login`, { replace: true });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    name: yup.string().required('El nombre es un campo requerido'),
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('El correo electrónico es un campo requerido'),
    phone: yup
      .number()
      .required('Digita el número de teléfono')
      .max(999999999999999, 'El teléfono no debe ser menor a 15 dígitos'),
    id_area: yup.number().required('Selecciona el tipo de area'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img
          alt='logo-keys'
          style={{ objectFit: 'contain' }}
          src={'/images/OrentaAll.png'}
        />
      </div>
      {!success && (
        <form className={classes.box} onSubmit={formik.handleSubmit}>
          <TextField
            id='form-name'
            name='name'
            label='Nombre'
            variant='outlined'
            fullWidth
            value={formik.values.name}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              formik.touched.name &&
              Boolean(formik.errors.name) &&
              formik.errors.name
            }
          />
          <TextField
            id='form-email'
            name='email'
            label='Correo electrónico'
            variant='outlined'
            fullWidth
            value={formik.values.email}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email &&
              Boolean(formik.errors.email) &&
              formik.errors.email
            }
          />
          <TextField
            id='form-phone'
            name='phone'
            label='Teléfono'
            variant='outlined'
            fullWidth
            value={formik.values.phone}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={
              formik.touched.phone &&
              Boolean(formik.errors.phone) &&
              formik.errors.phone
            }
          />
          <FormControl variant='outlined'>
            <InputLabel>Empresa</InputLabel>
            <Select
              id='form-id_area'
              name='id_area'
              value={formik.values.id_area}
              onChange={formik.handleChange('id_area')}
              error={formik.touched.id_area && Boolean(formik.errors.id_area)}
              label='Empresa de usuario'
            >
              <MenuItem value=''>
                <em>Selecciona una empresa</em>
              </MenuItem>
              {AreaOptions.map(({ id, name }: any) => (
                <MenuItem key={`area-section-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.flex}>
            <Button onClick={backToLogin}>Regresar</Button>
            <Button
              disabled={loading}
              className={classes.button}
              size='medium'
              color='secondary'
              variant='contained'
              onClick={onAction}
            >
              {'Registrarse'}
            </Button>
          </div>
        </form>
      )}
      {success && (
        <div className={classes.box}>
          <h1>Registro completado</h1>
          <span>
            Hemos enviado tu contraseña a tu correo electrónico{' '}
            <b>{formik.values.email}</b> para que puedas ingresar. Ingresa a tu
            correo para obtener tu contraseña. Se validará tu informacion con el
            administrador
          </span>
          <div className={classes.flex}>
            <Button
              className={classes.button}
              color='secondary'
              onClick={backToLogin}
            >
              Iniciar sesión
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
