import React, {useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { useSyles } from './sendEmailSuccess.styles';
import { useAppSelector } from 'src/redux/hooks';
import Loading from '../../common/Loading';
import { RootState } from 'src/domain/models/RootState';
import { ocultarCorreo } from 'src/helpers/utils';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import Button from 'src/components/common/Button';


const SendEmailSuccess = () => {
  const classes = useSyles({ colorHeader: '#FFFFF' });
  const classesG = useStylesG({});
  const { auth } = useAppSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);

  const emailHidden = ocultarCorreo(auth.register.email)
  const nothing = () => {

  }

  return (
    <div className={classesG.container}>
      <Header title={"Correo enviado"} 
              subtitle={`Enviamos un link de recuperacion a tu correo ${emailHidden}`}
              colorHeader={"#014951"}
              heightHeader={'210px'}
      />
      
      <div className={classesG.body}>
        <div className={classes.content}>
        <div className={classes.animationContainer}>
          <div className={classes.circle}>
            <CheckIcon className={classes.checkIcon} />
          </div>
        </div>
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
            <Button onClick={nothing} >
                <span >Verificar Correo Electrónico</span>
            </Button>
             
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default SendEmailSuccess;
