import { PropsCell } from '../common/Table/TableHeader';

export const headCells: PropsCell[] = [
  { id: 'ud', numeric: false, disablePadding: false, label: 'ID' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    center: false,
  },
  { id: 'ct_companies_status', numeric: false, disablePadding: false, label: 'ESTATUS' },
  {
    id: 'obsercation',
    numeric: false,
    disablePadding: false,
    label: 'Observaciones',
  },
  {
    id: 'domain',
    numeric: false,
    disablePadding: false,
    label: 'DOMINIO',
    center: true,
  },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Acciones' },
];

export const fieldsFilter = [
  { key: 'nid', name: 'NID' },
  { key: 'url', name: 'URL' },
  { key: 'address', name: 'Dirección' }
];
