import { useState, useEffect } from "react";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import { useNavigate } from "react-router-dom";
import { Card, SectionServices } from "./Services.styles";
import { getCompanyHelpLine } from "src/redux/companies/apis";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";

const Services = () => {
  const navigate = useNavigate();
  const { auth } = useAppSelector((state: RootState) => state);
  const [companyHelpLineTitle, setCompanyHelpLineTitle] = useState("");

  useEffect(() => {
    const fetchHelpLineTitle = async () => {
      try {
        const helpLineTitle = await getCompanyHelpLine(auth.id);
        setCompanyHelpLineTitle(helpLineTitle || "Linea de ayuda");
      } catch (error) {
        console.log("Error fetching help line title:", error);
      }
    };

    fetchHelpLineTitle();
  }, []);

  const cardsData = [
    [
      {
        image: "/images/home/employee/Shortcuts/Enlineacontigo.png",
        title: companyHelpLineTitle,
        text: "",
        url: "/linea-contigo",
      },
      {
        image: "/images/home/employee/Shortcuts/whatsapp.png",
        title: "Whatsapp",
        text: "",
        url: "/whatsapp",
      },
    ],
    [
      {
        image: "/images/home/employee/Shortcuts/TestDeBienestar.png",
        title: "Test de bienestar",
        text: "",
        url: "/test-bienestar",
      },
      {
        image: "/images/home/employee/Shortcuts/TuTerapia.png",
        title: "Tu terapia",
        text: "",
        url: "/terapia-invidual",
      },
    ],
  ];

  const goToUrl = (url: string) => {
    navigate(`${url}`, { replace: true });
  };

  return (
    <SectionServices>
      {cardsData.map((cards, index) => (
        <GridContainer
          p-lg={"0px"}
          key={index}
        >
          {cards.map((card, index) => (
            <GridItem
              lg={4}
              sm={2}
              key={index}
            >
              <Card onClick={() => goToUrl(card.url)}>
                <>
                  {card.image !== "" && (
                    <img
                      src={card.image}
                      alt={card.title}
                    />
                  )}
                  {card.title !== "" && <h2>{card.title}</h2>}
                  {card.text !== "" && (
                    <div dangerouslySetInnerHTML={{ __html: card.text }} />
                  )}
                </>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      ))}
    </SectionServices>
  );
};

export default Services;
