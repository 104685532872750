import React, { useState, useEffect } from 'react';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { Card, SectionBeneficios,Title } from './Beneficios.styles';
import Button from '@material-ui/core/Button';

const Beneficios = () => {
  const [activeTab, setActiveTab] = useState(1);

  const tabsTitles = ["Tu aliado en tu bienestar organizacional.", "Tu espacio de bienestar a tu manera."];
  const tabs = ["Empresas", "Colaboradores"];
  const cardsData = [
    [
      { title: "66%", text: "Disminución de ausencias por enfermedad",reference:"Forbes, 2010" },
      { title: "108%", text: "Mayor compromiso por parte de tus colaboradores",reference:"Pryce-Jones, 2011"},
      { title: "59%", text: "Disminución de la rotación de personal",reference:"Gallup, 2017"},
    ]
  ];

  

  return (
    <SectionBeneficios>
      <GridContainer p-lg={'0px'}>
          <GridItem lg={12}>
             <Title>Únete a los líderes que cuidan la salud mental de su gente y experimenta los beneficios:</Title>
          </GridItem>
      </GridContainer>
      <GridContainer p-lg={'0px'}>
        {cardsData[activeTab - 1].map((card, index) => (
          <GridItem lg={4} >
            <Card key={index}>
              <h2>{card.title}</h2>
              <div
                  dangerouslySetInnerHTML={{__html: card.text}}
                />
              <p
                  dangerouslySetInnerHTML={{__html: card.reference}}
                />
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </SectionBeneficios>
  );
};

export default Beneficios;
