import { makeStyles } from '@material-ui/core';

interface StylesProps {
  backgroundColor?: string;
  titleHeaderColor?: string;
  titleHeaderFontSize?: string;
  titleHeaderTextAlign?: any;
  titleHeaderWeight?: number;
  titleHeaderBackgroundColor?: string;
  subtitleHeaderColor?: string;
  subtitleHeaderFontSize?: string;
  subtitleHeaderWeight?: number;
  subtitleHeaderTextAlign?: any;

  titleBodyColor?: string;
  titleBodyFontSize?: string;
  titleBodyTextAlign?: any;
  titleBodyWeight?: number;
  titleBodyBackgroundColor?: string;
  subtitleBodyColor?: string;
  subtitleBodyFontSize?: string;
  subtitleBodyWeight?: number;
  subtitleBodyTextAlign?: any;

  titleFooterColor?: string;
  titleFooterFontSize?: string;
  titleFooterTextAlign?: any;
  titleFooterWeight?: number;
  titleFooterBackgroundColor?: string;
  subtitleFooterColor?: string;
  subtitleFooterFontSize?: string;
  subtitleFooterWeight?: number;
  subtitleFooterTextAlign?: any;
}

export const useStyles = makeStyles((theme) => ({
  
  container: (props: StylesProps) => ({
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: props.backgroundColor,
  }),

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '40px',
    margin:'10px',
    borderRadius: '8px',
    //background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
    
  },
    titles:{
      alignSelf: 'stretch',
      //display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      '& > img ': {
        width: '100vw',
        height: 'auto',
      },
    },
    titleHeader:(props: StylesProps) => ({
      //color: props.urlGif ? 'white':'black',
      color: props.titleHeaderColor,
      /* M3/titleHeader/medium */
      fontFamily: 'Antonia H3',
      fontSize: props.titleHeaderFontSize,
      fontStyle: 'normal',
      fontWeight: props.titleHeaderWeight,
      lineHeight: '54px', /* 150% */
      letterSpacing: '0.15px',
      paddingTop:'20px',
      textAlign: props.titleHeaderTextAlign,
      background: props.titleHeaderBackgroundColor,
      padding: "0px 16px",
    }),
    subtitleHeader:(props: StylesProps) => ({
        //color: props.urlGif ? 'white':'black',
        color: props.subtitleHeaderColor,
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: props.subtitleHeaderFontSize,
        fontStyle: 'normal',
        fontWeight: props.subtitleHeaderWeight,
        lineHeight: '16px', /* 133.333% */
        letterSpacing: '0.5px',
        textAlign:props.subtitleHeaderTextAlign,
        padding: "0px 16px",
        '& > img ': {
          
          paddingTop:'30px'
        },
        
    }),
    titleBody:(props: StylesProps) => ({
      //color: props.urlGif ? 'white':'black',
      color: props.titleBodyColor,
      /* M3/titleBody/medium */
      fontFamily: 'Antonia H3',
      fontSize: props.titleBodyFontSize,
      fontStyle: 'normal',
      fontWeight: props.titleBodyWeight,
      lineHeight: '54px', /* 150% */
      letterSpacing: '0.15px',
      paddingTop:'20px',
      textAlign: props.titleBodyTextAlign,
      padding: "0px 16px",
    }),
    subtitleBody:(props: StylesProps) => ({
        //color: props.urlGif ? 'white':'black',
        color: props.subtitleBodyColor,
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: props.subtitleBodyFontSize,
        fontStyle: 'normal',
        fontWeight: props.subtitleBodyWeight,
        lineHeight: '16px', /* 133.333% */
        letterSpacing: '0.5px',
        textAlign:props.subtitleBodyTextAlign,
        padding: "0px 16px",
        '& > img ': {
          
          paddingTop:'30px'
        },
        
    }),
    titleFooter:(props: StylesProps) => ({
      //color: props.urlGif ? 'white':'black',
      color: props.titleFooterColor,
      /* M3/titleFooter/medium */
      fontFamily: 'Antonia H3',
      fontSize: props.titleFooterFontSize,
      fontStyle: 'normal',
      fontWeight: props.titleFooterWeight,
      lineHeight: '54px', /* 150% */
      letterSpacing: '0.15px',
      paddingTop:'20px',
      textAlign: props.titleFooterTextAlign
    }),
    subtitleFooter:(props: StylesProps) => ({
        //color: props.urlGif ? 'white':'black',
        color: props.subtitleFooterColor,
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: props.subtitleFooterFontSize,
        fontStyle: 'normal',
        fontWeight: props.subtitleFooterWeight,
        lineHeight: '16px', /* 133.333% */
        letterSpacing: '0.5px',
        textAlign:props.subtitleFooterTextAlign,
        '& > img ': {
          
          paddingTop:'30px'
        },
        
    }),
      sub_footer:{
        color: '#625B71',
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '26px', /* 133.333% */
        letterSpacing: '0.5px',
        textAlign:'center',
        wordSpacing: '20px',
        padding: "40px 40px 0px 40px",
    },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width:'100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
    width:'100%'
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    
  },
  information:{
    display: 'flex',
    padding: '16px 10px 20px 10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'10px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#E2F7FE',
    marginBottom:'40px'
  },
  information1:{
    alignSelf: 'stretch',
    color: '#2E95BB',
    textAlign: 'center',
    /* Heading/Heading 06/Bold */
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '13px', /* 130% */
    paddingBottom: "2rem"
  },
  information2:{
    alignSelf: 'stretch',
    color: '#003C3C',
    textAlign: 'center',
    /* Heading/Heading 06/Medium */
    fontFamily: 'Work Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%', /* 14px */
    letterSpacing: '-0.3px',
    
  }

}));
