import React, { useState } from 'react';


const Index = () => {
  return (
    <div className='flex flex-row !mt-12 mb-4 pl-4 py-2 gap-2 items-center border border-red-600 rounded-lg shadow overflow-hidden darkNot:bg-gray-900 darkNot:border-violet-400'>
      <span className='flex-shrink-0 inline-flex mx-0 item-center justify-center leadi rounded-full darkNot:bg-violet-400 darkNot:text-gray-900'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          className='h-8 w-8 fill-red-500'
        >
          <path
            fillRule='evenodd'
            d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'
            clipRule='evenodd'
          ></path>
        </svg>
      </span>
      <div className='flex-1 p-2'>
        <p className='text-sm darkNot:text-gray-100'>
          Lo sentimos, hubo un error. <br /> Inténtalo de nuevo.
        </p>
      </div>
    </div>        
  );
};

export default Index;
