import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSyles } from './styles';

type PropsWrapper = {
  button?: boolean;
  buttonTitle?: string | number;
  actionTitle?: () => void;
  children: any;
};
const Wrapper = ({
  button,
  buttonTitle,
  actionTitle,
  children,
}: PropsWrapper) => {
  const classes = useSyles();
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.containerHeader} container item spacing={4}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Wrapper;
