import React, { useEffect } from 'react';
import { useStyles } from './terms.styles';
import Button from 'src/components/common/Button';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import { useNavigate } from 'react-router-dom';


const Policy = ({onShowTerms,view}:any) => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const classesG = useStylesG({});
  const navigate = useNavigate();

  useEffect(() => {
    navigate('?view=terms');
  }, []);

  const handleShowTerms = () => {
    navigate(window.location.pathname,{replace:true});
    if(onShowTerms){
      onShowTerms(false);
    }else{
      window.open(`/home`,'_self');
    }
  }

  return (
    <div className={classesG.container}>
    <Header title={"Términos y políticas"} 
            subtitle={"Términos y Condiciones de Uso de la Plataforma ORENTA de Fundación ORIGEN"}
            colorHeader={"#014951"}
            heightHeader={'240px'}
    />
    
      <div className={`${classesG.body} ${classes.scroll}`}>
        <div className={classes.titles}>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>1. Contenidos de la Plataforma</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Los consejos, artículos, blogs, videos y otra información publicada en la plataforma ORENTA  Fundación ORIGEN (en adelante, el "Contenido") no deben considerarse como servicios o tratamientos médicos. El Contenido se ofrece con el objetivo de mejorar el bienestar del Usuario cuando no se encuentre en una situación de emergencia. En ningún caso el Contenido debe entenderse como un reemplazo del tratamiento psicológico profesional, y el Usuario deberá siempre consultar a un profesional de la salud ante cualquier circunstancia Ningún Contenido representa o garantiza que algún medicamento o tratamiento en particular sea seguro, apropiado o efectivo.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>2. Usos Permitidos de la Plataforma</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>El uso de la plataforma es de exclusiva responsabilidad del usuario, quien deberá servirse de la misma de acuerdo a las funcionalidades permitidas y usos autorizados en estos Términos y Condiciones. El usuario se obliga a utilizarla respetando las normas de Internet, las leyes aplicables donde la use, las buenas costumbres y derechos de terceros. Los usuarios se comprometen a utilizar el Contenido exclusivamente para su uso personal, sin explotación comercial.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>3. Propiedad Intelectual</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>La Fundación ORIGEN es titular de todos los derechos de propiedad intelectual e industrial referidos a los contenidos publicados en la plataforma, los cuales no podrán ser utilizados por terceros sin su autorización.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>4. Política de Privacidad</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>ORENTA  procesará los datos personales que el usuario proporcione o facilite a través de la plataforma ORENTA de conformidad con su Política de Privacidad.</p>
            <br />
            <p className={classes.subtitle}>ORENTA se compromete a tratar los datos personales de los usuarios con fines de: prestación y mejora de los servicios ofrecidos en la plataforma, envío de comunicaciones sobre novedades en la plataforma y eventos de la Fundación, y realización de encuestas de satisfacción.</p>
            <br />
            <p className={classes.subtitle}> Los usuarios podrán ejercer en cualquier momento los derechos de acceso, rectificación, cancelación y oposición previstos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, mediante solicitud dirigida a ORENTA a través del correo:   
privacidad@orenta.mx </p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>5. Cuenta del usuario</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Para poder acceder a ciertas funcionalidades de la plataforma, ORENTA puede requerir que el usuario se registre y cree una cuenta de usuario (en adelante, la "Cuenta"). Al registrarse, el usuario acepta (a) proporcionar datos precisos, actualizados y completos sobre su persona, según se solicita en el formulario de registro; y (b) mantener actualizada la información para que sea precisa, veraz, actualizada y completa.</p>
            <br />
            <p className={classes.subtitle}>Todas las cuentas de los usuarios son propiedad de ORENTA y pueden ser canceladas o suspendidas en cualquier momento sin previo aviso, si incumplen algún punto de este apartado o se identifica mal uso de la plataforma.</p>
            <br />
            <p className={classes.subtitle}>La confidencialidad de los datos de la cuenta y la contraseña del usuario son responsabilidad exclusiva del usuario. Además, todas las actividades realizadas en la plataforma bajo su nombre de usuario y contraseña son responsabilidad exclusiva del usuario.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>6. Propiedad intelectual e industrial</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Todos los contenidos, materiales e información publicados en la plataforma ORENTA son propiedad de la Fundación ORIGEN, quien ostenta todos los derechos de propiedad intelectual e industrial sobre los mismos.</p>
            <br />
            <p className={classes.subtitle}>Entre dichos contenidos se encuentran, sin limitarse a ello, textos, imágenes, logotipos, archivos de audio y video. Asimismo, la Fundación es titular de los derechos de autor y marcas registradas relacionadas con la plataforma.</p>
            <br />
            <p className={classes.subtitle}>Queda estrictamente prohibido para terceros utilizar, reproducir, distribuir, comunicar públicamente o modificar cualquier contenido de la plataforma sin la debida autorización por escrito de la Fundación ORIGEN.</p>
            <br />
            <p className={classes.subtitle}>Los usuarios únicamente tendrán derecho a acceder a la información disponible en la plataforma para su uso personal, no pudiendo realizar ningún otro uso no autorizado expresamente en estos términos y condiciones.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>7. Responsabilidad e indemnización</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Cada usuario se compromete a asumir la responsabilidad y a indemnizar a ORENTA, sus empresas asociadas, accionistas, directores, representantes, empleados, y cualquier otra entidad relacionada, frente a cualquier reclamo, demanda, acción legal, penalidades, intereses, costos, multas o honorarios, presentados por otros usuarios o terceros debido a las actividades realizadas en la plataforma de ORENTA o por el incumplimiento de estos Términos y Condiciones de Uso, así como cualquier otra política que forme parte de los mismos, o por la violación de cualquier ley o derechos de terceros.</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>8. Modificaciones</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>ORENTA se reserva el derecho de modificar de manera unilateral, y sin previo aviso ni comunicación a los usuarios, estos Términos y Condiciones, así como el diseño, presentación o configuración de la plataforma. Estas modificaciones no generarán derecho a reclamo o indemnización alguna por parte de los usuarios. Por lo tanto, se recomienda a los usuarios revisar periódicamente los Términos y Condiciones para informarse sobre cualquier actualización o cambio realizado. </p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>9. Limitación de responsabilidad</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>El usuario reconoce y acepta lo siguiente:</p>
            <br />
            <p className={classes.subtitle}>A) El usuario asume todos los riesgos relacionados con el uso, consulta o acceso al sitio web. El sitio web se proporciona "tal cual" y "según disponibilidad", sin garantía alguna sobre su correcto y continuo funcionamiento.</p>
            <br />
            <p className={classes.subtitle}>B) ORENTA declina expresamente y en este acto toda garantía o responsabilidad, ya sea expresa o implícita, legal o de otro tipo, incluyendo aquellas derivadas del contenido del sitio web.</p>
            <br />
            <p className={classes.subtitle}>C) Aunque ORENTA implementa medidas de seguridad para proteger el sitio web y su contenido contra ataques informáticos, ORENTA no garantiza que el sitio web esté libre de errores, virus o ataques de terceros, ni garantiza su funcionamiento ininterrumpido, disponibilidad continua, adecuación a las necesidades del usuario, ni la fiabilidad, exactitud, integridad, validez o veracidad de la información proporcionada por el usuario.</p>
            <br />
            <p className={classes.subtitle}>D) El usuario acepta asumir todos los riesgos asociados con el uso del sitio web o la información proporcionada por el usuario. Además, el usuario exonera de toda responsabilidad a ORENTA, sus subsidiarias, controlantes, directores, ejecutivos, empleados y proveedores, frente a reclamaciones o demandas de indemnización por daños y perjuicios de cualquier tipo, conocidos o desconocidos, derivados del uso del sitio web, la información proporcionada por el usuario o las transacciones resultantes del uso del sitio web.</p>
            <br />
            <p className={classes.subtitle}>Dentro de los límites legales, el usuario asume y acepta que en ningún caso ORENTA, sus subsidiarias o controlantes, ni sus accionistas, directores, ejecutivos, empleados o proveedores, serán responsables por daños emergentes, directos, indirectos o consecuenciales, incluyendo, entre otros, daños a la propiedad, daños económicos, pérdida de clientes, pérdida de ingresos o de datos, o interrupción del uso, independientemente de la causa que los haya provocado, ya sea incumplimiento contractual, responsabilidad extracontractual (incluyendo negligencia) o incumplimiento de garantía. En caso de que la legislación aplicable prohíba la exclusión de ciertas garantías, la limitación de responsabilidad o la exención de responsabilidad por ciertos daños, la responsabilidad acumulada de ORENTA por daños no excederá los costos de la sesión o plan contratado por el USUARIO, en la medida permitida por la ley.</p>
            <br />
            <p className={classes.subtitle}>Esta limitación de responsabilidad no se aplicará en caso de daños causados por dolo.</p>
            <br />
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>10. General</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Estos Términos y Condiciones, junto con las modificaciones que ORENTA pueda realizar en el futuro a su exclusivo criterio, constituyen el único acuerdo entre el usuario y ORENTA y rigen el uso del sitio web, reemplazando cualquier contrato previo, ya sea verbal o escrito. Sin perjuicio de los términos y condiciones particulares que puedan aplicarse al usuario por su registro en el sitio web.</p>
            <br />
            <p className={classes.subtitle}>Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de los Estados Unidos Mexicanos, excluyendo sus normas de derecho internacional privado. Cualquier conflicto que surja en relación con estos Términos y Condiciones será sometido y resuelto exclusivamente por los tribunales competentes de la Ciudad de México.</p>
            <br />
            <p className={classes.subtitle}>La invalidez o inaplicabilidad de cualquier parte de estos Términos y Condiciones no afectará la validez o aplicabilidad de los demás términos y condiciones, que continuarán en pleno vigor y efecto, excluyendo únicamente la cláusula inválida.</p>
            <br />
            <p className={classes.subtitle}>Si el usuario desea obtener más información sobre el sitio web o estos Términos y Condiciones, puede contactar a ORENTA enviando un correo electrónico a citas@orenta.mx</p>
            <br />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
            <Button onClick={()=>handleShowTerms()} >
              <span >Entendido</span>
            </Button>
        </div>
      </div>
    </div>
  );
};
export default Policy;
