import clientAxios from "./../../config/axios";
import clientStrapi from "./../../config/strapi";

export const getAllUsers = ({ filter, pagination }: any) => {
  const filterTmp = filter ?? "";
  const complement = pagination.page
    ? `?page=${pagination.page}&items_per_page=${pagination.rowsPerPage}${filterTmp}`
    : `?${filterTmp}`;
  const url = `/users${complement}`;
  return clientAxios.get(url);
};

export const updateUsers = (user: any) => {
  const url = `/users/${user.id}`;
  return clientAxios.put(url, user);
};

export const createUser = (user: any) => {
  const url = `/users`;
  return clientAxios.post(url, user);
};

export const getContentsUser = async (id: number) => {
  const res = await clientStrapi().get("/contents", {
    params: {
      "fields[0]": "title",
      "fields[2]": "uuid",
      "fields[3]": "goals",
      "fields[4]": "legacy_id",
      "populate[1]": "image",
      "pagination[pageSize]": "5",
    },
  });

  return res.data.data.map((x: any) => ({
    ...x.attributes,
    id: x.attributes.legacy_id,
    image:
      process.env.REACT_APP_STRAPI_BASE_URL +
      x.attributes.image?.data?.attributes?.url,
  }));
};
