import React, { useState, useRef } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ErrorMessage, Field, useFormik } from "formik";
import * as yup from "yup";
import { useSyles } from "./step6.styles";
import { register } from "../../../redux/auth/thunks";
import { useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RegisterRequest } from "src/redux/auth/models/RegisterRequest";
import { RootState } from "src/domain/models/RootState";
import { useStylesG } from "../Login/index.styles";
import Header from "../header";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { Link } from "react-router-dom";
import Button from "src/components/common/Button";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Policy from "../PolicyTerms/policy";
import Terms from "../PolicyTerms/terms";

const Login = () => {
  const classes = useSyles({ colorHeader: "#42B048" });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  const datePickerRef = useRef(null);
  const initialValues = {
    email: auth.register.email,
    user_id: auth.register.user_id,
    name: "",
    nickname: "",
    lastname: "",
    second_lastname: "",
    birthdate: "2000-01-01T00:00:00",
    gender: "",
    acceptTerms: false,
  };

  const handlerSubmit = async (values: RegisterRequest) => {
    try {
      await dispatch(register({ ...values }))
        .unwrap()
        .then(() => {
          setSuccess(true);
        });
    } catch (error) {
      console.log("pasa error");
    } finally {
      setSuccess(false);
    }
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    name: yup.string().required("El nombre es requerido"),
    lastname: yup.string().required("El apellido es requerido"),
    second_lastname: yup.string().required("El segundo apellido es requerido"),
    birthdate: yup
      .date()
      .required("La fecha de nacimiento es requerida")
      .max(new Date(), "La fecha de nacimiento no puede ser en el futuro"),
    gender: yup
      .string()
      .oneOf(
        ["Masculino", "Femenino", "No binario", "Otro"],
        "Género no válido"
      )
      .required("El género es requerido"),
    acceptTerms: yup
      .boolean()
      .oneOf([true], "Debe aceptar los términos y condiciones."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2000-01-01T00:00:00")
  );

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    formik.setFieldValue("birthdate", date);
    if (datePickerRef.current) {
      //console.log(datePickerRef.current);
    }
  };

  const onShowPolicy = (tmpShowPolicy: boolean) => {
    setShowPolicy(tmpShowPolicy);
  };

  const onShowTerms = (tmpShowTerms: boolean) => {
    setShowTerms(tmpShowTerms);
  };
  
  if (showPolicy) {
    return <Policy onShowTerms={onShowPolicy} />;
  } else {
    if (showTerms) {
      return <Terms onShowTerms={onShowTerms} />;
    } else {
      return (
        <div className={classesG.container}>
          <Header
            title={"¡Vamos a crear tu espacio de bienestar!"}
            subtitle={
              "Ayúdanos a crear tu cuenta llenando los siguientes datos:"
            }
            colorHeader={"#014951"}
            heightHeader={"250px"}
          />
          <form onSubmit={formik.handleSubmit}>
            <div className={classesG.body}>
              <div className={classes.content}>
                <div className={classes.form}>
                  <div className={classes.row}>
                    <TextField
                      id="form-name"
                      name="name"
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      value={formik.values.name}
                      disabled={loading}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={
                        formik.touched.name &&
                        Boolean(formik.errors.name) &&
                        formik.errors.name
                      }
                      className={classes.inputL}
                    />
                    <TextField
                      id="form-nickname"
                      name="nickname"
                      label="Sobrenombre"
                      variant="outlined"
                      fullWidth
                      value={formik.values.nickname}
                      disabled={loading}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nickname &&
                        Boolean(formik.errors.nickname)
                      }
                      helperText={
                        formik.touched.nickname &&
                        Boolean(formik.errors.nickname) &&
                        formik.errors.nickname
                      }
                      className={classes.inputR}
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      id="form-lastname"
                      name="lastname"
                      label="Apellido"
                      variant="outlined"
                      fullWidth
                      value={formik.values.lastname}
                      disabled={loading}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname) &&
                        formik.errors.lastname
                      }
                      className={classes.inputL}
                    />
                    <TextField
                      id="form-second_lastname"
                      name="second_lastname"
                      label="Segundo apellido"
                      variant="outlined"
                      fullWidth
                      value={formik.values.second_lastname}
                      disabled={loading}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.second_lastname &&
                        Boolean(formik.errors.second_lastname)
                      }
                      helperText={
                        formik.touched.second_lastname &&
                        Boolean(formik.errors.second_lastname) &&
                        formik.errors.second_lastname
                      }
                      className={classes.inputR}
                    />
                  </div>
                  <div className={classes.row}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <KeyboardDatePicker
                        ref={datePickerRef}
                        label="Fecha de nacimiento"
                        format="dd/MM/yyyy"
                        margin={undefined}
                        id="date-picker-inline"
                        value={selectedDate}
                        showTodayButton={false}
                        inputVariant="outlined"
                        variant="dialog"
                        autoOk={false}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={
                          formik.touched.birthdate &&
                          Boolean(formik.errors.birthdate)
                        }
                        helperText={
                          formik.touched.birthdate &&
                          Boolean(formik.errors.birthdate) &&
                          formik.errors.birthdate
                        }
                        className={classes.inputL}
                      />
                    </MuiPickersUtilsProvider>
                    <FormControl
                      variant="outlined"
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      className={classes.inputR}
                    >
                      <InputLabel htmlFor="gender">Género</InputLabel>
                      <Select
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        inputProps={{ id: "gender" }}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        label="genero"
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        <MenuItem value="Masculino">Masculino</MenuItem>
                        <MenuItem value="Femenino">Femenino</MenuItem>
                        <MenuItem value="Otro">Otro</MenuItem>
                        <MenuItem value="No binario">No binario</MenuItem>
                      </Select>
                      {formik.touched.gender && formik.errors.gender && (
                        <span
                          className={
                            "error MuiFormHelperText-root MuiFormHelperText-contained Mui-error " +
                            classes.error
                          }
                        >
                          {formik.errors.gender}
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div className={classes.information}>
                    <GridContainer
                      p-lg={"0px"}
                      p-sm={"0px"}
                    >
                      <GridItem
                        lg={8}
                        sm={3}
                      >
                        <p className={classes.information1}>
                          Orenta es tu espacio seguro
                        </p>
                        <p className={classes.information2}>
                          Porque tu salud y privacidad son primero, proteger tu
                          información es nuestra prioridad.{" "}
                        </p>
                        <p className={classes.information3}>
                          <a
                            href="#"
                            onClick={() => onShowTerms(true)}
                          >
                            Conoce más aquí.
                          </a>
                        </p>
                      </GridItem>
                      <GridItem
                        lg={4}
                        sm={1}
                      >
                        <div className={classes.containerEspacioSeguro}>
                          <img
                            src="./images/Auth/Onboarding/espacioSeguroCandado.png"
                            alt=""
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <GridContainer
                    p-lg={"0px"}
                    p-sm={"0px"}
                  >
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.acceptTerms}
                            onChange={formik.handleChange}
                            name="acceptTerms"
                          />
                        }
                        label={
                          <div className={classes.containerTerminos}>
                            <span
                              className={
                                formik.errors.acceptTerms
                                  ? "error " + classes.terminos
                                  : classes.terminos
                              }
                            >
                              Al registrarme en Orenta acepto los{" "}
                              <Link
                                to="#"
                                onClick={() => onShowPolicy(true)}
                              >
                                Términos y Condiciones
                              </Link>
                              , y{" "}
                              <Link
                                to="#"
                                onClick={() => onShowPolicy(true)}
                              >
                                Políticas de Privacidad
                              </Link>
                              .
                            </span>
                          </div>
                        }
                        className={classes.inputL}
                      />
                      {formik.touched.acceptTerms &&
                        formik.errors.acceptTerms && (
                          <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                            {formik.errors.acceptTerms}
                          </span>
                        )}
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </div>

            <div className={classes.footer}>
              {!loading ? (
                <div className={classes.buttons}>
                  <Button onClick={onAction}>
                    <span>Siguiente</span>
                  </Button>
                </div>
              ) : (
                <Loading height="150px" />
              )}
            </div>
          </form>
        </div>
      );
    }
  }
};
export default Login;