import React, { useState } from 'react';
import { useSyles } from './styles';
import { Hidden } from '@material-ui/core';
import Header from '../Header';
import Menu from '../Menu';

const Layout = ({ children }) => {
  const classes = useSyles();
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = () => setOpenMenu(!openMenu);


  return (
    <div className={classes.layout}>
      <Header onOpenMenu={handleOpenMenu} />
      <Hidden smDown>
        <Menu variant='permanent' open={true} />
      </Hidden>
      <Menu variant='temporary' open={openMenu} onClose={handleOpenMenu} />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default Layout;
