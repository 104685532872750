import React from 'react';
import { GridItem } from 'src/components/common/OrentaGrid';
import { WrapperText, Header2, Description } from './information.styles';

const Information = () => {

    return (
        <>
            <GridItem lg={1}>
                <></>
            </GridItem>
            <GridItem lg={10}>
                <WrapperText sepTop={'57px'}>
                    <Header2 align={'center'} color={'white'}><div>Una empresa productiva empieza por una cultura de bienestar.</div></Header2>
                    <Description>Un líder sabe que cada esfuerzo invertido en el bienestar de su equipo es un paso más hacia el crecimiento de su empresa. Orenta ayuda a fortalecer la salud mental de cada uno de tus colaboradores  para maximizar su potencial.</Description>
                </WrapperText>
            </GridItem>
            <GridItem lg={1} >
                <></>
            </GridItem>
        </>
              
    );
};

export default Information;
