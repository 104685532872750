import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import { RootState } from '../../domain/models/RootState';

export const getAllTimeZones = createAsyncThunk<
  any,
  void,
  { state: RootState }
>('TimeZones/getAll', async (_, thunkAPI) => {
  try {
    const { auth }: RootState = thunkAPI.getState();
    return await API.getTimeZones(auth.token);
  } catch (error) {
    throw error;
  }
});
