import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useStyles } from "./processResults.styles";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import { register, updateWelfare } from "src/redux/content/test/thunks";
import { questions } from "./dataWelfare";
import { countWeights, sumWeights } from "src/helpers/utils";
import Loading from "src/components/common/Loading";
import { Grid, Button } from "@material-ui/core";

const ProcessResult = () => {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const { contentTest, auth } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    if (contentTest.step === 3) {
      handlerSubmit();
    }
  }, [contentTest.step]);

  const handlerSubmit = async () => {
    try {
      const data = {
        user_id: auth.id,
        ct_type_test_id: contentTest.ct_type_test_id,
        answer_1: contentTest.answers[0],
        weight_1: questions[0].weights[contentTest.answers[0]],
        answer_2: contentTest.answers[1],
        weight_2: questions[1].weights[contentTest.answers[1]],
        answer_3: contentTest.answers[2],
        weight_3: questions[2].weights[contentTest.answers[2]],
        answer_4: contentTest.answers[3],
        weight_4: questions[3].weights[contentTest.answers[3]],
        answer_5: contentTest.answers[4],
        weight_5: questions[4].weights[contentTest.answers[4]],
        answer_6: contentTest.answers[5],
        weight_6: questions[5].weights[contentTest.answers[5]],
        answer_7: contentTest.answers[6],
        weight_7: questions[6].weights[contentTest.answers[6]],
        answer_8: contentTest.answers[7],
        weight_8: questions[7].weights[contentTest.answers[7]],
        answer_9: contentTest.answers[8],
        weight_9: questions[8].weights[contentTest.answers[8]],
        answer_10: contentTest.answers[9],
        weight_10: questions[9].weights[contentTest.answers[9]],
        answer_11: contentTest.answers[10],
        weight_11: questions[10].weights[contentTest.answers[10]],
        answer_12: contentTest.answers[11],
        weight_12: questions[11].weights[contentTest.answers[11]],
        answer_13: contentTest.answers[12],
        weight_13: questions[12].weights[contentTest.answers[12]],
        answer_14: contentTest.answers[13],
        weight_14: questions[13].weights[contentTest.answers[13]],
      };

      const weights: Record<string, number> = {
        "1": questions[0].weights[contentTest.answers[0]],
        "2": questions[1].weights[contentTest.answers[1]],
        "3": questions[2].weights[contentTest.answers[2]],
        "4": questions[3].weights[contentTest.answers[3]],
        "5": questions[4].weights[contentTest.answers[4]],
        "6": questions[5].weights[contentTest.answers[5]],
        "7": questions[6].weights[contentTest.answers[6]],
        "8": questions[7].weights[contentTest.answers[7]],
        "9": questions[8].weights[contentTest.answers[8]],
        "10": questions[9].weights[contentTest.answers[9]],
        "11": questions[10].weights[contentTest.answers[10]],
        "12": questions[11].weights[contentTest.answers[11]],
        "13": questions[12].weights[contentTest.answers[12]],
        "14": questions[13].weights[contentTest.answers[13]],
      };

      const counts = countWeights(weights);
      let category = "Estancado";
      let emotional_percentage = (sumWeights(weights, 1, 4) * 100) / 15;
      let social_percentage = (sumWeights(weights, 5, 9) * 100) / 25;
      let psychological_percentage = (sumWeights(weights, 10, 15) * 100) / 30;
      if (
        Number(counts.weight4) + Number(counts.weight5) >= 7 &&
        Number(counts.hedonic45) >= 1
      ) {
        category = "Fluyendo";
      } else {
        if (
          Number(counts.weight0) + Number(counts.weight1) >= 7 &&
          Number(counts.hedonic01) >= 1
        ) {
          category = "Desconectado";
        }
      }

      await dispatch(
        updateWelfare({
          user: auth.id,
          category: category,
          emotional_percentage: emotional_percentage,
          social_percentage: social_percentage,
          psychological_percentage: psychological_percentage,
        })
      ).unwrap();
      await dispatch(updateStep(4));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          src="./images/OrentaAll.png"
          alt=""
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            "Estamos calculando tus <br /> resultados, ¡Un momento!"
          </p>
        </div>
        <div className={classes.content}>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
          >
            <Loading />
          </Grid>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.information}>
          <p className={classes.information1}>"La salud mental es salud"</p>
        </div>
        <div className={classes.buttons}>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            //onClick={}
            disabled={true}
          >
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ProcessResult;
