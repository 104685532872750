// Libraries.
import styled from 'styled-components';

export const WraperContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props['gtc-sm'] || '4'}, [col] 1fr);
    grid-auto-flow: row;
    padding: ${(props) => props['p-sm'] ?? '0 16px'};
    margin:  ${({margin}) => margin ? margin: '0'};
    grid-gap: ${(props) => props['ggap-sm'] || '8'}px;
    align-items: ${({ alignItems }) => alignItems || 'stretch'};
    align-content: ${({ alignContent }) => alignContent || 'stretch'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    grid-template-rows: ${(props) => props['gtr-sm'] || 'none'};
    width: 100%;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        grid-template-columns: repeat(8, [col] 1fr);
        grid-gap: ${(props) => props['ggap-md'] || '16'}px;
        padding: ${(props) => props['p-md'] || '0'};
        grid-template-rows: ${(props) => props['gtr-md'] || 'none'};
    }

    @media ${({ theme }) => theme.screenSizes.laptop} {
        grid-template-columns: repeat(12, [col] 1fr);
        grid-gap: ${(props) => props['ggap-lg'] ?? '24'}px;
        padding: ${(props) => props['p-lg'] ?? '0'};
        grid-template-rows: ${(props) => props['gtr-lg'] ?? 'none'};
    }
`;

const itemGridColumn = (size, col, defaultMedia) => {
    if (size) {
        return 'col / span ' + size;
    }
    if (col) {
        return 'col / span 1';
    }
    return 'col / span ' + defaultMedia;
};
export const WraperItem = styled.div`
    grid-column: ${({ sm, col }) => itemGridColumn(sm, col, 4)};
    grid-column-start: ${(props) => props['sm-start'] || 'auto'};
    display: ${(props) => (props['sm-d-none'] ? 'none' : 'block')};
    grid-row-start: ${(props) => props['grs'] || 'auto'};
    margin:  ${({margin}) => margin ? margin: '0'};

    @media ${({ theme }) => theme.screenSizes.mobile} {
        ${({ customOrder }) => customOrder && `
        order: -1;
        
    `}
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        grid-column: ${({ md, col }) => itemGridColumn(md, col, 8)};
        grid-column-start: ${(props) => props['md-start'] || 'auto'};
        display: ${(props) => (props['md-d-none'] ? 'none' : 'block')};
        ${({ customOrder }) => customOrder && `
            order: 1;
        `}
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        grid-column: ${({ lg, col }) => itemGridColumn(lg, col, 12)};
        grid-column-start: ${(props) => props['lg-start'] || 'auto'};
        display: ${(props) => (props['lg-d-none'] ? 'none' : 'block')};
        ${({ customOrder }) => customOrder && `
            order: 1;
        `}
    }
   
`;
