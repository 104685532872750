import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useStyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '74px',
    margin:'10px',
    borderRadius: '8px',
    background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign:'left'
    },
      title:{
        color:  '#149D90',
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal', /* 150% */
        letterSpacing: '-0.72px', 
        paddingBottom: '25px',
        textAlign:"left"
      },
      bloqueText:{
        paddingBottom:'20px', 
        paddingTop:'20px', 
      },
      subtitle:{
        color: '#104B71',
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '21px', /* 133.333% */
        letterSpacing: '-0.36px',
        '& > span ': {
          lineHeight: '24px',
          fontSize: '16px',
          fontWeight: 700,
        },
    },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  contentCircles:{
    width: 'calc(100% - 72px)',
  },
  titleCircle:{
    color:"#272727",
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0.64px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontSize: '15px',
    lineHeight: '21px', /* 175% */
  },
  containerWelfare: {
    padding: '20px',
  },
  circleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: 'blue',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#272727',
    //marginBottom: theme.spacing(4),
    textAlign: 'center',
    /* SubTitle */
    '& > H5 ': {
      fontFamily: 'Antonia H3',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.72px',
    }
  },
  circle2: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: 'gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
      height:'48px'
    },
  },
  information:{
    display: 'flex',
    padding: '30px 10px 0px 10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'10px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    marginBottom:'0px'
  },
  information1:{
    color: '#000000',
    textAlign: 'center',
    fontFeatureSettings: 'liga off',
    /* Heading/Heading 05/Bold */
    fontFamily: 'Work Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-0.42px',
  },
  linksResults:{
    color:  '#014951',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight:600,
    lineHeight: '24px', /* 171.429% */
    letterSpacing: '-0.42px',
  }

}));
