import React from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import RequestDemoF from './requestDemoF';
import RequestDemoS from './requestDemoS';

const Orchestrator = () => {
  const { prospects } = useAppSelector((state: RootState) => state);

  switch(prospects.register.step){
    case 1: return (<RequestDemoF />);
    case 2: return (<RequestDemoS />);
    default: return (<RequestDemoF />);
  }
};
export default Orchestrator;
