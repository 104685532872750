import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../domain/models/RootState';
import type { setupStore, store } from './store';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAPIApp = {
  state: RootState;
  dispatch?: AppDispatch;
  extra?: any;
  rejectValue?: any;
  serializedErrorType?: any;
  pendingMeta?: any;
  fulfilledMeta?: any;
  rejectedMeta?: any;
};

export type AsyncThunkPayloadCreatorApp<R, P> = AsyncThunkPayloadCreator<
  R,
  P,
  ThunkAPIApp
>;

// Use This helper to make thunks with RootState type defined
export const createAsyncThunkApp = <R, P>(
  thunksName: string,
  callbackThunk: AsyncThunkPayloadCreatorApp<R, P>
) => createAsyncThunk<R, P, ThunkAPIApp>(thunksName, callbackThunk);
