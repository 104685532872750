// Libraries.
import styled from 'styled-components';

export const WrapperBenefits = styled.section`
    .benefits-title {
        text-align: center;
        font-family: "Antonia H3";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px; /* 130% */
        letter-spacing: -1.2px;
        margin-bottom: 16px;
        padding: 0 24px;
        color:${({ theme }) => theme.color.titleSection};
        span {
            color: ${({ theme }) => theme.color.spanishViridian500};
        }
    }
    .benefits-paragraph {
        color: ${({ theme }) => theme.color.titleSection};
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.54px;
        margin: 0;
        text-align: center;
        margin-bottom: 32px;
    }
    .btn-card-publicar {
        margin-top: 16px;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        .benefits-title {
            font-size: 40px;
            text-align: right;
        }
        .benefits-paragraph {
            font-size: 18px;
            text-align: left;
        }
        .btn-card-publicar {
            //width: 238px;
            //margin: 8px 0 0 auto;
        }
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        .benefits-title {
            font-size: 40px;
            text-align: right;
        }
        .benefits-paragraph {
            font-size: 18px;
            text-align: left;
        }
        .btn-card-publicar {
            //width: 322px;
        }
    }
    button {
        border-radius: 30px;
        background: ${({ theme }) => theme.color.greenActions};
        color:${({ theme }) => theme.color.titleSection};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        height: 52px;
        width: 100%;
    }
    .separator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 48px; /* Ajusta la altura del contenedor según tus necesidades */

    }

    .horizontal-line{
        width: 100%; /* Ajusta el ancho de la línea horizontal según tus necesidades */
        border: 0;
        border-top: 2px solid black; /* Ajusta el estilo según tus necesidades */
        opacity: 0.2;
        background: var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
    }
`;

export const BenefitsCard = styled.div`
    padding: 32px 0px;
    //background: #f9f9fb;
    border-radius: 24px;
    display: grid;
    //gap: 38px;
    position: relative;
    text-align: center;
    .item-card {
        display: grid;
        grid-template: 'text check close';
        grid-template-columns: 50% 25% 25%;
        //border: 0.2px solid var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
        .item-elements {
            border: 0.2px solid var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
            padding: 20px 16px;
            width: 100%;
            height: -webkit-fill-available;
            color: var(--colors-dark, #272727);
            /* H6 Bold */
            font-family: "Work Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px; /* 175% */
            letter-spacing: -0.36px;
            @media ${({ theme }) => theme.screenSizes.tablet} {
                color: var(--colors-dark, #272727);
                /* H3 Bold */
                font-family: "Work Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 144.444% */
                letter-spacing: -0.54px;
            }
            @media ${({ theme }) => theme.screenSizes.laptop} {
                color: var(--colors-dark, #272727);
                /* H3 Bold */
                font-family: "Work Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 144.444% */
                letter-spacing: -0.54px;
            }
            
        }
        &.desktop {
            //height: 100%;
            //width: 100%;
            position: absolute;
            padding: inherit;
            .orenta-icon-close {
                position: relative;
                top: -68px;
                height: 100%;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: ${({ theme }) => theme.color.scarpaGray500};
                //width: 100px;
            }
            .orenta-icon-circle {
                //width: 100%;
                background: #f1f8f5;
                mix-blend-mode: darken;
                border: 2px solid ${({ theme }) => theme.color.spanishViridian500};
                border-radius: 24px;
                position: relative;
                top: -80px;
                padding: 16px 40px;
                height: calc(100% + 92px);
                justify-content: center;
                display: flex;
                align-items: baseline;
            }
            
        }
    }
    .orenta-icon-circle {
        grid-area: check;
        path {
            fill: ${({ theme }) => theme.color.spanishViridian500};
        }
    }
    .orenta-icon-close {
        display: block !important;
        grid-area: close;
        margin: auto;
        stroke: #f65570 !important;
        stroke-width: 50px;
        
        path {
            fill: #f65570;
        }
    }
    .item-title-service{
        padding: 0px 32px;
    }
    .item-text {
        grid-area: text;
        text-align: left;
    }
    .item-title {
        margin: 0;
        font-family: 'Antonia H3';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: -0.54px;
        width: 100%;
        color: ${({ theme }) => theme.color.titleSection};
        border: 0.2px solid var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
        @media ${({ theme }) => theme.screenSizes.tablet} {
                font-family: "Antonia H3";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px; /* 130% */
                letter-spacing: -1.2px;
            }
            @media ${({ theme }) => theme.screenSizes.laptop} {
                font-family: "Antonia H3";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px; /* 130% */
                letter-spacing: -1.2px;
            }
    }
    .item-paragraph {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.color.scarpaGray500};
    }
    .logo-lg {
        display: none;
        padding: 0;
        margin: 0;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 32px 54px;
        .item-card {
            grid-template: 'text check close';
            grid-template-columns: 34% 33% 33%;
            //grid-template-columns: auto 110px 110px;
            &.desktop {
                //display: grid;
            }
        }
        .orenta-icon-circle {
            margin: auto;
        }
        .orenta-icon-close {
            display: block !important;
        }
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 32px 108px;
        .logo-lg {
            display: block;
        }
        .logo-sm {
            display: none;
        }
        .item-card.desktop .orenta-icon-circle {
           // padding: 16px;
        }
        .item-card {
            grid-template-columns: 34% 33% 33%;
        }
    }

    
`;

export const BenefitsButton = styled.div`
    padding: 32px 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 32px 54px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 32px 108px;
    }

    
`;
