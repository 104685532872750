import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//Components
import Form from './Form';
import Layout from './../common/Layout';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateUsers, getAllUsers } from '../../redux/users/thunks';
import { Box, Button } from '@material-ui/core';

const EditUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const idEdit: number = parseInt(location?.pathname.split('/').pop() || '');
  useEffect(() => {
    const filter = `&id=${idEdit}`;
    dispatch(getAllUsers({ filter, pagination: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEdit]);

  // Users a edit
  const { users } = useSelector((state: any) => state.users.users);
  const userEdit = users?.filter((item: any) => item.id === idEdit);
  const goToUser = () => {
    navigate('/users', { replace: true });
  };

  const submitEditUser = (values: any) => {
    dispatch(updateUsers({ values }))
      .unwrap()
      .then((res: any) => {
        if (res) {
          goToUser();
        }
      });
  };
  return (
    <>
      {users && userEdit[0] ? (
        <Form
          submitEdit={submitEditUser}
          initialValues={userEdit[0]}
          actionText='GUARDAR'
        />
      ) : (
        <Layout>
          <Box sx={{ p: 2 }}>No existe el usuario a editar</Box>
          <Box>
            <Button
              onClick={() => goToUser()}
              size='medium'
              variant='text'
              color='secondary'
            >
              ir al listado de usuarios
            </Button>
          </Box>
        </Layout>
      )}
    </>
  );
};

export default EditUser;
