import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  updateSelfKnowledge,
  updateWelfare,
  updateDepression,
} from "./thunks";
import { ContentTestState } from "./state";

// Initial state for Redux store:
export const initialStateContentTest: ContentTestState = {
  user_id: 0,
  step: 1,
  id: "",
  loading: false,
  answers: [],
  categories: [],
  test_id: 0,
  ct_type_test_id: -1,
  welfare: [],
  depresion: [],
};

const contentTestSlice = createSlice({
  name: "contentState",
  initialState: initialStateContentTest, // Define initial state
  reducers: {
    updateStep: (state, action) => {
      state.step = action.payload;
      state.loading = false;
    },
    saveAnswers: (state, action) => {
      state.answers = action.payload;
      state.loading = false;
    },
    setCtTypeTestId: (state, action) => {
      state.ct_type_test_id = action.payload;
      state.answers = [];
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      state.step = 3;
    });
    builder.addCase(register.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateSelfKnowledge.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSelfKnowledge.fulfilled, (state, action) => {
      state.categories = action.meta.arg.categoriesOrig;
      state.loading = false;
    });
    builder.addCase(updateSelfKnowledge.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateWelfare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateWelfare.fulfilled, (state, action) => {
      state.welfare = action.meta.arg;
      state.loading = false;
    });
    builder.addCase(updateWelfare.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateDepression.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDepression.fulfilled, (state, action) => {
      state.depresion = action.meta.arg;
      state.loading = false;
    });
    builder.addCase(updateDepression.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Export actions generated by "createSlice()":
export const { updateStep, saveAnswers, setCtTypeTestId } =
  contentTestSlice.actions;

// Export reducer generated by "createSlice()":
export default contentTestSlice.reducer;
