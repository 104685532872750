import React from 'react';

const Loading = ({ height }: any) => (
  <div
    style={{
      display: ' grid',
      placeItems: 'center',
      height: height ? height : '50vh',
    }}
  >
    {' '}
    <img width='80px' src='/loading_2.gif' alt='' />
  </div>
);

export default Loading;
