import React, { useState, useEffect } from "react";
import { Container, Button } from "@material-ui/core";
import Question from "./Question";
import { RootState } from "src/domain/models/RootState";
import { useSelector, useDispatch } from "react-redux";
import Loading from "src/components/common/Loading";
import { saveTracking } from "src/redux/content/thunks";

const Questionnaire: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch<any>();
  const [questions, setQuestions] = useState([]);
  const { content, auth } = useSelector((state: RootState) => state);
  const [answers, setAnswers] = useState<Record<string, string>>({});

  useEffect(() => {
    if (content.templates.length) {
      setCurrentPage(content.currentOrchestratorIndex);
      const q = content.templates[content.currentOrchestratorIndex];
      setQuestions(q);
    }
  }, [content.templates]);

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleNext = (selectedOption: string) => {
    const currentQuestion = questions[currentPage];
    const questionKey = `question${currentPage + 1}`;
    setAnswers({ ...answers, [questionKey]: selectedOption });
    setCurrentPage(currentPage + 1);
  };

  if (content.templates?.length) {
    return (
      <Question
        {...content.templates[content.currentOrchestratorIndex]}
        currentOrchestratorIndex={content.currentOrchestratorIndex}
      />
    );
  } else {
    return <Loading />;
  }
};

export default Questionnaire;
