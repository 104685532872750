import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import Swal from 'sweetalert2';

export const getAllCompanies = createAsyncThunk(
  'Companies/getAllCompanies',
  async ({ filter, pagination = null }: any) => {
    try {
      const res: any = await API.getAllCompanies({ filter, pagination });
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const getCatalogCompanies = createAsyncThunk(
  'Companies/getCatalogCompanies',
  async () => {
    try {
      const res: any = await API.getCatalogCompanies();
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const updateCompany = createAsyncThunk(
  'Companies/updateCompany',
  async ({ values, text }: any) => {
    try {
      const res = await API.updateCompany(values);
      Swal.fire({
        icon: 'success',
        title: `${text || 'Editado'} con exito`,
      });
      return res;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Hubo un error al editar los datos`,
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const createCompany = createAsyncThunk(
  'Companies/createCompany',
  async (property: any) => {
    try {
      const res = await API.createCompany(property);
      if (res.status === 200) {
        Swal.fire(
          'Correcto',
          'La propiedad se agregó correctamente',
          'success'
        );
        return true;
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al crear la propiedad',
        text: 'Por favor, intenta de nuevo',
      });
      throw error;
    }
  }
);

export const getProblems = createAsyncThunk(
  'Companies/getProblems',
  async () => {
    try {
      const res = await API.getProblems();
      return res.data.problems || [];
    } catch (error) {
      throw error;
    }
  }
);


export const getCatalogRolsAreas = createAsyncThunk(
  'Companies/getCatalogRolsAreas',
  async (id:number) => {
    try {
      const res: any = await API.getCatalogRolsAreas(id);
      return res.data.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
        confirmButtonColor: '#57A450',
        cancelButtonColor: '#57A450',
      });
      throw error;
    }
  }
);

export const getCompany = createAsyncThunk(
  'Companies/getCompany',
  async (id:number) => {
    try {
      const res: any = await API.getCompany(id);
      console.log(res);
      return res.data.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
        confirmButtonColor: '#57A450',
        cancelButtonColor: '#57A450',
      });
      throw error;
    }
  }
);

export const getCompanyByUserId = createAsyncThunk(
  'Companies/getCompany',
  async (id:string) => {
    try {
      const res: any = await API.getCompanyByUserId(id);
      return res.data.data[0];
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
        confirmButtonColor: '#57A450',
        cancelButtonColor: '#57A450',
      });
      throw error;
    }
  }
);