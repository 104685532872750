import React, { useState, useEffect } from 'react';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { AvatarImage, Card, Card2, Cards, ContentTestimonials, SectionTestimonials, Tab, TabsContainer,Title, ContentGrids} from './Testimonials.styles';
import Button from '@material-ui/core/Button';

const Testimonials = () => {
  const [activeTab, setActiveTab] = useState(1);

  const tabsTitles = ["<p>Testimoniales</p>Lo que dice la gente sobre nosotros.", "<p>Testimoniales</p>Lo que dice la gente sobre nosotros.","<p>Testimoniales</p>Lo que dice la gente sobre nosotros."];
  const tabs = ["<ul><li></li></ul>", "<ul><li></li></ul>","<ul><li></li></ul>"];
  const cardsData = [
    [
      { image: "/images/home/testimonials/Avatar.png", title: "Lizbeth Fajardo", title2: "Luis Castro", text: '“Desde el primer momento recibimos información y orientación para la implementación y arranque del proyecto, el servicio ha sido siempre efectivo, en todo momento responden nuestras dudas con cortesía, y el acompañamiento a nuestros colaboradores ha sido totalmente excepcional”' },
      ],
    [
      { image: "/images/home/testimonials/Avatar_02.png", title: "Luis Castro", title2: "Morcio Sierra",text: '“Desde el primer momento recibimos información y orientación para la implementación y arranque del proyecto, el servicio ha sido siempre efectivo”' },
      ],
      [
        { image: "/images/home/testimonials/Avatar_02.png", title: "Morcio Sierra",title2: "Lizbeth Fajardo", text: '“Desde el primer momento recibimos información y orientación para la implementación y arranque del proyecto, el servicio ha sido siempre efectivo, en todo momento responden nuestras dudas con cortesía.”' },
        ],
  ];

  useEffect(() => {
    let activeTabTmp = 1;
    if(activeTab === 1){
      activeTabTmp = 2; 
    }
    if(activeTab === 2){
      activeTabTmp = 3; 
    }
    if(activeTab === 3){
      activeTabTmp = 1; 
    }
    const interval = setInterval(() => {
        setActiveTab(activeTabTmp);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [activeTab]);

  return (
    <SectionTestimonials>
      <ContentTestimonials>
        <ContentGrids>
          <GridContainer p-lg={'0px'}>
              <GridItem lg={5} md={3}>
                <Title>
                    <div
                          dangerouslySetInnerHTML={{__html: tabsTitles[activeTab - 1]}}
                      />
                  </Title>
                <TabsContainer>
                  {tabs.map((tab, index) => (
                    <Tab
                      key={index}
                      onClick={() => setActiveTab(index + 1)}
                      style={{ color: activeTab === index + 1 ? '#000000' : '#6674B2'}
                            }
                    >
                      <div
                          dangerouslySetInnerHTML={{__html: tab}}
                      />
                      
                    </Tab>
                  ))}
                </TabsContainer>
              </GridItem>
              <GridItem lg={7} md={5}>
                  {cardsData[activeTab - 1].map((card, index) => (
                    <Cards >
                      <Card>
                        <AvatarImage src={card.image} alt="Avatar" />
                          <div
                            dangerouslySetInnerHTML={{__html: card.text}}
                          />
                          <h2>{card.title}</h2>
                        
                      </Card>
                      <Card2 >
                        <div
                            
                          />
                        <h2>{card.title2}</h2>
                      </Card2>
                    </Cards>
                  
                ))}
              </GridItem>
          </GridContainer>
        </ContentGrids>
      </ContentTestimonials>
    </SectionTestimonials>
  );
};

export default Testimonials;
