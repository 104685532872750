import styled from 'styled-components';



export const SectionLibrary = styled.section`
   background-color: ${({ theme }) => theme.color.white};
   padding: 32px 16px;
   max-width: 100vw;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 65px 92px 32px 92px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 95px 132px 32px 132px;
    }

    button {
        border-radius: 30px;
        background: ${({ theme }) => theme.color.greenActions};
        color:${({ theme }) => theme.color.titleSection};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        height: 52px;
        width: 100%;
    }
    .separator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 48px; /* Ajusta la altura del contenedor según tus necesidades */

    }

    .horizontal-line{
        width: 100%; /* Ajusta el ancho de la línea horizontal según tus necesidades */
        border: 0;
        border-top: 2px solid black; /* Ajusta el estilo según tus necesidades */
        opacity: 0.2;
        background: var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
    }
    
`;
// Estilos para el contenedor de las pestañas
export const TabsContainer = styled.div`
   margin-bottom: 100px;
`;

export const Category = styled.div`
  background-color:#DDF6EF;
  border-radius: 21px;
  display: flex;
  height: 37px;
  padding: 10px 2px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: -webkit-fill-available;
  text-align: center;
    /* @media ${({ theme }) => theme.screenSizes.tablet} {
      justify-content: flex-end;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      justify-content: flex-end;
    } */
`;


export const Title = styled.div`
    text-align: center;
      /* Title */
    font-family: "Antonia H3";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 130% */
    letter-spacing: -1.2px;
    span {
        color: ${({ theme }) => theme.color.titleSection};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
      text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      text-align: left;
    }
`;

// Estilos para una pestaña individual
export const Tab = styled.div`
  padding: 10px 2px;
  cursor: pointer;
`;


// Estilos para una tarjeta individual
export const Card = styled.div`
  background: white;
  box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
  margin: 10px;
  //padding: 20px;
  img {
    width: -webkit-fill-available;
    height: auto;
  }
  h2{
    padding: 22px 12px 20px 12px;
    /* SubTitle */
    font-family: "Antonia H3";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
  }
  div {
    padding: 0px 12px 30px 12px;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 147.368% */
    letter-spacing: -0.57px;

    ul {
      list-style-type: disc; /* Utiliza disc para viñetas tipo punto */
      margin-left: 20px; /* Ajusta el margen izquierdo según tu preferencia */
    }
  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;