import { createSlice } from "@reduxjs/toolkit";
import { getContent, saveAnswer, getLibraries, saveTracking } from "./thunks";
import { ContentState } from "./state";

// Initial state for Redux store:
export const initialStateContent: ContentState = {
  user_id: 0,
  currentOrchestratorIndex: 0,
  id: "",
  title: "",
  loading: true,
  answers: [],
  content: [],
  templates: [],
  libraries: {
    categories: [],
    contents: [],
  },
};

const contentSlice = createSlice({
  name: "content",
  initialState: initialStateContent, // Define initial state
  reducers: {
    updateCurrentOrchestratorIndex: (state, action) => {
      state.currentOrchestratorIndex = action.payload;
      state.loading = false;
    },
    saveAnswers: (state, action) => {
      state.answers = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContent.fulfilled, (state, action) => {
      state.loading = false;
      state.title = action.payload.title;
      state.id = action.payload.id;
      state.content = action.payload.content;
      state.related_contents = action.payload.related_content;
      state.templates = action.payload.templates;
    });

    builder.addCase(saveAnswer.pending, (state) => {});
    builder.addCase(saveAnswer.fulfilled, (state, action) => {});
    builder.addCase(saveAnswer.rejected, (state) => {});

    builder.addCase(saveTracking.pending, (state) => {});
    builder.addCase(saveTracking.fulfilled, (state, action) => {});
    builder.addCase(saveTracking.rejected, (state) => {});
    builder.addCase(getLibraries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLibraries.fulfilled, (state, action) => {
      state.libraries = action.payload;
      state.loading = false;
    });
    builder.addCase(getLibraries.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Export actions generated by "createSlice()":
export const { updateCurrentOrchestratorIndex, saveAnswers } =
  contentSlice.actions;

// Export reducer generated by "createSlice()":
export default contentSlice.reducer;
