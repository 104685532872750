import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSyles } from "./step7.styles";
import {
  sendCode,
  postValidateUserCode,
  firstLogin,
} from "../../../redux/auth/thunks";
import { useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { RootState } from "src/domain/models/RootState";
import { useAppSelector } from "src/redux/hooks";
import { useStylesG } from "../Login/index.styles";
import Header from "../header";
import Button from "src/components/common/Button";

const Login = () => {
  const classes = useSyles({ colorHeader: "#6DB3EE" });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const loading = false; //useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const name = auth.register.name;
  const [prompt, setPrompt] = useState<number>(0);
  const [complete, setComplete] = useState(true);

  const initialValues = {
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  };

  const handlerSubmit = async (values: any) => {
    try {
      const data = {
        id: auth.register.user_id,
        code:
          "" +
          values.digit1 +
          "" +
          values.digit2 +
          "" +
          values.digit3 +
          "" +
          values.digit4,
      };
      await dispatch(postValidateUserCode({ ...data })).unwrap();
      await dispatch(firstLogin(auth.register)).unwrap();
    } catch (error) {}
  };

  const handleInputChangeWrite = (index: number, value: string) => {
    // if (value.length === 1 && index < inputRefs.length - 1 && inputRefs[index + 1]) {
    //   // Mover el foco al siguiente campo
    //   inputRefs[index + 1].current?.focus();
    // }
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    digit1: yup
      .number()
      .required("Campo obligatorio")
      .min(0, "El número debe ser mayor o igual a 0")
      .max(9, "El número debe ser menor o igual a 9"),
    digit2: yup
      .number()
      .required("Campo obligatorio")
      .min(0, "El número debe ser mayor o igual a 0")
      .max(9, "El número debe ser menor o igual a 9"),
    digit3: yup
      .number()
      .required("Campo obligatorio")
      .min(0, "El número debe ser mayor o igual a 0")
      .max(9, "El número debe ser menor o igual a 9"),
    digit4: yup
      .number()
      .required("Campo obligatorio")
      .min(0, "El número debe ser mayor o igual a 0")
      .max(9, "El número debe ser menor o igual a 9"),
  });

  const handleInputChange = (index: any, e: any) => {
    const { value } = e.target;
    if (/^[0-9]*$/.test(value) && value.length <= 1) {
      formik.handleChange(e);
    }
    handleInputChangeWrite(index, e.target.value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });
  const sendCodeLocal = async () => {
    try {
      const data = {
        id: auth.register.user_id,
      };
      await dispatch(sendCode({ ...data })).unwrap();
    } catch (error) {
    } finally {
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("Text")
      .split("")
      .map(Number);

    for (let i = 0; i < pastedData.length && i < 4; i++) {
      const fieldName = `digit${i + 1}`;
      formik.setFieldValue(fieldName, pastedData[i]);
      setPrompt(i + 2);
    }
  };

  useEffect(() => {
    if (
      formik.values.digit1 !== "" &&
      formik.values.digit2 !== "" &&
      formik.values.digit3 !== "" &&
      formik.values.digit4 !== ""
    ) {
      setComplete(false);
    } else {
      setComplete(true);
    }
  }, [formik.values]);

  useEffect(() => {
    sendCodeLocal();
  }, []);

  return (
    <div className={classesG.container}>
      <Header
        title={`${name}, ahora vamos a validar tu identidad.`}
        subtitle={"Revisa tu correo e ingresa el código de verificación."}
        colorHeader={"#014951"}
        heightHeader={"260px"}
      />

      <div className={classesG.body}>
        <div className={classes.content}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.row}>
              <TextField
                type="number"
                variant="standard"
                label=""
                onPaste={handlePaste}
                inputProps={{
                  style: {
                    fontSize: "50px",
                    textAlign: "center",
                    borderBottom: "9px solid",
                    borderBottomColor: "#ACE4FD",
                  },
                }}
                id="digit1"
                name="digit1"
                value={formik.values.digit1}
                onChange={(e) => handleInputChange(0, e)}
                onBlur={formik.handleBlur}
                error={formik.touched.digit1 && Boolean(formik.errors.digit1)}
                helperText={
                  formik.touched.digit1 &&
                  Boolean(formik.errors.digit1) &&
                  formik.errors.digit1
                }
                className={classes.inputL}
              />
              <TextField
                type="number"
                variant="standard"
                label=""
                onPaste={handlePaste}
                inputProps={{
                  style: {
                    fontSize: "50px",
                    textAlign: "center",
                    borderBottom: "9px solid",
                    borderBottomColor: "#ACE4FD",
                  },
                }}
                id="digit2"
                name="digit2"
                value={formik.values.digit2}
                onChange={(e) => handleInputChange(1, e)}
                onBlur={formik.handleBlur}
                error={formik.touched.digit2 && Boolean(formik.errors.digit2)}
                helperText={
                  formik.touched.digit2 &&
                  Boolean(formik.errors.digit2) &&
                  formik.errors.digit2
                }
                className={classes.inputR + " " + classes.inputL}
              />
              <TextField
                type="number"
                variant="standard"
                label=""
                onPaste={handlePaste}
                inputProps={{
                  style: {
                    fontSize: "50px",
                    textAlign: "center",
                    borderBottom: "9px solid",
                    borderBottomColor: "#ACE4FD",
                  },
                }}
                id="digit3"
                name="digit3"
                value={formik.values.digit3}
                onChange={(e) => handleInputChange(2, e)}
                onBlur={formik.handleBlur}
                error={formik.touched.digit3 && Boolean(formik.errors.digit3)}
                helperText={
                  formik.touched.digit3 &&
                  Boolean(formik.errors.digit3) &&
                  formik.errors.digit3
                }
                className={classes.inputL}
              />
              <TextField
                type="number"
                variant="standard"
                label=""
                onPaste={handlePaste}
                inputProps={{
                  style: {
                    fontSize: "50px",
                    textAlign: "center",
                    borderBottom: "9px solid",
                    borderBottomColor: "#ACE4FD",
                  },
                }}
                id="digit4"
                name="digit4"
                value={formik.values.digit4}
                onChange={(e) => handleInputChange(3, e)}
                onBlur={formik.handleBlur}
                error={formik.touched.digit4 && Boolean(formik.errors.digit4)}
                helperText={
                  formik.touched.digit4 &&
                  Boolean(formik.errors.digit4) &&
                  formik.errors.digit4
                }
                className={classes.inputR}
              />
            </div>
          </form>
          <div className={classes.information}>
            <p className={classes.information1}>
              Si no recibiste un email, revisa tu carpeta de spam, o envíanos un
              correo a soporte.orenta@forigen.org
            </p>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        {!loading ? (
          <div className={classes.buttons}>
            <Button onClick={onAction}>
              <span>Validar Código</span>
            </Button>
            <Button
              backgroundColor="transparent"
              borderColor="#EDEDED"
              onClick={() => sendCodeLocal()}
            >
              Reenviar código por correo
            </Button>
          </div>
        ) : (
          <Loading height="150px" />
        )}
      </div>
    </div>
  );
};
export default Login;
