import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSyles } from "./requestDemoF.styles";
import { useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { createProspectFull } from "src/redux/prospects/thunks";
import { RegisterProspectRequest } from "src/redux/auth/models/RegisterRequest";
import { GridContainer, GridItem } from "src/components/common/OrentaGrid";
import { Header2, ContentRequest, Buttons } from "./information.styles";

const RequestDemo = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  const initialValues = {
    companyName: '',
    fullname: '',
    phone: '',
    companyEmail: '',
    collaborators:0,
    role:'',
    implementationTime: '',
    sentFrom: 'DemoRequest'
  };

  const handlerSubmit = (values: RegisterProspectRequest) => {
    dispatch(createProspectFull({ ...values }))
      .unwrap()
      .then(() => {
        setSuccess(true);
      });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    companyName: yup.string().required('El nombre de la empresa es requerido'),
    fullname: yup.string().required('El nombre es requerido'),
    phone: yup.string().required('El teléfono es requerido'),
    companyEmail: yup.string().required('El email de la empresa es requerido'),
    collaborators:yup.number(),
    role:yup.string().required('Tu rol es requerido'),
    implementationTime:yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  return (
    <ContentRequest>
      <GridContainer
        p-lg={"0px"}
        p-sm={"0px"}
      >
        <GridItem lg={12}>
          <Header2
            color={"#6375B6"}
            align={"left"}
          >
            <div>
              Compártenos tu información y un asesor se comunicará contigo.
            </div>
          </Header2>
        </GridItem>
      </GridContainer>
      <GridContainer
        p-lg={"0"}
        p-sm={"0"}
      >
        <GridItem
          lg={6}
          md={4}
        >
          <TextField
            id="form-fullname"
            name="fullname"
            label="Nombre completo"
            variant="outlined"
            fullWidth
            value={formik.values.fullname}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.fullname && Boolean(formik.errors.fullname)}
            helperText={
              formik.touched.fullname &&
              Boolean(formik.errors.fullname) &&
              formik.errors.fullname
            }
            className={classes.inputL}
          />
        </GridItem>
        <GridItem
          lg={6}
          md={4}
        >
          <TextField
            id="form-companyName"
            name="companyName"
            label="Nombre de la empresa"
            variant="outlined"
            fullWidth
            value={formik.values.companyName}
            disabled={loading}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={
              formik.touched.companyName &&
              Boolean(formik.errors.companyName) &&
              formik.errors.companyName
            }
            className={classes.inputR}
          />
        </GridItem>
        <GridItem
          lg={6}
          md={4}
        >
          <TextField
            id="form-companyEmail"
            name="companyEmail"
            label="Email de la empresa"
            variant="outlined"
            fullWidth
            value={formik.values.companyEmail}
            disabled={loading}
            onChange={formik.handleChange}
            error={
              formik.touched.companyEmail &&
              Boolean(formik.errors.companyEmail)
            }
            helperText={
              formik.touched.companyEmail &&
              Boolean(formik.errors.companyEmail) &&
              formik.errors.companyEmail
            }
            className={classes.inputR}
          />
        </GridItem>
        <GridItem
          lg={6}
          md={4}
        >
          <TextField
            id="form-phone"
            name="phone"
            label="Teléfono"
            variant="outlined"
            fullWidth
            value={formik.values.phone}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={
              formik.touched.phone &&
              Boolean(formik.errors.phone) &&
              formik.errors.phone
            }
            className={classes.inputL}
          />
        </GridItem>
        <GridItem
          lg={4}
          md={3}
        >
          <TextField
            id="form-collaborators"
            name="collaborators"
            label="No. de colaboradores"
            variant="outlined"
            fullWidth
            value={formik.values.collaborators}
            disabled={loading}
            onChange={formik.handleChange}
            error={
              formik.touched.collaborators &&
              Boolean(formik.errors.collaborators)
            }
            helperText={
              formik.touched.collaborators &&
              Boolean(formik.errors.collaborators) &&
              formik.errors.collaborators
            }
            className={classes.inputR}
          />
        </GridItem>
        <GridItem
          lg={4}
          md={3}
        >
          <TextField
            id="form-role"
            name="role"
            label="Rol"
            variant="outlined"
            fullWidth
            value={formik.values.role}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={
              formik.touched.role &&
              Boolean(formik.errors.role) &&
              formik.errors.role
            }
            className={classes.inputL}
          />
        </GridItem>
        <GridItem
          lg={4}
          md={2}
        >
          <TextField
            id="form-implementationTime"
            name="implementationTime"
            label="Tiempo en implementar"
            variant="outlined"
            fullWidth
            value={formik.values.implementationTime}
            disabled={loading}
            onChange={formik.handleChange}
            error={
              formik.touched.implementationTime &&
              Boolean(formik.errors.implementationTime)
            }
            helperText={
              formik.touched.implementationTime &&
              Boolean(formik.errors.implementationTime) &&
              formik.errors.implementationTime
            }
            className={classes.inputR}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        p-lg={"0px"}
        p-sm={"0px"}
      >
        <GridItem lg={12}>
          {!loading ? (
            <Buttons>
              <Button
                size="medium"
                variant="contained"
                onClick={onAction}
              >
                <span>Enviar</span>
              </Button>
            </Buttons>
          ) : (
            <Loading height="150px" />
          )}
          <div className={classes.information}>
            <p className={classes.information1}>
              {" "}
              <span>
                Al enviar este formulario, estás aceptando los términos y
                condiciones de Orenta
              </span>
            </p>
          </div>
        </GridItem>
      </GridContainer>
    </ContentRequest>
  );
};
export default RequestDemo;
