import React, { useState } from 'react';
import { useSyles } from './step2.styles';
import Loading from '../../common/Loading';
import { updateStep } from '../../../redux/auth/slice';
import { useDispatch } from 'react-redux';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import Icon from 'src/components/common/Icon';
import Button from 'src/components/common/Button';

const Step2 = () => {
  const dispatch = useDispatch<any>();
  const classes = useSyles();
  const classesG = useStylesG({});
  const [loading] = useState(false);

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  return (
    <div className={classesG.container}>
      <Header title={"¡Que alegría que formes parte de Orenta,"} 
              subtitle={"Salud mental diseñada para ti, elige entre las diferentes opciones de cuidado que tenemos para ti:"}
              colorHeader={"#014951"}
              heightHeader={'280px'}
      />
      
      <div className={classesG.body}>
       
        <div className={classes.content}>
          <div className={classes.containerList}>
            <div>
              <div className={classes.item}>
                <div className={classes.containerIcon}>
                  <Icon icon="orenta-icon-Laptop" />
                </div> 
                <span className={classes.label}>Herramientas digitales</span>
              </div>
              <div className={classes.item}>
                <div className={classes.containerIcon}>
                  <Icon icon="orenta-icon-home-opts" />
                </div>
                <span className={classes.label}>Terapia clinica</span>
              </div>
              <div className={classes.item}>
                  <div className={classes.containerIcon}>
                    <Icon icon="orenta-icon-phone2" />
                  </div>
                  <span className={classes.label}>Línea de ayuda</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
              <Button onClick={()=>handleUpdateStep(3)} >
                <span >Siguiente</span>
              </Button>
              <Button
                  backgroundColor='transparent'
                  borderColor='#EDEDED'
                  onClick={()=>handleUpdateStep(6)}>
                  Saltar introducción
                </Button>
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default Step2;
// function dispatch(arg0: { payload: any; type: "auth/updateStep"; }) {
//   throw new Error('Function not implemented.');
// }

