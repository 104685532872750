import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import Swal from 'sweetalert2';

export const getDashboardData = createAsyncThunk(
  'DashboardData/getDashboardData',
  async () => {
    try {
      const res: any = await API.getAllData();
      return res.data.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
        confirmButtonColor: '#57A450',
        cancelButtonColor: '#57A450',
      });
      throw error;
    }
  }
);
