import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./question.styles";
import { saveAnswers, updateStep } from "../../../redux/content/test/slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import Button from "src/components/common/Button";
import Header from "../Header";
import { setSelfKnowledge } from "../../../redux/auth/slice";

const Question = ({ questions }: any) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const { contentTest } = useAppSelector((state: RootState) => state);
  const [answers, setAnswers] = useState<number[]>(contentTest.answers);
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentQuestionIndex === questions.length) {
      saveAnswersToLocalStorage(answers);
    }
  }, [currentQuestionIndex, answers]);

  const handleOptionClick = (optionIndex: number) => {
    setSelectedOption(optionIndex);
  };

  const saveAnswersToLocalStorage = (answers: number[]) => {
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const handleContinue = () => {
    let selectedOptionLocal = selectedOption;
    if (
      currentQuestionIndex === questions.length - 1 &&
      selectedOptionLocal === null
    ) {
      selectedOptionLocal = -1;
    }

    if (selectedOptionLocal !== null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = selectedOptionLocal;
      setAnswers(newAnswers);
      dispatch(saveAnswers(newAnswers));

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedOption(null);
      } else {
        // El usuario ha completado todas las preguntas
        // Puedes mostrar la retroalimentación aquí si lo deseas

        // Guarda las respuestas en JSON
        saveAnswersToLocalStorage(newAnswers);
        dispatch(updateStep(3));
      }
    }
  };

  const renderOptions = () => {
    return questions[currentQuestionIndex].options.map(
      (option: string, index: number) => (
        <div className={classes.row}>
          <div className={classes.containerButton}>
            {selectedOption === index ? (
              <Button onClick={() => handleOptionClick(index)}>{option}</Button>
            ) : (
              <Button
                backgroundColor="transparent"
                borderColor="#EDEDED"
                onClick={() => handleOptionClick(index)}
              >
                {option}
              </Button>
            )}
          </div>
          {/* <Button
          key={index}
          variant={selectedOption === index ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleOptionClick(index)}
          style={{ margin: "5px",width:'100%',height:'48px' }}
        >
          {option}
        </Button> */}
        </div>
      )
    );
  };
  let disabledOption = true;
  if (
    !(selectedOption === null) ||
    currentQuestionIndex === questions.length - 1
  ) {
    disabledOption = false;
  }

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      const prevQuestionIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevQuestionIndex);
      setSelectedOption(answers[prevQuestionIndex]);
    } else {
      dispatch(updateStep(1));
    }
  };

  const goToHome = () => {
    dispatch(setSelfKnowledge(false));
    navigate(`/home`, { replace: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header
          fnBack={handleBack}
          fnClose={goToHome}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.subtitle}>
            {questions[currentQuestionIndex].text1}
          </p>
          <p className={classes.title}>
            {questions[currentQuestionIndex].text2}
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.form}>{renderOptions()}</div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button
            onClick={handleContinue}
            disabled={disabledOption}
          >
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Question;
