import { rootReducer } from './root-reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {
  AnyAction,
  AsyncThunkPayloadCreator,
  Dispatch,
  PreloadedState,
  configureStore,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { RootState } from '../domain/models/RootState';
import { initialStateUsers } from './users/slice';
import { initialStateDashboard } from './dashboard/slice';
import { initialStateCompanies } from './companies/slice';
import { initialStateProspects } from './prospects/slice';
import { initialStateAuth } from './auth/slice';
import { initialStateContentTest } from './content/test/slice';
import { initialStateContent } from './content/slice';
import { initialStateTimeZones } from './time_zones/slice';

const middleware: any[] = [];
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

export const preloadedState: RootState = {
  dashboard: initialStateDashboard,
  companies: initialStateCompanies,
  prospects: initialStateProspects,
  users: initialStateUsers,
  auth: initialStateAuth,
  timeZones: initialStateTimeZones,
  contentTest:initialStateContentTest,
  content:initialStateContent
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const setupStore = (state?: PreloadedState<RootState>) =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
export const store = setupStore(preloadedState);
