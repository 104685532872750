import React, { useState } from "react";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Card,
  SectionTools,
  Title,
} from "src/components/Dashboard/Employee/Tools/Tools.styles";
import { RootState } from "src/domain/models/RootState";
import { useSelector } from "react-redux";
import LazyImage from "src/components/common/Lazy/LazyImage";

const ElementCarousel = () => {
  const { content } = useSelector((state: RootState) => state);
  const [activeTab, setActiveTab] = useState(1);
  const [cardsData, setCardsData] = useState(content.related_contents ?? []);
  console.log(content);
  console.log(content.related_contents ?? []);

  const goToUrl = (url: string) => {
    window.open(url, "_self");
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <SectionTools>
      <GridContainer p-lg={"0px"}>
        <GridItem
          lg={6}
          md={4}
        >
          <Title></Title>
        </GridItem>
      </GridContainer>
      <Carousel
        key={"carouserSuccess"}
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        //autoPlay={true}
        //autoPlaySpeed={2000}
        keyBoardControl={true}
        //customTransition="all .5"
        //transitionDuration={5000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"mobile"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-10px"
        partialVisible={true}
      >
        {cardsData.map(
          (
            card: {
              uuid: number;
              image: string;
              title: string;
            },
            index: number
          ) => (
            <Card
              key={`tools_${index}`}
              onClick={() => goToUrl(`/contents/${card.uuid}`)}
            >
              <>
                {card.image != "" && (
                  <LazyImage
                    src={card.image}
                    alt={""}
                  />
                )}
                {card.title != "" && <h2>{card.title}</h2>}
              </>
            </Card>
          )
        )}
      </Carousel>
    </SectionTools>
  );
};

export default ElementCarousel;
