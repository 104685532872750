import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useSyles = makeStyles((theme) => ({
  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto',
    padding: theme.spacing(1),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
      height:'48px'
    },
  },
  information:{
    display: 'flex',
    padding: '16px 0px 20px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'10px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#FFFFFF',
    marginBottom:'40px'
  },
  information1:{
    alignSelf: 'stretch',
    color: '#606060',
    textAlign: 'left',
    /* Heading/Heading 06/Bold */
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: "21px", /* 175% */
    letterSpacing: "-0.36px",
    '& > span ': {
      color: "#014951",
      /* H6 Bold */
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "-0.36px",
    }
  }

}));
