import React from 'react';
import { ButtonOrenta, ButtonProps } from './index.styles';

const Index: React.FC<ButtonProps> = ({
  backgroundColor,
  borderRadius,
  textColor,
  borderColor,
  onClick,
  children,
  className,
  disabled,
  width,
}) => {
  return (
    <ButtonOrenta
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      textColor={textColor}
      borderColor={borderColor}
      onClick={onClick}
      className={className}
      disabled={disabled}
      width={width}
    >
      {children}
    </ButtonOrenta>
  );
};
export default Index;
