import { useState } from 'react';
import parse from 'html-react-parser';
import { AccordionItem, AccordionItemTitle, AccordionItemContent, TitleFaq } from './styles';
import Icon from 'src/components/common/Icon';

type QuestionProps = {
    '@type': string;
    name: string;
    acceptedAnswer: {
        '@type': string;
        text: string;
    };
};

type FaqsAccordionProps = {
    schema: {
        '@context': string;
        '@type': string;
        mainEntity: QuestionProps[];
    };
    titleAs?: 'h3' | 'h2' | 'h4' | 'h5';
};

const FaqsAccordion = ({ schema, titleAs }: FaqsAccordionProps) => {
    const [activeState, setActiveState] = useState([true]);
    const toggleAccordion = (index: number) => {
        let newActiveState = activeState.slice();
        if (activeState[index] === true) {
            newActiveState[index] = !newActiveState[index];
        } else {
            newActiveState = activeState.map(() => false);
            newActiveState[index] = true;
        }
        setActiveState(newActiveState);
    };

    return (
        <>
            {schema?.mainEntity.map((question, index) => (
                <AccordionItem
                    key={index}
                    active={activeState[index]}
                    className="accordion-item"
                    data-testid={`container-q${index + 1}`}
                    data-gtm={`container-q${index + 1}`}
                >
                    <AccordionItemTitle className="accordion-item-title" onClick={() => toggleAccordion(index)} active={activeState[index]}>
                        <TitleFaq as={titleAs}>{question.name}</TitleFaq>
                        <Icon icon={activeState[index] ? "orenta-icon-circle-minus-filled": "orenta-icon-circle-plus-filled"} size={32} data-gtm={`accordion-${activeState[index] ? 'close' : 'open'}`} style={{color:'#FFB9C6',fill:'#FFB9C6',stroke:'#FFB9C6'}} />
                    </AccordionItemTitle>
                    <AccordionItemContent className="accordion-item-content" active={activeState[index]}>
                        {parse(question.acceptedAnswer.text)}
                    </AccordionItemContent>
                </AccordionItem>
            ))}
        </>
    );
};

export default FaqsAccordion;
