import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useStyles = makeStyles((theme) => ({
  
  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width:'100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
    width:'100%'
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
   
  },
  

}));
