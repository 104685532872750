import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSyles } from "./changePasswordForm.styles";
import {
  login,
  resetPassword,
  updatePassword,
} from "../../../redux/auth/thunks";
import { useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { RegisterRequest } from "src/redux/auth/models/RegisterRequest";
import { obtenerTokenDesdeURL } from "src/helpers/utils";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import { useStylesG } from "../Login/index.styles";
import SecurityIndicator from "../SecurityIndicator";
import Button from "src/components/common/Button";

const ChangePassword = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRev, setShowPasswordRev] = useState(false);
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  const handlerSubmit = (values: {
    password: string;
    passwordConfirmation: string;
  }) => {
    let code = obtenerTokenDesdeURL();
    if (!code) return setSuccess(false);
    dispatch(resetPassword({ code, ...values }))
      .unwrap()
      .then(() => {
        setSuccess(true);
        navigate(`/home`, { replace: true });
      });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Debe contener al menos 8 caracteres")
      .matches(/[a-zA-Z]/, "Debe contener al menos una letra")
      .matches(/[0-9]/, "Debe contener al menos un número")
      .matches(/[^a-zA-Z0-9]/, "Debe contener al menos un carácter especial"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  useEffect(() => {
    let securityLevelTmp = 0;
    if (formik.values.password != "" && formik.values.password.length >= 8) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[a-zA-Z]/) !== null) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[0-9]/) !== null) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null) {
      securityLevelTmp++;
    }
    setSecurityLevel(securityLevelTmp);
  }, [formik.values.password]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibilityRev = () => {
    setShowPasswordRev(!showPasswordRev);
  };

  useEffect(() => {
    localStorage.removeItem("jwt");
  }, []);

  return (
    <div className={classesG.container}>
      <Header
        title={"Cambia tu contraseña."}
        subtitle={""}
        colorHeader={"#014951"}
        heightHeader={"220px"}
      />

      <div className={classesG.body}>
        <div className={classes.titles}>
          <div className={classes.containerList}>
            <p className={classes.subtitle}>
              Utiliza los siguientes criterios:
            </p>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.password != "" &&
                      formik.values.password.length >= 8
                    }
                    className={classes.checkbox}
                  />
                }
                label={
                  <span className={classes.label}>
                    Al menos 8 caracteres: cuantos más caracteres, mejor.
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formik.values.password.match(/[a-zA-Z]/) !== null}
                  />
                }
                label={
                  <span className={classes.label}>
                    Una mezcla de letras mayúsculas y minúsculas
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formik.values.password.match(/[0-9]/) !== null}
                  />
                }
                label={
                  <span className={classes.label}>
                    Una mezcla de letras y números.
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={
                      formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null
                    }
                  />
                }
                label={
                  <span className={classes.label}>
                    ¡Inclusión de al menos uno de estos caracteres especiales! @
                    # ?
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            {/* <TextField
           id="password"
           name="password"
           label="Contraseña"
           type="password"
           variant='outlined'
           value={formik.values.password}
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}
           className={classes.inputL}
           error={formik.touched.password && Boolean(formik.errors.password)}
           helperText={formik.touched.password && formik.errors.password}
         /> */}
            <TextField
              id="password"
              label="Contraseña"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              //  className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.errors.password as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPassword ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {securityLevel > 0 && (
              <SecurityIndicator securityLevel={securityLevel} />
            )}

            {/* <TextField
             id="passwordConfirmation"
             name="passwordConfirmation"
             label="Confirmar Contraseña"
             type="password"
             variant='outlined'
             className={classes.inputL}
             value={formik.values.passwordConfirmation}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
             helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}     
           /> */}
            <TextField
              id="passwordConfirmation"
              label="Confirmar Contraseña"
              variant="outlined"
              name="passwordConfirmation"
              type={showPasswordRev ? "text" : "password"}
              //className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
              helperText={formik.errors.passwordConfirmation as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibilityRev}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPasswordRev ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </div>
      </div>
      <div className={classes.footer}>
        {true ? (
          <div className={classes.buttons}>
            <Button
              onClick={onAction}
              disabled={
                !formik.isValid ||
                formik.values.password !== formik.values.passwordConfirmation
              }
            >
              <span>Modificar contraseña</span>
            </Button>
          </div>
        ) : (
          <Loading height="150px" />
        )}
      </div>
    </div>
  );
};
export default ChangePassword;
