// import { makeStyles } from '@material-ui/core/styles';

// export const useStyles = makeStyles((theme) => ({
//   buttonContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'flex-start',
//     gap: theme.spacing(2), // Espacio horizontal de 16px
//   },
//   categorie: {
//     flexBasis: 'calc(50% - 8px)', // Ancho igual a la mitad con espacio horizontal de 16px
//     height: '0',
//     paddingTop: '120px', // Alto igual a la mitad con espacio horizontal de 16px
//     textAlign: 'left', // Alinear el texto a la izquierda
//     textTransform: 'none', // Evitar que el texto se convierta en mayúsculas
//     fontSize: 'inherit', // Usar el tamaño de fuente heredado
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//     padding: theme.spacing(2), // Espaciado interno para el contenido
//     background: "linear-gradient(180deg, #98DBD0 0%, #2CE5C7 100%)", // Color de fondo
//     color: theme.palette.primary.contrastText, // Color de texto
//     borderRadius: "22px", // Borde redondeado
//     '& > span ': {
//       color:"#104B71",
//       /* M3/label/medium - prominent */
//       fontFamily: 'Work Sans',
//       fontSize: '14px',
//       fontStyle: 'normal',
//       fontWeight: 600,
//       lineHeight: '24px', /* 133.333% */
//       letterSpacing: '-0.42px',
//       //paddingBottom:"30px",
//       textAlign:'center'
//     },
//   },
// }));
import styled from 'styled-components';

export const ContentCategories = styled.div`
    padding: 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 60px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 60px;
    }
    .seleccionado {
      background-color:  #15e2c0;
    }
    .noSeleccionado {
      background-color:  #b5dcd6;
    }
`;

export const Category = styled.div`
      // Color de fondo
     border-radius: 22px; // Borde redondeado
     height: 120px;
     span {
      color: var(--colors-navy, #104B71);
      text-align: center;
      /* H5 Bold */
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.42px;
     }
`;

export const CategoryText = styled.p`
      color: var(--colors-navy, #104B71);
      text-align: center;
      /* H5 Bold */
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.42px;
      text-align: center;
      margin: 0px 14px 14px 14px;
`;