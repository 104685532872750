// Libraries.
import styled from 'styled-components';

export const WrapperB = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-bottom: 50px;
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0px 20px 66px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    z-index: 3;
    border-radius: 24px;
     position: relative;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        justify-content: flex-end;
        isolation: isolate;
        position: relative;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        position: relative;
        background-color: ${({ theme }) => theme.color.white};
        //padding: 320px 56px 0px;
    }
`;

export const ContentB = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 60px 16px 0px 16px;
    //gap: 72px;
    //background: rgba(250, 250, 250, 0.75);
    width: 100%;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        //gap: 56px;
        border-radius: 24px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        //gap: 56px;
        border-radius: 24px;
    }
    
`;

export const WrapperTextB = styled.div`
    color:${({ theme }) => theme.color.dark};
    width: 70%;
    button {
        border-radius: 30px;
        background: ${({ theme }) => theme.color.greenActions};
        color:${({ theme }) => theme.color.dark};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        height: 52px;
        width: 100%;
    }
`;

export const Header2B = styled.h2`
    /* Title */
    font-family: "Antonia H3";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.42px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 14px;
    color: ${({ theme }) => theme.color.dark};;
    span{
        color: ${({ theme }) => theme.color.white};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px; /* 130% */
        letter-spacing: -1.2px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px; /* 130% */
        letter-spacing: -1.2px;
    }
    
`;

export const DescriptionB = styled.p`
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
    letter-spacing: -0.36px;
    
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    padding-bottom: 14px;
    color: ${({ theme }) => theme.color.dark};;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 163.636% */
        letter-spacing: -0.66px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 163.636% */
        letter-spacing: -0.66px;
    }
`

export const WrapperCategory = styled.div`
    border-radius: 15.241px;
    background: var(--colors-white, #FFF);
    box-shadow: 0px 11.00255px 45.11043px 0px rgba(0, 0, 0, 0.06), 0px 0px 2.20051px 0px rgba(0, 0, 0, 0.13);
    font-feature-settings: 'liga' off;
    /* H2 Bold */
    color: var(--colors-blu, #6674B2);
    /* H4 Bold */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${({ theme }) => theme.color.dark};
    padding-top: 30px;
    img {
        padding: 10px;
        width: 120px;
        height: auto;
    }
    p{
        padding: 30px;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        display: flex;
        align-items: center;
        text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`

export const WrapperEstatus = styled.div`
    color:${({ theme }) => theme.color.dark};
    
    position: relative;
    width: 100%; /* Asegura que el contenedor tenga un ancho definido */
    height: 0; /* Inicialmente establece la altura en 0 para mantener la relación de aspecto */
    padding-bottom: 100%; /* Establece la altura en función del ancho para mantener la relación de aspecto */
    overflow: hidden; /* Recorta cualquier contenido que se desborde del contenedor */
    img{
            position: absolute;
            width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
            height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
            object-fit: fill; 
        }
    @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
        img{
            position: absolute;
            width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
            height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
            object-fit: fill; 
            bottom: 60px;
        }
    }
    @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
        img{
            position: absolute;
            width: 100%; /* Haz que la imagen ocupe el 100% del ancho del contenedor */
            height: auto; /* Haz que la imagen ocupe el 100% de la altura del contenedor */
            object-fit: fill;
            bottom: 60px;
        }
    }
    
    
`;
