import clientAxios from "./../../config/axios";
import clientStrapi from "./../../config/strapi";

export const getContent = async (data: any) => {
  const { id } = data;
  if (/^[A-Za-z0-9]{5}$/.test(id)) {
    const _res = await clientStrapi().get(`/contents/get/${data.id}`);
    const _entry = _res.data;

    const related_content = _entry.related_content?.map((entry: any) => ({
      ...entry,
      image: process.env.REACT_APP_STRAPI_BASE_URL + entry.image?.url,
    }));
    const category_content = _entry.category?.contents.map((entry: any) => ({
      ...entry,
      image: process.env.REACT_APP_STRAPI_BASE_URL + entry.image?.url,
    }));

    return {
      id: _entry.legacy_id | _entry.uuid,
      ..._entry,
      templates: _entry.content,
      related_content:
        (related_content && related_content.length) > 0
          ? related_content
          : category_content,
    };
  }

  const res = await clientStrapi().get("/contents", {
    params: {
      "filters[legacy_id][$eq]": data.id,
      "pagination[pageSize]": "1",
      "populate[category][populate][contents][fields][0]": "title",
      "populate[category][populate][contents][fields][1]": "image",
      "populate[category][populate][contents][fields][2]": "uuid",
      "populate[category][populate][contents][fields][3]": "legacy_id",
    },
  });

  const entry = res.data.data[0];

  if (entry.attributes.category.data)
    return {
      id: entry.attributes.legacy_id,
      ...entry.attributes,
      templates: entry.attributes.content,
      related_content:
        entry.attributes.category.data.attributes.contents.data.map(
          (x: any) => ({
            ...x.attributes,
            id: x.legacy_id,
          })
        ),
    };
  return {
    id: entry.attributes.legacy_id,
    ...entry.attributes,
    templates: entry.attributes.content,
  };
};

export const saveAnswer = async (data: any) => {
  const url = `contents`;
  const result = await clientAxios.post(url, data);
  return result.data;
};

export const saveTracking = async (data: any) => {
  const url = `trackings`;
  const result = await clientStrapi().post(url, { data });
  return result.data;
};

export const getLibraries = async () => {
  const res = await clientStrapi().get("/contents", {
    params: {
      "fields[0]": "title",
      "fields[2]": "uuid",
      "fields[3]": "goals",
      "fields[4]": "legacy_id",
      "populate[image]": "*",
      "populate[category][fields][0]": "title",
      "pagination[pageSize]": "30",
      sort: "createdAt:desc",
    },
  });

  let entries = res.data.data.map((entry: any) => {
    return {
      ...entry.attributes,
      id: entry.attributes.legacy_id,
      category: entry.attributes?.category?.data?.attributes?.title,
      image:
        process.env.REACT_APP_STRAPI_BASE_URL +
        entry.attributes.image?.data?.attributes?.url,
    };
  });

  console.log({ entries });
  let categories_content: { [id: string]: Array<string> } = {};
  entries.forEach((entry: any) => {
    if (!categories_content[entry.category])
      categories_content[entry.category] = [];
    categories_content[entry.category].push(entry);
  });

  return {
    categories: Object.keys(categories_content),
    contents: Object.values(categories_content),
  };
};
