import React, { useEffect } from "react";

import { useStyles } from "./index.styles";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/domain/models/RootState";
import Loading from "src/components/common/Loading";
import Introduction from "../Introduction";
import Button from "src/components/common/Button";
import Header from "../Header";
import { saveTracking } from "src/redux/content/thunks";
import { useNavigate } from "react-router-dom";

function buildURL(url: string) {
  try {
    new URL(url);
    return url;
  } catch (e) {
    return process.env.REACT_APP_STRAPI_BASE_URL + url;
  }
}

function Index() {
  const dispatch = useDispatch<any>();
  const { content, auth } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  let estilos;

  try {
    estilos = JSON.parse(
      content.templates[content.currentOrchestratorIndex].style
    );
  } catch (e) {
    estilos = {
      background: { color: "#FFFFFF" },
      titleHeader: {
        color: "#FFFFFF",
        backgroundColor: "#6674B2",
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "center",
      },
      subtitleHeader: {
        color: "#104B71",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "left",
      },
      titleBody: "",
    };
  }

  const classes = useStyles({
    backgroundColor: estilos?.background?.color
      ? estilos.background.color
      : "red",
    titleHeaderColor: estilos?.titleHeader?.color
      ? estilos.titleHeader.color
      : "#104B71",
    titleHeaderBackgroundColor: estilos?.titleHeader?.background
      ? estilos.titleHeader.background
      : "#104B71",
    titleHeaderFontSize: estilos?.titleHeader?.fontSize
      ? estilos.titleHeader.fontSize
      : "24px",
    titleHeaderWeight: estilos?.titleHeader?.fontWeight
      ? estilos.titleHeader.fontWeight
      : 700,
    titleHeaderTextAlign: estilos?.titleHeader?.textAlign
      ? estilos.titleHeader.textAlign
      : "center",
    subtitleHeaderColor: estilos?.subtitleHeader?.color
      ? estilos.subtitleHeader.color
      : "#104B71",
    subtitleHeaderFontSize: estilos?.subtitleHeader?.fontSize
      ? estilos.subtitleHeader.fontSize
      : "16px",
    subtitleHeaderWeight: estilos?.subtitleHeader?.fontWeight
      ? estilos.subtitleHeader.fontWeight
      : 500,
    subtitleHeaderTextAlign: estilos?.subtitleHeader?.textAlign
      ? estilos.subtitleHeader.textAlign
      : "center",

    titleBodyColor: estilos?.titleBody?.color
      ? estilos.titleBody.color
      : "#104B71",
    titleBodyBackgroundColor: estilos?.titleBody?.background
      ? estilos.titleBody.background
      : "#104B71",
    titleBodyFontSize: estilos?.titleBody?.fontSize
      ? estilos.titleBody.fontSize
      : "24px",
    titleBodyWeight: estilos?.titleBody?.fontWeight
      ? estilos.titleBody.fontWeight
      : 700,
    titleBodyTextAlign: estilos?.titleBody?.textAlign
      ? estilos.titleBody.textAlign
      : "center",
    subtitleBodyColor: estilos?.subtitleBody?.color
      ? estilos.subtitleBody.color
      : "#104B71",
    subtitleBodyFontSize: estilos?.subtitleBody?.fontSize
      ? estilos.subtitleBody.fontSize
      : "16px",
    subtitleBodyWeight: estilos?.subtitleBody?.fontWeight
      ? estilos.subtitleBody.fontWeight
      : 500,
    subtitleBodyTextAlign: estilos?.subtitleBody?.textAlign
      ? estilos.subtitleBody.textAlign
      : "center",

    titleFooterColor: estilos?.titleFooter?.color
      ? estilos.titleFooter.color
      : "#104B71",
    titleFooterBackgroundColor: estilos?.titleFooter?.background
      ? estilos.titleFooter.background
      : "#104B71",
    titleFooterFontSize: estilos?.titleFooter?.fontSize
      ? estilos.titleFooter.fontSize
      : "24px",
    titleFooterWeight: estilos?.titleFooter?.fontWeight
      ? estilos.titleFooter.fontWeight
      : 700,
    titleFooterTextAlign: estilos?.titleFooter?.textAlign
      ? estilos.titleFooter.textAlign
      : "center",
    subtitleFooterColor: estilos?.subtitleFooter?.color
      ? estilos.subtitleFooter.color
      : "#104B71",
    subtitleFooterFontSize: estilos?.subtitleFooter?.fontSize
      ? estilos.subtitleFooter.fontSize
      : "16px",
    subtitleFooterWeight: estilos?.subtitleFooter?.fontWeight
      ? estilos.subtitleFooter.fontWeight
      : 500,
    subtitleFooterTextAlign: estilos?.subtitleFooter?.textAlign
      ? estilos.subtitleFooter.textAlign
      : "center",
  });

  const handleUpdateCurrentOrchestratorIndex = () => {
    dispatch(
      updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
    );
  };

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  if (content.templates?.length) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header fnBack={handleBack} />
        </div>
        <div className={classes.body}>
          <div className={classes.titles}>
            <p className={classes.titleHeader}>
              {content.templates[content.currentOrchestratorIndex].header}
            </p>
            <p className={classes.subtitleHeader}>
              {content.templates[content.currentOrchestratorIndex].sub_header}
            </p>
          </div>
          <div className={classes.content}>
            <div className={classes.titles}>
              <p className={classes.titleBody}>
                {content.templates[content.currentOrchestratorIndex].body}
              </p>
              <p className={classes.subtitleBody}>
                {content.templates[content.currentOrchestratorIndex].sub_body}
              </p>
              {content.templates[content.currentOrchestratorIndex]
                .image_body && (
                <img
                  src={buildURL(
                    content.templates[content.currentOrchestratorIndex]
                      .image_body
                  )}
                />
              )}
            </div>
            <div className={classes.titles}>
              <p className={classes.titleFooter}>
                {content.templates[content.currentOrchestratorIndex].footer}
              </p>
              <p className={classes.subtitleFooter}>
                {content.templates[content.currentOrchestratorIndex].sub_footer}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <Button onClick={handleUpdateCurrentOrchestratorIndex}>
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Introduction />;
  }
}

export default Index;
