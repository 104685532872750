import React, { useState, useEffect, useMemo } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { useDispatch } from "react-redux";
import { useStyles } from "./question.styles";
import { useSelector } from "react-redux";
import Loading from "src/components/common/Loading";
import { RootState } from "src/domain/models/RootState";
import { saveAnswer } from "src/redux/content/thunks";
import Button from "src/components/common/Button";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {};
    img.src = src;
  });
}

function buildURL(url: string) {
  try {
    new URL(url);
    return url;
  } catch (e) {
    return process.env.REACT_APP_STRAPI_BASE_URL + url;
  }
}

interface QuestionProps {
  question: string;
  image_default: string;
  option_1: string;
  option_2: string;
  image_1: string;
  image_2: string;
  feedback_1: string;
  feedback_2: string;
  feedback_title_1: string;
  feedback_title_2: string;
  currentOrchestratorIndex: number;
}

const Question: React.FC<QuestionProps> = ({
  question,
  image_default,
  option_1,
  option_2,
  image_1,
  image_2,
  feedback_1,
  feedback_2,
  feedback_title_1,
  feedback_title_2,
  currentOrchestratorIndex,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const dispatch = useDispatch<any>();
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const { content, auth } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  image_1 = buildURL(image_1);
  image_2 = buildURL(image_2);
  image_default = buildURL(image_default);
  preloadImage(image_1);
  preloadImage(image_2);
  const defaultImg = useMemo(
    () => (
      <img
        src={image_default}
        alt={""}
      />
    ),
    [image_default]
  );

  useEffect(() => {
    setSelectedOption(null);
  }, [content.currentOrchestratorIndex]);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
  };

  const handleUpdateCurrentOrchestratorIndex = async () => {
    try {
      const data = {
        user: auth.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        template_response: selectedOption === "option1" ? option_1 : option_2,
        template_response_id: selectedOption,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
      };

      // await dispatch(saveAnswer({ ...data }))
      //   .unwrap()
      //   .then((res: any) => {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
      );
      //   });
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  const renderImage = () => {
    switch (selectedOption) {
      case "option1":
        return (
          <img
            src={image_1}
            alt={option_1}
          />
        );
      case "option2":
        return (
          <img
            src={image_2}
            alt={option_2}
          />
        );
      default:
        return defaultImg;
    }
  };

  function renderText() {
    switch (selectedOption) {
      case "option1":
        return (
          <>
            <p className={classes.title}>{feedback_title_1}</p>
            <p className={classes.content}>{feedback_1}</p>
          </>
        );
      case "option2":
        return (
          <>
            <p className={classes.title}>{feedback_title_2}</p>
            <p className={classes.content}>{feedback_2}</p>
          </>
        );
      default:
        return <p className={classes.title}>{question}</p>;
    }
  }

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
    }
  };

  if (content.templates?.length) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header fnBack={handleBack} />
        </div>
        <div className={classes.body}>
          {renderImage()}
          <div className={classes.titles}>{renderText()}</div>
          <div className={classes.content}>
            <div className={classes.form}>
              <div className={classes.row}>
                {selectedOption === "option1" ? (
                  <Button onClick={() => handleOptionSelect("option1")}>
                    {option_1}
                  </Button>
                ) : (
                  <Button
                    backgroundColor="transparent"
                    borderColor="#EDEDED"
                    onClick={() => handleOptionSelect("option1")}
                  >
                    {option_1}
                  </Button>
                )}
              </div>
              <div className={classes.row}>
                {selectedOption === "option2" ? (
                  <Button onClick={() => handleOptionSelect("option2")}>
                    {option_2}
                  </Button>
                ) : (
                  <Button
                    backgroundColor="transparent"
                    borderColor="#EDEDED"
                    onClick={() => handleOptionSelect("option2")}
                  >
                    {option_2}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <Button
              onClick={handleUpdateCurrentOrchestratorIndex}
              disabled={selectedOption == null}
            >
              <span>Siguiente</span>
            </Button>
            {/* <Button
              size='large'
              color='secondary'
              variant='contained'
              onClick={handleUpdateCurrentOrchestratorIndex}
              disabled={selectedOption === null}
              >
              <span >Siguiente</span>
              </Button>     */}
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Question;
