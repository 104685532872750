export enum RolesIds {
  Administrator = 1,
  Storer = 2,
  Guest = 3,
  AreaManager = 4,
}

export const RolesOptions = [
  { id: RolesIds.Administrator, name: 'Administrador' },
  { id: RolesIds.Storer, name: 'Psicologo' },
  { id: RolesIds.Guest, name: 'Colaborador' },
  { id: RolesIds.AreaManager, name: 'Jefe de Recursos Humanos' },
];
