import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useSyles } from "./introduction.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import UpdateCategoriesItems from "./updateCategoriesItems";
import Button from "src/components/common/Button";
import Header from "../../Header";
import { updateSelfKnowledge } from "src/redux/content/test/thunks";
import Swal from "sweetalert2";
import { setSelfKnowledge } from "src/redux/auth/slice";
import { mapper } from "src/helpers/utils";

const Categories = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { related_contents } = useAppSelector((state: RootState) => state.auth);

  const { contentTest } = useAppSelector((state: RootState) => state);
  const buttonTexts: string[] = [
    "Fortalecer mi autoestima",
    "Manejar preocupaciones o ansiedad",
    "Conocer mis emociones",
    "Enfrentar la tristeza",
    "Regular el estrés",
    "Reforzar mi relación de pareja",
  ];

  const saveAndGoToLogin = () => {
    let clickedItemsTmp = [];
    for (let i = 0; i < clickedItems.length; i++) {
      if (clickedItems[i]) {
        clickedItemsTmp.push(i);
      }
    }
    const categoriesMap = mapper(clickedItemsTmp);

    const newData = {
      id: contentTest.test_id,
      categories: [],
      categories_complement: categoriesMap,
    };

    dispatch(updateSelfKnowledge({ ...newData }))
      .unwrap()
      .then(() => {
        dispatch(setSelfKnowledge(false));
        navigate("home");
      });
  };

  const [clickedItems, setClickedItems] = useState<boolean[]>([]);

  useEffect(() => {
    let clickedItemsTmp = [false, false, false, false, false, false];
    for (let i = 0; i < clickedItemsTmp.length; i++) {
      if (contentTest.categories.includes(i)) {
        clickedItemsTmp[i] = true;
      }
    }
    setClickedItems(clickedItemsTmp);
  }, []);

  const handleItemClick = (index: number) => {
    // Copiar el estado actual de clics
    const newClickedItems = [...clickedItems];
    // Cambiar el estado del elemento clicado
    newClickedItems[index] = !newClickedItems[index];
    let cont = 0;
    for (let i = 0; i < newClickedItems.length; i++) {
      if (newClickedItems[i]) {
        cont++;
      }
    }
    // Actualizar el estado
    if (cont <= 3) {
      setClickedItems(newClickedItems);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Categorias",
        text: "Solo se permiten máximo 3 categorias",
      });
    }
  };

  const handleBack = () => {
    dispatch(updateStep(4));
  };

  const goToHome = () => {
    dispatch(setSelfKnowledge(false));
    navigate(`/home`, { replace: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} fnClose={goToHome}/>
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>Este es tú espacio: tú decides.</p>
          <p className={classes.subtitle}>¿Por dónde te gustaría empezar?</p>
        </div>
        <div className={classes.content}>
          <UpdateCategoriesItems
            items={buttonTexts}
            clickedItems={clickedItems}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={() => saveAndGoToLogin()}>
            <span>Diseñar mi espacio</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Categories;
