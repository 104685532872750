import { Description, Title, WrapperSection,ContentSectionFaq } from './Faqs.styles';
import { schemaFAQPage } from './Faqs.constants';
import FaqsAccordion from './FaqsAccordion';

const Faqs = () => (
    <ContentSectionFaq>
        <WrapperSection>
            <Description>
                <Title>Preguntas frecuentes</Title>
                <p>
                    Respuestas a tus preguntas
                </p>
            </Description>
            <FaqsAccordion schema={schemaFAQPage} titleAs='h3'/>
        </WrapperSection>
    </ContentSectionFaq>
);
export default Faqs;
