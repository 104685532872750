const year = new Date().getFullYear();

export const footerContent = {
  orenta: {
    text: "Juan O'donojú 221, Lomas Virreyes, Lomas de Chapultepec IV Secc, Miguel Hidalgo, 11000 Ciudad de México, CDMX.",
  },
  acercaDe: {
    text: "Acerca",
    link: "#",
    gtm: "menu-acerca",
  },
  contactoTelefono: {
    text: "Tel: (800) 015 1617",
    link: "tel:+8000151617",
    gtm: "telephone",
  },
  preguntas: {
    text: "Preguntas",
    link: "#",
    gtm: "questions",
  },
  terminos: {
    text: "Términos y condiciones",
    link: "/terminos-y-politicas",
    gtm: "menu-terminos",
  },
  privacidad: {
    text: "Aviso de privacidad",
    link: "/terminos-y-politicas",
    gtm: "menu-privacidad",
  },
  twitter: {
    link: "https://twitter.com/orenta",
    gtm: "icon-twitter",
  },
  facebook: {
    link: "https://www.facebook.com/orenta",
    gtm: "icon-facebook",
  },
  linkedin: {
    link: "https://www.linkedin.com/company/orenta/",
    gtm: "icon-linkedin",
  },
  instagram: {
    link: "https://www.instagram.com/orenta/",
    gtm: "icon-instagram",
  },
  copyright: {
    text: `Todos los derechos reservados Orenta.com ${year}`,
  },
};
