import React, { useState } from 'react';
import { useSyles } from './step3.styles';
import { useDispatch } from 'react-redux';
import Loading from '../../common/Loading';
import { updateStep } from '../../../redux/auth/slice';
import { useStylesG } from '../Login/index.styles';
import { useStyles1 } from './step1.styles';
import Header from '../header';
import Button from 'src/components/common/Button';

const Step4 = () => {
  const classes = useSyles({ colorHeader: '#FFE672' });
  const classesG = useStylesG({});
  const classes1 = useStyles1({ colorHeader:"#014951"});

  const dispatch = useDispatch<any>();
  const [loading] = useState(false);

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  return (
    <div className={classesG.container}>
      <Header title={"Terapias 1:1 ilimitadas"} 
              subtitle={"Crea el futuro que quieres para ti de la mano de nuestros psicólogos certificados."}
              colorHeader={"#014951"}
              heightHeader={'250px'}
      />
      <div className={classesG.body}>
       
        <div className={classes.content}>
        <img src="./images/Auth/Onboarding/02.png" alt="" />
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
             <Button onClick={()=>handleUpdateStep(5)} >
                <span >Siguiente</span>
              </Button>
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default Step4;
