import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles((theme) => ({
  roleName: {
    fontSize: '11px',
  },
  outherinfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menu: {
    marginTop: theme.spacing(6),
  },
  pointer: {
    cursor: 'pointer',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  header: {
    background: theme.palette.primary.main,
    width: '100%',
    height: '100vh',
  },
  appBar: {
    zIndex: 1201,
  },
  menuIcon: {
    marginRight: '2px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    width: '90px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: 'auto',
    },
    '& > img ': {
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subtitle: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
}));
