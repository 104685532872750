import React, {  useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { useStyles } from './introduction.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../redux/content/test/slice';
import Button from 'src/components/common/Button';
import Icon from 'src/components/common/Icon';


const Introduction = () => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const name = auth.register.name
  const [success, setSuccess] = useState(false);
  const initialValues = {
    name_company: '',
    name: '',
    lastname: '',
    human_resources_manager: '',
    email_manager: '',
  };

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  const goToHome = () => {
    navigate(`/home`, { replace: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src="./images/OrentaAll.png" alt="" />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>Check-in de Bienestar</p>
          <span className={classes.subtitle}>Conoce tu nivel de bienestar</span>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>¿Qué es?</span></p>
            <p className={classes.subtitle}>Es un test corto dónde responderás algunas preguntas acerca de cómo te has sentido durante el último mes</p>
          </div>
          <div className={classes.bloqueText}>
            <p className={classes.subtitle}><span>¿Por que es importante?</span></p>
            <p className={classes.subtitle}>Te ayuda a darle seguimiento a tu progreso a lo largo del tiempo</p>
          </div>
        
        </div>
        <div className={classes.content}>
          <p><Icon icon="orenta-icon-time" />Tiempo de duración: 30 Segundos</p>
          <div className={classes.bloqueText}>
            <a href='/' >¿Necesitas hablar con alguien ahora?</a>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
            <Button onClick={()=>handleUpdateStep(2)} >
              <span >Iniciar Test</span>
            </Button>
            <Button
                backgroundColor='transparent'
                borderColor='#EDEDED'
                onClick={goToHome}>
                Realizar después
            </Button>
        </div>
      </div>
    </div>
  );
};
export default Introduction;
