import styled from 'styled-components';



export const SectionBeneficios = styled.section`
   background-color: ${({ theme }) => theme.color.white};
   padding: 32px 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 65px 92px 32px 92px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 95px 132px 32px 132px;
    }
    
`;

export const Title = styled.div`
    text-align: center;
      /* Title */
    font-family: "Antonia H3";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 100% */
    letter-spacing: -0.9px;
    color: #6375B6;
    padding-bottom: 20px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 130% */
      letter-spacing: -1.2px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 130% */
      letter-spacing: -1.2px;
    }
`;

// Estilos para una pestaña individual
export const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
`;


// Estilos para una tarjeta individual
export const Card = styled.div`
  background: white;
  box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
  margin: 10px;
  text-align: center;
  h2{
    padding: 22px 12px 20px 12px;
    /* SubTitle */
    font-family: "Antonia H3";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 130% */
    letter-spacing: -1.2px;
    
    color: #6375B6;
  }
  div {
    padding: 0px 12px 30px 12px;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;

  }
  p {
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
    letter-spacing: -0.36px;
    padding-bottom: 20px;
  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;