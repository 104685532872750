import React from "react";
import Layout from "../Layout";
import Portada from "./sections/portada";
import Benefits from "./sections/Benefits/Benefits";
import Faqs from "./sections/Faqs/Faqs";
import BienestarEmocional from "./sections/BienestarEmocional/BIenestarEmocional";
import Clientes from "./sections/Clientes";
import FacilSeguro from "./sections/FacilSeguro/FacilSeguro";
import Testimonials from "./sections/Testimonials/Testimonials";
import YourSpace from "./sections/YourSpace/YourSpace";
import { useNavigate } from "react-router-dom";

const Index = ({ props }: any) => {
  const navigate = useNavigate();

  if (localStorage.getItem("jwt")) {
    navigate("/home");
    setTimeout(() => navigate("/home"), 1000);
  }

  return (
    <Layout>
      <Portada />
      <FacilSeguro />
      <YourSpace />
      <Testimonials />
      <Clientes />
      <Benefits />
      <BienestarEmocional />
      <Faqs />
    </Layout>
  );
};

export default Index;
