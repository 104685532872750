import React,{useEffect} from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import Introduction from './introduction';
import Question from '../question';
import ProcessResult from './processResult';
import Result from './result';
import {questions} from './dataWelfare';
import { useDispatch } from 'react-redux';
import { setCtTypeTestId } from 'src/redux/content/test/slice';

const Index = () => {
  const dispatch = useDispatch<any>();
  const { contentTest } = useAppSelector((state: RootState) => state);
  
  useEffect(() => {
    dispatch(setCtTypeTestId(2));
  }, []);

  switch(contentTest.step){
    case 1: return (<Introduction />);
    case 2: return (<Question questions={questions}/>);
    case 3: return (<ProcessResult />);
    case 4: return (<Result />);
    
  }
  return (
    <Introduction />
  );
};
export default Index;
