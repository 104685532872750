import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useStyles1 = makeStyles({

  titles:{
    position:'absolute',
    bottom:'0px',
    alignSelf: 'stretch',
    display: 'flex',
    width: '70%',
    padding: '0 0 50px 17px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
  },
    title:{
      
      color: '#FFF',
      /* Title Mobile */
      fontFamily:' Antonia H3',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight:700,
      lineHeight: '30px', /* 100% */
      letterSpacing: '-0.9px',
    },
    subtitle:{
        color: '#FFF',
        fontFamily: 'Work Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.42px',
    },
     
     

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
    form: {
      background: '#ffffff',
      width: '100%',
      padding: '16px',
      borderRadius: '16px',
      display: 'grid',
      gap: '24px',
    },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width:'100%',
  },

  buttons:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '16px',
    width:'100%',
    
  },
  backButton: {
    marginRight: '8px',
    width:'initial !important'
  },
  createButton: {
    marginLeft: '8px', // Espacio entre los botones
    flex: 1, // Hace que el botón ocupe el espacio restante
  },

});
