import React, { useEffect } from 'react';
import Layout from '../common/Layout';
import { CardTotal } from './CardTotal/CardTotal';
import { useStyles } from './styles';
import { GraphicDonut } from './GraphicDonut/GraphicDonut';
import { getDashboardData } from '../../redux/dashboard/thunks';
import { getContentsUser } from '../../redux/users/thunks';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../common/Loading';
import { People, House, VpnKey } from '@material-ui/icons';
import { Card, Box, Typography } from '@material-ui/core';
import Employee from './Employee';

const Dashboard = () => {
  const dispatch = useDispatch<any>();
  const {
    loading,
    data: { companies, users },
  } = useSelector((state: any) => state.dashboard);
  const { type, name, id } = useSelector((state: any) => state.auth);
  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getContentsUser(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <>
          {type < 3 ? (
            <div className={classes.container}>
            <div className={classes.gridArea}>
              {companies?.length > 0 && (
                <CardTotal
                  list={companies}
                  title='Total Empresas registradas'
                  color='primary'
                  icon={House}
                />
              )}
              
              
              {users?.length > 0 && (
                <CardTotal
                  list={users}
                  title='Total Usuarios'
                  color='secondary'
                  progress
                  icon={People}
                />
              )}
              {companies?.length > 0 ? (
                  <GraphicDonut listData={companies} title='Total de Empresas' />
                ) : (
                  ''
                )}
              {users?.length > 0 ? (
                  <GraphicDonut listData={users} title='Total de Usuarios' />
                ) : (
                  ''
                )}
            </div>
          </div>
          ) : (
            <Employee name={name}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
