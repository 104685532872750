import React, { useState } from 'react';
import Formulario from './formulario';
import RemplacePassword from './RemplacePassword';
import Information from './information';
import Header from 'src/components/Contents/Header';

const Index = () => {
  const [activeSection, setActiveSection] = useState(1);
  const handleSectionClick = (sectionNumber: number) => {
    setActiveSection(sectionNumber);
    // Aquí podrías añadir la lógica para navegar a la sección correspondiente
    // dependiendo de la sección en la que se hizo clic
  };

  const goToBack = () => {
    handleSectionClick(activeSection-1);
  }

  switch(activeSection){
    case 1:
      return (
        <>
          <Header />
          <Information handleSectionClick={handleSectionClick}/>
        </>
        
      );
    case 2:
      return (
        <>
          <Header fnBack={goToBack}/>
          <Formulario handleSectionClick={handleSectionClick} />
        </>
      );
    case 3:
      return (
        <>
          <Header fnBack={goToBack}/>
          <RemplacePassword />
        </>
      );
    default: 
      return (
        // <Information />
        <></>
      );
  }
  
};
export default Index;
