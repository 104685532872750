import React from "react";

interface LazyImageProps {
  src: string;
  alt: string;
  style?: any;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, style = {} }) => {
  return (
    <img
      loading="lazy"
      src={src}
      alt={alt}
      style={{ ...style }}
    />
  );
};

export default LazyImage;
