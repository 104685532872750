import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import {TitlePreloader,SubTitlePreloader} from './preloader.styles';

interface CustomProgressBarProps {
  progress: number;
  barColor: string;
  heightBar: string;
  rainbowBar: boolean;
  velocity?:number;
  animation?:boolean;
  limitInferior:number;
  limitInter:number;
  limitSup:number;
  leftText:string;
  centerText:string;
  rightText:string;
  title:string;
  titleAlignment:any;
}

const Preloader: React.FC<CustomProgressBarProps> = ({
  progress,
  barColor,
  heightBar,
  rainbowBar,
  velocity=100,
  animation=false,
  limitInferior,
  limitInter,
  limitSup,
  leftText,
  centerText,
  rightText,
  title,
  titleAlignment,

}) => {
  const [animatedProgress, setAnimatedProgress] = useState(limitInferior);
  // if(!velocity)
  //   velocity = 100;
  // if(!animation)
  //   animation = false;
  useEffect(() => {
    
    if (animation) {
      const interval = setInterval(() => {
        if(animatedProgress<=limitInter)
          velocity = 400;
        if(animatedProgress > limitInter)
          velocity = 50;
        setAnimatedProgress((prevProgress) => 
          prevProgress < limitSup ? prevProgress + 1 : 0
        );
      }, velocity); // Controla la velocidad de la animación
      return () => clearInterval(interval);
    }
  }, [animation]);

  return (
    <div>
      <TitlePreloader className={titleAlignment}>
        {title}
      </TitlePreloader>
      <div
        style={{
          width: '100%',
          backgroundColor: 'lightgray',
          height: heightBar,
          borderRadius: '32.928px',
        }}
      >
        <div
          style={{
            width: `${animatedProgress}%`,
            height: '25px',
            backgroundColor: barColor,
            backgroundImage: rainbowBar
              ? ' linear-gradient(90deg, #FFB0BF 0%, #B6D4FA 48.96%, #8DD6CA 100%)'
              : undefined,
            borderRadius: '32.928px',
            transition: 'width 0.5s ease', // Controla la animación de la barra de progreso
          }}
        ></div>
        {leftText && centerText && rightText && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SubTitlePreloader className="left">
            {leftText}
          </SubTitlePreloader>
          <SubTitlePreloader className="center">
            {centerText}
          </SubTitlePreloader>
          <SubTitlePreloader className="right">
            {rightText}
          </SubTitlePreloader>
        </div>
      )}
      </div>
    </div>
  );
};

export default Preloader;
