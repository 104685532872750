import React, { useState, useEffect } from "react";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import { useNavigate } from "react-router-dom";
import { Card, SectionTools, Title } from "./Tools.styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RootState } from "src/domain/models/RootState";
import { useSelector } from "react-redux";
import Loading from "src/components/common/Loading";
import LazyImage from "src/components/common/Lazy/LazyImage";

const Tools = () => {
  const navigate = useNavigate();
  const { users } = useSelector((state: RootState) => state);
  const [activeTab, setActiveTab] = useState(1);

  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    setCardsData(users.contents);
  }, [users.contents.length]);

  const goToUrl = (url: string) => {
    window.open(url, "_self");
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  if (cardsData.length > 0) {
    return (
      <SectionTools>
        <GridContainer p-lg={"0px"}>
          <GridItem
            lg={6}
            md={4}
          >
            <Title>Herramientas sugeridas</Title>
          </GridItem>
        </GridContainer>
        <Carousel
          key={"carouserlTools"}
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          //autoPlay={true}
          //autoPlaySpeed={2000}
          //keyBoardControl={true}
          customTransition="all .5"
          //transitionDuration={5000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={"mobile"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-10px"
          partialVisible={true}
        >
          {cardsData.map(
            (
              card: { id: number; uuid: string; image: string; title: string },
              index
            ) => (
              <Card
                key={`tools_${index}`}
                onClick={() => goToUrl(`/contents/${card.uuid}`)}
              >
                <>
                  {card.image != "" && (
                    <LazyImage
                      src={card.image}
                      alt={""}
                    />
                  )}
                  {card.title != "" && <h2>{`${card.title}`}</h2>}
                </>
              </Card>
            )
          )}
        </Carousel>
      </SectionTools>
    );
  } else {
    return <Loading />;
  }
};

export default Tools;
