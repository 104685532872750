import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useStyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '74px',
    margin:'10px',
    borderRadius: '8px',
    background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
      title:{
        color:  '#625B71',
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px', /* 150% */
        letterSpacing: '0.15px',
        paddingTop:'20px',
        textAlign: "center"
      },
      subtitle:{
          color: '#625B71',
          /* M3/label/medium - prominent */
          fontFamily: 'Work Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px', /* 133.333% */
          letterSpacing: '0.5px',
      },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width:'100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
    width:'100%'
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },
  containerButton:{
    width: '100%',
    display: 'grid',
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      height: '48px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
    },
  },
  information:{
    display: 'flex',
    padding: '16px 10px 20px 10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'10px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#E2F7FE',
    marginBottom:'40px'
  },
  information1:{
    alignSelf: 'stretch',
    color: '#2E95BB',
    textAlign: 'center',
    /* Heading/Heading 06/Bold */
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '13px', /* 130% */
    paddingBottom: "2rem"
  },
  information2:{
    alignSelf: 'stretch',
    color: '#003C3C',
    textAlign: 'center',
    /* Heading/Heading 06/Medium */
    fontFamily: 'Work Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%', /* 14px */
    letterSpacing: '-0.3px',
    
  }

}));
