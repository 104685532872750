import styled from 'styled-components';

export const ContentFormulario = styled.div`
    height: 100%;
    padding: 16px;
    position: relative;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 60px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 60px;
    }
`;


export const Name = styled.p`
    color: var(--colors-navy, #104B71);
    /* SubTitle */
    font-family: "Antonia H3";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`;

export const Job = styled.p`
    color: var(--colors-navy, #104B71);
    /* H4 Bold */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`;

export const Email = styled.p`
    color: var(--colors-navy, #104B71);
    /* H4 Bold */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`;

export const Subseccion = styled.p`
    color: var(--colors-gray, #606060);
    /* H6 Bold */
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
    letter-spacing: -0.36px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
`;

export const ContentImgCompany = styled.div`
    
    img {

    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
`;
export const Header = styled.div`
    
    img {
      
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
`;

export const Body = styled.div`
    
    img {
      
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
`;

export const Footer = styled.div`
    position: absolute;
    width: calc( 100% - 32px);
    bottom: 70px;

    button {
      margin-bottom:10px;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
`;

export const Buttons = styled.div`
    
    img {
      
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        
    }
`;