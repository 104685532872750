import React, {useState } from 'react';
import { useSyles } from './step3.styles';
import { useDispatch } from 'react-redux';
import Loading from '../../common/Loading';
import { updateStep } from '../../../redux/auth/slice';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import Button from 'src/components/common/Button';

const Step6 = () => {
  const classes = useSyles({ colorHeader: '#EC6B60' });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const [loading] = useState(false);

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  return (
    <div className={classesG.container}>
      <Header title={"Atención psicológica 24/7, 365 días al año"} 
              subtitle={"Sea de día o de noche nuestros psicólogos están listos para orientarte a través de la Línea de Ayuda o Whatsapp."}
              colorHeader={"#014951"}
              heightHeader={'250px'}
      />
      <div className={classesG.body}>
        <div className={classes.content}>
        <img src="./images/Auth/Onboarding/03.png" alt="" />
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
            <Button onClick={()=>handleUpdateStep(6)} >
                <span >Siguiente</span>
              </Button>
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default Step6;
