import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
//Material
import { TextField, Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//Components
import { useSyles } from './styles';
import Layout from '../common/Layout';
import Loading from '../common/Loading';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { AreaOptions } from '../../domain/constants/Areas';
import { RolesOptions } from '../../domain/constants/Roles';

type PropsForm = {
  submitEdit: (_: any) => void;
  initialValues: any;
  actionText?: any;
};
const Form = ({ submitEdit, initialValues, actionText }: PropsForm) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const classes = useSyles();

  // Acceder al state del store
  const { users, loading } = useSelector((state: any) => state.users);

  // función que redirige de forma programada
  const cancelAdd = () => {
    navigate(`/users`, { replace: true });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    ct_rols_id: yup.number().required('Selecciona el tipo de rol'),
    name: yup.string().required('Escribe el nombre del usuario'),
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('Escribe un correo electrónico'),
    phone: yup.number().required('Digita el número de teléfono'),
    id_area: yup.number().required('Selecciona el tipo de area'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => submitEdit(values),
  });
  return (
    <Layout>
      <div className={classes.container}>
        {!loading ? (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <FormControl variant='outlined'>
              <InputLabel>Rol de Usuario</InputLabel>
              <Select
                id='form-roles_id'
                name='ct_rols_id'
                value={formik.values.roles_id}
                onChange={formik.handleChange('roles_id')}
                error={
                  formik.touched.roles_id && Boolean(formik.errors.roles_id)
                }
                label='Rol de usuario'
              >
                <MenuItem value=''>
                  <em>Selecciona un rol de usuario</em>
                </MenuItem>
                {RolesOptions.map(({ id, name }: any) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id='form-name'
              name='name'
              label='Nombre'
              variant='outlined'
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            <TextField
              id='form-email'
              name='email'
              label='Correo electrónico'
              variant='outlined'
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <TextField
              id='form-phone'
              name='phone'
              label='Teléfono'
              variant='outlined'
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
            <FormControl variant='outlined'>
              <InputLabel>Empresa</InputLabel>
              <Select
                id='form-id_area'
                name='id_area'
                value={formik.values.id_area}
                onChange={formik.handleChange('id_area')}
                error={formik.touched.id_area && Boolean(formik.errors.id_area)}
                label='Empresa'
              >
                <MenuItem value=''>
                  <em>Selecciona una empresa</em>
                </MenuItem>
                {AreaOptions.map(({ id, name }: any) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.flex}>
              <Button onClick={cancelAdd}>Cancelar</Button>
              <Button
                className={classes.button}
                size='medium'
                color='secondary'
                variant='contained'
                onClick={onAction}
              >
                {actionText}
              </Button>
            </div>
          </form>
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};

export default Form;
