import React, { ReactNode } from 'react';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { ContentTemplate, WrapperTemplate,FondoWhite,ContentB, WrapperB } from './index.styles';

interface LayoutProps {
  children: {
    secondaryChild: ReactNode;
    principalChild: ReactNode;
  };
  backgroundColor?: any;
}

const Template: React.FC<LayoutProps> = ({ children,backgroundColor }) => {
    return (
        <WrapperTemplate backgroundColor={backgroundColor}>
            <ContentTemplate >
                <GridContainer p-lg={'0'} p-sm={'0'} ggap-lg={0} className={"contenedorCat"}>
                    <GridItem lg={1} md={0} sm={0}>
                        <></>
                    </GridItem>
                    <GridItem lg={10} md={8}>
                        <WrapperB  >
                            <ContentB >
                                <GridContainer>
                                    <GridItem lg={12} md={12} sm={6}>
                                        {children.secondaryChild}
                                    </GridItem>
                                </GridContainer>
                            </ContentB>
                        </WrapperB>
                    </GridItem>
                    <GridItem lg={1} md={0} sm={0} >
                        <></>
                    </GridItem>
                </GridContainer>
                <GridContainer p-lg={'0px'}>
                    {children.principalChild}
                </GridContainer>
            </ContentTemplate>
            <FondoWhite></FondoWhite>
        </WrapperTemplate>
    );
};

export default Template;
