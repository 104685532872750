import { PropsCell } from '../common/Table/TableHeader';

export const headCells: PropsCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'type_user', numeric: false, disablePadding: false, label: 'Tipo de usuario' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Correo' },
  { id: 'rol', numeric: false, disablePadding: false, label: 'Tipo de usuario' },
  { id: 'state', numeric: false, disablePadding: false, label: 'Estatus' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Acciones' },
];

export const fieldsFilter = [
  { key: 'name', name: 'Nombre de usuario' },
  { key: 'email', name: 'Correo' },
  { key: 'ct_status_users_id', name: 'Estatus' },
  { key: 'company_id', name: 'Empresa' },
];

export const valuesEstatus = [
  { key: '1', name: 'Inactivo' },
  { key: '2', name: 'Activo' },
  { key: '3', name: 'Eliminado' },
  { key: '4', name: 'Pendiente' },
];
