import { BrowserRouter, Routes, Route } from "react-router-dom";

/* import internal modules */
import Dashboard from "../components/Dashboard/Dashboard";
import Companies from "../components/Companies";
import NewCompany from "../components/Companies/NewCompany";
import EditCompany from "../components/Companies/EditCompany";
import Protected from "./Protected";
import Users from "../components/Users";
import EditUser from "./../components/Users/EditUser";
import NewUser from "./../components/Users/NewUser";
import Login from "../components/Auth/Login";
import { useSelector } from "react-redux";
import { Register } from "../components/Auth/Register";
import { SystemActions } from "../domain/constants/SystemActions";
import { RolePermissions } from "../domain/constants/RolePremissions";
import ForgotPassword from "src/components/Auth/ForgotPassword";
import Header from "../components/common/Header";
import Step1 from "src/components/Auth/CreateAccount/step1";
import Step2 from "src/components/Auth/CreateAccount/step2";
import Step3 from "src/components/Auth/CreateAccount/step3";
import Step4 from "src/components/Auth/CreateAccount/step4";
import Step5 from "src/components/Auth/CreateAccount/step5";
import Step6 from "src/components/Auth/CreateAccount/step6";
import Step7 from "src/components/Auth/CreateAccount/step7";
import Step8 from "src/components/Auth/CreateAccount/step8";
import EmailNotFound from "src/components/Auth/EmailNotFound";
import RequestDemo from "src/components/Auth/RequestDemo";
import RequestDemoS from "src/components/Auth/RequestDemo/requestDemoS";
import SendEmailSuccess from "src/components/Auth/ForgotPassword/sendEmailSuccess";
import ChangePassword from "src/components/Auth/ForgotPassword/changePasswordForm";
import ChangePasswordForm from "src/components/Auth/ForgotPassword/changePasswordForm";
import CreateAccount from "src/components/Auth/CreateAccount";
import QuizFeedback from "src/components/Contents/QuizFeedback";
//import VideoQuestion from 'src/components/Contents/VideoQuestion';
import Perception from "src/components/Contents/Perception";
import Classify from "src/components/Contents/Classify";
import SelfKnowledge from "src/components/Contents/Test/SelfKnowledge";
import Welfare from "src/components/Contents/Test/Welfare";
import ProcessResult from "src/components/Contents/Test/Welfare/processResult";
import Contents from "src/components/Contents";
import Home from "src/components/Home";
import Norma035 from "src/components/Norma035";
import LineWithYou from "src/components/Dashboard/Employee/LineWithYou/LineWithYou";
import Whatsapp from "src/components/Dashboard/Employee/Whatsapp/Whatsapp";
import IndividualTherapy from "src/components/Dashboard/Employee/IndividualTherapy/IndividualTherapy";
import OnlyMobile from "src/components/OnlyMobile";
import Policy from "src/components/Auth/PolicyTerms/policy";
import Depression from "src/components/Contents/Test/Depression";
import Gtm from "src/components/common/Gtm";

type routeProps = {
  path: string;
  component: any;
  permissionsName: string;
};

const Router = () => {
  const isMobile = window.innerWidth <= 768; // Puedes ajustar el valor según tus necesidades
  const user = useSelector(({ auth }: any) => auth.token);
  const type = useSelector(({ auth }: any) => auth.type);

  const routeList: routeProps[] = [
    {
      path: "/companies",
      component: <Companies />,
      permissionsName: SystemActions.read_companies,
    },
    {
      path: "/companies/new",
      component: <NewCompany />,
      permissionsName: SystemActions.create_companies,
    },
    {
      path: "/companies/edit/:id",
      component: <EditCompany />,
      permissionsName: SystemActions.edit_companies,
    },
    {
      path: "/users",
      component: <Users />,
      permissionsName: SystemActions.read_users,
    },
    {
      path: "/users/edit/:id",
      component: <EditUser />,
      permissionsName: SystemActions.edit_users,
    },
    {
      path: "/users/new",
      component: <NewUser />,
      permissionsName: SystemActions.create_users,
    },
  ];

  return (
    <BrowserRouter>
      <Routes>
        {routeList?.map(({ path, permissionsName, component }, i) => (
          <Route
            key={`route-key-${i}`}
            path={path}
            element={
              <Protected
                isAuth={
                  user &&
                  RolePermissions.allowed(
                    Number(type),
                    permissionsName as SystemActions
                  )
                }
              >
                <Header />
                <Gtm />
                {component}
              </Protected>
            }
          />
        ))}
        <Route
          element={
            isMobile ? (
              <Protected isAuth={user}>
                <Header />
                <Gtm />
                <Dashboard />
              </Protected>
            ) : (
              <OnlyMobile />
            )
          }
          path="/home"
        />
        <Route
          element={
            <>
              <Gtm />
              <Home />
            </>
          }
          path="/"
        />
        ;
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Login />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/login"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step1 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-1"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step2 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-2"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step3 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-3"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step4 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-4"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step5 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-5"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step6 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-6"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step7 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-7"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Step8 />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/step-8"
        />
        <Route
          element={
            <>
              <Gtm />
              <RequestDemo />
            </>
          }
          path="/request-demo"
        />
        <Route
          element={
            <>
              <Gtm />
              <Norma035 />
            </>
          }
          path="/norma035"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <EmailNotFound />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/email-not-found"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <RequestDemoS />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/request-demo-success"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Register />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/register"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <CreateAccount />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/create-account"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <ForgotPassword />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/forgot-password"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <SendEmailSuccess />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/send-email-success"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <ChangePassword />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/change-password"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <ChangePasswordForm />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/change-password-form"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <QuizFeedback />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/quiz-feedback"
        />
        {/* <Route element={isMobile ? (<><Gtm /><VideoQuestion /></>) : (<OnlyMobile />)} path='/video-question' /> */}
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Perception />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/perception"
        />
        {/* <Route element={isMobile ? (<><Gtm /><Fork /></>) : (<OnlyMobile />)} path='/fork' /> */}
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Classify />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/classify"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <SelfKnowledge />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/test-autoconocimiento"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Welfare />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/test-bienestar"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Depression />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/test-depresion"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <ProcessResult />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/process"
        />
        <Route
          element={
            isMobile ? (
              <Protected isAuth={user}>
                <Gtm />
                <Contents />
              </Protected>
            ) : (
              <OnlyMobile />
            )
          }
          path="/contents/:id"
        />
        ;
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <LineWithYou />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/linea-contigo"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Whatsapp />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/whatsapp"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <IndividualTherapy />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/terapia-invidual"
        />
        <Route
          element={
            isMobile ? (
              <>
                <Gtm />
                <Policy />
              </>
            ) : (
              <OnlyMobile />
            )
          }
          path="/terminos-y-politicas"
        />
        <Route
          element={<OnlyMobile />}
          path="/desktop"
        />
        {/* <Route
          path='/*'
          element={
            <Protected isAuth={user}>
              <Header />
              <Dashboard />
            </Protected>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
