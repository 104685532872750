import React, { useEffect } from 'react';
import { useStyles } from './terms.styles';
import Button from 'src/components/common/Button';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import { useNavigate } from 'react-router-dom';


const Terms = ({onShowTerms,view}:any) => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const classesG = useStylesG({});
  const navigate = useNavigate();

  useEffect(() => {
    navigate('?view=terms');
  }, []);

  const handleShowTerms = () => {
    navigate(window.location.pathname,{replace:true});
    if(onShowTerms){
      onShowTerms(false);
    }else{
      window.open(`/home`,'_self');
    }
  }

  return (
    <div className={classesG.container}>
    <Header title={"¡Tu privacidad es nuestra prioridad!"} 
            subtitle={"Toda tu información es tratada con privacidad y seguridad"}
            colorHeader={"#014951"}
            heightHeader={'240px'}
    />
    
      <div className={`${classesG.body} ${classes.scroll}`}>
        <div className={classes.titles}>
          <div className={classes.bloqueText}>
            <img src='/images/Terminos/01.png' />
            <p className={classes.subtitle}><span>Tu información es confidencial</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>En ningún momento divulgaremos los detalles que compartas con nosotros, asegurando así un espacio seguro para tu bienestar emocional.</p>
          </div>
          <div className={classes.bloqueText}>
            <img src='/images/Terminos/02.png' />
            <p className={classes.subtitle}><span>Tu empresa recibe información acerca del uso general de la plataforma</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>Entregamos reportes para que la empresa pueda conocer el nivel de bienestar global y los temas de mayor interés de sus equipos.</p>
            <br />
            <p className={classes.subtitle}>Los datos son anónimos y presentados como un conjunto. No contienen datos específicos que puedan identificarte.</p>
          </div>
          <div className={classes.bloqueText}>
          <img src='/images/Terminos/03.png' />
            <p className={classes.subtitle}><span>En caso de emergencia, ¿qué información se comparte con mi empresa?</span></p>
            <hr className={classes.line}/>
            <p className={classes.subtitle}>En caso de que tu seguridad esté en riesgo, tomaremos medidas para comunicarnos con tu contacto de emergencia.</p>
            <br />
            <p className={classes.subtitle}>Solo compartiremos con tu empresa la información que nos autorices a compartir</p>
          </div>
        
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
            <Button onClick={()=>handleShowTerms()} >
              <span >Entendido</span>
            </Button>
        </div>
      </div>
    </div>
  );
};
export default Terms;
