import React from 'react';
import Layout from 'src/components/Layout';
import Information from './information';
import Gtm from '../common/Gtm';

const Index = () => {

    return (
      <Layout>
        <Gtm />
        <Information /> 
      </Layout>
    );
  
};
export default Index;
