import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useSyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '104px',
    margin:'10px',
    borderRadius: '8px',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
      title:{
          color:'#149D90',
          /* Heading/Heading 03 */
          fontFamily: 'Antonia H3',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          letterSpacing: '-0.72px'
      },
      subtitle:{
          color: '#272727',
          fontFamily: 'Work Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.42px',
          textAlign:'center'
      },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto',
    width: 'calc( 100% - 32px)',
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%',
    padding: '16px',
    
  },

  buttons:{
    width: '100%',
    display: 'grid',
    '& > button ': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
      height:'48px'
    },
  },
  information:{
    display: 'flex',
    padding: '16px 10px 20px 10px',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '20px',
    background: '#FFFFFF',
    marginBottom:'40px',
    boxShadow: "0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03)",
  },
  information1:{
    alignSelf: 'stretch',
    color: '#FFFFF',
    textAlign: 'left',
    /* Heading/Heading 06/Bold */
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px', /* 130% */
    letterSpacing: '-0.42px',
    paddingLeft:'16px'

  },
  information2:{
    alignSelf: 'stretch',
    color: '#104B71',
    textAlign: 'left',
    /* Heading/Heading 06/Medium */
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px', /* 14px */
    letterSpacing: '-0.36px',
    width: "225px",
    paddingLeft:'16px'
  },
  information3:{
    alignSelf: 'stretch',
    color: '#014951',
    textAlign: 'left',
    /* Heading/Heading 06/Medium */
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px', /* 14px */
    letterSpacing: '-0.42px',
    paddingLeft:'16px'
  },
  containerEspacioSeguro:{
    position: "relative",
    '& > img ': {
      position:'absolute',
      top: "20px",
      left:'0px'
    }
  },
  
  

}));
