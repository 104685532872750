export const schemaFacilPage = {
    
    mainEntity: [
        {
            name: 'Bienestar personalizado y continuo.',
            acceptedAnswer: {
                text: `<p>Cada nivel de bienestar es único. Te apoyamos a identificar el nivel de bienestar de cada uno de los miembros de tu equipo y medir su progreso para que puedan elegir entre nuestras opciones que van desde la prevención hasta el tratamiento.</p>`,
            },
            icon:'/images/home/facilSeguro/bienestar_icon.png'
        },
        {
            name: 'Enfoque profesional basado en evidencia clínica.',
            acceptedAnswer: {
                text: `<p>Contamos con herramientas de evidencia clínica diseñadas por nuestros psicólogos para que puedas recorrer tu camino de bienestar de una manera sencilla, atractiva y a tu ritmo.  </p>`,
            },
            icon:'/images/home/facilSeguro/professional_focus_icon.png'
        },
        {
            name: 'Apoyo psicológico personalizado en todo momento.',
            acceptedAnswer: {
                text: `<p>Cada nivel de bienestar es único. Te apoyamos a identificar el nivel de bienestar de cada uno de los miembros de tu equipo y medir su progreso para que puedan elegir entre nuestras opciones que van desde la prevención hasta el tratamiento.</p>`,
            },
            icon:'/images/home/facilSeguro/support_icon.png'
        },
        {
            name: 'Elevando el nivel de cuidado personalizado.',
            acceptedAnswer: {
                text: `<p>Contamos con herramientas de evidencia clínica diseñadas por nuestros psicólogos para que puedas recorrer tu camino de bienestar de una manera sencilla, atractiva y a tu ritmo. </p>`,
            },
            icon:'/images/home/facilSeguro/level_icon.png'
        },
        
    ],
};
