import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
  urlGif?:string;
}

export const useStyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    //background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '74px',
    margin:'10px',
    borderRadius: '8px',
    //background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  root:(props: StylesProps) => ({
    position: 'relative',
    height: '100vh',
    background: `url('${props.urlGif}') center center fixed`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    backgroundSize: 'cover',
  }),
  overlay: (props: StylesProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: props.urlGif ? 'rgba(0, 0, 0, 0.5)':'rgba(0, 0, 0, 0.01)', // Cambia el valor alpha para ajustar el contraste
  }),
  text: {
    zIndex: 1,
  },
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    //backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
      title:(props: StylesProps) => ({
        color: "#149D90",
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '54px', /* 150% */
        letterSpacing: '0.15px',
        paddingTop:'20px',
        textAlign:'center'
      }),
      subtitle:(props: StylesProps) => ({
        color: "#104B71",
          /* M3/label/medium - prominent */
          fontFamily: 'Work Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px', /* 133.333% */
          letterSpacing: '-0.42px',
      }),

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    //backgroundColor: '#FFF',
    alignItems: 'center',
  },

  footer: {
    //backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%',
    
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      height: '48px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
    },
  },
  

}));
