import { AppBar, IconButton, Toolbar, Box } from "@material-ui/core";
import { Close, ArrowBack } from "@material-ui/icons";
import { useSyles } from "./styles";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  fnBack?: () => void;
  fnClose?: () => void;
}

const Header: React.FC<HeaderProps> = ({ fnBack, fnClose }) => {
  const classes = useSyles();
  const navigate = useNavigate();

  const onOpenHome = () => {
    window.open(`/home`, "_self");
  };

  const onBack = () => {
    if (fnBack) {
      fnBack();
    } else {
      navigate(-1);
    }
  };

  const onClose = () => {
    if (fnClose) {
      fnClose();
    } else {
      onOpenHome();
    }
  };

  return (
    <AppBar
      // className={`${classes.appBar} pb-0.5 !shadow-xl`}
      color="inherit"
      position="fixed"
      elevation={0}
      style={{ borderBottom: "1px solid #ccc" }}
    >
      <Toolbar>
        <IconButton
          className={classes.menuIcon}
          edge="start"
          color="inherit"
          aria-label="menu"
          href=""
          onClick={() => onBack()}
        >
          <ArrowBack />
        </IconButton>

        <Box className={classes.logo}>
          <img
            alt="Orenta"
            src={"/images/OrentaMin.png"}
            style={{ objectFit: "contain" }}
            width={30}
            height={30}
            onClick={() => onClose()}
          />
        </Box>
        <IconButton
          className={classes.menuIcon}
          edge="start"
          color="inherit"
          aria-label="menu"
          href=""
          onClick={() => onClose()}
        >
          <Close />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
