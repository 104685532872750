import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSyles } from "./step8.styles";
import { login, updatePassword } from "../../../redux/auth/thunks";
import { useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { RegisterRequest } from "src/redux/auth/models/RegisterRequest";
import Header from "../header";
import { useStylesG } from "../Login/index.styles";
import SecurityIndicator from "../SecurityIndicator";
import Button from "src/components/common/Button";
import { firstLogin } from "src/redux/auth/apis";

const Step8 = () => {
  const classes = useSyles({ colorHeader: "#003C3C" });
  const classesG = useStylesG({});
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRev, setShowPasswordRev] = useState(false);
  const [success, setSuccess] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const initialValues = {
    user_id: auth.register.user_id,
    password: "",
    verify_password: "",
  };

  const handlerSubmit = (values: RegisterRequest, email: string) => {
    dispatch(updatePassword({ ...values }))
      .unwrap()
      .then(() => {
        setSuccess(true);
        navigate(`/home`, { replace: true });
      });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Debe contener al menos 8 caracteres")
      .matches(/[a-zA-Z]/, "Debe contener al menos una letra")
      .matches(/[0-9]/, "Debe contener al menos un número")
      .matches(/[^a-zA-Z0-9]/, "Debe contener al menos un carácter especial"),
    verify_password: yup
      .string()
      .oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values, auth.register.email);
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibilityRev = () => {
    setShowPasswordRev(!showPasswordRev);
  };

  useEffect(() => {
    let securityLevelTmp = 0;
    if (formik.values.password != "" && formik.values.password.length >= 8) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[a-zA-Z]/) !== null) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[0-9]/) !== null) {
      securityLevelTmp++;
    }
    if (formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null) {
      securityLevelTmp++;
    }
    setSecurityLevel(securityLevelTmp);
  }, [formik.values.password]);

  return (
    <div className={classesG.container}>
      <Header
        title={"¡Por último, crea tu contraseña!"}
        subtitle={""}
        colorHeader={"#014951"}
        heightHeader={"220px"}
      />

      <div className={classesG.body}>
        <div className={classes.titles}>
          <div className={classes.containerList}>
            <p className={classes.subtitle}>
              Utiliza los siguientes criterios:
            </p>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.password != "" &&
                      formik.values.password.length >= 8
                    }
                    className={classes.checkbox}
                  />
                }
                label={
                  <span className={classes.label}>
                    Al menos 8 caracteres: cuantos más caracteres, mejor.
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formik.values.password.match(/[a-zA-Z]/) !== null}
                  />
                }
                label={
                  <span className={classes.label}>
                    Una mezcla de letras mayúsculas y minúsculas
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formik.values.password.match(/[0-9]/) !== null}
                  />
                }
                label={
                  <span className={classes.label}>
                    Una mezcla de letras y números.
                  </span>
                }
              />
            </div>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={
                      formik.values.password.match(/[^a-zA-Z0-9@#\?]/) !== null
                    }
                  />
                }
                label={
                  <span className={classes.label}>
                    ¡Inclusión de al menos uno de estos caracteres especiales! @
                    # ?
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            {/* <TextField
           id="password"
           name="password"
           label="Contraseña"
           type="password"
           variant='outlined'
           value={formik.values.password}
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}
           className={classes.inputL}
           error={formik.touched.password && Boolean(formik.errors.password)}
           helperText={formik.touched.password && formik.errors.password}
         /> */}
            <TextField
              id="password"
              label="Contraseña"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              //  className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.errors.password as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPassword ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {securityLevel > 0 && (
              <SecurityIndicator securityLevel={securityLevel} />
            )}

            {/* <TextField
             id="verify_password"
             name="verify_password"
             label="Confirmar Contraseña"
             type="password"
             variant='outlined'
             className={classes.inputL}
             value={formik.values.verify_password}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             error={formik.touched.verify_password && Boolean(formik.errors.verify_password)}
             helperText={formik.touched.verify_password && formik.errors.verify_password}     
           /> */}
            <TextField
              id="verify_password"
              label="Confirmar Contraseña"
              variant="outlined"
              name="verify_password"
              type={showPasswordRev ? "text" : "password"}
              //className='w-full'
              className={classes.inputL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.verify_password &&
                Boolean(formik.errors.verify_password)
              }
              helperText={formik.errors.verify_password as any}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibilityRev}
                      edge="end"
                    >
                      {/* Usa tus imágenes SVG aquí */}
                      {!showPasswordRev ? (
                        <img
                          src={"/images/Auth/Ingresar/eye-line.svg"}
                          alt="eye-open"
                        />
                      ) : (
                        <img
                          src={"/images/Auth/Ingresar/eye-off-line.svg"}
                          alt="eye-closed"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </div>
      </div>
      <div className={classes.footer}>
        {true ? (
          <div className={classes.buttons}>
            <Button
              onClick={onAction}
              disabled={
                !formik.isValid ||
                formik.values.password !== formik.values.verify_password
              }
            >
              <span>Crear cuenta</span>
            </Button>
          </div>
        ) : (
          <Loading height="150px" />
        )}
      </div>
    </div>
  );
};
export default Step8;
