import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import Swal from 'sweetalert2';
//import { RegisterTestRequest } from './models/RegisterTestRequest';


export const register = createAsyncThunk(
  'registerTest',
  async (data: any) => {
    try {
      const res = await API.register(data);
      return { ...res };
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo error al registrar la prueba',
        text: 'Por favor, contacta a un administrador',
      });
      throw error;
    }
  }
);

export const updateSelfKnowledge = createAsyncThunk(
  'updateSelfKnowledge',
  async (data: any) => {
    try {
      const res = await API.updateSelfKnowledge(data);
      return { ...res };
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo error al registrar las categorias',
        text: 'Por favor, contacta al administrador',
      });
      throw error;
    }
  }
);

export const updateWelfare = createAsyncThunk(
  'updateWelfare',
  async (data: any) => {
    try {
      const res = await API.updateWelfare(data);
      return { ...res };
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo error al registrar las categorias',
        text: 'Por favor, contacta al administrador',
      });
      throw error;
    }
  }
);

export const updateDepression = createAsyncThunk(
  'updateDepression',
  async (data: any) => {
    try {
      const res = await API.updateDepression(data);
      return { ...res };
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo error al registrar el nivel de depresión',
        text: 'Por favor, contacta al administrador',
      });
      throw error;
    }
  }
);
