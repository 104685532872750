import { useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import { MoreVert as MenuIcon, Close } from "@material-ui/icons";
import { useSyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/auth/thunks";
import { RootState } from "../../../domain/models/RootState";
import { useNavigate } from "react-router-dom";

const Header = ({ onOpenMenu }: any) => {
  const classes = useSyles();
  const navigate = useNavigate();
  const { token, user, name, role_name, type } = useSelector(
    (state: RootState) => state.auth
  );
  const title = ""; //useSelector((state: RootState) => state.renovation.title);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTerminos = () => {
    navigate(`/terminos-y-politicas`, { replace: true });
  };

  const handleSoporte = () => {
    // Reemplaza 'destinatario@example.com' con la dirección de correo electrónico a la que deseas enviar el correo.
    const destinatario = "soporte.orenta@forigen.org";

    // Puedes personalizar el asunto y el cuerpo del correo electrónico según tus necesidades.
    const asunto = "Solicito ayuda a Orenta";
    const cuerpo = "Describe detalladamente tu situación";

    // Crea la URL con el esquema 'mailto:' y los parámetros correspondientes.
    const mailtoURL = `mailto:${destinatario}?subject=${encodeURIComponent(
      asunto
    )}&body=${encodeURIComponent(cuerpo)}`;

    // Cambia la ubicación actual a la URL creada, abriendo así el cliente de correo predeterminado.
    window.location.href = mailtoURL;
  };

  const handleLogout = () => {
    dispatch<any>(logout())
      .unwrap()
      .then(() => {
        // window.location.href = '/';
      });
  };

  return (
    <AppBar
      // className={`${classes.appBar} pb-0.5 !shadow-xl`}
      elevation={0}
      color="inherit"
      position="fixed"
      style={{ borderBottom: "1px solid #ccc" }}
    >
      <Toolbar>
        {token && type != 3 && (
          <IconButton
            className={classes.menuIcon}
            edge="start"
            color="inherit"
            aria-label="menu"
            href=""
            onClick={() => onOpenMenu()}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box className={classes.logo}>
          <img
            alt="Orenta"
            src={"/images/OrentaOnly.png"}
            style={{ objectFit: "contain" }}
            width={30}
            height={30}
          />
        </Box>
        <div className={classes.subtitle}>{title}</div>

        {token && (
          <>
            <Box
              className={classes.pointer}
              onClick={(event) => handleMenuOpen(event)}
            >
              {/* <div className={classes.outherinfo}>
                <span className={classes.roleName}>{name}</span>
              </div>
              <Avatar alt={name} className={classes.avatar} /> */}
              <IconButton
                className={classes.menuIcon}
                edge="start"
                color="inherit"
                aria-label="menu"
                href=""
                //onClick={() => onOpenMenu()}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className={classes.menu}
              key={"menu"}
            >
              <MenuItem key={"menu1"}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar
                    alt={name}
                    src={""}
                    className={classes.avatar}
                  />
                  <Box marginTop={1}>{role_name}</Box>
                  <Box marginTop={1}>{user}</Box>
                </Box>
              </MenuItem>
              <MenuItem
                key={"menu3"}
                onClick={() => handleSoporte()}
              >
                Soporte
              </MenuItem>
              <MenuItem
                key={"menu4"}
                onClick={() => handleTerminos()}
              >
                Términos y Políticas
              </MenuItem>
              <MenuItem
                key={"menu6"}
                onClick={() => handleLogout()}
              >
                Cerrar sesión
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
