import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSyles } from './IndividualTherapy.styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import Button from 'src/components/common/Button';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import Terms from 'src/components/Auth/PolicyTerms/terms';
import Header from 'src/components/common/Header';
import { getParameterUrl } from 'src/helpers/utils';
import { getCompanyByUserId } from 'src/redux/companies/thunks';

const IndividualTherapy = () => {
  const dispatch = useDispatch<any>();
  const classes = useSyles({ colorHeader: '#42B048' });
  const { auth, companies } = useAppSelector((state: RootState) => state);
  const [showTerms, setShowTerms] = useState(false);
  const [phoneHelp, setPhoneHelp] = useState("1234567890");
  const navigate = useNavigate();

  const call = () => {
  }

  const analiceUrl = () => {
    const viewSection = getParameterUrl("view");
    if (viewSection === 'terms') {
      setShowTerms(true);
    } else {
      setShowTerms(false);
    }
  }

  useEffect(() => {
    dispatch(getCompanyByUserId(auth.id));
  }, []);

  useEffect(() => {
    if(companies.company.attributes === undefined) return;
    setPhoneHelp(companies.company.attributes.phoneHelp)
  }, [companies]);

  useEffect(() => {
    analiceUrl();
  }, [window.location.href]);

  const cancelCall = () => {
    navigate('/home', { replace: true });
  }

  const onShowTerms = (tmpShowTerms: boolean) => {
    setShowTerms(tmpShowTerms);
  }

  if (showTerms) {
    return <Terms onShowTerms={onShowTerms} view={"terapia-invidual"} />
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.body}>
          <div className={classes.titles}>
            <p className={classes.title}>Terapia Individual</p>
            <p className={classes.subtitle}>¿Quieres trabajar de la mano con un psicólogo?</p>
          </div>
          <div className={classes.titles}>
            <p className={classes.subtitle}>Llama y agenda tu cita con un psicólogo</p>
          </div>
          <div className={classes.content}>
            <div className={classes.form}>
              <img src="./images/TuTerapia/IMG.png" alt="" />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.information}>
            <GridContainer p-lg={'0px'} p-sm={'0px'}>
              <GridItem lg={4} sm={1}>
                <div className={classes.containerEspacioSeguro} >
                  <img src="./images/TuTerapia/Privacy.png" alt="" />
                </div>
              </GridItem>
              <GridItem lg={8} sm={3}>
                <p className={classes.information1}>Orenta es tu espacio seguro</p>
                <p className={classes.information2}>Porque tu salud y privacidad son primero, proteger tu información es nuestra prioridad. </p>
                <p className={classes.information3}><a href="#" onClick={() => onShowTerms(true)}>Conoce más aquí.</a></p>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.buttons}>
            <a href={`tel:${phoneHelp}`}>
              <Button onClick={() => call()} >
                Hacer llamada
              </Button>
            </a>
            <Button
              backgroundColor='transparent'
              borderColor='#EDEDED'
              onClick={() => cancelCall()}>
              Cancelar llamada
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
export default IndividualTherapy;