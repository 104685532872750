import axios from "axios";

// const clientStrapi = axios.create({
//   baseURL: process.env.REACT_APP_STRAPI_BASE_URL,
//   headers: {
//     Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`,
//   },
// });

const clientStrapi = () =>
  axios.create({
    baseURL: process.env.REACT_APP_STRAPI_BASE_URL + "/api",
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem("jwt") ?? process.env.REACT_APP_STRAPI_KEY
      }`,
    },
  });

export default clientStrapi;
