import React,{useState} from 'react';
import { useStyles } from './intro.styles'
import Button from 'src/components/common/Button';
import Header from '../Header';
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';
import { useDispatch } from 'react-redux';
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

function Intro({handleContinue,title,subtitle}:any) {
  
    const classes = useStyles({colorHeader: '#FFFFF'});
    const { content } = useAppSelector((state: RootState) => state);
    const dispatch = useDispatch<any>();

    const handleBack = () => {
      if(content.currentOrchestratorIndex - 1 >= 0){
        dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1));
      }else{
        window.open(`/home`,'_self');
      }
    };
    return (
      <div className={classes.root}>
        <div className={classes.overlay} />
        <div className={classes.text}>
          <div className={classes.container}>
            <div className={classes.header}>
              <Header fnBack={handleBack}/>
            </div>
            <div className={classes.body}>
              <div className={classes.content}>
                <div className={classes.titles}>
                  <p className={classes.title}>{title}</p>
                  <p className={classes.subtitle}>{subtitle}</p>
                </div> 
              </div>
            </div>
            <div className={classes.footer}>
              <div className={classes.buttons}>
              <Button onClick={handleContinue} >
                  <span >Siguiente</span>
              </Button>
                   
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  
}

export default Intro;