import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import { Style } from '@material-ui/icons';
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IconButton } from '@material-ui/core';

ChartJS.register(ArcElement, Tooltip, Legend);

export const GraphicDonut = ({ listData, title }: any) => {
  const list = listData.filter((item: any) => item.status !== 'General');
  const filterLabels = list.map((item: any) => item[0]);
  const filterDataValues = list.map((item: any) => item[1]);
  const colors = ['#9634FF', '#00C29C', '#FF7A79', '#3781EF'];
  const data = {
    datasets: [
      {
        data: filterDataValues,
        backgroundColor: colors,
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF',
      },
    ],
    labels: filterLabels,
  };

  const options = {
    animation: true,
    cutoutPercentage: 100,
    layout: { padding: 0 },
    legend: {
      display: true,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: 'white',
      bodyFontColor: '#144D66',
      borderColor: '#144D66',
      borderWidth: 1,
      enabled: true,
      footerFontColor: '#144D66',
      intersect: false,
      mode: 'index',
      titleFontColor: '#144D66',
    },
  };

  return (
    <Card>
      <CardHeader
        title={title}
        color='primary'
        avatar={
          <IconButton color='primary'>
            <Style />
          </IconButton>
        }
      />

      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative',
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
          }}
        >
          {list?.map(({ status, total }: any, i: number) => (
            <Box
              key={status}
              sx={{
                p: 1,
                textAlign: 'center',
              }}
            >
              <Typography color='textPrimary' variant='body1'>
                {status}
              </Typography>
              <Typography
                style={{ color: colors[i] || '#FFBDBC' }}
                variant='h4'
              >
                <b>{total}</b>
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
