import React, { useEffect, useState } from 'react';
import Form from './Form';
import { getCompanyByNid } from 'src/redux/companies/apis';
import { updateCompany } from '../../redux/companies/thunks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../common/Loading';

const EditCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { id: nid } = useParams();

  const [propertyEdit, setCompanyEdit] = useState({
    data: { nid: null, url: '', address: '', observations: '' },
    isLoading: true,
    error: false,
  });

  const { companies } = useSelector(
    (state: any) => state.companies.companies
  );
  const submitEditCompany = (values: any) => {
    dispatch(updateCompany({ values }))
      .unwrap()
      .then((res: any) => {
        if (res) {
          navigate('/companies', { replace: true });
        }
      });
  };

  const getCompanyToEdit = async () => {
    const { data } = await getCompanyByNid({
      nid: nid as string,
    });
    const property = data.companies[0];
    setCompanyEdit({ isLoading: false, error: false, data: property });
  };

  useEffect(() => {
    if (!nid) {
      return;
    }

    if (!companies.length) {
      getCompanyToEdit();
    } else {
      setCompanyEdit({
        isLoading: false,
        error: false,
        data: companies.find((item: any) => item.nid === parseInt(nid, 10)),
      });
    }
  }, [nid]);

  if (propertyEdit.isLoading) {
    return (
      <div className='flex items-center justify-center flex-col'>
        <Loading />
      </div>
    );
  }
  return (
    <Form
      submitCompany={submitEditCompany}
      initialValues={propertyEdit.data}
      actionText='GUARDAR'
      type='edicion'
    />
  );
};

export default EditCompany;
