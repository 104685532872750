import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles({
  
  


  content: {
    //flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingTop: '10px',
    paddingLeft: '30px',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    alignItems: 'baseline',
    justifyContent: 'left',
  },

  containerList: {
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    padding: '100px 46px 0px 46px'
    //flexDirection: 'column',    
  },
  checkbox: {
    color: '#014951', // Color naranja
    marginLeft: '16px', // Espacio entre el icono y el texto
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px', // Espacio entre los renglones
  },
  label: {
    color: '#014951', 
    marginLeft: '8px', // Espacio entre el icono y el texto
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button':{
      marginTop:'10px'
    }
    
  },
  containerIcon:{
    backgroundColor:'#014951',
    width: '27.235px',
    height: '26.742px',
    borderRadius: '30px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#FFFFFF',
    '& > svg ': {
      width: '13.235px',
      height: '13.742px',
      path:{
        fill:'#FFFFFF'
      }
    }
  }

});
