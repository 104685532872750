import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader: string;
}

export const useSyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '74px',
    margin:'10px',
    borderRadius: '8px',
    background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
      title:{
        color:  '#625B71)',
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px', /* 150% */
        letterSpacing: '0.15px', 
      },
      subtitle:{
          color: '#625B71',
          /* M3/label/medium - prominent */
          fontFamily: 'Work Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px', /* 133.333% */
          letterSpacing: '0.5px',
      },

      content: {
        flex:2,
        display: 'flex',
        width: '100%',
        height: 'auto',
        paddingBottom: '0px',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        alignItems: 'center',
      },
        form: {
          background: '#ffffff',
          width: '100%',
          padding: '16px',
          borderRadius: '16px',
          display: 'grid',
          gap: '24px',
        },
  containerList: {
    display: 'flex',
    flexDirection: 'column',
      
  },
  checkbox: {
    color: '#ACE4FD !important', // Color naranja
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0px', // Espacio entre los renglones
  },
  label: {
    color: '#104B71', 
    marginLeft: '8px', // Espacio entre el icono y el texto
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px', /* 130% */
    letterSpacing: '-0.36px',
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputL: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  inputR: {
    flex: 1,
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
   
  },
  

}));
