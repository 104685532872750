import React from 'react';
import { useStyles } from './successPerception.styles'
import Button from 'src/components/common/Button';
import Header from '../Header';
import { RootState } from 'src/domain/models/RootState';
import { useAppSelector } from 'src/redux/hooks';
import { useDispatch } from 'react-redux';
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

const SuccessPerception = ({question,backMenu,handleUpdateCurrentOrchestratorIndex}:any) => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const { content } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if(content.currentOrchestratorIndex - 1 >= 0){
      dispatch(updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1));
    }else{
      window.open(`/home`,'_self');
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack}/>
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>¡Muy buen trabajo!</p>
          <p className={classes.subtitle}>De {question.question}, ya tienes una descripción realista</p>
        </div>
        <div className={classes.content}>
          <div className={classes.animationContainer}>
            <img src="/images/content/perception/success.png" />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
            
          <div className={classes.buttons}>
            <Button onClick={()=>backMenu()} >
                  <span >Regresar al menú</span>
            </Button>
              <Button
                backgroundColor='transparent'
                borderColor='#EDEDED'
                onClick={handleUpdateCurrentOrchestratorIndex}>
                Finalizar
              </Button>
          </div>
         
      </div>
    </div>
  );
}

export default SuccessPerception;
