import React, { useState, useEffect } from 'react';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { Card, SectionYourSpace, Tab, TabsContainer,Title } from './YourSpace.styles';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/common/Button';

const YourSpace = () => {
  const [activeTab, setActiveTab] = useState(1);

  const tabsTitles = ["Tu aliado en tu bienestar organizacional.", "Tu espacio de bienestar a tu manera."];
  const tabs = ["Empresas", "Colaboradores"];
  const cardsData = [
    [
      { image: "/images/home/impacto.png", title: "Trabajo en equipo de alto impacto", text: "Porque tu éxito es el nuestro, cuentas con un asesor con experiencia clínica dedicado a construir contigo un programa de bienestar a tu medida." },
      { image: "/images/home/insights.png", title: "Insights para toma de decisiones", text: "Tienes acceso a reportes de bienestar laboral que incluyen los motivos de consulta más frecuentes para que puedas apoyar a tus equipos en los temas que más lo necesitan. " },
      { image: "/images/home/preventiva.png", title: "Atención preventiva", text: "Cuentas con nuestro apoyo para identificar y prevenir casos de riesgo a través de evaluaciones y planes de acción." },
    ],
    [
      { image: "/images/home/autocuidad.png", title: "Herramientas de autocuidado", text: "Plataforma con acceso inmediato a herramientas de evidencia clínica" },
      { image: "/images/home/autocuidad.png", title: "Soporte humano 1:1", text: "<ul><li>Terapia clínica</li><li>Línea de ayuda 24/7</li><li>Coaching on-demand</li><li>Asesoría legal, médica y nutricional</li></ul>" },
      { image: "/images/home/equipo.png", title: "En equipo", text: "<ul><li>Talleres de bienestar</li><li>Lives mensuales</li></ul>" },
    ],
  ];
  const navigate = useNavigate();
  const goToRequestDemo = () => {
      navigate(`/request-demo`, { replace: true });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab === 1 ? 2 : 1));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SectionYourSpace>
      <GridContainer p-lg={'0px'}>
          <GridItem lg={6} md={4}>
             <Title>{tabsTitles[activeTab - 1]}</Title>
          </GridItem>
          <GridItem lg={6} md={4}>
            <TabsContainer>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  onClick={() => setActiveTab(index + 1)}
                  style={{ borderBottom: activeTab === index + 1 ? '4px solid #606060' : 'none'}
                        }
                >
                  {tab}
                </Tab>
              ))}
            </TabsContainer>
          </GridItem>
      </GridContainer>
      

      <GridContainer p-lg={'0px'}>
        {cardsData[activeTab - 1].map((card, index) => (
          <GridItem lg={4} >
            <Card key={index}>
              <img src={card.image} alt={card.title} />
              <h2>{card.title}</h2>
              <div
                  dangerouslySetInnerHTML={{__html: card.text}}
                />
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <GridContainer p-lg={'16px'}>
          <GridItem lg={8} md={4} className={"separator logo-lg"} >
              <hr className="horizontal-line" />
          </GridItem>
          <GridItem lg={4} md={4} sd={4}>     
              <Button onClick={goToRequestDemo} >
                  Solicita una demo
              </Button>
          </GridItem>
      </GridContainer>
    </SectionYourSpace>
  );
};

export default YourSpace;
