import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow, IconButton, Box } from '@material-ui/core';
import { Check, Close, Edit, AccessTimeRounded } from '@material-ui/icons';
import { StatusCode } from '../../domain/constants/StatusCode';
import { updateUsers } from '../../redux/users/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { useSyles } from './styles';
import { SystemActions } from '../../domain/constants/SystemActions';
import { RolePermissions } from '../../domain/constants/RolePremissions';

const User = ({ user, read }: any) => {
  const { auth } = useSelector((state: any) => state);
  const allowEdit = RolePermissions.allowed(
    Number(auth.type),
    SystemActions.edit_users
  );
  const dispatch = useDispatch<any>();
  const navigate = useNavigate(); // habilitar navigate para redirección
  const classes = useSyles();
  // función que redirige de forma programada
  const redirectEdition = (id: number | string) => {
    allowEdit && navigate(`/users/edit/${id}`, { replace: true });
  };

  const changeStatusUser = (status: StatusCode, text: string) => {
    const values = { ...user };
    values['status_users_id'] = status;
    dispatch(updateUsers({ values, status, text }))
      .unwrap()
      .then((res: any) => {
        setTimeout(function () {
          navigate(0);
        }, 1000);
      });
  };

  const srtStatus: any = {
    [StatusCode.active]: 'activo',
    [StatusCode.inactive]: 'inactivo',
  };
  const ActiveButton = () => {
    return (
      <>
        {user.status_users_id === StatusCode.active ? (
          <IconButton
            aria-label='check'
            size='medium'
            title='desactivar usuario'
            onClick={() =>
              allowEdit && changeStatusUser(StatusCode.inactive, 'Desactivado')
            }
          >
            <Check className={classes.check} />
          </IconButton>
        ) : user.status_users_id === StatusCode.pending ? (
          <IconButton
            aria-label='check'
            size='medium'
            title='validar usuario'
            onClick={() =>
              allowEdit && changeStatusUser(StatusCode.active, 'Validar')
            }
          >
            <AccessTimeRounded />
          </IconButton>
        ) : (
          <IconButton
            aria-label='check'
            size='medium'
            title='activar usuario'
            onClick={() => changeStatusUser(StatusCode.active, 'Activado')}
          >
            <Close color='error' />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <TableRow key={1}>
      <TableCell component='th' scope='row'>
        {user.id}
      </TableCell>
      <TableCell component='th' scope='row'>
        {user.roles_str ? user.roles_str : ''}
      </TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.rol}</TableCell>
      <TableCell>
        {read ? srtStatus[user.status_users_id] : <ActiveButton />}
      </TableCell>
      {!read && allowEdit && (
        <TableCell align='right'>
          <IconButton
            aria-label='editar'
            size='medium'
            onClick={() => redirectEdition(user.id)}
          >
            <Edit color='action' />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default User;
