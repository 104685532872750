import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./apis";
import Swal from "sweetalert2";

export const getContent = createAsyncThunk(
  "content/getContent",
  async (data: any) => {
    try {
      const res: any = await API.getContent(data);
      return res;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hubo un error al cargar los datos",
        text: "intenta de nuevo",
        confirmButtonColor: "#57A450",
        cancelButtonColor: "#57A450",
      });
      throw error;
    }
  }
);

export const saveAnswer = createAsyncThunk("saveAnswer", async (data: any) => {
  // try {
  //   const res = await API.saveAnswer(data);
  //   return { ...res };
  // } catch (error) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Hubo error al registrar la prueba",
  //     text: "Por favor, contacta a un administrador",
  //   });
  //   throw error;
  // }
});

export const saveTracking = createAsyncThunk(
  "saveTracking",
  async (data: any) => {
    try {
      const res = await API.saveTracking(data);
      return { ...res };
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Hubo error al registrar el tracking',
      //   text: 'Por favor, contacta a un administrador',
      // });
      throw error;
    }
  }
);

export const getLibraries = createAsyncThunk("Users/getLibraries", async () => {
  try {
    const res: any = await API.getLibraries();
    return res;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Hubo un error al cargar los datos",
      text: "intenta de nuevo",
      confirmButtonColor: "#57A450",
      cancelButtonColor: "#57A450",
    });
    throw error;
  }
});
