import React,{useEffect} from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import Question from '../question';
import ProcessResult from './processResult';
import Result from './result';
import {questions} from './dataDepression';
import { useDispatch } from 'react-redux';
import { setCtTypeTestId } from 'src/redux/content/test/slice';
import Basic from './Basic';
import Introduction from './Introduction';
import { updateStep } from '../../../../redux/content/test/slice';

const Index = () => {
  const dispatch = useDispatch<any>();
  const { contentTest } = useAppSelector((state: RootState) => state);
  
  useEffect(() => {
    dispatch(updateStep(0));
    dispatch(setCtTypeTestId(3));
  }, []);

  switch(contentTest.step){
    case 0: return (<Introduction />);
    case 1: return (<Basic />);
    case 2: return (<Question questions={questions}/>);
    case 3: return (<ProcessResult />);
    case 4: return (<Result />);
    
  }
  return (
    <Introduction />
  );
};
export default Index;
