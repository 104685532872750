
export const screenSizes = {
    mobileM: 566,
    mobile: 320,
    tablet: 768,
    tabletL: 1024,
    laptopS: 1199,
    laptop: 1280,
    laptopL: 1444,
};

export const sizes = {
    mobileM: '566px',
    mobile: '320px',
    tablet: '768px',
    tabletL: '1024px',
    laptopS: '1199px',
    laptop: '1280px',
    laptopL: '1444px',
};

const theme = {
    color: {
        backgroundLigth: '#FEFEFE',
        white: '#FFFFFF',
        light: '#F9F9FB',
        dark: '#1B1C1D',
        gray: '#3b4144',
        grayInput: '#757A78',
        lightGray: '#8D9290',
        lightGray2: '#ecedec',
        livianGray: '#433E47',
        grayMedium: '#78747b',
        grayMediumlabel: '#7E7E7E',
        grayIcon: '#2F3130',
        graySecundaryIcon: '#78747B',
        grayTrack: '#bbbbbb',
        greenPrimary: '#008059',
        greenPrimary50: ' #147458',
        greenDark: '#005855',
        greenGray2: '#E1E1E1',
        greenGray: '#F3F4F4',
        greenLight: '#F1F8F5',
        greenLemon: '#4ad200',
        greenHover: '#007350',
        greenActions: '#00E486',
        green3: '#014951',
        greenActive: '#005e46',
        greenFocus: '#006647',
        yellowHighlighted: '#E9B147',
        skyBlue200: '#ADE6FF',
        yellowHover: '#DBA743',
        yellowFocus: '#D19F40',
        error: '#FF5041',
        favoriteOn: '#FF5041',
        favoriteOff: '#B7B7B7',
        headerHover: '#F1F8F5',
        red: '#c1413a',
        redLight: '#fbfbfb',
        infonavit: '#e51717',
        fovissste: '#155925',
        bancario: '#0365c0',
        nologgedBG: '#F3F4F6',
        nologged: '#D1D5DB',
        users: ['#F27A79', '#005334', '#5900A9', '#7C01FF', '#0662EC', '#0662EC', '#FF5041', '#147458', '#198038', '#A67314', '#E2AD49', '#48A6B0'],
        borderButton: '#CDCFCE',
        disabledButton: '#D2D2D2',
        disabledText: '#c0bfc2',
        banks: '#0558D4',
        borderTopContac: '#D7D7D7',
        /** HABI KEY-CHAIN COLORS */
        /** SCARPA GRAY */
        maximunYellow500: '#E9B147',
        scarpaGray25: '#FAFAFA',
        scarpaGray50: '#F3F3F3',
        scarpaGray200: '#D3D2D4',
        scarpaGray400: '#979499',
        scarpaGray500: '#6D6970',
        scarpaGray600: '#433E47',
        scarpaGray800: '#252129',
        spanishViridian500: '#008059',
        spanishViridian25: '#F1F8F5',
        tomatoCoral600: '#CC4746',
        warning: '#F09700',
        cobalBlue600: '#0444A5',
        questions:'#FFB9C6',
        titleSection:'#104B71',
        tableBenefis:'#606060',
        testimonials:'#ACE4FD',
    },
    // gradients: {
    //     habi_primary: `linear-gradient(90deg, ${electricPurple700} 0%, ${electricPurple400} 100%)`,
    //     pçom_primary: `linear-gradient(90deg, ${spanishViridian700} 0%, ${spanishViridian400} 100%)`
    // },
    fonts: 'Work Sans',
    fontSizes: {
        small: '1em',
        medium: '2em',
        large: '3em',
    },
    screenSizes: {
        mobileM: `(min-width: ${sizes.mobileM})`,
        mobile: `(min-width: ${sizes.mobile})`,
        tablet: `(min-width: ${sizes.tablet})`,
        laptopS: `(min-width: ${sizes.laptopS})`,
        laptop: `(min-width: ${sizes.laptop})`,
        laptopL: `(min-width: ${sizes.laptopL})`,
    },
    zindex: {
        header: 100,
        modal: 1000,
        stiky: 101,
    },
    
};

export default theme;
