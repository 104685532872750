import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles({
  container: {
    backgroundColor: '#FFFFFF',
    backgroundImage: 'url(/images/background.png)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginBottom: '8px',
    '& > img ': {
      objectFit: 'cover',
      width: '100%',
    },
  },
  box: {
    background: '#ffffff',
    width: '320px',
    padding: '24px',
    borderRadius: '16px',
    display: 'grid',
    gap: '24px',
    textAlign: 'center',
  },
  container_form: {
    backgroundSize: 'contain',
    maxWidth: '400px',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  button: {
    width: '150px',
  },
});
