import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import Swal from 'sweetalert2';

export const getAllTypekeys = createAsyncThunk(
  'Typekeys/getAllTypekeys',
  async (filter: any) => {
    try {
      const res: any = await API.getAllTypekeys({ filter });
      return res.data.type_keys;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const updateTypekey = createAsyncThunk(
  'Typekeys/updateTypekey',
  async ({ values, text }: any) => {
    try {
      const res = await API.updateTypekey(values);
      Swal.fire({
        icon: 'success',
        title: `${text || 'Editado'} con exito`,
      });
      return res;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Hubo un error al editar los datos`,
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const createTypekey = createAsyncThunk(
  'Typekeys/createTypekey',
  async (typekey: any) => {
    try {
      const res = await API.createTypekey(typekey);
      if (res.status === 200) {
        Swal.fire('Correcto', 'El almacen se agregó correctamente', 'success');
        return true;
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al crear el almacen',
        text: 'Por favor, intenta de nuevo',
      });
      throw error;
    }
  }
);
