export enum AreaIds {
  CanalDirecto = 1,
  CanalBrokers = 2,
  Remo = 3,
  TramitesBuyers = 4,
  Propertymanagement = 5,
  Proveedores = 6,
}

export const AreaOptions = [
  { id: AreaIds.CanalDirecto, name: 'Empresa 1' },
  { id: AreaIds.CanalBrokers, name: 'Empresa 2' },
  { id: AreaIds.Remo, name: 'Empresa 3' },
  { id: AreaIds.TramitesBuyers, name: 'Empresa 4' },
  { id: AreaIds.Propertymanagement, name: 'Empresa 5' },
  { id: AreaIds.Proveedores, name: 'Empresa 6' },
];
