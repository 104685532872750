import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from './apis';
import Swal from 'sweetalert2';

export const getAllProspects = createAsyncThunk(
  'Prospects/getAllProspects',
  async ({ filter, pagination = null }: any) => {
    try {
      const res: any = await API.getAllProspects({ filter, pagination });
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al cargar los datos',
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const updateProspect = createAsyncThunk(
  'Prospects/updateProspect',
  async ({ values, text }: any) => {
    try {
      const res = await API.updateProspect(values);
      Swal.fire({
        icon: 'success',
        title: `${text || 'Editado'} con exito`,
      });
      return res;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Hubo un error al editar los datos`,
        text: 'intenta de nuevo',
      });
      throw error;
    }
  }
);

export const createProspect = createAsyncThunk(
  'Prospects/createProspect',
  async (data: any) => {
    try {
      const res = await API.createProspect(data);
      if (res.success) {
        Swal.fire(
          'Correcto',
          'La solicitud se agregó correctamente',
          'success'
        );
        return true;
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al crear la solicitud',
        text: 'Por favor, intenta de nuevo',
      });
      throw error;
    }
  }
);

export const createProspectFull = createAsyncThunk(
  'Prospects/createProspectFull',
  async (data: any) => {
    try {
      const res = await API.newCreateProspectFull(data);
      if (res.success) {
        Swal.fire(
          'Correcto',
          'La solicitud se agregó correctamente',
          'success'
        );
        return true;
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error al crear la solicitud',
        text: 'Por favor, intenta de nuevo',
      });
      throw error;
    }
  }
);
