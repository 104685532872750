import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

interface StylesProps {
  colorHeader: string;
}

export const Card = styled.div`
  background: transparent;
  height: 170px;
  img {
    width: -webkit-fill-available;
    height: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  h2{
    padding: 14px;
    /* SubTitle */
    color: #104B71;
    text-align: center;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 140% */
    letter-spacing: -0.42px;

  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  
`;

export const ContentCategories = styled.div`
    padding: 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 60px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        padding: 60px;
    }
    .seleccionado {
      background-color:  #15e2c0;
    }
    .noSeleccionado {
      background-color:  #b5dcd6;
    }
`;
export const Aclaration = styled.span`
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  text-align: left;
`;
export const Category = styled.div`
      // Color de fondo
     border-radius: 22px; // Borde redondeado
     height: 120px;
     margin:16px 8px 16px 8px;
     span {
      color: var(--colors-navy, #104B71);
      text-align: center;
      /* H5 Bold */
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.42px;
     }
`;

export const CategoryText = styled.p`
      color: var(--colors-navy, #104B71);
      text-align: center;
      /* H5 Bold */
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.42px;
      text-align: center;
      margin: 14px 14px 14px 14px;
`;

export const useStyles = makeStyles((theme) => ({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: '74px',
    margin:'10px',
    borderRadius: '8px',
    background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
  contentPreloader:{
    position:"relative",
    width: `calc(100% - 32px)`,
    '& > img ': {
      position:"absolute",
      width: '15px',
      height: 'auto',
      top:'10px'
    },
    '& > div ': {
      position:"relative",
    },

  },
  bar : {
    width: `100%`,
    height: '25px',
    margin: '16px',
    //backgroundColor: barColor,
    backgroundImage: ' linear-gradient(90deg, #8DD6CA 0%, #B6D4FA 48.96%, #FFB0BF 100%)',
    borderRadius: '32.928px',
    //transition: 'width 0.5s ease', // Controla la animación de la barra de progreso
  
  },

    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign:'left'
    },
    titlePrincipal:{
      color:  '#149D90',
      /* M3/title/medium */
      fontFamily: 'Antonia H3',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal', /* 150% */
      letterSpacing: '-0.72px',
      padding:'16px 16px 16px 16px',
      textAlign:"center"
    },
      title:{
        color:  '#149D90',
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal', /* 150% */
        letterSpacing: '-0.72px',
        padding:'16px 16px 16px 16px',
        textAlign:"left"
      },
      bloqueText:{
        paddingBottom:'20px', 
        paddingTop:'20px', 
      },
      subtitle:{
        color: '#104B71',
        /* M3/label/medium - prominent */
        fontFamily: 'Work Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        padding: '16px 16px 0px 16px',
        '& > span ': {
          lineHeight: '24px',
          fontSize: '16px',
          fontWeight: 700,
        },
    },

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  contentCircles:{
    width: 'calc(100% - 72px)',
  },
  titleCircle:{
    color:"#272727",
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0.64px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontSize: '15px',
    lineHeight: '21px', /* 175% */
  },
  containerWelfare: {
    padding: '20px',
  },
  circleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: 'blue',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#272727',
    //marginBottom: theme.spacing(4),
    textAlign: 'center',
    /* SubTitle */
    '& > H5 ': {
      fontFamily: 'Antonia H3',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.72px',
    }
  },
  circle2: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: 'gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
  },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& > button ': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      marginTop:'10px',
      textTransform:'initial',
      height:'48px'
    },
  },
  information:{
    display: 'flex',
    padding: '30px 10px 0px 10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'10px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    marginBottom:'0px'
  },
  information1:{
    color: '#000000',
    textAlign: 'center',
    fontFeatureSettings: 'liga off',
    /* Heading/Heading 05/Bold */
    fontFamily: 'Work Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-0.42px',
  },
  linksResults:{
    color:  '#014951',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight:600,
    lineHeight: '24px', /* 171.429% */
    letterSpacing: '-0.42px',
  }

}));
