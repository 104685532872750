import { makeStyles } from '@material-ui/core';

interface StylesProps {
  colorHeader?: string;
  heightHeader?: string;
}

export const useStylesG = makeStyles({
  
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#FFF',
  },

  transparentImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    //opacity:"0.7", /* Cambia la opacidad según tus preferencias */
    position: "absolute",
    bottom:'0px',
    right:'0px'
  },
  starts: {
    maxWidth: "100%",
    maxHeight: "100%",
    //opacity:"0.7", /* Cambia la opacidad según tus preferencias */
    position: "absolute",
    top:'30px',
    right:'40px'
  },
  logo:{
    position: "absolute",
    top:'30px',
    left:'20px'
  },

  header:(props: StylesProps) => ({
    position:'relative',
    width: '100%',
    height: props.heightHeader,
    flexShrink: 0,
    backgroundColor: props.colorHeader,
    
  }),
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    backgroundColor: '#fff',
  },
    titles:{
      position:'absolute',
      bottom:'0px',
      alignSelf: 'stretch',
      display: 'flex',
      width: '50%',
      padding: '0 0 29px 17px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
      title:{
        
        color: '#FFF',
        /* Title Mobile */
        fontFamily:' Antonia H3',
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight: '30px', /* 100% */
        letterSpacing: '-0.9px',
      },
      subtitle:{
          color: '#FFF',
          fontFamily: 'Work Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '-0.42px',
      },
  instructions:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    '& > p':{
      color: '#104B71',
      textAlign: 'center',
      /* H5 Bold */
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px', /* 171.429% */
      letterSpacing: '-0.42px',
      padding:'20px'
    }
  },
  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
    form: {
      background: '#ffffff',
      width: '100%',
      padding: '16px',
      borderRadius: '16px',
      display: 'grid',
      gap: '24px',
    },

  footer: {
    backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%'
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    '& button':{
      marginTop:'10px'
    }
  },
  forgot:{
    color: "#014951",
    textAlign: 'center',
    /* H5 Bold */
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px', /* 171.429% */
    letterSpacing: '-0.42px',
    marginTop: "30px",
    padding: "14px 24px"
  }

});
