import React from 'react';
import FooterOrenta from './FooterHome/FooterOrenta';
import OrentaMenu from '../common/Menu/OrentaMenu';
import Main from './Main/Main';

const Index = ({ children }:any) => {
    return (
        <>
            <head>
                <title>Orenta</title>
                <meta name="description" content="description" />
                <link rel="canonical" href="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:url" content="" />
                <meta
                    property="og:image"
                    content=""
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Orenta.com" />
                
            </head>
            <OrentaMenu />
            <Main>{ children }</Main>
            <FooterOrenta />
        </>
    );
};

export default Index;
