import clientAxios from './../../config/axios';
import { TimeZone } from './state';

export const getTimeZones = async (token: string): Promise<TimeZone[]> => {
  const url = `/time_zones?country=${process.env.REACT_APP_ENV_COUNTRY}`;
  return (
    await clientAxios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data.list;
};
