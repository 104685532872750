import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/domain/models/RootState";
import { useStyles } from "./index.styles";
import Header from "../Header";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { useNavigate } from "react-router-dom";
import Button from "src/components/common/Button";
import { TextField } from "@material-ui/core";
import { useState } from "react";

function Feedback() {
  const { content, auth } = useSelector((state: RootState) => state);
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [items, setItems] = useState<String[]>([""]);

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
    }
  };

  const handleUpdateCurrentOrchestratorIndex = () => {
    dispatch(
      updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
    );
  };

  const updateItem = (item: string, ix: number) => {
    items[ix] = item;
    setItems([...items]);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div
        // className={classes.body}
        style={{
          marginTop: "4rem",
          display: "flex",
          flexDirection: "column",
          gap: "12 rem",
          flexGrow: "1",
          width: "95%",
          justifyContent: "space-around",
        }}
      >
        <div className={classes.content}>
          <p className={classes.title}>
            {content.templates[content.currentOrchestratorIndex].question}
          </p>
        </div>
        <div className={classes.content}>
          {items.map((item, ix) => (
            <TextField
              key={`item-${ix}`}
              placeholder={`Ejemplo: ${
                content.templates[content.currentOrchestratorIndex].example
              }`}
              value={item}
              variant="outlined"
              onChange={(e) => updateItem(e.target.value, ix)}
              style={{ margin: "0.5rem auto" }}
              fullWidth
            />
          ))}

          <button
            onClick={() => setItems([...items, ""])}
            style={{ textAlign: "start", padding: "1rem 0.5rem" }}
          >
            + Agregar Elemento
          </button>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={handleUpdateCurrentOrchestratorIndex}>
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
