import React, { useState, useEffect } from 'react';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { Card, ContentFacilSeguro, SectionFacilSeguro,Title,Card2,Card3,PhoneMockup} from './FacilSeguro.styles';
import { schemaFacilPage } from './FacilSeguro.constants';
import Accordion from './Accordion';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/common/Button';

const FacilSeguro = () => {
    const [activeState, setActiveState] = useState([true]);
    const navigate = useNavigate();
    const goToRequestDemo = () => {
        navigate(`/request-demo`, { replace: true });
    } 
    const toggleAccordion = (index: number) => {
      let newActiveState = activeState.slice();
      if (activeState[index] === true) {
          newActiveState[index] = !newActiveState[index];
      } else {
          newActiveState = activeState.map(() => false);
          newActiveState[index] = true;
      }
      setActiveState(newActiveState);
  };
  return (
    <SectionFacilSeguro>
      <GridContainer p-lg={'0px'}>
          <GridItem lg={6} md={4}>
             <Title><p>Fácil y Seguro</p>Salud Mental de Calidad Para Todos</Title>
             <Accordion schema={schemaFacilPage} titleAs='h3'/>
          </GridItem>
          <GridItem lg={6} md={4} customOrder={{ order: -1,  mobileOrder: 1 }}>
            <ContentFacilSeguro>
                <Card key={1}>
                    <img src="/images/home/facilSeguro/card_image.jpg" alt={""} />
                    <h2>¿Cómo comunicarse asertivamente?</h2>
                    <p>
                        ¿A veces te ha pasado que no sabes como comunicarte en tu ambiente laboral? en este podcast te enseñamos.
                    </p>
                    <div>24 personas vieron esto</div>
                </Card>
                <Card2 key={1}>
                    <GridContainer p-lg={'0px'}>
                        <GridItem lg={4} md={4}>
                            <img src="/images/home/facilSeguro/user_avatar.png" alt={""} />
                        </GridItem>
                        <GridItem lg={8} md={4}>
                            <p>
                                En proceso
                            </p>
                            <h2>Comunicación asertiva</h2>
                            <h2>40% completado</h2>
                            
                        </GridItem>
                        
                    </GridContainer>
                    <GridContainer p-lg={'0px'}>
                        
                        <GridItem >
                            <div className='preload'>
                                {/* <Preloader
                                progress={40}
                                title=""
                                barColor="#6674B2"
                                titleAlignment="left"
                                leftText=""
                                centerText=""
                                rightText=""
                                heightBar="7px"
                                /> */}
                            </div>
                            
                        </GridItem>
                        
                    </GridContainer>
                </Card2>
                <Card3 key={1}>
                    <p>
                        Una experiencia tecnológica creada por psicólogos expertos a las manos de tus colaboradores.
                    </p>  
                </Card3>
                <PhoneMockup src="/images/home/facilSeguro/phone_mockup.png" />
            </ContentFacilSeguro>
          </GridItem>
      </GridContainer>
      <GridContainer p-lg={'16px'}>
          <GridItem lg={8} md={4} className={"separator logo-lg"} >
              <hr className="horizontal-line" />
          </GridItem>
          <GridItem lg={4} md={4} sd={4}>     
                <Button onClick={goToRequestDemo}>
                    Solicita una demo
                </Button>
          </GridItem>
      </GridContainer>
     
    </SectionFacilSeguro>
  );
};

export default FacilSeguro;
