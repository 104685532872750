export enum SystemActions {
  
  read_users = 'read.users',
  create_users = 'create.users',
  edit_users = 'edit.users',

  read_companies = 'read.companies',
  create_companies = 'create.companies',
  edit_companies = 'edit.companies',
  read_problems = 'read.problems',
  carga_masiva = 'carga_masiva',
  read_dashboard = 'read.dashboard',
  login = 'login',
  register = 'register',
}
