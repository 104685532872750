import { useSelector } from 'react-redux';
import { RootState } from 'src/domain/models/RootState';
import Introduction from '../Introduction';
import Template from './template';

 

function Index() {
  const { content } = useSelector((state: RootState) => state);
  

  if(content.templates?.length){
    return (
      <Template />
    );
  }else{
    return(<Introduction />)
  }
}

export default Index;