import React, { useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useSyles } from "./introduction.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import Button from "src/components/common/Button";
import { setSelfKnowledge } from "../../../../redux/auth/slice";
import Header from "../../Header";
import { setTestResults } from "src/redux/auth/apis";

const Introduction = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const name = auth.name;
  const [success, setSuccess] = useState(false);
  const [nextLoading, setLoading] = useState(false);
  const initialValues = {
    name_company: "",
    name: "",
    lastname: "",
    human_resources_manager: "",
    email_manager: "",
  };

  const handleUpdateStep = (newStep: number) => {
    dispatch(updateStep(newStep));
  };

  const goToHome = async () => {
    setLoading(true);
    dispatch(setSelfKnowledge(false));
    await setTestResults({ user_id: auth.id });
    navigate(`/home`, { replace: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header
          fnBack={goToHome}
          fnClose={goToHome}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>
            {name} <br /> Queremos conocerte mejor
          </p>
          <p className={classes.subtitle}>
            Contesta algunas preguntas para ayudarnos a diseñar tu experiencia
            de bienestar.
          </p>
        </div>
        <div className={classes.content}>
          <img src="/images/content/TestResults/inicial.png" />
          <p>Tiempo de duración: 30 Segundos</p>
          <a href="/">¿Necesitas hablar con alguien ahora?</a>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button
            onClick={() => handleUpdateStep(2)}
            disabled={nextLoading}
          >
            <span>Iniciar Test</span>
          </Button>
          <Button
            backgroundColor="transparent"
            borderColor="#EDEDED"
            disabled={nextLoading}
            onClick={goToHome}
          >
            Omitir
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Introduction;
