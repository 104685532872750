import styled from 'styled-components';



export const SectionTestimonials = styled.section`
   background-color: ${({ theme }) => theme.color.white};
   padding: 32px 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 32px 28px 32px 28px;
      height: 536px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 32px 28px 32px 28px;
      height: 536px;
    }
    
`;

export const ContentTestimonials = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.testimonials};
  height: 600px;
  position: relative;
  @media ${({ theme }) => theme.screenSizes.tablet} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

`;
// Estilos para el contenedor de las pestañas
export const TabsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.testimonials};
  display: flex;
  justify-content: center;
  position: absolute;
  bottom:10px;
  left: 30%;
  ul {
      list-style-type: disc;
      margin-left: 20px;
      font-size: 40px;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
      position: initial;
      justify-content: flex-start;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      position: initial;
      justify-content: flex-start;
    }
`;

export const Title = styled.div`
    text-align: center;
      /* Title */
    font-family: "Antonia H3";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
    padding-top: 30px;
    p {
        color: ${({ theme }) => theme.color.titleSection};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 2.8px;
        text-transform: uppercase;

    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
      text-align: left;
      padding-bottom: 30px;
      padding-right: 100px;
      font-size: 40px;
      letter-spacing: -1.2px;
      line-height: 52px; /* 130% */
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      text-align: left;
      padding-bottom: 30px;
      padding-right: 150px;
      font-size: 40px;
      letter-spacing: -1.2px;
      line-height: 52px; /* 130% */
    }
`;

// Estilos para una pestaña individual
export const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
`;

// Estilos para una pestaña individual
export const ContentGrids = styled.div`
  display: grid;
  width: 95%;
  @media ${({ theme }) => theme.screenSizes.tablet} {
      width: 90%;
  }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      width: 90%;
  }
`;


// Estilos para una tarjeta individual
export const Card = styled.div`
  background: white;
  box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.00), 0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.01), 0px 64.81481px 46.85185px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);  margin: 10px;
  width: 100%;
  height: 400px;
  z-index: 2;

  h2{
    position: absolute;
    bottom: 20px;
    left: 35px;
    /* SubTitle */
    color: var(--colors-blu, #6674B2);
    /* H3 Bold */
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
  div {
    color: var(--colors-blu, #6674B2);
    /* H4 Regular */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;
    padding: 40px 67px 7px 35px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 20px 67px 7px 35px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 20px 67px 7px 35px;
    }
  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media ${({ theme }) => theme.screenSizes.tablet} {
    position: relative;
    width: 504px;
    height: 245px;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    position: relative;
    width: 504px;
    height: 245px; 
  }

`;

export const Card2 = styled.div`
  display: none;
 
  h2{
    position: absolute;
    bottom: 20px;
    left: 35px;
    /* SubTitle */
    font-family: "Work Sans";
    color: var(--colors-blu, #6674B2);
    /* H3 Bold */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
  
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media ${({ theme }) => theme.screenSizes.tablet} {
    display:block;
    background: ${({ theme }) => theme.color.testimonials};
    border: 3px solid ${({ theme }) => theme.color.white};
    box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.00), 0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.01), 0px 64.81481px 46.85185px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);  margin: 10px;
    position: absolute;
    top: 60px;
    left: 60px;
    z-index: 1;
    width: 504px;
    height: 245px;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    display:block;
    background: ${({ theme }) => theme.color.testimonials};
    border: 3px solid ${({ theme }) => theme.color.white};
    box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.00), 0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.01), 0px 64.81481px 46.85185px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);  margin: 10px;
    position: absolute;
    top: 60px;
    left: 60px;
    z-index: 1;
    width: 504px;
    height: 245px; 
  }

  
`;

export const Cards = styled.div`
  position: relative;
  width: 100%; /* Ajusta el ancho de la línea horizontal según tus necesidades */
  border: 0; 
`;

export const AvatarImage = styled.img`
  position: absolute;
  
  z-index: 3;
  top: -35px;
  left: 25px;
  border-radius: 55px;
  width: 62px;
  height: 68px;

  @media ${({ theme }) => theme.screenSizes.tablet} {
    top: -35px;
    left: -35px;
    border-radius: 40px;
    width: 68px;
    height: 70px;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    top: -35px;
    left: -35px;
    border-radius: 40px;
    width: 68px;
    height: 70px;
  }

`;

