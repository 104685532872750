import styled from 'styled-components';

export interface ButtonProps {
  backgroundColor?: string;
  borderRadius?: string;
  textColor?: string;
  borderColor?: string;
  className?:string;
  onClick?: () => void;
  disabled?:boolean;
  width?:string;
}

export const ContentButtonOrenta = styled.div`
    display: "flex";
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    z-index: 99;
`;

export const ButtonOrenta = styled.button<ButtonProps>`
  background-color: ${(props) => props.backgroundColor || props.theme.color.greenActions};
  border-radius: ${(props) => props.borderRadius || '30px'};
  color: ${(props) => props.textColor || '#104B71'};
  border: 2px solid ${(props) => props.borderColor || 'transparent'};
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
  //margin-top:10px;
  height: auto;
  min-height: 48px;
  z-index: 99;
  width: ${(props) => props.width || '100%'};
  &:hover,&:active ,&:focus,&:visited   {
    background-color: ${(props) => props.backgroundColor || 'lightblue'};
    border-color: ${(props) => props.borderColor || 'transparent'};
    color: ${(props) => props.textColor || '#104B71'};
  }
    
    &.span{
      color:${({ theme }) => theme.color.dark};
      text-align: center;
      /* H5 Bold */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.42px;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
    }
    
`;