import React, {  useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { useStyles } from './result.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Paper, Typography, Grid } from '@material-ui/core';
import { updateStep } from '../../../../redux/content/test/slice';
import Button from 'src/components/common/Button';
import Header from '../../Header';

const CircleWithPercentage = ({ percentage, description,classes,color }:any) => {

  return (
    <Grid item xs={4} className={classes.containerWelfare}>
      <div className={classes.circleContainer}>
        <Paper className={classes.circle} style={{backgroundColor:color}}>
          <Typography variant="h5">{percentage.toFixed(0)}%</Typography>
        </Paper>
      </div>
      <Typography variant="body1" align="center" className={classes.titleCircle}>
        {description}
      </Typography>
    </Grid>
  );
};

const Result = () => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { contentTest } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  const goToLineaContigo = () => {
    navigate(`/linea-contigo`, { replace: true });
  };

  const goToWhatsapp = () => {
    navigate(`/whatsapp`, { replace: true });
  };

  const goToDigitalTools = () => {
    navigate(`/home?view=explora`, { replace: true });
  };

  const category = contentTest.welfare.category;
  let text1 = "";
  let text2 = "";
  let text3 = "";
  let img = "";
  
  switch(category){
    case "Estancado":
      img = "/images/content/TestResults/Estancado.png";
      text1 = "En este momento es posible que te sientas en un limbo. No estas 100% satisfecho con tu vida, pero tampoco te quejas.";
      text2 = "Quizás hay algunos aspectos que te gustaría trabajar para lograr disfrutar tu vida al máximo.";
      text3 = "¡No has momento como el presente para construir tu bienestar y nosotros estamos aquí para acompañar en este camino!.";
    break;
    case "Desconectado":
      img = "/images/content/TestResults/desconectado.png";
      text1 = "En este momento es posible que te sientas un poco desconectado e inclusive desilucionado con algunos aspectos de tu vida.";
      text2 = "No te preocupes, a todos nos pasa en algún punto.";
      text3 = "Lo importante es que no te quedes alli ¡No dejes pasar más tiempo, hazte una prioridad e inicia tu camino hacia el bienestar!.";
    break;
    case "Fluyendo":
      img = "/images/content/TestResults/Fluyendo.png";
      text1 = "En este momento es posible que te sientas conectado con tu vida, amigos, familia y trabajo.";
      text2 = "Básicamente estás disfrutando tu vida y probablemente confias en tus habilidades para modificar las cosas que no te gustan.";
      text3 = "¡Eso sí, el bienestar se trabaja todos los días!, Nosotros estamos aqui para acompañarte en este camino!.";
    break;
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.body}>
      <div className={classes.circleContainer}>
        {img === "" ?
                <Paper className={classes.circle2}>
                </Paper>
        :
        <img src={img} />
        }
      </div>
        <div className={classes.content}>
          <div className={classes.titles}>
            <p className={classes.title}>{category}</p>
            <p className={classes.subtitle}>{text1}</p>
            <p className={classes.subtitle}>{text2}</p>
            <p className={classes.subtitle}>{text3}</p>
          </div>
          <div>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <CircleWithPercentage percentage={contentTest.welfare.emotional_percentage} description="Bienestar emocional" classes={classes} color={"#ACE4FD"}/>
              <CircleWithPercentage percentage={contentTest.welfare.social_percentage} description="Bienestar social" classes={classes} color={"#FFB9C6"}/>
              <CircleWithPercentage percentage={contentTest.welfare.psychological_percentage} description="Bienestar psicológico" classes={classes} color={"#FCE497"}/>
            </Grid>
            {/* <p>Bienestar emocional</p>
            <p>Bienestar social</p>
            <p>Bienestar psicológico</p> */}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.information}>
              <p className={classes.information1}>¿Por dónde te gustaría iniciar?</p>
        </div>
        <div className={classes.buttons}>
        <Button
          backgroundColor='transparent'
          borderColor='#EDEDED'
          onClick={goToLineaContigo}>
            <span className={classes.linksResults}>Línea de ayuda </span>      
        </Button>
        <Button
          backgroundColor='transparent'
          borderColor='#EDEDED'
          onClick={goToWhatsapp}>
            <span className={classes.linksResults}>Whatsapp</span>      
        </Button>
        <Button
          backgroundColor='transparent'
          borderColor='#EDEDED'
          onClick={goToDigitalTools}>
            <span className={classes.linksResults}>Herramientas digitales</span>    
        </Button>
          
        </div>
      </div>
    </div>
  );
};
export default Result;
