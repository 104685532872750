// Libraries.
import styled from 'styled-components';
import { SchrollCSS } from './SchrollBar/styles';
export const DesktopNav = styled.ul`
    display: none;
    width: 100%;
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        //width: 100%;
        align-items: center;
        justify-content: normal;
        position: relative;
        z-index: 1;
        li {
            min-width: fit-content;
        }
        background-color: ${({ theme }) => theme.color.white};
        a {
            color: ${({ theme }) => theme.color.dark};
            margin: 0px 16px;
            height: auto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-decoration: none;
        }
        
    }
`;

export const HeaderLogo = styled.div`
    width: 32px;
    height: 56px;
    align-items: center;
    display: flex;
    a {
        //width: 32px;
        //height: 32px;
        img {
            width: auto;
            height: 32px;
            padding: 0px;
            border: 0px;
            margin-bottom: 0px;
        }
    }
    .logo-sm span img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    .logo-lg {
        display: none;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
        width: 206px;
        display: flex;
        a {
            width: 167px;
            height: 20px;
            img {
                padding: 0px;
                border: 0px;
                margin-bottom: 0px;
            }
        }
        .logo-sm {
            display: none;
            img {
                width: aut0;
                height: 42px;
            }
        }
        .logo-lg {
            display: block;
            img {
                width: auto;
                height: 37px;
            }
        }
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        width: 206px;
        height: 80px;
        a {
            width: 172px;
            height: 21.5px;
        }
    }
`;
export const ContainerMenu = styled.div`
    position: fixed;
    z-index: ${({ theme }) => theme.zindex.header};
    width: 100%;
    height: 56px;
    background-color: ${({ theme }) => theme.color.white};
    top: 0;
    left: 0;
    padding: 0 16px;
    .browser-container {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .orenta-main-menu {
        height: calc(100vh - 50px);
        z-index: 99;
        overflow-y: auto;
        position: fixed;
        overflow-x: hidden;
        ${()=>SchrollCSS}
        width: 100%;
        top: 50px;
        left: 0;
        padding-bottom: 50px;
        background: ${({ theme }) => theme.color.white};
        transition: transform 300ms ease;
        transform: translate3d(100%, 0, 0);
    }
    .orenta-menu {
        width: 100%;
        .active {
            transition: transform 300ms ease;
            transform: translate3d(0%, 0, 0);
        }
        .orenta-menu-nav {
            width: 100%;
            margin: 0 auto;
        }
    }
    /* contenedor principal de grid */
    .orenta-header {
        display: grid;
        grid-template-columns: repeat(12, [col] 1fr);
        height: auto;
        justify-content: center;
        align-content: center;
        gap: 8px;
    }
    /* grid-column-start - posicion en el header */
    .orenta-logo {
        grid-column: col / span 2;
    }
    .orenta-search-input {
        background: ${({ theme }) => theme.color.white};
        grid-column: col / span 8;
        grid-column-start: 3;
        display: flex;
        justify-items: center;
        align-items: center;
        &.display-none {
            display: none;
        }
    }
    .orenta-menu-hamburger {
        grid-column: col / span 2;
        grid-column-start: 11;
        display: flex;
        justify-content: flex-end;
    }
    /*fin grids - posicion en el hepader */

    @media ${({ theme }) => theme.screenSizes.tablet} {
        padding: 0;
        height: 64px;
        /* grid-column-start - posicion en el header */
        .orenta-logo {
            grid-column: col / span 3;
        }
        .orenta-search-input {
            grid-column: col / span 6;
            grid-column-start: 6;
        }
        .orenta-menu-hamburger {
            grid-column: col / span 1;
            grid-column-start: 12;
        }
        .orenta-header {
            height: 64px;
        }
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        .orenta-submenu-container {
            max-height: calc(100vh - 100px);
            overflow-y: auto;
            ${()=>SchrollCSS}
        }
        .orenta-header {
            height: 80px;
        }
        .classIngresar{
            z-index: 1;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 80px;
            background: ${({ theme }) => theme.color.green};
            right: -135px;
        }
        .classSolicitar{
            
            z-index: 1;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({ theme }) => theme.color.green};
            right: -335px;
            border-radius: 30px;
            background: #00E486;
            color: #1B1C1D;
            font-family: "Work Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.42px;
            height: 52px;
            width: 166px; 
        }
        height: 80px;
        padding: 0;
        .orenta-main-menu {
            display: none;
            transition: transform 300ms ease;
            transform: none;
            height: 80px;
            width: auto;
            right: 0;
            top: 0;
            width: 200px;
            background-color: ${({ theme }) => theme.color.white};
            left: auto;
            position: absolute;
            overflow-y: hidden;
            padding-bottom: 0;
            border-radius: 0 0 8px 8px;
            z-index: 0;
            grid-column: col / span 2;
            grid-column-start: 10;
            &:hover {
                box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
                height: auto;
                width: 402px;
            }
            .orenta-menu-nav {
                width: 100%;
                padding: 0px;
            }
        }
        .orenta-menu {
            display: grid;
            margin: 0 auto;
            height: 79px;
            position: relative;
        }
        /* grid-column-start - posicion en el header */
        .orenta-logo {
            grid-column: col / span 2;
        }

        .orenta-search-input {
            /* grid-column: col / span 3;
            grid-column-start: 8;
            z-index: 1;
            /* solo se oculta en diapositivos mobile con menu abierto */
            /*&.display-none {
                display: flex;
            } */
        }
        .orenta-desktop-nav {
            grid-column: col / span 5;
            grid-column-start: 4;
            svg {
            height: 20px;
            width: 20px;
            margin-right: 5px;
            top:2px;
            path {
                fill: ${({ theme }) => theme.color.dark};
            }
        }
        }
        .orenta-menu-hamburger {
            z-index: 1;
            position: absolute;
            display: block;
            width: 25px;
            height: 80px;
            background: ${({ theme }) => theme.color.white};
            right: -25px;
        }
    }
`;
