// icon.js
import React from "react";
import IcoMoon from "react-icomoon";
const iconSet = require("./selection.json");

const Index = (props) => {
  return <IcoMoon iconSet={iconSet} {...props} />;
};

export default Index;
