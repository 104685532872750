import { createSlice } from '@reduxjs/toolkit';
import { getAllTimeZones } from './thunks';
import { TimeZonesState } from './state';

// Initial state for Redux store:
export const initialStateTimeZones: TimeZonesState = {
  timezones: [],
  loading: false,
};

const timeZoneSlice = createSlice({
  name: 'time_zones',
  initialState: initialStateTimeZones, // Define initial state
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTimeZones.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTimeZones.fulfilled, (state, action) => {
      state.timezones = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllTimeZones.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// Export actions generated by "createSlice()":
// export const { } = timeZoneSlice.actions;

// Export reducer generated by "createSlice()":
export default timeZoneSlice.reducer;
