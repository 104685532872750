import React from 'react';
import Orchestrator from './orchestrator';
import Template from 'src/components/common/Template';
import Layout from 'src/components/Layout';
import Information from './information';
import Beneficios from './Beneficios/Beneficios';

const Index = () => {

    return (
      <Layout>  
        <Template backgroundColor={'#006B48'}>
          {{
            secondaryChild: <Orchestrator />,
            principalChild: <Information />,
          }}
        </Template>
        <Beneficios />
      </Layout>
    );
  
};
export default Index;
