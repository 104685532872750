import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSyles } from './requestDemoS.styles';
import { login } from '../../../redux/auth/thunks';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../common/Loading';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import { Buttons, ContentRequest, Header2,ContentImgHappy,ContentSuccessText,Information} from './information.styles';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';

const RequestDemoS = () => {
  const classes = useSyles({ colorHeader: '#FFFFF' });
  const navigate = useNavigate();
  
  const goToLogin = () => {
    navigate(`/`, { replace: true });
  };

  return (
    <ContentRequest>
      <GridContainer p-lg={'0px'} p-sm={'0px'}>
        <GridItem lg={12} >
              <Header2 color={'#6375B6'} align={'left'}><div>Compártenos tu información y un asesor se comunicará contigo.</div></Header2>
        </GridItem>
      </GridContainer>
      <GridContainer p-lg={'0'} p-sm={'0'} >
        <GridItem lg={6} md={4}>
        <ContentImgHappy>  
          <img src='/images/Auth/RequestDemo/Pantalla4_feliz.png' />
        </ContentImgHappy>
        </GridItem>
        <GridItem lg={6} md={4}>
          <GridContainer p-lg={'0px'} p-sm={'0px'}>
          <GridItem lg={12} >
          <ContentSuccessText>
            <p className={"title"}>¡Solicitud enviada con éxito!</p>
            <p className={"subtitle"}>Felicidades, pronto uno de nuestros asesores estará en contacto contigo.</p>
          </ContentSuccessText>
          </GridItem>
          <GridItem lg={12} >
              <Buttons>
                <Button
                  size='medium'
                  variant='contained'
                  onClick={()=>goToLogin()}
                >
                  <span >Volver a la pagina principal</span>
                </Button>
                
              </Buttons>
          </GridItem>
          
        </GridContainer>
        </GridItem>
      </GridContainer>   
      <Information>
        <p className={"information1"}> Al enviar este formulario, estás aceptando los términos y condiciones de Orenta</p>
      </Information>
         
    </ContentRequest>
  );

};
export default RequestDemoS;
