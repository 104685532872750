import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow, IconButton, Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { BiLinkExternal } from 'react-icons/bi';

const Company = ({ company }) => {
  const { name, ct_companies_status, id, observations, problems, domain } =
    company;
  const navigate = useNavigate(); // habilitar navigate para redirección
  
  // función que redirige de forma programada
  const redirectEdition = (id) => {
    navigate(`/companies/edit/${id}`, { replace: true });
  };

  return (
    <TableRow key={id}>
      <TableCell component='th' scope='row'>
        {id}
      </TableCell>
      <TableCell>
        {name}
      </TableCell>
      <TableCell>{ct_companies_status}</TableCell>
      <TableCell>
        {problems?.length > 0 && (
          <div className='bg-blue-100 text-black p-3 rounded-lg'>
            {problems.map((item) => {
              if (Number(item.problem_id) === 7) {
                return (
                  <p
                    key={item.id}
                    className={problems.length >= 2 ? 'pt-4' : ''}
                  >
                    <span className='font-bold'>Observación:</span> <br />{' '}
                    {observations}
                  </p>
                );
              } else {
                return <p key={item.id}>* {item.description}</p>;
              }
            })}
          </div>
        )}
        {!problems?.length && !!observations && (
          <div className='bg-blue-100 text-black p-3 rounded-lg'>
            <p>
              <span className='font-bold'>Observación:</span> <br />{' '}
              {observations}
            </p>
          </div>
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ textAlign: 'center', width: 'max-content', margin: 'auto' }}>
          <p>{domain}</p>
        </Box>
      </TableCell>
      <TableCell align='right'>
        <IconButton
          aria-label='editar'
          size='medium'
          onClick={() => redirectEdition(company.id)}
        >
          <Edit color='action' />
        </IconButton>
      </TableCell>
      
    </TableRow>
  );
};

export default Company;
