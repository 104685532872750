// Libraries.
import styled from 'styled-components';

export const WrapperFooterOrenta = styled.footer`
    background-color: ${({ theme }) => theme.color.green3};
    padding-bottom: 81px;
    padding-top: 40px;
    color:${({ theme }) => theme.color.white};
`;

export const ContentFooterOrenta = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin: auto;
    color:${({ theme }) => theme.color.white};
    @media ${({ theme }) => theme.screenSizes.tablet} {
        max-width: calc(100% - 64px);
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        max-width: 1254px;
    }
    @media ${({ theme }) => theme.screenSizes.laptopL} {
        max-width: 1328px;
    }
`;

export const WrapperApps = styled.div`
    color:${({ theme }) => theme.color.white};
    .logo{
        margin-bottom: 20px;
        img {
            width: auto;
            height: 34px;
            fill: ${({ theme }) => theme.color.white};
        }
    }
    .about {
        font-size: 16px;
        line-height: 24px;
        padding-right: 0px;
        @media ${({ theme }) => theme.screenSizes.laptop} {
            padding-right: 16px;
        }
    }
    .apps-links {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 16px;
        a {
            margin-right: 16px;
            &.apps{
                width: 138px;
                height: 40px;
            }
        }
        @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
            padding-bottom: 0px;
            justify-content: right;
        }
        @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
            justify-content: left;
        }
    }
    @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
        display: block;
    }
`;

export const WrapperLinks = styled.div`
    color:${({ theme }) => theme.color.white};
    font-size: 16px;
    line-height: 18.75px;
    div {
        padding-bottom: 16px;
    }
    .subtitle {
        font-weight: 700;
        @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
            margin-top: 0px;
        }
    }
    li {
        margin-top: 24px;
        a {
            font-weight: 400;
            color: ${({ theme }) => theme.color.white};
        }
        .schedule {
            margin: 0px;
            margin-top: 8px;
            line-height: 24px;
            span::before {
                @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
                    content: ' ';
                    display: block;
                }
                @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
                    content: none;
                }
            }
        }
        .phone,
        .mail {
            font-weight: 700;
            color: ${({ theme }) => theme.color.white};
            text-decoration: underline;
        }
    }
    .social-networks {
        margin-top: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        svg {
            height: 20px;
            width: 20px;
            margin-right: 80px;
            path {
                fill: ${({ theme }) => theme.color.white};
            }
        }
        @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
            justify-content: left;
            margin-top: 0px;
            svg {
            height: 20px;
            width: 20px;
            margin-right: 20px;
            path {
                fill: ${({ theme }) => theme.color.white};
            }
        }
        }
        @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
            justify-content: left;
            margin-top: 0px;
            svg {
            height: 20px;
            width: 20px;
            margin-right: 20px;
            path {
                fill: ${({ theme }) => theme.color.white};
            }
        }
        }
    }
    @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
        display: grid;
        grid-template-columns: repeat(3, 1fr) 1.1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 8px;
        grid-row-gap: 0px;
    }
`;

export const WrapperCopyright = styled.div`
    border-top: 1px solid rgba(243, 240, 240, 0.1);
    padding-top: 32px;
    text-align: center;
    .copyright {
        font-size: 14px;
        line-height: 22px;
        display: contents;
        vertical-align: top;
        padding-right: 8px;
    }
    figure {
        display: inline-block;
        padding-left: 8px;
        margin: 0 ;
        width: 20px;
        height: 12px;
    }
    .options {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 8px;
        height: 100%;
        a {
            font-size: 14px;
            line-height: 16.4px;
            font-weight: 500;
            color: ${({ theme }) => theme.color.white};
            text-decoration: underline;
            white-space: nowrap;
            margin: 0 10px;
        }
        .last-option {
            grid-area: 2 / 1 / 3 / 3;
            @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
                grid-area: 1 / 3 / 2 / 4;
            }
        }
        @media only screen and ${({ theme }) => theme.screenSizes.tablet} {
            /* grid-template-columns: repeat(3, 1fr); */
            display: flex;
            justify-content: center;
        }
        @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
            margin-top: 4px;
            justify-content: center;
        }
    }
    

    @media only screen and ${({ theme }) => theme.screenSizes.laptop} {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`;
