import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import Intro from "./intro";
import Menu from "./menu";
import { Opinion, Question } from "./data";
import Informative from "./informative";
import QuestionOpinion from "./QuestionOpinion";
import Introduction from "../Introduction";
import Retroalimentation from "./retroalimentation";
import SuccessPerception from "./successPerception";
import Review from "./review";
import { saveTracking } from "src/redux/content/thunks";

interface Template {
  id: number;
  question: string;
  description: string;
  type_template_id: number;
}

const filterTemplatesByType = (templates: Template[]): Template[] => {
  return templates.filter((template) => template.type_template_id === 5);
};

const Index = () => {
  const { content, auth } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();
  const [configuration, setConfiguration] = useState<any>({
    index: 0,
    questions: [],
    opinions: [],
    optionSelected: -1,
  });

  useEffect(() => {
    setConfiguration({
      ...configuration,
      questions: filterTemplatesByType(content.templates),
      opinions: filterTemplatesByType(content.templates).map(() => ({
        positive: [],
        negative: [],
        complementary: [],
      })),
    });
  }, [content.templates.length]);

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleContinue = () => {
    setConfiguration({
      ...configuration,
      index: configuration.index + 1,
    });
  };

  const handleOptionClick = (optionSelectedNow: number) => {
    setConfiguration({
      ...configuration,
      optionSelected: optionSelectedNow,
      index: 1,
    });
  };

  const backMenu = () => {
    setConfiguration({
      ...configuration,
      optionSelected: -1,
      index: 0,
    });
  };

  const handleUpdateCurrentOrchestratorIndex = () => {
    dispatch(
      updateCurrentOrchestratorIndex(
        content.currentOrchestratorIndex + configuration.questions.length
      )
    );
  };

  const setOpinions = (updatedOpinions: Opinion[]) => {
    setConfiguration({
      ...configuration,
      opinions: updatedOpinions,
    });
  };

  if (content.templates.length) {
    switch (configuration.index) {
      case 1:
        return (
          <Intro
            handleContinue={handleContinue}
            title={
              configuration.questions[configuration.optionSelected].question
            }
            subtitle={
              configuration.questions[configuration.optionSelected].description
            }
          />
        ); //Básico
      case 2:
        return (
          <QuestionOpinion
            question={configuration.questions[configuration.optionSelected]}
            opinions={configuration.opinions[configuration.optionSelected]}
            onUpdateOpinions={(updatedOpinion: Opinion) => {
              const updatedOpinions = [...configuration.opinions];
              updatedOpinions[configuration.optionSelected] = updatedOpinion;
              setOpinions(updatedOpinions);
            }}
            onContinue={handleContinue}
          />
        ); //QuesitonOpinion
      case 3:
        return <Review handleContinue={handleContinue} />; //Informativo
      case 4:
        return <Informative handleContinue={handleContinue} />; //Informativo
      case 5:
        return (
          <Retroalimentation
            opinions={configuration.opinions}
            setOpinions={setOpinions}
            questionIndex={configuration.optionSelected}
            question={configuration.questions[configuration.optionSelected]}
            onContinue={handleContinue}
          />
        ); //Percepción
      case 6:
        return (
          <SuccessPerception
            question={configuration.questions[configuration.optionSelected]}
            backMenu={backMenu}
            handleUpdateCurrentOrchestratorIndex={
              handleUpdateCurrentOrchestratorIndex
            }
          />
        ); //SuccessPerception
      case 0:
      default:
        return (
          <Menu
            questions={configuration.questions}
            handleOptionClick={handleOptionClick}
          />
        ); //Menu
    }
  } else {
    return <Introduction />;
  }
};

export default Index;
