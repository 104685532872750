import styled from 'styled-components';



export const SectionTools = styled.section`
   background-color: transparent;
   /* margin-bottom: 150px; */
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 65px 92px 32px 92px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 95px 132px 32px 132px;
    }
    .carousel-item-padding-10px{
      padding: 0px 5px 0 0px;
      margin: 5px;
    }
`;

export const Title = styled.div`
    text-align: left;
      /* Title */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    letter-spacing:  -0.48px;
    color: "#104B71";
    padding-bottom: 20px;
    span {
        //color: ${({ theme }) => theme.color.titleSection};
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
      text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      text-align: left;
    }
`;

// Estilos para una tarjeta individual
export const Card = styled.div`
  background: white;
  height: auto;
  min-width: 160px;
  margin-bottom: 48px;
  box-shadow: 0px 1.85589px 3.15501px 0px rgba(0, 0, 0, 0.00), 0px 8.1659px 6.53272px 0px rgba(0, 0, 0, 0.01), 0px 20.04357px 13.02832px 0px rgba(0, 0, 0, 0.01), 0px 38.60244px 25.537px 0px rgba(0, 0, 0, 0.01), 0px 20px 20px 0px rgba(0, 0, 0, 0.02), 0px 10px 20px 0px rgba(0, 0, 0, 0.02);
  img {
    width: -webkit-fill-available;
    height: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  h2{
    padding: 14px;
    /* SubTitle */
    color: #104B71;
    text-align: center;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 140% */
    letter-spacing: -0.42px;

  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  
`;