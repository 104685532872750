import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles({
  container: {
    backgroundSize: 'contain',
    width: '100%',
    height: '100vh',
    display: 'flex',
    maxWidth: '400px',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  logo: {
    width: '250px',
    height: 'auto',
    marginBottom: '8px',
  },
  form: {
    background: '#ffffff',
    width: '100%',
    padding: '24px',
    borderRadius: '16px',
    display: 'grid',
    gap: '24px',
    position: 'relative',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  button: {
    width: '150px',
  },
  input: {
    height: '56px',
  },
  check: {
    color: '#009175',
  },
  info: {
    padding: '8px',
  },
});
