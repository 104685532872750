export enum StatusCode {
  active = 2,
  inactive = 1,
  deleted = 3,
  pending = 4,
}

export enum StatusCodeKey {
  inventario = 1,
  prestada = 2,
  bajaVenta = 3,
  bajaExtravio = 4,
}
