import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useSyles } from "./introduction.styles";
import { useDispatch } from "react-redux";
import { updateStep } from "../../../../redux/content/test/slice";
import CategoriesResults from "./categoriesResults";
import Button from "src/components/common/Button";
import Header from "../../Header";
import { setSelfKnowledge } from "src/redux/auth/slice";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const classes = useSyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { related_contents } = useAppSelector((state: RootState) => state.auth);

  const handleUpdateStep = (newStep: number) => {
    dispatch(updateStep(newStep));
  };

  const goToHome = () => {
    navigate(`/home`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnClose={goToHome} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>¡Terminaste! 👏</p>
          <p className={classes.subtitle}>
            Analizamos tus resultados y creemos que estos temas te pueden
            interesar:.
          </p>
        </div>
        <div className={classes.content}>
          <CategoriesResults enabledButtons={related_contents} />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={() => goToHome()}>
            <span>De acuerdo</span>
          </Button>
          {/* <Button
            backgroundColor="transparent"
            borderColor="#EDEDED"
            onClick={() => handleUpdateStep(5)}
          >
            Modificar Categorias
          </Button> */}
        </div>
      </div>
    </div>
  );
};
export default Categories;
