import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStyles1 } from './step1.styles';
import { registerEmail } from '../../../redux/auth/thunks';
import { useAppSelector } from '../../../redux/hooks';
import { useDispatch } from 'react-redux';
import Loading from '../../common/Loading';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RegisterRequest } from 'src/redux/auth/models/RegisterRequest';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import Button from 'src/components/common/Button';


const Step1 = () => {
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const classes = useStyles1({ colorHeader:"#014951"});
  const classesG = useStylesG({});

  const [success, setSuccess] = useState(false);
  const initialValues = {
    email: '',
  };

  const handlerSubmit = async (values: RegisterRequest) => {
    try{
       await dispatch(
          registerEmail({ ...values})
        )
          .unwrap()
          .then(() => {
            setSuccess(true);
          });
    }catch(error){
      console.log("pasa error");
    }finally {
      setSuccess(false);
    }
  };

  // función que redirige de forma programada
  const backToLogin = () => {
    navigate(`/login`, { replace: true });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('El correo electrónico es un campo requerido'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  return (
    <div className={classesG.container}>
      <Header title={"¡Inicia tu viaje de bienestar!"} 
              subtitle={"Compártenos tu correo electrónico."}
              colorHeader={"#014951"}
              heightHeader={'210px'}
      />
      <div className={classesG.body}>
        <div className={classesG.instructions}>
          <p>Ingresa tus datos para continuar:</p>
        </div>
        <div className={classes.content}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
            id='form-email'
            name='email'
            label='Correo electrónico'
            variant='outlined'
            fullWidth
            value={formik.values.email}
            disabled={loading}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email &&
              Boolean(formik.errors.email) &&
              formik.errors.email
            }
          />

       </form>
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
           <div className={classes.buttons}>
              <Button onClick={()=>backToLogin()}
                backgroundColor='transparent'
                borderColor='#EDEDED'
                textColor='#00E486'
                className={classes.backButton}
              >
                <ArrowBackIcon />
              </Button>
              <Button onClick={onAction} 
              className={classes.createButton}
              >
                <span >Crear cuenta</span>
              </Button>
           </div>
         ) : (
           <Loading height='150px' />
         )}
      </div>
    </div>
  );
};
export default Step1;
