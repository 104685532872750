import { QuestionInterface } from "../modelo";

export const questions: QuestionInterface[] = [
  {
    id: 1,
    text1: "En las últimas semanas",
    text2: "¿Cómo te has sentido?",
    options: ["😊 Feliz", "Triste", "Agotado", "Preocupado","No lo sé"],
    weights: [0,0,0,0,0]
  },
  {
    id: 2,
    text1: "En las últimas dos semanas, que tan frecuentemente has experimentado:",
    text2: "¿Has tenido dificultades con el sueño? Quedándote o permaneciendo dormido, despertándote mucho más temprano de lo necesario o durmiendo en exceso",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [4,3,2,1,0]
  },
  {
    id: 3,
    text1: "En las últimas dos semanas, que tan frecuentemente has experimentado:",
    text2: "¿Has tenido malestares físicos? Dolores de espalda o cabeza, malestares estomacales, taquicardia, mareos o desmayos",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [4,3,2,1,0]
  },
  {
    id: 4,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Me siento bien conmigo mismo",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [0,1,2,3,4]
  },
  {
    id: 5,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Me preocupo mucho",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [4,3,2,1,0]
  },
  {
    id: 6,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Mis emociones son manejables aún cuando se me presentan obstáculos",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [0,1,2,3,4]
  },
  {
    id: 7,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Mis problemas no tienen solución",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [4,3,2,1,0]
  },
  {
    id: 8,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Estoy emocionalmente agotado por mi trabajo",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [4,3,2,1,0]
  },
  {
    id: 9,
    text1: "Con qué frecuencia te identificas con esta frase:",
    text2: "Tengo una relación positiva con mi pareja",
    options: ["Casi siempre", "Muchas veces", "A veces", "Algunas veces", "Casi nunca"],
    weights: [0,1,2,3,4]
  },
  {
    id: 10,
    text1: "",
    text2: "¿Qué te trae a Orenta?",
    options: ["Fortalecer mi autoestima","Manejar preocupaciones o ansiedad","Conocer mis emociones","Enfrentar la tristeza","Regular el estrés", "Reforzar mi relación de pareja"],
    weights: [0,0,0,0,0]
  }
];