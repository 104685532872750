import React from 'react';
import { useStylesG } from './Login/index.styles';
import { useStyles1 } from './CreateAccount/step1.styles';

interface PropsHeader {
  title: string;
  subtitle:string;
  heightHeader:string;
  colorHeader:string;
}

const Header = ({title,subtitle,heightHeader,colorHeader}:PropsHeader) => {
  const classesG = useStylesG({ colorHeader:colorHeader,heightHeader:heightHeader});
  const classes1 = useStyles1({ colorHeader:colorHeader});

  return (
    <div className={classesG.header}>
      <img className={classesG.starts} src="/images/Auth/Ingresar/starts.png" alt="" />
      <img className={classesG.logo} src="/images/Auth/Ingresar/Logo.svg" alt="" />
      <div className={classes1.titles}>
          <p className={classes1.title}>{title}</p>
          <p className={classes1.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
};
export default Header;
