import React from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import ForgotPassword from './forgotPasswordForm';
import SendEmailSuccess from './sendEmailSuccess';
import ChangePassword from './changePasswordForm';

const Index = () => {
  
  const { auth } = useAppSelector((state: RootState) => state);


  switch(auth.register.step){
    case 1: return (<ForgotPassword />);
    case 2: return (<SendEmailSuccess />);
    case 3: return (<ChangePassword />);
    default: return (<ForgotPassword />);
  }
  
};
export default Index;
