import { createSlice } from '@reduxjs/toolkit';
import { getAllProspects,createProspectFull } from './thunks';

// Initial state for Redux store:
export const initialStateProspects = {
  prospects: {
    total: 0,
    prospects: [],
    paginate: {
      current_page: 1,
      items: 0,
      pages: 1,
    },
    success: false,
  },
  register: 
  { 
    step: 1,
  },
  loading: false,
};

const prospectsSlice = createSlice({
  name: 'prospects',
  initialState: initialStateProspects, // Define initial state
  reducers: {
    setProspects: (state: any, action: any) => {
      state.prospects = action.payload;
    },
    updateStep: (state, action) => {
      state.register.step = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProspects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllProspects.fulfilled, (state, action) => {
      state.prospects = action.payload;
      state.loading = false;
    });
    builder.addCase(createProspectFull.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createProspectFull.fulfilled, (state, action) => {
      state.loading = false;
      state.register.step = 2;
    });
    builder.addCase(createProspectFull.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Export actions generated by "createSlice()":
export const { setProspects } = prospectsSlice.actions;

// Export reducer generated by "createSlice()":
export default prospectsSlice.reducer;
