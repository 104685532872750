import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
//Material
import {
  TextField,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Search } from '@material-ui/icons';
//Components
import { useSyles } from './SearchForm.styles';
import { getAllTypekeys } from './../../../redux/typekeys/thunks';

const SearchForm = ({
  submitFilter,
  fields,
  initialValues,
  filter = 'other',
  valuesEstatus = [],
}) => {
  const classes = useSyles();
  const dispatch = useDispatch();
  // función que redirige de forma programada
  const cancelFilter = () => {
    formik.resetForm();
    formik.values.name_value = '';
    submitFilter(initialValues);
  };
  useEffect(() => {
    if (filter === 'k') {
      dispatch(getAllTypekeys(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Acceder al state del store
  const [valueScanner, setValueScanner] = useState('laser');
  const handleChange = (event) => {
    setValueScanner(event.target.value);
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    name_value: yup.string().required('Escribe un valor a buscar'),
  });

  const beforeSubmitFilter = (values) => {
    submitFilter(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => beforeSubmitFilter(values),
  });

  const getFieldSearch = () => {
    switch (formik?.values?.key) {
      case 'status_users_id':
        return (
          <FormControl variant='outlined' size='small'>
            <InputLabel>Estatus</InputLabel>
            <Select
              id='form-status_users_id'
              name='name_value'
              value={formik.values.status_users_id}
              onChange={formik.handleChange('name_value')}
              error={
                formik.touched.status_users_id &&
                Boolean(formik.errors.status_users_id)
              }
              label='Estatus'
              size='small'
              className={classes.select}
            >
              <MenuItem value=''>
                <em>Selecciona el estatus</em>
              </MenuItem>
              {valuesEstatus.map((estatus) => {
                return (
                  <MenuItem
                    key={`status-users-${estatus.key}`}
                    value={estatus.key}
                  >
                    {estatus.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
        return (
          <FormControl variant='outlined' size='small'>
            <InputLabel>Estatus</InputLabel>
            <Select
              id='form-p2p_transfer_status_id'
              name='name_value'
              value={formik.values.p2p_transfer_status_id}
              onChange={formik.handleChange('name_value')}
              error={
                formik.touched.p2p_transfer_status_id &&
                Boolean(formik.errors.p2p_transfer_status_id)
              }
              label='Estatus'
              size='small'
              className={classes.select}
            >
              <MenuItem value=''>
                <em>Selecciona el estatus</em>
              </MenuItem>
              {valuesEstatus.map((estatus) => {
                return (
                  <MenuItem
                    key={`status-keys-${estatus.key}`}
                    value={estatus.key}
                  >
                    {estatus.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case 'created':
        return (
          <TextField
            type='date'
            id='form_created'
            name='name_value'
            variant='outlined'
            size='small'
            className={classes.field}
            value={formik.values.name_value}
            onChange={formik.handleChange}
            error={
              formik.touched.name_value && Boolean(formik.errors.name_value)
            }
            helperText={formik.touched.name_value && formik.errors.name_value}
          />
        );
      default:
        return (
          <TextField
            id='form_name_value'
            name='name_value'
            label='ingresa el valor a buscar'
            variant='outlined'
            size='small'
            className={classes.field}
            value={formik.values.name_value}
            onChange={formik.handleChange}
            error={
              formik.touched.name_value && Boolean(formik.errors.name_value)
            }
            helperText={formik.touched.name_value && formik.errors.name_value}
          />
        );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.container}>
      {fields && (
        <FormControl variant='outlined' size='small'>
          <InputLabel>Buscar por:</InputLabel>
          <Select
            id='form_key'
            name='key'
            className={classes.select}
            value={formik.values.key}
            onChange={formik.handleChange('key')}
            error={formik.touched.key && Boolean(formik.errors.key)}
            label='Buscar por:'
            size='small'
          >
            <MenuItem value=''>
              <em>Selecciona un campo</em>
            </MenuItem>
            {fields.map((field) => {
              return (
                <MenuItem key={`search-options-${field.key}`} value={field.key}>
                  {field.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {false && (
        <FormControl size='small'>
          <FormLabel id='controlled-scanner'>Escanear por:</FormLabel>
          <RadioGroup
            aria-labelledby='controlled-scanner'
            name='controlled-scanner'
            value={valueScanner}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value='laser'
              control={<Radio color='primary' />}
              label='Laser'
            />
            <FormControlLabel
              value='camara'
              control={<Radio color='primary' />}
              label='Cámara'
            />
          </RadioGroup>
        </FormControl>
      )}
      {getFieldSearch()}
      <Button
        id='form_action_find'
        name='action_find'
        className={classes.button}
        color='primary'
        variant='contained'
        onClick={onAction}
      >
        <Search />
      </Button>

      <Button
        className={classes.button}
        variant='contained'
        onClick={cancelFilter}
      >
        Restaurar
      </Button>
    </form>
  );
};

export default SearchForm;
