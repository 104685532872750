import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { GridContainer, GridItem } from "src/components/common/OrentaGrid";
import { Body, Buttons, ContentFormulario, Footer, Header, Name, Subseccion } from "./formulario.styles";
import Loading from "src/components/common/Loading";
import Button from "src/components/common/Button";
import Swal from "sweetalert2";
import { me, updateUser } from "src/redux/auth/apis";

const Formulario = ({ handleSectionClick }: any) => {
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { auth } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    day: "01",
    month: "01",
    year: "2000",
  });

  const initialValues = {
    id: auth?.id,
    name: "",
    nickname: "",
    lastname: "",
    second_lastname: "",
    phone: "",
    birthdate: "2000-01-01T00:00:00",
    gender: "",
    name_company: "",
    department: "",
    rol: "",
    companyEmail: "",
    email: "",
  };

  const [userInfo, setUserInfo] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    me().then((data) => {

      setUserInfo(
        `${data.username ?? ""} ${data.lastname ?? ""} ${data.second_lastname ?? ""}`
      );
      setEmail(data.email);
      formik.setValues({
        ...formik.values,
        name: data.name ?? "",
        nickname: data.nickname ?? "",
        lastname: data.lastname ?? "",
        second_lastname: data.second_lastname ?? "",
        phone: data.phone ?? "",
        birthdate: data.birthdate ?? "",
        gender: data.gender ?? "",
        companyEmail: data.email ?? "",
        department: data.department ?? "",
        rol: data.rol ?? "",
        name_company: data.company?.name ?? "",
      });
      const birthDate = new Date(data.birthdate);
      setSelectedDate({
        day: String(birthDate.getDate()).padStart(2, "0"),
        month: String(birthDate.getMonth() + 1).padStart(2, "0"),
        year: String(birthDate.getFullYear()),
      });
      setSuccess(true);
    });
  }, []);

  const handlerSubmit = async (values: any) => {
    try {
      const fullBirthdate = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T00:00:00`;
      let data = { ...values, birthdate: fullBirthdate };
      const id = data.id;
      delete data.id;
      delete data.email;
      delete data.name_company;
      setSuccess(false);
      await updateUser(id, data);
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "Se guardaron tus datos exitosamente.",
        timer: 3000,
      });
      setSuccess(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocurrió un error :(",
        text: "Por favor, intenta más tarde.",
      });
      setSuccess(true);
    }
  };

  const validationSchema = yup.object({
    name: yup.string().trim().nullable().required("El nombre es requerido"),
    lastname: yup.string().trim().nullable().required("El apellido es requerido"),
    second_lastname: yup.string().trim().nullable().required("El apellido es requerido"),
    phone: yup.string().trim().nullable().required("El teléfono es requerido"),
    gender: yup
      .string()
      .nullable()
      .trim()
      .oneOf(
        ["Masculino", "Femenino", "Otro", "No binario"],
        "Género no válido"
      )
      .required("El género es requerido"),
    birthdate: yup
      .date()
      .nullable()
      .required("La fecha de nacimiento es requerida")
      .max(new Date(), "La fecha de nacimiento no puede ser en el futuro"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  const handleDateChange = (value: string, type: string) => {
    setSelectedDate((prevState) => ({
      ...prevState,
      [type]: value,
    }));
    formik.setFieldValue(
      "birthdate",
      `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T00:00:00`
    );
  };

  const onChangePassword = () => {
    handleSectionClick(3);
  };

  if (success) {
    return (
      <ContentFormulario>
        <form onSubmit={formik.handleSubmit}>
          <Header>
            <Name>{userInfo}</Name>
          </Header>
          <Body>
            <GridContainer p-lg={"0"}>
              <GridItem lg={12} md={8}>
                <Subseccion>Información personal</Subseccion>
              </GridItem>
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="form-name"
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  value={formik.values.name}
                  disabled={loading}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name &&
                    Boolean(formik.errors.name) &&
                    formik.errors.name
                  }
                />
              </GridItem>
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="form-nickname"
                  name="nickname"
                  label="Sobrenombre"
                  variant="outlined"
                  fullWidth
                  value={formik.values.nickname}
                  disabled={loading}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nickname && Boolean(formik.errors.nickname)
                  }
                  helperText={
                    formik.touched.nickname &&
                    Boolean(formik.errors.nickname) &&
                    formik.errors.nickname
                  }
                />
              </GridItem>
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="form-lastname"
                  name="lastname"
                  label="Primer apellido"
                  variant="outlined"
                  fullWidth
                  value={formik.values.lastname}
                  disabled={loading}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={
                    formik.touched.lastname &&
                    Boolean(formik.errors.lastname) &&
                    formik.errors.lastname
                  }
                />
              </GridItem>
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="form-second_lastname"
                  name="second_lastname"
                  label="Segundo apellido"
                  variant="outlined"
                  fullWidth
                  value={formik.values.second_lastname}
                  disabled={loading}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.second_lastname &&
                    Boolean(formik.errors.second_lastname)
                  }
                  helperText={
                    formik.touched.second_lastname &&
                    Boolean(formik.errors.second_lastname) &&
                    formik.errors.second_lastname
                  }
                />
              </GridItem>
              <GridItem lg={6} md={4}>
                <TextField
                  id="form-phone"
                  name="phone"
                  label="Teléfono"
                  variant="outlined"
                  fullWidth
                  value={formik.values.phone}
                  disabled={loading}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={
                    formik.touched.phone &&
                    Boolean(formik.errors.phone) &&
                    formik.errors.phone
                  }
                />
              </GridItem>
              <GridItem lg={12} md={8}>
                <Subseccion>Fecha de Nacimiento</Subseccion>
              </GridItem>
              <GridItem lg={4} md={4} sm={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Día</InputLabel>
                  <Select
                    id="day"
                    name="day"
                    value={selectedDate.day}
                    onChange={(e) => handleDateChange(e.target.value as string, "day")}
                    label="Día"
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <MenuItem key={i + 1} value={String(i + 1).padStart(2, "0")}>
                        {String(i + 1).padStart(2, "0")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem lg={4} md={4} sm={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Mes</InputLabel>
                  <Select
                    id="month"
                    name="month"
                    value={selectedDate.month}
                    onChange={(e) => handleDateChange(e.target.value as string, "month")}
                    label="Mes"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <MenuItem key={i + 1} value={String(i + 1).padStart(2, "0")}>
                        {String(i + 1).padStart(2, "0")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem lg={4} md={4} sm={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Año</InputLabel>
                  <Select
                    id="year"
                    name="year"
                    value={selectedDate.year}
                    onChange={(e) => handleDateChange(e.target.value as string, "year")}
                    label="Año"
                  >
                    {Array.from({ length: 100 }, (_, i) => (
                      <MenuItem key={2024 - i} value={String(2024 - i)}>
                        {2024 - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem lg={4} md={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <InputLabel>Género</InputLabel>
                  <Select
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    inputProps={{ id: "gender" }}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    label="Género"
                  >
                    <MenuItem value="">Seleccione</MenuItem>
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Femenino">Femenino</MenuItem>
                    <MenuItem value="Otro">Otro</MenuItem>
                    <MenuItem value="No binario">No binario</MenuItem>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <span
                      className={
                        "error MuiFormHelperText-root MuiFormHelperText-contained Mui-error "
                      }
                    >
                      {formik.errors.gender}
                    </span>
                  )}
                </FormControl>
              </GridItem>
              {/* Empresa */}
              <GridItem lg={6} md={4} sm={4}>
                <TextField
                  id="company"
                  name="name_company"
                  label="Empresa"
                  variant="outlined"
                  fullWidth
                  value={formik.values.name_company}
                  disabled={true}
                  onChange={formik.handleChange}
                />
              </GridItem>
              {/* Área */}
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="department"
                  name="department"
                  label="Área"
                  variant="outlined"
                  fullWidth
                  value={formik.values.department}
                  onChange={formik.handleChange}
                />
              </GridItem>
              {/* Rol */}
              <GridItem lg={6} md={4} sm={2}>
                <TextField
                  id="rol"
                  name="rol"
                  label="Rol"
                  variant="outlined"
                  fullWidth
                  value={formik.values.rol}
                  onChange={formik.handleChange}
                />
              </GridItem>
              {/* Correo Empresarial */}
              <GridItem lg={6} md={4} sm={4}>
                <TextField
                  id="companyEmail"
                  name="companyEmail"
                  label="Correo Empresarial"
                  variant="outlined"
                  fullWidth
                  value={formik.values.companyEmail}
                  disabled={true}
                  onChange={formik.handleChange}
                />
              </GridItem>
            </GridContainer>
          </Body>
          <Footer>
            <GridContainer p-lg={"0px"}>
              <GridItem lg={12}>
                {!loading ? (
                  <Buttons>
                    <Button
                      backgroundColor="transparent"
                      borderColor="#EDEDED"
                      onClick={onChangePassword}
                    >
                      Cambiar mi contraseña
                    </Button>
                    <Button
                      backgroundColor="transparent"
                      borderColor="#EDEDED"
                      onClick={formik.handleSubmit}
                    >
                      Guardar información
                    </Button>
                  </Buttons>
                ) : (
                  <Loading height="150px" />
                )}
              </GridItem>
            </GridContainer>
          </Footer>
        </form>
      </ContentFormulario>
    );
  } else {
    return <Loading />;
  }
};

export default Formulario;