import React, { useEffect, useState } from "react";
import Information from "./Information/Information";
import Services from "./Services/Services";
import Navigation from "./NavigationBar/Navigation";
import Library from "./Libraries/Library";
import Tools from "./Tools/Tools";
import Profile from "./Profile";
import { getParameterUrl } from "src/helpers/utils";
import { RootState } from "src/domain/models/RootState";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Index = ({ name }: any) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state);
  const [activeSection, setActiveSection] = useState(0);
  const handleSectionClick = (sectionNumber: number) => {
    setActiveSection(sectionNumber);
    // Aquí podrías añadir la lógica para navegar a la sección correspondiente
    // dependiendo de la sección en la que se hizo clic
  };

  useEffect(() => {
    const viewSection = getParameterUrl("view");
    switch (viewSection) {
      case "inicio":
        setActiveSection(1);
        break;
      case "explora":
        setActiveSection(2);
        break;
      case "perfil":
        setActiveSection(3);
        break;
      default:
        setActiveSection(1);
    }
    console.log(auth);
    if (!auth?.self_knowledge_complete && auth?.self_knowledge) {
      navigate(`/test-autoconocimiento`, { replace: true });
    }
  }, []);

  useEffect(() => {
    let view = null;
    if (activeSection == 1) view = "inicio";
    else if (activeSection == 2) view = "explora";
    else if (activeSection == 3) view = "perfil";
    if (view) {
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("view", view);
      window.history.replaceState(null, "", "?" + queryParams.toString());
    }
  }, [activeSection]);

  switch (activeSection) {
    case 1:
      return (
        <>
          <Information />
          <Services />
          <Tools />
          <br />
          <br />
          <br />
          <br />
          <Navigation
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
          />
        </>
      );
    case 2:
      return (
        <>
          <Library />
          <Navigation
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
          />
        </>
      );
    case 3:
      return (
        <>
          <Profile />
          <Navigation
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
          />
        </>
      );
    default:
      return (
        <>
          <Information />
          <Services />
          <Tools />
          <br />
          <br />
          <br />
          <br />
          <Navigation
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
          />
        </>
      );
  }
};
export default Index;
