import React from 'react';
import { ProfileAvatar } from '../ProfileAvatar';
import { WrapperProfileMenu, ArrowButton } from './styles';
import { useAppSelector } from 'src/redux/hooks';
//import useIsLogged from 'hooks/useIsLogged';
//import Icon from 'components/general/Icon';
import { RootState } from 'src/domain/models/RootState';
const ProfileMenu = ({ className, option, onToggle, openLogin, open, _key }) => {
    const { auth } = useAppSelector((state) => state);
    //const { isLogged } = useIsLogged();
    const  isLogged  = false;//useIsLogged();
    const handlerClick = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        onToggle(data);
    };

    return (
        <WrapperProfileMenu
            key={_key}
            onClick={handlerClick}
            data-testid="content"
            className={`${!isLogged && 'nologged'} ${className || ''} orenta-user-menu ${open && 'open'} `}
            
        >
            <div className={`orenta-wrapper-user-menu`}>
                <div data-gtm="profile" className="orenta-profile-menu">
                    <ProfileAvatar />
                    <div className="orenta-profile-avatar-info" data-testid="user-data">
                        <span data-testid="user-name" className={`orenta-menu-item-name ${option.classTitle}`}>
                            <span className="textName">
                                {isLogged && `Hola, ${auth.name}`}
                            </span>
                            <ArrowButton className="orenta-menu-item-icon-arrow" >
                                {/* <Icon icon="orenta-icon-chevron-right" size={14} /> */}
                            </ArrowButton>
                        </span>
                        {isLogged && (
                            <span data-testid="user-email" className={`orenta-menu-item-email`}>
                                {auth.email}
                            </span>
                        )}
                    </div>
                    {!isLogged && (
                        <span className="login-signin" data-testid="login-signin">
                            <a data-gtm="ingresar" data-testid="anchor-login" rel="nofollow" href="/login" onClick={() => openLogin('Ingresar')} title="Ingresar">
                                Ingresar
                            </a>
                            o
                            <a data-gtm="crear-cuenta" data-testid="anchor-signin" rel="nofollow" href="/registro" onClick={() => openLogin('Registrarse')} title="registrarse o crear cuenta">
                                Crear cuenta
                            </a>
                        </span>
                    )}
                </div>
                {option?.options?.length > 0 && (
                    <ArrowButton className="orenta-menu-item-icon-arrow" data-testid="arrow-options" >
                        <span className="right"></span>
                    </ArrowButton>
                )}
                {option.labelName ? <span className={`orenta-menu-item-class-label ${option.classLabel}`}> {option.labelName}</span> : null}
            </div>
        </WrapperProfileMenu>
    );
};

export default ProfileMenu;
