import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MenuList, MenuItem, Drawer, ListItemIcon } from '@material-ui/core';
import {
  People,
  Ballot,
  Business,
} from '@material-ui/icons';
import { useSyles } from './styles';
import { SystemActions } from '../../../domain/constants/SystemActions';
import { RolePermissions } from '../../../domain/constants/RolePremissions';

const Menu = ({ variant, open, onClose }) => {
  const { type } = useSelector((state) => state.auth);
  const classes = useSyles();

  const listMenu = [
    {
      label: 'Dashboard',
      link: '/',
      Icon: Ballot,
      permission: 0,
    },
    {
      label: 'Empresas',
      link: '/companies',
      Icon: Business,
      permission: SystemActions.read_companies,
    },
    {
      label: 'Usuarios',
      link: '/users',
      Icon: People,
      permission: SystemActions.read_users,
    },
  ];

  const currentLocation = window.location.pathname;
  return (
    <Drawer
      className={classes.menu}
      open={open}
      onClose={onClose ? onClose : null}
      variant={variant}
    >
      <MenuList key={`menu-items`} className={classes.menu}>
        <div className={classes.toolbar} key={'toolbar-key'}></div>
        {listMenu.map(({ Icon, link, label, permission }, index) => {
          const isSelected = currentLocation.startsWith(link) && link !== '/';
          return (
            <>
              {(RolePermissions.allowed(type, permission) ||
                permission === 0) && (
                <Link key={`menu-items-${index}`} to={link} color='primary'>
                  <MenuItem
                    key={`menu-item-${index}`}
                    className={`${classes.menuItem} !rounded-r-full ${
                      isSelected
                        ? '!bg-[#57A450] !text-white !font-semibold'
                        : ''
                    }`}
                    selected={isSelected}
                  >
                    {!!Icon && (
                      <ListItemIcon>
                        <Icon
                          className={`${classes.iconItem} ${
                            isSelected ? '!text-white' : ''
                          }`}
                        />
                      </ListItemIcon>
                    )}
                    {label}
                  </MenuItem>
                </Link>
              )}
            </>
          );
        })}
      </MenuList>
    </Drawer>
  );
};
export default Menu;
