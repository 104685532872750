import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles((theme) => ({
  menu: {
    width: '150px',
    height: '100%',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
  toolbar: theme.mixins.toolbar,
  menuItem: {
    color: '#57A450',
    height: '90px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'left',
      gap: '16px',
    },
  },
  iconItem: {
    width: '30px',
    height: '30px',
    color: '#57A450',
    margin: 'auto',
  },
}));
