import { combineReducers } from '@reduxjs/toolkit';
import { RootState } from '../domain/models/RootState';
import companiesReducer from './companies/slice';
import prospectsReducer from './prospects/slice';
import usersReducer from './users/slice';
import authReducer from './auth/slice';
import dashboardReducer from './dashboard/slice';
import timeZonesReducer from './time_zones/slice';
import contentTestReducer from './content/test/slice';
import contentReducer from './content/slice';

export const rootReducer: any = combineReducers<RootState>({
  //NOTA: Agregar todos los reducers
  dashboard: dashboardReducer,
  companies: companiesReducer,
  prospects: prospectsReducer,
  users: usersReducer,
  auth: authReducer,
  timeZones: timeZonesReducer,
  contentTest: contentTestReducer,
  content: contentReducer,
});
