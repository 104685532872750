import axios from 'axios';

const clientAxios = axios.create({
  baseURL: process.env.REACT_APP_ENV_API_BASE_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_ENV_API_KEY_BASE_URL,
  },
});

clientAxios.interceptors.request.use((config) => {
  // const selectedWarehouse = localStorage.getItem('selectedWarehouse');
  // if (selectedWarehouse) {
  //   config.headers['warehouse-id'] = JSON.parse(selectedWarehouse)?.id || null;
  // }
  return config;
});

clientAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default clientAxios;
