import React, {  useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import { Card, useStyles, Category, ContentCategories,CategoryText,Aclaration  } from './result.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Paper, Typography, Grid } from '@material-ui/core';
import { updateStep } from '../../../../redux/content/test/slice';
import Button from 'src/components/common/Button';
import Header from '../../Header';
import {results} from './dataDepressionResult';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';

const Result = () => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const { contentTest } = useAppSelector((state: RootState) => state);
  const [success, setSuccess] = useState(false);
  

  const handleUpdateStep = (newStep:number) => {
    dispatch(updateStep(newStep));
  };

  const goToLineaContigo = () => {
    navigate(`/linea-contigo`, { replace: true });
  };

  const goToWhatsapp = () => {
    navigate(`/whatsapp`, { replace: true });
  };

  const goToDigitalTools = () => {
    navigate(`/home?view=explora`, { replace: true });
  };

  const gotoLink = (url:string) => {
    navigate(url, { replace: true });
  }

  const depression = results[contentTest.depresion.nivel_depression - 1];
  const title = depression.title;
  const text1 = depression.text1;
  const text2 = depression.text2;
  const text3 = depression.text3;
  const tools1 = depression.tools1;
  const text4 = depression.text4;
  const tools2 = depression.tools2;
  let nivel = contentTest.depresion.nivel_depression > 5 ? contentTest.depresion.nivel_depression - 5:contentTest.depresion.nivel_depression;
  //nivel = 1;
  const valor = `${ 78*(nivel - 1) + 30}px`;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.body}>
        <div className={classes.content}>
        <div className={classes.titles}>
          <p className={classes.titlePrincipal}>Intensidad del malestar</p>
         <div className={classes.contentPreloader}>
            <img src="./images/content/indicator.svg" style={{
                left:`${valor}`,
                zIndex:20
               }} />
            <div className={classes.bar}
            ></div>
        </div>
        </div>
          <div className={classes.titles}>
            <p className={classes.title}>{title}</p>
            <p className={classes.subtitle}>{text1}</p>
            <p className={classes.subtitle}>{text2}</p>
            <p className={classes.subtitle}>{text3}</p>
          </div>
          <ContentCategories>
            <GridContainer lg={12} p-lg={'0px'} p-sm={'0px'}>
                  {tools1.map((elem, index) => (
                    <>
                    {elem.text === "shortcut" ?
                      <GridItem lg={4} sm={2}>
                        <Card key={index} onClick={()=>gotoLink(elem.url)}>
                          <>
                            {elem.image && elem.image != "" && <img src={elem.image} alt={elem.title} />}
                            {elem.title != "" && <h2>{elem.title}</h2> }
                          </>
                        </Card>
                      </GridItem>
                      :
                      <GridItem key={`grid_cat_${index}`} lg={6} md={4} sm={2} onClick={()=>gotoLink(elem.link)} >
                        <Category key={`cat_${index}`} className={"seleccionado"}>
                        </Category>
                        <CategoryText>{elem.text}</CategoryText>
                      </GridItem>
                    }
                    </>
                  ))}

            </GridContainer>
          </ContentCategories>
          <div className={classes.titles}>
            <p className={classes.subtitle}>{text4}</p>
          </div>
          <ContentCategories>
            <GridContainer lg={12} p-lg={'0px'} p-sm={'0px'}>
                  {tools2.map((elem, index) => (
                      <>
                      {elem.text === "shortcut" ?
                        <GridItem lg={4} sm={2}>
                          <Card key={index} onClick={()=>gotoLink(elem.url)}>
                            <>
                              {elem.image && elem.image != "" && <img src={elem.image} alt={elem.title} />}
                              {elem.title != "" && <h2>{elem.title}</h2> }
                            </>
                          </Card>
                        </GridItem>
                        :
                        <GridItem key={`grid_cat_${index}`} lg={6} md={4} sm={2} onClick={()=>gotoLink(elem.link)} >
                          <Category key={`cat_${index}`} className={"seleccionado"}>
                          </Category>
                          <CategoryText>{elem.text}</CategoryText>
                        </GridItem>
                      }
                      </>
                  ))}
                
            </GridContainer>
          </ContentCategories>
        </div>
      </div>
      <div className={classes.footer}>
       
        <div className={classes.buttons}>
        
        <Button
          backgroundColor='transparent'
          borderColor='#EDEDED'
          onClick={goToDigitalTools}>
            <span className={classes.linksResults}>Regresar</span>    
        </Button>
        <Aclaration>
          Recuerda que esté test no es un diagnóstico, para recibir un diagnóstico es necesario acudir a un profesional de salud mental.
        </Aclaration>
        </div>
      </div>
    </div>
  );
};
export default Result;
