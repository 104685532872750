import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    background: 'white',
    height: '150px',
  },
  gridArea: {
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 48%)',
    gap: '24px',
  },
  avatar: {
    width: '55px',
    height: '55px',
    opacity: '0.9',
    float: 'right',
    '&.primary': {
      background: theme.palette.primary.main,
    },
    '&.secondary': {
      background: theme.palette.secondary.main,
    },
    '&.green': {
      background: '#00C29C',
    },
    '& > svg': {
      width: '35px',
      height: '35px',
    },
  },
  flex: {
    width: '100%',
  },
}));
