import React from "react";
//import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider } from "styled-components";
import theme from "./styles/themeStyles";
import Router from "./Router";
//import Header from './components/common/Header';
// Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import persistor from "./persistor";
import { SnackbarProvider } from "notistack";
import Slide from "@material-ui/core/Slide";
import "moment/locale/es";
import moment from "moment";
import { GoogleOAuthProvider } from "@react-oauth/google";

moment().locale("es");
function App() {
  //console.log(theme.breakpoints.values);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        preventDuplicate={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
      >
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE_AUTH}
            >
              <Router />
            </GoogleOAuthProvider>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
