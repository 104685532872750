import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./apis";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { RegisterRequest } from "./models/RegisterRequest";
import { ILogin } from "./state";

export const forgotPasswordService = createAsyncThunk(
  "Auth/change_password_request",
  async (data: RegisterRequest) => {
    try {
      const res = await API.forgotPasswordService(data);
      return { ...res.data, email: data?.email };
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el correo de recuperación",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const register = createAsyncThunk(
  "Auth/users",
  async (data: RegisterRequest) => {
    try {
      const res = await API.register(data);
      return { ...res, email: data?.email, name: data?.name };
    } catch (error: any) {
      const err = error as any;
      if (err?.code === "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "error",
          title: "Hubo error al registrar el usuario",
          text: error.response.data.message,
        });
        throw error;
      }
      Swal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const registerEmail = createAsyncThunk(
  "Auth/validate_user",
  async (data: RegisterRequest) => {
    try {
      const res = await API.registerEmail(data);
      return {
        ...res,
        email: data?.email,
      };
    } catch (error) {
      const err = error as any;
      if (err.message === "User already registered") {
        Swal.fire({
          icon: "info",
          title: "Usuario ya registrado",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: `
          <a href="/login">Inicia sesión</a>
          `,
          text: "Ya existe una cuenta registrada con ese correo. Intenta nuevamente o prueba iniciar sesión",
        });
        throw error;
      }
      if (err?.code === "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "error",
          title: "No se encontro el correo",
          text: "Procederemos a realizar solicitud",
        });
        throw error;
      }
      Swal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const sendCode = createAsyncThunk(
  "Auth/send_code",
  async (data: any) => {
    try {
      const res = await API.sendCode(data);
      Swal.fire({
        icon: "success",
        title: `Se envió con éxito, checa tu correo`,
      });
      return { ...res.data, email: data?.email };
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el código",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const updatePassword = createAsyncThunk(
  "Auth/change_password",
  async (data: RegisterRequest) => {
    try {
      const res = await API.updatePassword(data);
      await Swal.fire({
        icon: "success",
        title: `Modificada con éxito`,
      });
      return { ...res.data, email: data?.email };
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo poner la contraseña",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const postValidateUserCode = createAsyncThunk(
  "Auth/post_validate_user_code",
  async (data: any) => {
    try {
      const res = await API.postValidateUserCode(data);
      return { ...res.data };
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo validar el código",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const firstLogin = createAsyncThunk(
  "Auth/firstlogin",
  async (data: any) => {
    try {
      const res = await API.firstLogin(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo validar el código",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const login = createAsyncThunk(
  "Auth/login",
  async ({ data, isGoogleAuth, googleCredentialResponse }: ILogin) => {
    try {
      if (isGoogleAuth) {
        const res = await API.googleAuth(googleCredentialResponse);
        return res.data;
      }
      const res = await API.login(data);

      return { ...res.data, email: data?.email };
    } catch (error) {
      const err = error as any;

      if (
        err.response?.data?.error.message.includes(
          "Invalid identifier or password"
        )
      ) {
        if (await API.exists(data?.email))
          Swal.fire({
            icon: "info",
            title: "Credenciales incorrectas",
            text: `La contraseña es incorrecta. Vuelve a intentarlo o haz click en "¿Olvidaste tu contraseña?" para restablecerla`,
          });
        else
          Swal.fire({
            icon: "error",
            title: "Cuenta no encontrada",
            text: `No pudimos encontrar una cuenta asociada a ese correo. Intenta nuevamente o crea una cuenta.`,
          });
        throw error;
      } else if (
        err.response?.data?.error.message.includes(
          "Your account email is not confirmed."
        )
      ) {
        Swal.fire({
          icon: "info",
          title: "Usuario no registrado",
          text: `A esta cuenta le hace falta hacer el proceso de registro. Registrate e intenta de nuevo.`,
        });
        throw error;
      }

      //
      /**/

      // let icon = "info" as SweetAlertIcon;
      // let text = "Por favor, intenta de nuevo";
      // let title = "Acceso Denegado";
      // if (
      //   err.response?.data?.error.message.includes(
      //     "Invalid identifier or password"
      //   )
      // ) {
      //   if (await API.exists(data?.email)) {
      //     text = `La contraseña es incorrecta. Vuelve a intentarlo o haz click en "¿Olvidaste tu contraseña?" para restablecerla`;
      //   } else {
      //     text = `Crea tu cuenta`;
      //     icon = "error";
      //   }
      // }
      // if (
      //   err.response?.data?.error.message.includes(
      //     "Your account email is not confirmed."
      //   )
      // ) {
      //   text = "Crea tu cuenta";
      // }
      // if (err?.code === "ERR_BAD_REQUEST") {
      //   Swal.fire({
      //     icon,
      //     title,
      //     text,
      //   });
      //   throw error;
      // }
      Swal.fire({
        icon: "error",
        title: "Hubo un error en login",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const logout = createAsyncThunk("Auth/logout", async () => {
  try {
    // const res = await API.logout();
    const res = {};
    return res;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Hubo un error en login",
      text: "Por favor, intenta de nuevo",
    });
    throw error;
  }
});

export const resetPassword = createAsyncThunk(
  "Auth/resetPassword",
  async (data: any) => {
    try {
      const intent = await API.resetPassword(data);
      const res = intent.data;
      return res;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hubo un error en login",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);

export const setTestResults = createAsyncThunk(
  "Auth/setTestResults",
  async (data: any) => {
    try {
      const res = await API.setTestResults(data);
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el correo de recuperación",
        text: "Por favor, intenta de nuevo",
      });
      throw error;
    }
  }
);
