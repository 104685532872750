import clientAxios from './../../config/axios';
import clientStrapi from "../../config/strapi";

export const getAllCompanies = ({ filter, pagination }: any) => {
  const filterTmp = filter ?? '';
  const complement = pagination.page
    ? `?page=${pagination.page}&items_per_page=${pagination.rowsPerPage}${filterTmp}`
    : `?${filterTmp}`;
  const url = `/companies${complement}`;
  return clientAxios.get(url);
};

export const getCompanyByNid = ({ nid }: { nid: string }) => {
  const complement = `all=1&nid=${nid}`;
  const url = `/companies?country=${process.env.REACT_APP_ENV_COUNTRY}&${complement}`;
  return clientAxios.get(url);
};

export const getCatalogCompanies = () => {
  const url = `/companies/catalog`;
  return clientAxios.get(url);
};

export const updateCompany = (property: any) => {
  const url = `/companies?country=${process.env.REACT_APP_ENV_COUNTRY}&id=${property.nid}`;
  return clientAxios.put(url, property);
};

export const createCompany = (property: any) => {
  const url = `/companies`;
  return clientAxios.post(url, property);
};

export const getProblems = () => {
  const url = `/problems/?country=${process.env.REACT_APP_ENV_COUNTRY}`;
  return clientAxios.get(url);
};

export const getCatalogRolsAreas = (id: number) => {
  const url = `/companies/catalogs/${id}`;
  return clientAxios.get(url);
};

export const getCompany = (id: number) => {
  const url = `/companies/${id}`;
  return clientAxios.get(url);
};

export const getCompanyByUserId = (userId: string) => {
  const url = `/companies?filters[users][id][$eq]=${userId}`;
  return clientStrapi().get(url);
};

export const getCompanyHelpLine = async (userId : string) => {
  const company = await getCompanyByUserId(userId);
  return company.data.data[0]?.attributes.helpLine;
}