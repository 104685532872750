import clientAxios from './../../config/axios';

export const getAllTypekeys = ({ filter }: any) => {
  const filterTmp = filter ?? '';
  const url = `/type_keys?country=${process.env.REACT_APP_ENV_COUNTRY}&all=1${filterTmp}`;
  return clientAxios.get(url);
};

export const updateTypekey = (typekey: any) => {
  const url = `/type_keys?country=${process.env.REACT_APP_ENV_COUNTRY}&id=${typekey.id}`;
  return clientAxios.put(url, typekey);
};

export const createTypekey = (typekey: any) => {
  const url = `/type_keys`;
  return clientAxios.post(url, typekey);
};
