import React, { useState, useEffect } from 'react';
import OrentaMenuItem from './OrentaMenuItem/OrentaMenuItem';
import menu from './data/mainMenu.json';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ContainerMenu, DesktopNav, HeaderLogo } from './OrentaMenu.styles';
//import { getUtms } from 'helpers/utms.helpers';
import ProfileMenu from './ProfileMenu';
import { useAppSelector } from 'src/redux/hooks';
import { SessionTypes } from 'src/domain/constants/SessionTypes';
import Hamburger from './Hamburger/Hamburger';
import {WrapperMainContainer} from '../../Layout/Main/Main.styles';
import Icon from 'src/components/common/Icon';
import { isMobile, isTablet,isDesktop } from 'react-device-detect';



const site = process.env.NEXT_PUBLIC_REACT_APP_PROPERTIES_SERVICES;

const OrentaMenu = () => {
    const [zindex, setZindex] = useState(10);//theme.zindex.header);
    const { auth } = useAppSelector((state) => state);
    const optionsHeader = [];
    const [profile] = useLocalStorage('profile');
    const [activeAside, updateActiveAside] = useState(false);
    const prepareMenuArrayPermissions = (options, required_type_session) => {
        const optionsTmp = [];
        let indice = 0;
        let indiceHeader = 0;
        options.map((option) => {
            let subM;
            let optionTmp = option;
            if (option.options && option.options.length > 0) {
                subM = prepareMenuArrayPermissions(option.options, required_type_session);
                optionTmp.options = subM;
            }
            if (option.type_session === required_type_session || option.type_session === SessionTypes.All) {
                if (option.itemHeader && isDesktop) {
                    optionsHeader[indiceHeader] = option;
                    indiceHeader++;
                } else {
                    optionsTmp[indice] = option;
                    indice++;
                }
            }
        });
        return optionsTmp;
    };
    const [mainMenu, updateMainMenu] = useState(prepareMenuArrayPermissions(menu, SessionTypes.Logged));
    const handleMenuRight = (open, id) => {
        let res = toggleContent(mainMenu, id, open);
        updateMainMenu(res);
    };

    useEffect(() => {
        //if (!auth?.id) {
            updateMainMenu(prepareMenuArrayPermissions(menu, SessionTypes.Guest));
        //}
    }, [auth]);

    const toggleContent = (options, id, open) => {
        let optionsTmp = [];
        let indice = 0;
        options.map((option) => {
            let subM;
            let optionTmp = option;
            if (option.options && option.options.length > 0) {
                subM = toggleContent(option.options, id, open);
                optionTmp.options = subM;
            }
            if (option.id === id) {
                option.open = open;
            }
            optionsTmp[indice] = option;
            indice++;
        });
        return optionsTmp;
    };
    const openActiveAside = () => {
        let action = 'Abrir';
        if (activeAside) {
            action = 'Cerrar';
            setZindex(10);//theme.zindex.header);
        } else {
            setZindex(102);
        }
        updateActiveAside(!activeAside);
    };
    const openLogin = (option) => {
        let url = '/login?next=' + window.location.href;
        if (option !== 'Ingresar') {
            url = '/create-account?next=' + window.location.href;
        }
        window.location.href = url;
    };
    const openSite = () => {
        let utms = "";//getUtms(window.location + '');
        if (utms !== '') {
            utms = '?' + utms;
        }
        //window.location = `${site}/${utms}`;
    };
    const renderSubMenu = (options, depthLevel = 0, open = true) => {
        let cont = 0;
        const menuOptions = options.map((option, i) => {
            if ((option.title === 'Ingresar' || option.title === 'Crear cuenta') && !isDesktop) {
                return <></>;
            }
            const hasOptions = option.options && option.options.length > 0;
            let subMenu;
            if (hasOptions) {
                const newDepthLevel = depthLevel + 1;
                subMenu = renderSubMenu(option.options, newDepthLevel, option.open);
            }
            cont++;
            return (
                <OrentaMenuItem
                    key={`item_${depthLevel}-${cont}-${i}`}
                    option={option}
                    subMenu={subMenu}
                    handleMenuRight={handleMenuRight}
                    openLogin={openLogin}
                />
            );
        });
        const status = open ? 'open' : '';
        return (
            <div key={'div_' + depthLevel} className={'orenta-submenu-container ' + status}>
                <ul key={'ul_' + depthLevel} className="orenta-menu-nav">
                    {menuOptions}
                </ul>
            </div>
        );
    };

    const handleClick = (optionLocal) => {
        let url = optionLocal.link;
        if (url !== '#') {
            const utms = "";//getUtms(window.location + '');
            if (utms !== '') {
                if (url.indexOf('?') > -1) {
                    url = `${url}&${utms}`;
                } else {
                    url = `${url}?${utms}`;
                }
            }
            if (!auth?.id && optionLocal.login_required) {
                url = '/login?next=' + url;
            }
            window.location = url;
        }
    };

    return (
        <ContainerMenu style={{ zIndex: zindex }} >
            <WrapperMainContainer  >
                <div id="orenta-menu-2019" className="orenta-menu orenta-menu-responsive">
                    <div className="orenta-header">
                        <HeaderLogo className="orenta-logo" >
                            <a
                                href={site}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openSite();
                                }}
                                title="orenta.com"
                            >
                                <div className="logo-lg">
                                    <img width="172" height="21.5" src="/images/home/Logo.svg"  alt="Orenta.com" title="Orenta.com" />
                                </div>
                                <div className="logo-sm">
                                    <img width="32" height="32" src="/images/home/Logo.svg"  alt="Orenta.com" title="Orenta.com" />
                                </div>
                            </a>
                        </HeaderLogo>
                        {isDesktop && (
                            <DesktopNav className="orenta-desktop-nav" >
                                {optionsHeader.map((option, index) => {
                                    return (
                                        <li className={option.classItem} data-gtm={`menu-${option.gtm}`} key={`desktop-navs-${index}`}>
                                            <a
                                                href={option.link}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleClick(option);
                                                }}
                                                title={option.title}
                                                
                                            >
                                              {option.classIcon !== "" && <Icon icon={option.classIcon} /> }  {option.title}
                                            </a>
                                        </li>
                                    );
                                })}
                            </DesktopNav>
                        )}
                        
                        <div className="orenta-menu-hamburger">
                            <Hamburger open={activeAside} onClick={openActiveAside} />
                        </div>
                        <div data-gtm="container-menu" className={!activeAside ? 'orenta-main-menu ' : 'orenta-main-menu active'}>
                            {typeof window !== 'undefined' && (
                                <ProfileMenu
                                    data-testid="submenu"
                                    className={'orenta-menu-item'}
                                    _key={`priflemenu`}
                                    option={{
                                        classTitle: '',
                                        title: 'profile',
                                        classLabel: '',
                                        labelName: '',
                                        classItem: '',
                                        options: [],
                                    }}
                                    onToggle={() => handleMenuRight('1', 'false')}
                                    openLogin={openLogin}
                                    open={true}
                                />
                            )}
                            {mainMenu !== null && renderSubMenu(mainMenu, 0, true)}
                        </div>
                    </div>
                </div>
            </WrapperMainContainer>
        </ContainerMenu>
    );
};

export default OrentaMenu;
