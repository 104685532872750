import React from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { RootState } from 'src/domain/models/RootState';
import Norma035F from './norma035F';
import Norma035S from './norma035S';

const Orchestrator = () => {
  const { prospects } = useAppSelector((state: RootState) => state);
  
  switch(prospects.register.step){
    case 1: return (<Norma035F />);
    case 2: return (<Norma035S />);
    default: return (<Norma035F />);
  }
};
export default Orchestrator;
