import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./index.styles";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/domain/models/RootState";
import { useDispatch } from "react-redux";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { saveAnswer, saveTracking } from "src/redux/content/thunks";
import Button from "src/components/common/Button";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

interface Question {
  id: number;
  text: string;
  text_secundary: string;
  options: string[];
  correctOption: number;
  feedBackOptions: string[];
}

interface Template {
  id: number;
  question: string;
  sub_question: string;
  options: string[];
  response: number;
  feedback_options: string[];
  type_template_id: number;
}

const filterTemplatesByType = (templates: Template[]): Template[] => {
  return templates.filter((template) => template.type_template_id === 8);
};

const Index = () => {
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const { content, auth } = useAppSelector((state: RootState) => state);
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const questions: Question[] = filterTemplatesByType(content.templates).map(
    (temp: Template, index: number) => ({
      id: index + 1,
      text: temp.question,
      text_secundary: temp.sub_question,
      options: temp.options,
      correctOption: temp.response,
      feedBackOptions: temp.feedback_options,
    })
  );

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error en tracking");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleOptionClick = (optionIndex: number) => {
    setSelectedOption(optionIndex);
  };

  const handleContinue = () => {
    setSelectedOption(-1);
    dispatch(
      updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
    );
  };

  const handleBack = () => {
    if (selectedOption === -1) {
      if (content.currentOrchestratorIndex - 1 >= 0) {
        dispatch(
          updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
        );
      } else {
        navigate(-1);
      }
    } else {
      setSelectedOption(-1);
    }
  };

  const renderOptions = () => {
    return content.templates[content.currentOrchestratorIndex].options.map(
      (option: any, index: any) => (
        <div className={classes.row}>
          {selectedOption === index ? (
            <Button onClick={() => handleOptionClick(index)}>{option}</Button>
          ) : (
            <Button
              backgroundColor="transparent"
              borderColor="#EDEDED"
              onClick={() => handleOptionClick(index)}
            >
              {option}
            </Button>
          )}
        </div>
      )
    );
  };

  const renderFeedback = () => {
    if (selectedOption === -1) {
      return (
        <Typography>
          Selecciona una respuesta y haz clic en Continuar.
        </Typography>
      );
    }
    const isCorrect =
      selectedOption ===
      content.templates[content.currentOrchestratorIndex].response;

    return (
      <div className={classes.information}>
        <p className={classes.information1}>
          Tu respuesta es {isCorrect ? "¡correcta!" : "¡incorrecta!"}.
        </p>
        <p className={classes.information2}>
          {
            content.templates[content.currentOrchestratorIndex]
              .feedback_options[selectedOption]
          }
        </p>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.subtitle}>
            {content.templates[content.currentOrchestratorIndex].question}
          </p>
          <p className={classes.title}>
            {content.templates[content.currentOrchestratorIndex].sub_question}
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.form}>
            {selectedOption === -1 ? renderOptions() : renderFeedback()}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button
            backgroundColor={selectedOption == -1 ? "transparent" : ""}
            onClick={handleContinue}
            disabled={selectedOption == -1}
          >
            <span>Siguiente</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
