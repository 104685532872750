// QuestionOpinion.tsx
import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { Question, Opinion } from "./data";
import { useStyles } from "./questionOpinion.styles";
import Button from "src/components/common/Button";
import Header from "../Header";
import { RootState } from "src/domain/models/RootState";
import { useAppSelector } from "src/redux/hooks";
import { useDispatch } from "react-redux";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";

interface QuestionOpinionProps {
  question: Question;
  opinions: Opinion;
  onUpdateOpinions: (updatedOpinion: Opinion) => void;
  onContinue: () => void;
}

const QuestionOpinion: React.FC<QuestionOpinionProps> = ({
  question,
  opinions, // Utiliza la prop opinions en lugar de currentOpinion
  onUpdateOpinions,
  onContinue,
}) => {
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const [currentOpinion, setCurrentOpinion] = useState<Opinion>(opinions);
  const { content } = useAppSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      window.open(`/home`, "_self");
    }
  };
  const handleAddOpinion = (type: "positive" | "negative") => {
    setCurrentOpinion((prevOpinion) => ({
      ...prevOpinion,
      [type]: [...prevOpinion[type], ""],
    }));
  };

  useEffect(() => {
    onUpdateOpinions(currentOpinion);
  }, [currentOpinion]);

  const handleChangeOpinion = (
    type: "positive" | "negative",
    index: number,
    value: string
  ) => {
    setCurrentOpinion((prevOpinion) => ({
      ...prevOpinion,
      [type]: prevOpinion[type].map((opinion, i) =>
        i === index ? value : opinion
      ),
    }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header fnBack={handleBack} />
      </div>
      <div className={classes.body}>
        <div className={classes.titles}>
          <p className={classes.title}>{question.question}</p>
        </div>
        <div className={classes.titles}>
          <p className={classes.title}>Fortalezas</p>
          {currentOpinion.positive.map((opinion, index) => (
            <TextField
              key={`positive-${index}`}
              label={`Fortaleza-${index + 1}`}
              value={opinion}
              variant="outlined"
              onChange={(e) =>
                handleChangeOpinion("positive", index, e.target.value)
              }
              fullWidth
            />
          ))}
          <Button
            backgroundColor="transparent"
            borderColor="#EDEDED"
            onClick={() => handleAddOpinion("positive")}
          >
            Agregar Fortaleza
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.titles}>
            <p className={classes.title}>Debilidades</p>
            {currentOpinion.negative.map((opinion, index) => (
              <TextField
                key={`negative-${index}`}
                label={`Debilidad-${index + 1}`}
                value={opinion}
                onChange={(e) =>
                  handleChangeOpinion("negative", index, e.target.value)
                }
                fullWidth
                variant="outlined"
              />
            ))}

            <Button
              backgroundColor="transparent"
              borderColor="#EDEDED"
              onClick={() => handleAddOpinion("negative")}
            >
              Agregar debilidad
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button onClick={() => onContinue()}>
            <span>Continuar</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuestionOpinion;
