import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Components
import Form from './Form';

// Actions de Redux
import { createCompany } from '../../redux/companies/thunks';

const NewProperties = () => {
  const navigate = useNavigate();
  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch<any>();

  const initialValues = {
    address: '',
    country: process.env.REACT_APP_ENV_COUNTRY,
    observations: '',
  };

  // cuando el usuario haga submit
  const submitNewCompany = (values: any) => {
    // crear el nuevo warehouse
    dispatch(createCompany(values))
      .unwrap()
      .then((res: any) => {
        if (res) {
          navigate('/properties', { replace: true });
        }
      });
  };

  return (
    <Form
      submitCompany={submitNewCompany}
      initialValues={initialValues}
      actionText={'GUARDAR'}
      type={'agregar'}
    />
  );
};

export default NewProperties;
