export const schemaFAQPage = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: '¿Qué es Orenta?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>Orenta es una plataforma en línea diseñada para brindar apoyo y orientación en temas relacionados con la salud mental. Ofrecemos una variedad de recursos y servicios para ayudarte a manejar el estrés, la ansiedad, la depresión y otros desafíos emocionales.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Quiénes son los profesionales de la salud mental en Orenta?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>En Orenta, trabajamos con psicólogos, terapeutas y consejeros con licencia que están capacitados para proporcionar orientación y apoyo en línea. Nuestros profesionales son altamente calificados y tienen experiencia en una amplia gama de áreas de la salud mental.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Cómo funciona la orientación en línea en Orenta?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>Nuestra plataforma ofrece sesiones de orientación en línea a través de videoconferencias seguras y confidenciales. Puedes programar sesiones con un profesional de la salud mental de tu elección y conectarte desde la comodidad de tu hogar.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Cómo puedo empezar?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>Para comenzar, simplemente crea una cuenta en Orenta y completa una breve evaluación para que podamos recomendarte un profesional que se adapte a tus necesidades. Luego, puedes programar tu primera sesión de orientación.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Es seguro y confidencial?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p> Sí, la privacidad y la confidencialidad son fundamentales en Orenta. Nuestra plataforma utiliza medidas de seguridad avanzadas para proteger tus datos y todas las sesiones son confidenciales de acuerdo con las leyes de protección de la salud mental.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Qué tipos de problemas de salud mental se trata en Orenta?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>Nuestros profesionales pueden ayudarte con una amplia variedad de problemas de salud mental, que incluyen ansiedad, depresión, estrés, relaciones, duelo y más. Si tienes preguntas específicas sobre tu situación, no dudes en preguntar.
                </p>`,
            },
        },
        {
            '@type': 'Question',
            name: '¿Cuál es el costo de los servicios en Orenta?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: `<p>El servicio de Orenta es gratuito para los colaboradores y sus dependientes.  El costo total del servicio es cubierto por la empresa.  Si deseas llevar Orenta a tu empresa,  solicita un demo aquí. </p>`,
            },
        },
    ],
};
