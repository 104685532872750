import React, { useState, useEffect } from 'react';
import { GridItem } from 'src/components/common/OrentaGrid/GridItem';
import { GridContainer } from 'src/components/common/OrentaGrid/GridContainer';
import { Card, SectionBeneficios,Title } from './Beneficios.styles';
import Button from '@material-ui/core/Button';

const Beneficios = () => {
  const [activeTab, setActiveTab] = useState(1);

  const cardsData = [
    [
      { title: "Identificar", text: "Identificación de factores de riesgos psicosociales (FRP) y colaboradores que fueron sujetos a acontecimientos traumáticos severos (ATS) a través de las guías de referencia de forma automatizada y seguimiento puntual a cada colaborador(a)." },
      { title: "Analizar", text: "Análisis  e interpretación de resultados obtenidos por colaborador, área y demográficos en magnitud, severidad y cumplimiento con respecto a los límites de la norma. "},
      { title: "Prevenir y Promover", text: "Definición de plan de acción con estrategias específicas para abordar los diferentes factores de riesgo psicosocial (FRP). "},
    ]
  ];

  

  return (
    <SectionBeneficios>
     
      <GridContainer p-lg={'0px'}>
        {cardsData[activeTab - 1].map((card, index) => (
          <GridItem lg={4} >
            <Card key={index}>
              <h2>{card.title}</h2>
              <div
                  dangerouslySetInnerHTML={{__html: card.text}}
                />
              
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <GridContainer p-lg={'0px'}>
          <GridItem lg={12}>
          <Card key={4}>
              <h2>Recuerda!</h2>
              <div>
                <ul>
                  <li>
                  Cumplir con la norma es de carácter obligatorio para todas las empresas. Promueve el bienestar de tus equipos y evita multas. 
                  </li>
                  <li>
                  El cumplimiento de la NOM-035 tiene una vigencia de dos años. Por lo que pasado ese tiempo es indispensable actualizarla.
                  </li>
                </ul>
              </div>
                
            </Card>
          </GridItem>
      </GridContainer>
    </SectionBeneficios>
  );
};

export default Beneficios;
