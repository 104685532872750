import { makeStyles } from '@material-ui/core';

export const useSyles = makeStyles({
  root: {
    flexGrow: '1',
  },
  container: {
    padding: '24px',
  },
  containerHeader: {
    flexGrow: '1',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'Bold',
    color: '#1d1a20',
  },
  button: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-end',
    padding: '8px 16px 0',
  },
});
