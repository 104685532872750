import { QuestionInterface } from "../modelo";

export const questions: QuestionInterface[] = [
  {
    id: 1,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Alegría",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 2,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Interés en la vida",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 3,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Satisfacción",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 4,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que tengo algo importante que contribuir a la sociedad",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 5,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que pertenezco a una comunidad",
    text3: "Un grupo social como el colegio o el vecindario",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 6,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que nuestra sociedad es un buen lugar para personas como yo",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 7,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que las personas son básicamente buenas",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 8,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que la dirección en la que va nuestra sociedad tiene sentido para mi",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 9,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que me gusta la mayor parte de mi personalidad",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 10,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que he tenido buen manejo de mis responsabilidades cotidianas",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 11,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que mis relaciones con los demás han sido cálidas y de confianza",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 12,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que mis experiencias me han planteado desafíos para crecer y llegar a ser mejor persona",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 13,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Confianza al pensar o expresar mis propias ideas y opiniones",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  },
  {
    id: 14,
    text1: "En el último mes con qué frecuencia he sentido",
    text2: "Que mi vida tiene sentido o significado",
    options: ["Todos los días", "Casi todos los días", "2 ó 3 veces por semana", "1 vez por semana", "1 ó 2 veces","Nunca"],
    weights: [5,4,3,2,1,0]
  }
];