import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//Components
import Company from './Company';
import Layout from '../common/Layout';
import Wrapper from '../common/Wrapper';
import SearchForm from '../common/Search/SearchForm';
import TableHeader from '../common/Table/TableHeader';
import Loading from '../common/Loading';
import { useSyles } from '../common/Wrapper/styles';
//Material
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getAllCompanies } from '../../redux/companies/thunks';
import { fieldsFilter, headCells } from './constants';
import { getSorting, stableSort } from 'src/helpers/sorts';

const Companies = (props: any) => {
  const classes = useSyles();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate(); // habilitar navigate para redirección
  const [filter, setFilter] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  // obtener el state
  const { companies, loading } = useSelector((state: any) => state.companies);
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    page: 1,
    total: 1,
    order: 'desc',
    orderBy: 'name',
  });

  const updateDataByPagination = async () => {
    await dispatch(getAllCompanies({ filter, pagination }));
  };

  useEffect(() => {
    pagination.page = 1;
    updateDataByPagination();
    setFirstLoad(false);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: companies.paginate?.items ? companies.paginate.items : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies.paginate]);

  useEffect(() => {
    if (!firstLoad) {
      updateDataByPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.rowsPerPage, pagination.page]);

  // función que redirige de forma programada
  const redirectAdd = () => {
    navigate(`/companies/new`, { replace: true });
  };
  //Tabla
  const emptyRows =
    pagination.rowsPerPage -
    Math.min(
      pagination.rowsPerPage,
      pagination.total - pagination.page * pagination.rowsPerPage
    );
  const { order, orderBy } = props;

  const handleChangePage = (event: any, page: any) => {
    setPagination({
      ...pagination,
      page: ++page,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPagination({
      ...pagination,
      rowsPerPage: event.target.value,
    });
  };

  //Fin de tabla
  //Filtros
  const initialValuesFilter = {
    key: '',
    name_value: '',
  };

  // cuando el usuario haga submit en el formulario de busqueda
  const submitFilterCompanies = (values: any) => {
    //preparar la data para filtrar
    if (values.key !== '' && values.name_value !== '') {
      setFilter(`&${values.key}=${values.name_value}`);
    } else {
      setFilter('');
    }
  };
  const sliceX = 0;
  const sliceY = pagination.rowsPerPage;
  //Fin de los filtros
  return (
    <Layout>
      <Wrapper
        button
        buttonTitle='Agregar propiedad'
        actionTitle={() => redirectAdd()}
      >
        <Grid className={classes.containerHeader} container item spacing={4}>
          <Grid item xs={12} sm={8} md={8} className={classes.button}>
            <SearchForm
              submitFilter={submitFilterCompanies}
              fields={fieldsFilter}
              initialValues={initialValuesFilter}
              filter={'PRO'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} className={classes.button}>
            <Button
              size='medium'
              color='secondary'
              variant='contained'
              onClick={() => redirectAdd()}
            >
              Agregar propiedad
              <Add />
            </Button>
          </Grid>
        </Grid>
        {!loading ? (
          <div>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label='simple table'>
                  <TableHeader cellsList={headCells} />
                  <TableBody>
                    {stableSort(
                      companies.data,
                      getSorting(order, orderBy)
                    )
                      .slice(sliceX, sliceY)
                      .map((company: any) => (
                        <Company key={company.nid} company={company} />
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component='div'
              count={pagination.total}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page - 1}
              labelRowsPerPage='Renglones por página'
              backIconButtonProps={{
                'aria-label': 'Anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'Siguiente',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <Loading />
        )}
      </Wrapper>
    </Layout>
  );
};

export default Companies;
