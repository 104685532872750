import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStyles } from './forgotPasswordForm.styles'
import { TextField } from '@material-ui/core';
import Loading from 'src/components/common/Loading';
import Message from 'src/components/common/Message';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/hooks';
import { RegisterRequest } from 'src/redux/auth/models/RegisterRequest';
import { forgotPasswordService } from 'src/redux/auth/thunks';
import { useStylesG } from '../Login/index.styles';
import Header from '../header';
import Button from 'src/components/common/Button';

const ForgotPassword = () => {
  const classes = useStyles({ colorHeader: '#FFFFF' });
  const classesG = useStylesG({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch<any>();
  const loading = useAppSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const initialValues = {
    email: '',
  };

  const handlerSubmit = (values: RegisterRequest) => {
    dispatch(
      forgotPasswordService({ ...values})
    )
      .unwrap()
      .then(() => {
        setSuccess(true);
      });
  };

  // función que redirige de forma programada
  const backToLogin = () => {
    navigate(`/login`, { replace: true });
  };

  const onAction = () => formik?.handleSubmit();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('El correo electrónico es un campo requerido'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handlerSubmit(values);
    },
  });

  return (
    <div className={classesG.container}>
      <Header title={"¿Olvidaste tu contraseña?"} 
              subtitle={"No te preocupes, ingresa tu correo para recuperar tu contraseña"}
              colorHeader={"#014951"}
              heightHeader={'230px'}
      />
      <div className={classesG.body}>
       
        <div className={classes.content}>

        {!success && (
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <div className={classes.row}>
              <TextField
              id='form-email'
              name='email'
              label='Correo electrónico'
              variant='outlined'
              fullWidth
              value={formik.values.email}
              disabled={loading}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                Boolean(formik.errors.email) &&
                formik.errors.email
              }
          />
            </div>
            {/* <div className='space-y-4'>
              <div>
                <label htmlFor='email' className='block mb-2 text-sm'>
                  Correo electrónico{' '}
                </label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  placeholder='email@orenta.mx'
                  className='w-full px-3 py-3 border rounded-md darkNot:border-gray-700 darkNot:bg-gray-900 darkNot:text-gray-100'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className='text-red-500 mt-1'>{formik.errors.email}</div>
                )}
              </div>
            </div> */}
            <div className='space-y-2'>
              {error && (
                <Message />
              )}
            </div>
            </form>
          )}
        </div>
      </div>
      <div className={classes.footer}>
      {!loading ? (
            <div className={classes.buttons}>
              <Button onClick={onAction} >
                <span >Cambiar mi contraseña</span>
              </Button>
              <Button
                  backgroundColor='transparent'
                  borderColor='#EDEDED'
                  onClick={backToLogin}>
                  Iniciar sesión
                </Button>
              
           </div>
         ) : (
           <Loading height='150px' />
         )}
     
             
        {success && (
          <div>
            <p>
              Revisa tu correo electrónico <b>{formik.values.email}</b>
            </p>
            <Link
              to='/login'
              className='mt-12 w-full flex items-center justify-center px-8 py-3 font-semibold border border-purple-600 text-purple-600 rounded-md darkNot:bg-violet-400 darkNot:text-gray-900'
            >
              Iniciar sesión
            </Link>
          </div>
        )}
        </div>
      </div>
    
  );
};

export default ForgotPassword;
