import styled from 'styled-components';



export const SectionFacilSeguro = styled.section`
   background-color: ${({ theme }) => theme.color.white};
   padding: 32px 16px;
    @media ${({ theme }) => theme.screenSizes.tablet} {
      padding: 65px 92px 32px 92px;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      padding: 95px 132px 32px 132px;
    }

    button {
        border-radius: 30px;
        background: ${({ theme }) => theme.color.greenActions};
        color:${({ theme }) => theme.color.titleSection};
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.42px;
        height: 52px;
        width: 100%;
    }
    .separator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 48px; /* Ajusta la altura del contenedor según tus necesidades */

    }

    .horizontal-line{
        width: 100%; /* Ajusta el ancho de la línea horizontal según tus necesidades */
        border: 0;
        border-top: 2px solid black; /* Ajusta el estilo según tus necesidades */
        opacity: 0.2;
        background: var(--colors-gray,${({ theme }) => theme.color.tableBenefis});
    }
    
`;


export const Title = styled.div`
    text-align: center;
      /* Title */
    font-family: "Antonia H3";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 130% */
    letter-spacing: -1.2px;
    padding-bottom: 30px;
    p {
        color: ${({ theme }) => theme.color.titleSection};
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 2.8px;
        text-transform: uppercase;
    }
    @media ${({ theme }) => theme.screenSizes.tablet} {
      text-align: left;
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      text-align: left;
    }
`;

export const ContentFacilSeguro = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 100%;
  position: relative;
  @media ${({ theme }) => theme.screenSizes.tablet} {
     
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
      
    }

`;

export const Card = styled.div`
  display: none;
  img {
    width: -webkit-fill-available;
    height: auto;
  }
  h2{
    //padding: 22px 12px 20px 12px;
    /* SubTitle */
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
  p{
    color: var(--colors-blu, #6674B2);
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
  div {
    padding: 20px 0px 30px 0px;
    /* H2 Regular */
    color: var(--colors-blu, #6674B2);
    /* H6 Bold */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
    letter-spacing: -0.36px;
  }
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media ${({ theme }) => theme.screenSizes.tablet} {
    display:block;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 10px;
    padding: 20px;
    width: 370px;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    display:block;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 10px;
    padding: 20px;
    width: 370px;
  }

`;

export const Card2 = styled.div`
  display: none;
  img {
    width: -webkit-fill-available;
    height: auto;
  }
  h2{
    //padding: 22px 12px 20px 12px;
    /* SubTitle */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.42px;
  }
  p {
    //padding: 0px 12px 30px 12px;
    /* H2 Regular */
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
    letter-spacing: -0.36px;
  }
  
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .preload{
    margin-top: 10px;
    background-color: #6674B2;
    width: 40%;
    height: 5px;
    border-radius: 10px;
  }

  @media ${({ theme }) => theme.screenSizes.tablet} {
    display:block;
    position: absolute;
    top: 20%;
    right: 0px;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 10px;
    padding: 20px;
    width: 272px;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    display:block;
    position: absolute;
    top: 20%;
    right: 0px;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 10px;
    padding: 20px;
    width: 272px;
  }
`;


export const Card3 = styled.div`
  
  display: none;

  
  p {
    //padding: 0px 12px 30px 12px;
    /* H2 Regular */
    color: var(--colors-gray, #606060);
    /* H3 Regular */
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
  
  transition: box-shadow 0.2s;
  border-radius: 20px;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media ${({ theme }) => theme.screenSizes.tablet} {
    display:block;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 30px 0px 10px 10px;
    position: inherit;
    padding: 20px;
    width: 300px;
    z-index: 3;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    display:block;
    background: white;
    box-shadow: 0px 20px 26px 0px rgba(21, 31, 55, 0.10), 0px -2.17249px 9.77622px 0px rgba(21, 31, 55, 0.03);
    margin: 30px 0px 10px 10px;
    position: inherit;
    padding: 20px;
    width: 300px;
    z-index: 3;
  }

`;


export const PhoneMockup = styled.img`
   width: 100%;

   @media ${({ theme }) => theme.screenSizes.tablet} {
    position: absolute;
    bottom: 10%;
    right: 45px;
    z-index: 2;
    width: inherit;
  }
  @media ${({ theme }) => theme.screenSizes.laptop} {
    position: absolute;
    bottom: 10%;
    right: 45px;
    z-index: 2;
    width: inherit;
  }
`;