import React from "react";
import { GridContainer, GridItem } from "src/components/common/OrentaGrid";
import {
  Category,
  ContentCategories,
  CategoryText,
} from "./CategoriesResults.styles";

interface Props {
  enabledButtons: [any?]; // Arreglo de índices de divs habilitados
}

const MyComponent: React.FC<Props> = ({ enabledButtons }) => {
  return (
    <ContentCategories>
      <GridContainer
        lg={12}
        p-lg={"0px"}
        p-sm={"0px"}
      >
        {enabledButtons.map((category, index) => (
          <GridItem
            lg={6}
            md={4}
            sm={2}
          >
            <Category
              key={index}
              className="seleccionado"
            ></Category>
            <CategoryText>{category.title}</CategoryText>
          </GridItem>
        ))}
      </GridContainer>
    </ContentCategories>
  );
};

export default MyComponent;
