import { Job } from "../Dashboard/Employee/Profile/formulario.styles";

interface JobDescriptionProps {
    department?: string;
    job?: string;
}

const JobDescription = ({ department, job }: JobDescriptionProps) => {
    const hasJobDescription = department || job;

    if (!hasJobDescription)
        return null

    let content = "";
    
    content += job ? `${job.trim()}/a` : "";

    content += department ? ( (content.length > 0 ? " de " : "") + department) : ""

    return (
        <Job>{content}</Job>
    )

}

export default JobDescription