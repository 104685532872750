import clientStrapi from "src/config/strapi";
import clientAxios from "./../../../config/axios";
import { RegisterTestRequest } from "./models/RegisterTestRequest";

export const register = async (data: any) => {
  const url = `/users/${data.user_id}`;
  return clientStrapi().put(url, { test_results: data });
};

export const updateSelfKnowledge = (data: any) => {
  const url = `/users/${data.user_id}`;
  console.log("calling update self");
  return clientStrapi().put(url, { test_results: data });
};

export const updateWelfare = (data: any) => {
  return clientStrapi().post(`/wellness-tests`, { data });
};

export const updateDepression = (data: any) => {
  const url = `/depression`;
  return clientAxios.post(url, data);
};
