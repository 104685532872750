import React from 'react';
import { GridContainer, GridItem } from 'src/components/common/OrentaGrid';
import { ContentImgCel, ContentImgCodBi, DescriptionCBI, Header1, WrapperP } from './information.styles';

const Information = () => {
    return (
        <WrapperP>
            <GridContainer p-lg={'0'} p-sm={'0'} ggap-lg={0}>
                <GridItem lg={7} md={5} >
                    <Header1 >
                        Vive una mejor experiencia de Orenta desde tu celular.
                    </Header1>
                    <DescriptionCBI>
                        Escanea el codigo QR desde tu celular o entra a Orenta.org.mx desde tu celular.
                    </DescriptionCBI>
                    <ContentImgCodBi>
                        <img src="/images/qr-code.svg"/>
                    </ContentImgCodBi>
                </GridItem>
                <GridItem lg={5} md={3}>
                    <ContentImgCel>
                        <img src="/images/phone.png"/>
                    </ContentImgCel>
                </GridItem>
            </GridContainer>
        </WrapperP>
    );
};

export default Information;
