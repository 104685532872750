import { makeStyles } from '@material-ui/core';
import { BiCalendarCheck } from 'react-icons/bi';

// interface StylesProps {
//   colorHeader: string;
//   urlGif:string;
// }
interface StylesProps {
  backgroundColor?: string;
  titleColor?: string;
  titleFontSize?: string;
  titleTextAlign?: any;
  titleWeight?: number;
  subtitleColor?: string;
  subtitleFontSize?: string;
  subtitleWeight?: number;
  subtitleTextAlign?: any;
}

export const useStyles = makeStyles((theme) => ({
  
  container: (props: StylesProps) => ({
    display: 'flex',
    width: '100%',
    height: '100vh',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: props.backgroundColor,
  }),

  header:(props: StylesProps) => ({
    width: 'calc(100vw - 20px)',
    height: 'auto',
    margin:'10px',
    borderRadius: '8px',
    //background: props.colorHeader,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'left', /* Centrado horizontal */
    alignItems: 'center',
    '& > img ': {
      width: '30%',
      height: 'auto',
    },
    
  }),
  root:(props: StylesProps) => ({
    position: 'relative',
    height: '100vh',
    backgroundSize: 'cover',
    //background: props.urlGif ? `url(${props.urlGif}) center center fixed`:'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   // color: props.urlGif ? 'white':'black',
    color: "black",
    textAlign: 'center',
    
  }),
  overlay: (props: StylesProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
   // backgroundColor: props.urlGif ? 'rgba(0, 0, 0, 0.5)':'rgba(0, 0, 0, 0.01)', // Cambia el valor alpha para ajustar el contraste
  }),
  text: {
    zIndex: 1,
  },
  body: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    //backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
    titles:{
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
      padding: '10px 16px',
      flexDirection: 'column',
      gap: '10px',
    },
      title:(props: StylesProps) => ({
        //color: props.urlGif ? 'white':'black',
        color: props.titleColor,
        /* M3/title/medium */
        fontFamily: 'Antonia H3',
        fontSize: props.titleFontSize,
        fontStyle: 'normal',
        fontWeight: props.titleWeight,
        lineHeight: '34px', /* 150% */
        letterSpacing: '0.15px',
        paddingTop:'20px',
        textAlign: props.titleTextAlign
      }),
      subtitle:(props: StylesProps) => ({
          //color: props.urlGif ? 'white':'black',
          color: props.subtitleColor,
          /* M3/label/medium - prominent */
          fontFamily: 'Work Sans',
          fontSize: props.subtitleFontSize,
          fontStyle: 'normal',
          fontWeight: props.subtitleWeight,
          lineHeight: '25px', /* 133.333% */
          letterSpacing: '0.5px',
          textAlign:props.titleTextAlign
      }),

  content: {
    flex:2,
    display: 'flex',
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    //backgroundColor: '#FFF',
    alignItems: 'center',
    '& > img ': {
      width: '100%',
      height: 'auto',
    },
  },

  footer: {
    //backgroundColor: '#FFF',
    color: 'white',
    textAlign: 'center',
    width:'100%',
    
  },

  buttons:{
    width: '100%',
    display: 'grid',
    padding: '16px',
    
  },
  

}));
