import React, { useState, useEffect, useRef } from "react";
import { Container, Paper, Button, Typography, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import Loading from "src/components/common/Loading";
import { RootState } from "src/domain/models/RootState";
import { useDispatch } from "react-redux";
import { useStyles } from "./index.styles";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { saveAnswer, saveTracking } from "src/redux/content/thunks";
import Header from "../Header";

interface Question {
  id: number;
  text: string;
  options: string[];
  correctOption: number;
  videoSrc: string;
}

// const questions: Question[] = [
//   {
//     id: 1,
//     text: "¿Cuál es la capital de Francia?",
//     options: ["Londres", "Berlín", "París", "Madrid"],
//     correctOption: 2,
//     videoSrc: "/videos/video1.mp4", // Ruta del video para esta pregunta
//   },
//   {
//     id: 2,
//     text: "¿Cuál es el océano más grande del mundo?",
//     options: ["Océano Atlántico", "Océano Índico", "Océano Ártico", "Océano Pacífico"],
//     correctOption: 3,
//     videoSrc: "/videos/video2.mp4", // Ruta del video para esta pregunta
//   },
//   {
//     id: 3,
//     text: "¿Cuál es el símbolo químico del oxígeno?",
//     options: ["O", "H", "C", "O2"],
//     correctOption: 0,
//     videoSrc: "/videos/video3.mp4", // Ruta del video para esta pregunta
//   },
// ];

const Video = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const [answers, setAnswers] = useState<number[]>([]);
  const [videoEnded, setVideoEnded] = useState(false);
  const dispatch = useDispatch<any>();
  const classes = useStyles({ colorHeader: "#FFFFF" });
  const { content, auth } = useSelector((state: RootState) => state);

  // Usamos un array de referencias con el tipo HTMLVideoElement
  const videoRefs = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (currentQuestionIndex >= 0) {
      // Esperando que el usuario inicie una pregunta
      const currentVideoRef = videoRefs.current;
      if (currentVideoRef) {
        currentVideoRef.addEventListener("ended", () => {
          setVideoEnded(true);
        });
        currentVideoRef.play();
      }
    } else {
      // Cambio a una nueva pregunta
      const currentVideoRef = videoRefs.current;
      if (currentVideoRef) {
        currentVideoRef.pause(); // Pausar el video
      }
      setVideoEnded(false);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  const handleOptionClick = (optionIndex: number) => {
    setSelectedOption(optionIndex);
  };

  // const handleContinue = () => {
  //   if (selectedOption !== null) {
  //     const newAnswers = [...answers];
  //     newAnswers[currentQuestionIndex] = selectedOption;
  //     setAnswers(newAnswers);

  //     if (currentQuestionIndex < questions.length - 1) {
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //       setVideoEnded(false);
  //       setSelectedOption(null);
  //     }
  //   }
  // };

  const renderOptions = () => {
    return content.templates[content.currentOrchestratorIndex].options.map(
      (option: string, index: number) => (
        <Button
          key={index}
          variant={selectedOption === index ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleOptionClick(index)}
          style={{ margin: "5px" }}
        >
          {option}
        </Button>
      )
    );
  };
  const handleUpdateCurrentOrchestratorIndex = async () => {
    try {
      const data = {
        user: auth.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        template_response:
          content.templates[content.currentOrchestratorIndex].options[
            selectedOption
          ],
        template_response_id: selectedOption,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
      };

      await dispatch(saveAnswer({ ...data }))
        .unwrap()
        .then((res: any) => {
          dispatch(
            updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
          );
        });
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  };

  const renderQuestion = () => {
    if (currentQuestionIndex === -1 || !videoEnded) {
      return null; // Esperando que el usuario inicie una pregunta o hasta que termine el video
    }

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
          background: "rgba(0, 0, 0, 0.5)", // Fondo semitransparente
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Typography variant="body1">
          {content.templates[content.currentOrchestratorIndex].question}
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {renderOptions()}
        </div>
      </div>
    );
  };

  if (content.templates?.length) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.body}>
          <video
            key={1}
            ref={(element) => (videoRefs.current = element)}
            autoPlay
            muted
            style={{
              position: "absolute",
              zIndex: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <source
              src={
                content.templates[content.currentOrchestratorIndex].url_video
              }
              type="video/mp4"
            />
          </video>

          {renderQuestion()}
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleUpdateCurrentOrchestratorIndex}
              //disabled={selectedOption === null}
            >
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Video;
