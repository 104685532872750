import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./index.styles";
import { updateCurrentOrchestratorIndex } from "src/redux/content/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/domain/models/RootState";
import Button from "src/components/common/Button";
import Header from "../Header";
import LazyImage from "src/components/common/Lazy/LazyImage";
import AutoPlayYouTubeVideo from "src/components/common/Lazy/LazyVideo";
import { saveTracking } from "src/redux/content/thunks";
import { useNavigate } from "react-router-dom";

function Template() {
  const dispatch = useDispatch<any>();
  const { content, auth } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);

  let estilos;
  try {
    estilos = JSON.parse(
      content.templates[content.currentOrchestratorIndex].style
    );
  } catch (e) {
    estilos = {
      background: { color: "#FFFFFF" },
      title: {
        color: "#149D90",
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "center",
      },
      subtitle: {
        color: "#104B71",
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
      },
      image: { position: "bottom" },
    };
  }
  const handleUpdateCurrentOrchestratorIndex = () => {
    dispatch(
      updateCurrentOrchestratorIndex(content.currentOrchestratorIndex + 1)
    );
  };
  const videoRefs = useRef<HTMLVideoElement | null>(null);

  const handleBack = () => {
    if (content.currentOrchestratorIndex - 1 >= 0) {
      dispatch(
        updateCurrentOrchestratorIndex(content.currentOrchestratorIndex - 1)
      );
    } else {
      navigate(-1);
      // window.open(`/home`, "_self");
    }
  };
  const classes = useStyles({
    backgroundColor:
      content.templates[content.currentOrchestratorIndex].background_color ??
      estilos?.background?.color ??
      "red",
    titleColor:
      content.templates[content.currentOrchestratorIndex].title_color ??
      estilos?.title?.color ??
      "#104B71",
    titleFontSize: estilos?.title?.fontSize ? estilos.title.fontSize : "24px",
    titleWeight: estilos?.title?.fontWeight ? estilos.title.fontWeight : 700,
    titleTextAlign: estilos?.title?.textAlign
      ? estilos.title.textAlign
      : "center",
    subtitleColor:
      content.templates[content.currentOrchestratorIndex].subtitle_color ??
      estilos?.subtitle?.color ??
      "#104B71",
    subtitleFontSize: estilos?.subtitle?.fontSize
      ? estilos.subtitle.fontSize
      : "16px",
    subtitleWeight: estilos?.subtitle?.fontWeight
      ? estilos.subtitle.fontWeight
      : 500,
    subtitleTextAlign: estilos?.subtitle?.textAlign
      ? estilos.subtitle.textAlign
      : "center",
  });

  useEffect(() => {
    try {
      const data = {
        user: auth.id,
        content: content.id,
        template_id: content.templates[content.currentOrchestratorIndex].id,
        type_template_id:
          content.templates[content.currentOrchestratorIndex].type_template_id,
        position: content.currentOrchestratorIndex + 1,
        total: content.templates.length,
      };
      dispatch(saveTracking({ ...data }));
    } catch (error) {
      console.log("pasa error");
    } finally {
    }
  }, [content.currentOrchestratorIndex]);

  useEffect(() => {
    if (content.templates[content.currentOrchestratorIndex].image)
      try {
        new URL(content.templates[content.currentOrchestratorIndex].image);
        setImageUrl(content.templates[content.currentOrchestratorIndex].image);
      } catch (e) {
        setImageUrl(
          process.env.REACT_APP_STRAPI_BASE_URL +
            content.templates[content.currentOrchestratorIndex].image
        );
      }
    else setImageUrl(null);
  }, [content.templates[content.currentOrchestratorIndex]]);

  return (
    <div
      className={classes.root}
      style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
    >
      {/* <div className={classes.root} style={{    backgroundSize: "contain",backgroundRepeat: 'no-repeat'}} onTouchStart={(e)=>onTouchStart(e)} onTouchMove={(e)=>onTouchMove(e)} onTouchEnd={(e)=>onTouchEnd(e)}> */}
      <div className={classes.overlay} />
      <div className={classes.text}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Header fnBack={handleBack} />
          </div>
          <div className={classes.body}>
            <div className={classes.content}>
              {imageUrl &&
                content.templates[content.currentOrchestratorIndex].topImage &&
                !content.templates[content.currentOrchestratorIndex].video && (
                  <img
                    src={imageUrl}
                    style={{ width: "initial", maxWidth: "90vw" }}
                    alt={""}
                  />
                )}
              {
                content.templates[content.currentOrchestratorIndex].topImage &&
                  content.templates[content.currentOrchestratorIndex].video && (
                    <video
                      key={1}
                      ref={(element) => (videoRefs.current = element)}
                      autoPlay
                      style={{
                        position: "absolute",
                        zIndex: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <source
                        src={
                          content.templates[content.currentOrchestratorIndex]
                            .video
                        }
                        type="video/mp4"
                      />
                    </video>
                  )
                // <AutoPlayYouTubeVideo videoUrl={content.templates[content.currentOrchestratorIndex].video} title="" />
              }
              <div className={classes.titles}>
                <p className={classes.title}>
                  {content.templates[content.currentOrchestratorIndex].title}
                </p>
                <p className={classes.subtitle}>
                  {
                    content.templates[content.currentOrchestratorIndex]
                      .sub_title
                  }
                </p>
              </div>
              {imageUrl &&
                !content.templates[content.currentOrchestratorIndex].topImage &&
                !content.templates[content.currentOrchestratorIndex].video && (
                  <img
                    src={imageUrl}
                    style={{ width: "initial", maxWidth: "90vw" }}
                    alt={""}
                  />
                )}
              {!content.templates[content.currentOrchestratorIndex].topImage &&
                content.templates[content.currentOrchestratorIndex].video && (
                  <video
                    key={1}
                    ref={(element) => (videoRefs.current = element)}
                    autoPlay
                    style={{
                      position: "absolute",
                      zIndex: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <source
                      src={
                        content.templates[content.currentOrchestratorIndex]
                          .video
                      }
                      type="video/mp4"
                    />
                  </video>
                )}
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.buttons}>
              <Button onClick={handleUpdateCurrentOrchestratorIndex}>
                <span>Siguiente</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template;
