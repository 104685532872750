// Libraries.
import styled from 'styled-components';

export const OrentaHamburger = styled.div`
    position: relative;
    right: -10px;
    height: 56px;
    width: 40px;
    margin: 0px;
    border: 0px;
    background-color: transparent;
    border-color: transparent;
    text-shadow: inherit;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active,
    &:focus-within &:visited {
        background-color: transparent;
        border-color: transparent;
        text-shadow: inherit;
        box-shadow: none;
        outline: 0;
    }
    .OrentaLineHamburger {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);
        display: block;
        position: absolute;
        height: 2px;
        background: ${({ theme }) => theme.color.greenPrimary};
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
            top: 19px;
        }
        &:nth-child(2) {
            top: 28px;
        }
        &:nth-child(3) {
            top: 37px;
        }
    }
    &.open {
        .OrentaLineHamburger {
            &:nth-child(1) {
                top: 25px;
                transform: rotate(135deg);
            }
            &:nth-child(2) {
                opacity: 0;
                right: -60px;
            }
            &:nth-child(3) {
                top: 25px;
                transform: rotate(-135deg);
            }
        }
        .OrentaLineHamburger {
            background: ${({ theme }) => theme.color.dark};
            width: calc(100% - 20px);
        }
    }
    @media ${({ theme }) => theme.screenSizes.laptop} {
        display: none;
    }
`;
