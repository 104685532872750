import { useState, useEffect } from "react";
import { GridItem } from "src/components/common/OrentaGrid/GridItem";
import { GridContainer } from "src/components/common/OrentaGrid/GridContainer";
import {
  Card,
  Category,
  SectionLibrary,
  Tab,
  TabsContainer,
} from "./Library.styles";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RootState } from "src/domain/models/RootState";
import { useDispatch, useSelector } from "react-redux";
import { getLibraries } from "src/redux/content/thunks";
import Loading from "src/components/common/Loading";
import LazyImage from "src/components/common/Lazy/LazyImage";
import { getParameterUrl } from "src/helpers/utils";

const Library = () => {
  let [activeTab, setActiveTab] = useState(1);
  const { content } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<any>();
  const [cardsData, setCardsData] = useState<any[]>([]);
  const [tabs, setTabs] = useState<any[]>([]);
  const [tabsLegacyRef, setTabLegacyRef] = useState<any>();

  useEffect(() => {
    dispatch(getLibraries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (content.libraries.categories.length > 0) {
      setCardsData(content.libraries.contents);
      setTabs(content.libraries.categories);
    }
    let tab = getParameterUrl("tab");
    if (tab) setActiveTab(parseInt(tab));
  }, [content.libraries.categories.length]);

  useEffect(() => {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tab", String(activeTab));
    window.history.replaceState(null, "", "?" + queryParams.toString());
  }, [activeTab]);

  useEffect(() => {
    if (!tabsLegacyRef) return;
    let tab = getParameterUrl("tab");
    if (tab) {
      tabsLegacyRef.goToSlide(parseInt(tab) + 1);
    }
  }, [tabsLegacyRef]);

  const navigate = useNavigate();

  const goToUrl = (url: string) => {
    window.open(url, "_self");
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  if (tabs.length > 0) {
    return (
      <SectionLibrary>
        <TabsContainer>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            renderDotsOutside={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            //autoPlay={true}
            //autoPlaySpeed={1000}
            keyBoardControl={true}
            //customTransition="all .5"
            //transitionDuration={3000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={"mobile"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10px"
            partialVisible={false}
            //focusOnSelect={true}
            centerMode={true}
            beforeChange={(nextSlide, { currentSlide }) => {
              if (currentSlide < tabs.length + 1) {
                setActiveTab(currentSlide);
              } else {
                setActiveTab(1);
              }
            }}
            //beforeChange={() => setActiveTab((prevTab) => (activeTab <= 6 ? activeTab++ : 1))}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                onClick={() => setActiveTab(index + 1)}
                style={{
                  borderBottom:
                    activeTab === index + 1 ? "4px solid #606060" : "none",
                }}
              >
                <Category>{tab}</Category>
              </Tab>
            ))}
          </Carousel>
          <GridContainer p-lg={"0px"}>
            {cardsData[activeTab - 1].map(
              (
                card: {
                  id: number;
                  uuid: string;
                  image: string;
                  title: string;
                },
                index: number
              ) => (
                <GridItem lg={4}>
                  <Card
                    key={`library_${index}`}
                    onClick={() => goToUrl(`/contents/${card.uuid}`)}
                  >
                    <LazyImage
                      src={card.image}
                      alt={""}
                    />
                    <div dangerouslySetInnerHTML={{ __html: card.title }} />
                  </Card>
                </GridItem>
              )
            )}
          </GridContainer>
        </TabsContainer>
      </SectionLibrary>
    );
  } else {
    return <Loading />;
  }
};

export default Library;
